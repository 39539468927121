import { SummaryService } from './../shared/services/summary.service';
import { LoginService } from './../shared/services/login.service';
import { PublicationService } from './../shared/services/publication.service';
import { Publication } from './../shared/models/publication';
import { NewSummary } from './../shared/models/new/summary.new';
import { Component, OnInit, Inject, ViewChild, HostListener } from '@angular/core';
import { FormGroup, Validators, FormControl, FormBuilder } from '@angular/forms';
import {
  MatPaginator, MatTableDataSource, MatPaginatorIntl, MatDialogRef, MAT_DIALOG_DATA, MatSort,
} from '@angular/material';
import { User } from '../shared/models/user';
import { DatePipe } from '@angular/common';
import { SummaryCategory } from '../shared/models/summary.category';

@Component({
  selector: 'app-summary-modal',
  templateUrl: './summary-modal.component.html',
  styleUrls: ['./summary-modal.component.scss'],
})
export class SummaryModalComponent implements OnInit {
  @HostListener('wheel', ['$event'])
  handleWheelEvent(event) {
    event.preventDefault();
  }
  
  descriptionFormGroup: FormGroup;
  model = new NewSummary(null, null, null, null, null, null, null);
  summaryCategory: SummaryCategory;
  summaryCategories: SummaryCategory[];
  categoryName: string;

  selectedPublications: string[] = [];
  publications: Publication[];
  confirmedPublications: Publication[];
  dsPublications;
  displayedColumnsPublications = [];
  @ViewChild('paginatorPublications') paginatorPublications: MatPaginator;
  @ViewChild('tablePublications', { read: MatSort }) sortPublications: MatSort;
  dsConfirmedPublications;
  displayedColumnsConfirmedPublications = [];
  @ViewChild('paginatorConfirmedPublications') paginatorConfirmedPublications: MatPaginator;
  @ViewChild('tableConfirmedPublications', { read: MatSort }) sortConfirmedPublications: MatSort;

  selectedUsers: string[] = [];
  users: User[];
  dsUsers;
  displayedColumnsUsers = [];
  @ViewChild('paginatorUsers') paginatorUsers: MatPaginator;
  @ViewChild('tableUsers', { read: MatSort }) sortUsers: MatSort;

  constructor(
    private _formBuilder: FormBuilder,
    private publicationService: PublicationService,
    private loginService: LoginService,
    private summaryService: SummaryService,
    private datePipe: DatePipe,
    public dialogRef: MatDialogRef<SummaryModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.descriptionFormGroup = this._formBuilder.group({
      'subject': ['', Validators.compose([Validators.required, Validators.minLength(2)])],
      'summary': ['', Validators.compose([Validators.required, Validators.minLength(2)])],
      'type': ['', Validators.compose([Validators.required, Validators.minLength(2)])],
    });
  }

  ngOnInit() {
    this.displayedColumnsUsers = ['name', 'selected'];
    this.displayedColumnsPublications = ['title', 'selected'];
    this.displayedColumnsConfirmedPublications = ['title'];
    this.loadSummaryCategories();
    this.loadUsers();
    this.loadPublications();
  }

  loadUsers() {
    this.publicationService
      .getUsers()
      .subscribe(
        users => {
          this.users = users;
          this.dsUsers =
            new MatTableDataSource<User>(this.users);
          this.dsUsers.paginator = this.paginatorUsers;
          this.dsUsers.sort = this.sortUsers;
        },
        err => {
        },
    );
  }

  loadPublications() {
    this.publicationService
      .getPublications(this.loginService.getUserId())
      .subscribe(
        publications => {
          this.publications = publications;
          this.dsPublications =
            new MatTableDataSource<Publication>(this.publications);
          this.dsPublications.paginator = this.paginatorPublications;
          this.dsPublications.sort = this.sortPublications;
        },
        err => {
        },
    );
  }

  loadSummaryCategories() {
    this.summaryService
    .getSummaryCategories()
    .subscribe(getSummaryCategories => {
      this.summaryCategories = getSummaryCategories;
    });
  }

  stepperChange(event) {
    if (event['selectedIndex'] === 3) {
      this.publicationService
        .getPublicationsByIds(this.selectedPublications.join(','))
        // .finally()
        .subscribe(publications => {
          this.confirmedPublications = publications;
          this.dsConfirmedPublications =
            new MatTableDataSource<Publication>(this.confirmedPublications);
          this.dsConfirmedPublications.paginator = this.paginatorConfirmedPublications;
          this.dsConfirmedPublications.sort = this.sortConfirmedPublications;
        });
    }
  }

  onSelectUser(id) {
    const index = this.selectedUsers.indexOf(id.toString());

    if (event.target['checked']) {
      if (index < 0) {
        this.selectedUsers.push(id.toString());
      }
    } else {
      if (index >= 0) {
        this.selectedUsers.splice(index, 1);
      }
    }
  }

  onSelectPublicaton(id) {
    const index = this.selectedPublications.indexOf(id.toString());

    if (event.target['checked']) {
      if (index < 0) {
        this.selectedPublications.push(id.toString());
      }
    } else {
      if (index >= 0) {
        this.selectedPublications.splice(index, 1);
      }
    }
  }

  dropDownSummaryCategoryChange(event) {
    this.categoryName = event.value['name'];
  }

  send() {
    this.model.userId = this.loginService.getUserId();
    this.model.date = new Date();
    this.model.summaryCategoryId = this.summaryCategory.id;
    this.data = {
      send: true,
      summary: this.model,
      userIds: this.selectedUsers,
      publicationIds: this.selectedPublications,
    };
    this.dialogRef.close(this.data);
  }

  save() {
    this.model.userId = this.loginService.getUserId();
    this.model.date = new Date();
    this.model.summaryCategoryId = this.summaryCategory.id;
    this.data = {
      send: false,
      summary: this.model,
      userIds: this.selectedUsers,
      publicationIds: this.selectedPublications,
    };
    this.dialogRef.close(this.data);
  }

  close() {
    this.dialogRef.close();
  }

}

import { Observable } from 'rxjs/Rx';
import { LoginService } from './../shared/services/login.service';
import { EmailService } from './../shared/services/email.service';
import { SummaryModalSendComponent } from './../summary-modal-send/summary-modal-send.component';
import { User } from './../shared/models/user';
import { Publication } from './../shared/models/publication';
import { SummaryService } from './../shared/services/summary.service';
import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Summary } from '../shared/models/summary';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import {
    MatPaginator, MatTableDataSource, MatPaginatorIntl,
    MatSnackBar, MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSort,
} from '@angular/material';
import { RepeatPipe } from './../shared/pipes/repeat.pipe';
import { filter } from 'rxjs/operators';

@Component({
    selector: 'app-summary-detail',
    templateUrl: './summary-detail.component.html',
    styleUrls: ['./summary-detail.component.scss'],
})
export class SummaryDetailComponent implements OnInit, AfterViewInit {

    loading: Boolean = false;
    publicationId: number;

    summaryId: number;
    summary: Summary;

    userCount: number;

    publications: Publication[];
    displayedColumnsPublications = [];
    dsPublications;
    @ViewChild('paginatorPublications') paginatorPublications: MatPaginator;
    @ViewChild('tablePublications', { read: MatSort }) sortPublications: MatSort;

    users: User[];
    displayedColumnsUsers = [];
    dsUsers;
    @ViewChild('paginatorUsers') paginatorUsers: MatPaginator;
    @ViewChild('tableUsers', { read: MatSort }) sortUsers: MatSort;


    constructor(
        private summaryService: SummaryService,
        private router: Router,
        public dialog: MatDialog,
        public location: Location,
        private route: ActivatedRoute,
        public snackBar: MatSnackBar,
        private emailService: EmailService,
        private loginService: LoginService,
    ) {
    }

    ngOnInit() {
        this.summaryId = this.route.snapshot.params['id'];
        this.publicationId = this.route.snapshot.params['pid'];

        if (this.router.url.indexOf('/publication') > -1) {
            if (this.isUserAdmin()) {
                this.router.navigate([`/publication/${this.publicationId}`]);
            } else {
                this.router.navigate([`/publication/${this.publicationId}/preview`]);
            }
        }
        this.displayedColumnsPublications = [
            'eye_id', 'title', 'date', 'bank.name',
            'analyst.fullName', 'pages', 'category.name',
            'subCategory.name', 'company.name', 'rating',
            'views', 'published'];
        this.displayedColumnsUsers = ['eye_id_user', 'name'];

        if (this.publicationId) {
            this.postSummaryView();
        }

    }

    postSummaryView() {
        this.summaryService
            .postSummaryView(this.summaryId, this.publicationId)
            .subscribe(res => {

            }, err => {

            }, () => {
                if (this.isUserAdmin()) {
                    setTimeout(() => this.router.navigate([`/publication/${this.publicationId}/preview`]), 500);
                } else {
                    setTimeout(() => this.router.navigate([`/publication/${this.publicationId}`]), 500);
                }
            });
    }

    ngAfterViewInit() {
        // this.loadUsers();
        // this.loadPublications();
        this.loadSummary();
    }

    loadSummary() {
        this.summaryService
            .getSummaryById(this.summaryId)
            .subscribe(summary => {
                this.summary = summary;
            }, err => {
                this.openSnackBar(err);
            }, () => {
                this.loadUsers();
            });
    }

    loadPublications() {
        this.summaryService
            .getSummaryPublications(this.summaryId)
            .subscribe(publications => {
                this.publications = publications;
                this.dsPublications =
                    new MatTableDataSource<Publication>(publications);
                // this.dsPublications.paginator = this.paginatorPublications;
                setTimeout(() => this.dsPublications.paginator = this.paginatorPublications);
                this.dsPublications.sort = this.sortPublications;
            }, err => {
                this.openSnackBar(err);
            }, () => {
                // this.loadSummary();
            });
    }

    loadUsers() {
        this.summaryService
            .getSummaryUsers(this.summaryId)
            .subscribe(users => {
                this.users = users;
                this.userCount = users.length;
                this.dsUsers =
                    new MatTableDataSource<User>(users);
                // this.dsUsers.paginator = this.paginatorUsers;
                setTimeout(() => this.dsUsers.paginator = this.paginatorUsers);
                this.dsUsers.sort = this.sortUsers;
            }, err => {
                this.openSnackBar(err);
            }, () => {
                this.loadPublications();
            });
    }

    isActive() {
        if (this.summary.status === 'active') {
            return true;
        } else {
            return false;
        }
    }

    edit() {
        this.router.navigate([`/summary/${this.summaryId}/edit`]);
    }

    reSend() {
        const dialog = this.dialog.open(SummaryModalSendComponent, {
            height: '200px',
            width: '500px',
            closeOnNavigation: true,
        });

        dialog.afterClosed()
            .pipe(filter(data => data))
            .subscribe(data => {
                if (data['send']) {
                    this.mail();
                }
            },
                err => {
                    this.openSnackBar(err);
                },
        );
    }

    mail() {

        const body = {
            summaryId: this.summaryId,
            userIds: this.users.map(user => user.id),
            publicationIds: this.publications.map(publication => publication.id),
        };

        this.emailService
            .sendPublications(body)
            .subscribe(
                res => {
                    this.summary.status = 'sent';
                    this.summaryService
                        .updateSummary(this.summaryId, this.summary)
                        .subscribe(summary => { this.summary = summary; });
                },
                err => {
                    
                },
                () => {
                    this.openSnackBar('Resumen renviado');
                });
    }

    backToList() {
        this.location.back();
    }

    openSnackBar(message: string) {
        this.snackBar.open(message, '', {
            duration: 2000,
        });
    }

    isUserAdmin() {
        return this.loginService.isUserAdmin();
    }

}

import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, Validators, FormControl, FormBuilder } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { PublicationService } from '../shared/services/publication.service';
import { User } from '../shared/models/user';
import { ParameterService } from 'app/shared/services/parameter.service';
import { UserType } from 'app/shared/models/user.type';

@Component({
  selector: 'app-user-modal-edit',
  templateUrl: './user-modal-edit.component.html',
  styleUrls: ['./user-modal-edit.component.scss'],
})
export class UserModalEditComponent implements OnInit {

  roles: any;
  form: FormGroup;
  user: User;
  userType: UserType;
  userTypes: UserType[];

  condition: Boolean = true;

  constructor(fb: FormBuilder,
    public sanitizer: DomSanitizer,
    private parameterService: ParameterService,
    private publicationService: PublicationService,
    public dialogRef: MatDialogRef<UserModalEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.user = this.data['user'];

    this.form = fb.group({
      'name': ['', Validators.compose([Validators.required, Validators.minLength(2)])],
      'category': ['', Validators.nullValidator],
      'lastname_Paternal': ['', Validators.compose([Validators.required, Validators.minLength(2)])],
      'lastname_Maternal': ['', Validators.nullValidator],
      'email': ['', Validators.compose([Validators.required, Validators.email])],
      'role': ['', Validators.required],
    });
  }

  ngOnInit() {
    this.loadRoles();
    this.loadUserTypes();
  }

  loadRoles() {
    this.roles = [
      { label: 'Administrador', value: 'Admin' },
      { label: 'Lector', value: 'Reader' },
    ];
  }

  loadUserTypes() {
    this.parameterService
    .getUserTypes()
    .subscribe(userTypes => this.userTypes = userTypes);
  }

  save() {
    this.data = {
      user: this.user,
    };
    this.dialogRef.close(this.data);
  }

  close() {
    this.dialogRef.close();
  }

}

import { ParameterRecommendedActionEditComponent }
  from './../parameter-recommended-action-edit/parameter-recommended-action-edit.component';
import { ParameterUserTypEditComponent } from './../parameter-user-typ-edit/parameter-user-typ-edit.component';
import { ParameterSummaryCategoryEditComponent }
  from './../parameter-summary-category-edit/parameter-summary-category-edit.component';
import { ParameterPublicationTypeEditComponent }
  from './../parameter-publication-type-edit/parameter-publication-type-edit.component';
import { ParameterCurrencyEditComponent } from './../parameter-currency-edit/parameter-currency-edit.component';
import { Http } from '@angular/http';
import { ParameterStockImageModalComponent }
  from './../parameter-stock-image-modal/parameter-stock-image-modal.component';
import { environment } from './../../environments/environment';
import { StockImage } from './../shared/models/stockImage';
import { PublicationType } from './../shared/models/publication.type';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { SummaryCategory } from 'app/shared/models/summary.category';
import { ParameterService } from '../shared/services/parameter.service';
import {
  MatSnackBar, MatDialog,
} from '@angular/material';
import { PublicationService } from '../../app/shared/services/publication.service';
import { LoginService } from '../../app/shared/services/login.service';
import { filter } from 'rxjs/operators';
import { Observable } from 'rxjs/Observable';
import { UserType } from '../shared/models/user.type';
import { ParameterUserTypeModalComponent } from 'app/parameter-user-type-modal/parameter-user-type-modal.component';
import { ParameterSummaryCategoryModalComponent } from
  'app/parameter-summary-category-modal/parameter-summary-category-modal.component';
import { ParameterPublicationTypeModalComponent } from
  '../parameter-publication-type-modal/parameter-publication-type-modal.component';
import { Currency } from '../shared/models/currency';
import { RecommendedAction } from '../shared/models/recommendedAction';
import { ParameterCurrencyModalComponent } from '../parameter-currency-modal/parameter-currency-modal.component';
import { ParameterRecommendedActionModalComponent } from
  '../parameter-recommended-action-modal/parameter-recommended-action-modal.component';
import { ParameterStockimageEditComponent } from '../parameter-stockimage-edit/parameter-stockimage-edit.component';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Idle } from 'idlejs/dist/idle';
import { Router } from '@angular/router';

@Component({
  selector: 'app-parameter-list',
  templateUrl: './parameter-list.component.html',
  styleUrls: ['./parameter-list.component.scss'],
})
export class ParameterListComponent implements OnInit {

  assetsEndPoint: string = `${environment.s3Endpoint}/app/img/stock` ;
  loading: Boolean = false;
  imageFile: File;

  sessionTime: string;
  sessionTimeId: number;

  summaryEmailTextHeader: string;
  summaryEmailTextHeaderId: number;

  contactFormRecipient: string;
  contactFormRecipientId: number;

  summaryCategories: SummaryCategory[];
  userTypes: UserType[];
  publicationTypes: PublicationType[];
  currencies: Currency[];
  recommendedActions: RecommendedAction[];
  stockImages: StockImage[];

  constructor(
    private parameterService: ParameterService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private http: Http,
    private _HttpClient: HttpClient,
    private router: Router,
    private publicationService: PublicationService,
    private loginService: LoginService
  ) { }

  ngOnInit() {
    this.loadSummaryCategories();
    this.loadUserTypes();
    this.loadPublicationTypes();
    this.loadCurrencies();
    this.loadRecommendedActions();
    this.loadStockImages();
    this.loadParameters();

    const idle = new Idle()
    .whenNotInteractive()
    .within(parseInt(localStorage.getItem('timer')))
    .do(() => {
      this.logout();
    })
    .start();
  }

  ngOnDestroy(){
    this.dialog.closeAll();
  }

  saveSessionTime() {
    let newSessionTime = parseInt(this.sessionTime) * 60;
    let sessionTimeOperation: Observable<boolean>;
    sessionTimeOperation = this.parameterService
      .updateParameter(this.sessionTimeId, { id: this.sessionTimeId, name: 'SessionTime', value: newSessionTime });
    sessionTimeOperation.subscribe(
      (result) => {

        //localStorage.removeItem("timer");
        //localStorage.setItem('timer', loginResponse['sessionMinutes']);
        //parseInt(result)   = parseInt(result) / 60;

      },
      err => {
        
      },
      () => {
        this.loadParameters();
      },
    );
  }

  saveSummaryEmailTextHeader() {
    let summaryEmailTextHeaderOperation: Observable<boolean>;
    summaryEmailTextHeaderOperation =
      this.parameterService
        .updateParameter(this.summaryEmailTextHeaderId, {
          id: this.summaryEmailTextHeaderId, name: 'SummaryEmailTextHeader', value: this.summaryEmailTextHeader
        });
    summaryEmailTextHeaderOperation.subscribe(
      () => {
      },
      err => {
        
      },
      () => {
        this.loadParameters();
      },
    );
  }

  saveContactFormRecipient() {
    let contactFormRecipientOperation: Observable<boolean>;
    contactFormRecipientOperation =
      this.parameterService
        .updateParameter(this.contactFormRecipientId, {
          id: this.contactFormRecipientId, name: 'ContactFormRecipient', value: this.contactFormRecipient,
        });
    contactFormRecipientOperation.subscribe(
      () => {
      },
      err => {
        
      },
      () => {
        this.loadParameters();
      },
    );
  }

  loadParameters() {
    this.parameterService
      .getParameters()
      .subscribe(parameters => {
        
        localStorage.removeItem("timer");
        localStorage.setItem('timer', String(parseInt(parameters[0]['value']) / 60));

        const idle = new Idle()
        .whenNotInteractive()
        .within(parseInt(localStorage.getItem('timer')))
        .do(() => {
          this.logout();
        })
        .start();

        this.loading = true;
        const parameterSessionTime = parameters.find(parameter => parameter.name === 'SessionTime');
        const parameterSummaryEmailTextHeader
          = parameters.find(parameter => parameter.name === 'SummaryEmailTextHeader');
        const parameterContactFormRecipient
          = parameters.find(parameter => parameter.name === 'ContactFormRecipient');
        this.sessionTime = String(parseInt(parameterSessionTime.value) / 60);
        this.sessionTimeId = parameterSessionTime.id;
        this.summaryEmailTextHeader = parameterSummaryEmailTextHeader.value;
        this.summaryEmailTextHeaderId = parameterSummaryEmailTextHeader.id;
        this.contactFormRecipient = parameterContactFormRecipient.value;
        this.contactFormRecipientId = parameterContactFormRecipient.id;
      }, () => {
        this.loading = false;
      }, () => {
        this.loading = false;
      });
  }

  logout() {
    this.publicationService.getUser(this.loginService.getUserId())
    .subscribe(user => {
      const _user = user;
      if (!this.loginService.isUserAdmin()) {
        _user.setPreferences = false;
        _user.setIntroduction = false;
        this.publicationService.updateUser(_user.id, _user).subscribe();
      }    
  }, err => {}, () => {
    localStorage.removeItem('token');
    localStorage.removeItem('timer');
    this.router.navigate([`/login/`]);
  });

  }

  loadRecommendedActions() {
    this.parameterService
      .getRecommendedActions()
      .subscribe(recommendedActions => {        
        this.recommendedActions = recommendedActions;
      }, err => {
      }, () => {
        
      });
  }

  loadSummaryCategories() {
    this.parameterService
      .getSummaryCategories()
      .subscribe(summaryCategories => {
        this.summaryCategories = summaryCategories;
      }, () => {
      }, () => {
      });
  }

  loadUserTypes() {
    this.parameterService
      .getUserTypes()
      .subscribe(userTypes => {
        this.userTypes = userTypes;
      }, () => {
      }, () => {
      });
  }

  loadCurrencies() {
    this.parameterService
      .getCurrencies()
      .subscribe(currencies => {
        this.currencies = currencies;
      }, () => {
      }, () => {
      });
  }

  loadPublicationTypes() {
    this.parameterService
      .getPublicationTypes()
      .subscribe(publicationTypes => {
        this.publicationTypes = publicationTypes;
      }, () => {
      }, () => {
      });
  }

  loadStockImages() {
    this.parameterService
      .getSockImages()
      .subscribe(stockImages => {
        this.stockImages = stockImages;
      }, () => {
      }, () => {
      });
  }

  deleteUserType(userTypeId) {
    this.parameterService
      .deleteUserType(userTypeId)
      .subscribe(() => this.loadUserTypes());
  }

  deletePublicationType(publicationTypeId) {
    this.parameterService
      .deletePublicationType(publicationTypeId)
      .subscribe(() => this.loadPublicationTypes());
  }

  deleteSummaryCategory(summaryCategoryId) {
    this.parameterService
      .deleteSummaryCategory(summaryCategoryId)
      .subscribe(() => this.loadSummaryCategories());
  }

  deleteCurrency(currencyId) {
    this.parameterService
      .deleteCurrency(currencyId)
      .subscribe(() => this.loadCurrencies());
  }

  deleteRecommendedAction(recommendedActionId) {
    this.parameterService
      .deleteRecommendedAction(recommendedActionId)
      .subscribe(() => this.loadRecommendedActions());
  }

  deleteStockImage() {
    // this.parameterService
    //   .deleteRecommendedAction(stockImageId)
    //   .subscribe(() => this.loadRecommendedActions());
  }

  openSummaryCategoryDialog() {
    const dialog = this.dialog.open(ParameterSummaryCategoryModalComponent, {
      height: '300px',
      width: '800px',
      closeOnNavigation: true,
    });

    dialog.afterClosed()
      .pipe(filter(data => data))
      .subscribe(data => {
        let summaryCategoryOperation: Observable<boolean>;
        summaryCategoryOperation = this.parameterService.postSummaryCategory(data['summaryCategory']);
        summaryCategoryOperation.subscribe(
          () => {
          },
          err => {
            this.openSnackBar(err);
          },
          () => {
            this.loadSummaryCategories();
          },
        );
      },
    );
  }

  openSummaryCategoryEditDialog(summaryCategory) {
    const dialog = this.dialog.open(ParameterSummaryCategoryEditComponent, {
      height: '300px',
      width: '800px',
      closeOnNavigation: true,
      data: { summaryCategory: summaryCategory }
    });

    dialog.afterClosed()
      .pipe(filter(data => data))
      .subscribe(data => {
        let summaryCategoryOperation: Observable<boolean>;
        summaryCategoryOperation = this.parameterService.updateSummaryCategory(data['id'], data['summaryCategory']);
        summaryCategoryOperation.subscribe(
          () => {
            this.loading = true;
          },
          err => {
            this.loading = false;
          },
          () => {
            this.loading = false;
            this.loadSummaryCategories();
          },
        );
      },
    );
  }

  openUserTypeDialog() {
    const dialog = this.dialog.open(ParameterUserTypeModalComponent, {
      height: '300px',
      width: '800px',
      closeOnNavigation: true,
    });

    dialog.afterClosed()
      .pipe(filter(data => data))
      .subscribe(data => {
        let userTypeOperation: Observable<boolean>;
        userTypeOperation = this.parameterService.postUserType(data['userType']);
        userTypeOperation.subscribe(
          () => {
          },
          err => {
            this.openSnackBar(err);
          },
          () => {
            this.loadUserTypes();
          },
        );
      },
    );
  }

  openUserTypeEditDialog(userType) {
    const dialog = this.dialog.open(ParameterUserTypEditComponent, {
      height: '300px',
      width: '800px',
      closeOnNavigation: true,
      data: { userType: userType },
    });

    dialog.afterClosed()
      .pipe(filter(data => data))
      .subscribe(data => {
        let userTypeOperation: Observable<boolean>;
        userTypeOperation = this.parameterService.updateUserType(data['id'], data['userType']);
        userTypeOperation.subscribe(
          () => {
          },
          err => {
          },
          () => {
            this.loadUserTypes();
          },
        );
      },
    );
  }

  openParameterTypeDialog() {
    const dialog = this.dialog.open(ParameterPublicationTypeModalComponent, {
      height: '300px',
      width: '800px',
      closeOnNavigation: true,
    });

    dialog.afterClosed()
      .pipe(filter(data => data))
      .subscribe(data => {
        let publicationTypeOperation: Observable<boolean>;
        publicationTypeOperation = this.parameterService.postPublicationType(data['publicationType']);
        publicationTypeOperation.subscribe(
          () => {
          },
          err => {
            this.openSnackBar(err);
          },
          () => {
            this.loadPublicationTypes();
          },
        );
      },
    );
  }

  openParameterTypeEditDialog(publicationType) {
    const dialog = this.dialog.open(ParameterPublicationTypeEditComponent, {
      height: '300px',
      width: '800px',
      closeOnNavigation: true,
      data: { publicationType: publicationType }
    });

    dialog.afterClosed()
      .pipe(filter(data => data))
      .subscribe(data => {
        let publicationTypeOperation: Observable<boolean>;
        publicationTypeOperation = this.parameterService.updatePublicationType(data['id'], data['publicationType']);
        publicationTypeOperation.subscribe(
          () => {
          },
          err => {
          },
          () => {
            this.loadPublicationTypes();
          },
        );
      },
    );
  }

  openCurrencyDialog() {
    const dialog = this.dialog.open(ParameterCurrencyModalComponent, {
      height: '300px',
      width: '800px',
      closeOnNavigation: true,
    });

    dialog.afterClosed()
      .pipe(filter(data => data))
      .subscribe(data => {
        let currencyOperator: Observable<boolean>;
        currencyOperator = this.parameterService.postCurrency(data['currency']);
        currencyOperator.subscribe(
          () => {
          },
          err => {
            this.openSnackBar(err);
          },
          () => {
            this.loadCurrencies();
          },
        );
      },
    );
  }

  openCurrencyEditDialog(currency: Currency) {
    const dialog = this.dialog.open(ParameterCurrencyEditComponent, {
      height: '300px',
      width: '800px',
      closeOnNavigation: true,
      data: { currency: currency }
    });

    dialog.afterClosed()
      .pipe(filter(data => data))
      .subscribe(data => {
        let currencyOperator: Observable<boolean>;
        currencyOperator = this.parameterService.updateCurrency(data['id'], data['currency']);
        currencyOperator.subscribe(
          () => {
          },
          err => {
          },
          () => {
            this.loadCurrencies();
          },
        );
      },
    );
  }

  openStockImageDialog() {
    const dialog = this.dialog.open(ParameterStockImageModalComponent, {
      height: '650px',
      width: '600px',
      closeOnNavigation: true,
    });

    dialog.afterClosed()
      .pipe(filter(data => data))
      .subscribe(data => {
        const headers = new HttpHeaders({ 'Authorization':'Bearer '+localStorage.getItem('token') });
        const formData = new FormData();
        this.imageFile = data['imgFile'];
        formData.append('file', this.imageFile);
        formData.append('name', data['stockImageName']);
        this._HttpClient.post(`${environment.devApiEndpoint}/parameter/stockimage`, formData, {headers})
          .map(response => response)
          .catch((error: any) => Observable.throw(error))
          .subscribe(() => this.loadStockImages());
      }, err => {
      }, () => {
        this.loadStockImages();
      });
  }

  openRecommendedActionDialog() {
    const dialog = this.dialog.open(ParameterRecommendedActionModalComponent, {
      height: '300px',
      width: '800px',
      closeOnNavigation: true,
    });

    dialog.afterClosed()
      .pipe(filter(data => data))
      .subscribe(data => {
        let recommendedActionOperator: Observable<boolean>;
        recommendedActionOperator = this.parameterService.postRecommendedAction(data['recommendedAction']);
        recommendedActionOperator.subscribe(
          () => {
          },
          err => {
            this.openSnackBar(err);
          },
          () => {
            this.loadRecommendedActions();
          },
        );
      },
    );
  }

  openRecommendedActionEditDialog(recommendedAction) {
    const dialog = this.dialog.open(ParameterRecommendedActionEditComponent, {
      height: '300px',
      width: '800px',
      closeOnNavigation: true,
      data: { recommendedAction: recommendedAction },
    });

    dialog.afterClosed()
      .pipe(filter(data => data))
      .subscribe(data => {
        let recommendedActionOperator: Observable<boolean>;
        recommendedActionOperator = this.parameterService
          .updateRecommendedAction(data['id'], data['recommendedAction']);
        recommendedActionOperator.subscribe(
          () => {
          },
          err => {
          },
          () => {
            this.loadRecommendedActions();
          },
        );
      },
    );
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, '', {
      duration: 2000,
    });
  }

  enableSummaryDelete(summaryCategory: SummaryCategory) {
    let result = false;
    if (summaryCategory.name.indexOf('semanal') < 0) {
      if (summaryCategory['isUsed'] <= 0) {
        result = true;
      }
    }

    return result;
  }

  openStockImageEditDialg(stockImage: StockImage) {
    const headers = new HttpHeaders({ 'Authorization':'Bearer '+localStorage.getItem('token') });
    const dialog = this.dialog.open(ParameterStockimageEditComponent, {
      height: '550px',
      width: '600px',
      closeOnNavigation: true,
      data: { stockImage: stockImage },
    });

    dialog.afterClosed()
      .pipe(filter(data => data))
      .subscribe(data => {
        const formData = new FormData();
        this.imageFile = data['imgFile'];
        formData.append('file', this.imageFile);
        formData.append('name', data['stockImageName']);
        formData.append('id', data['stockImageId']);
        this._HttpClient.put(`${environment.devApiEndpoint}/parameter/stockimage/${data['stockImageId']}`, formData, {headers})
          .map(response => response)
          .catch((error: any) => Observable.throw(error))
          .subscribe(() => this.loadStockImages());
      }, err => {
      }, () => {
        this.loadStockImages();
      });
  }

}

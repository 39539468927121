import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';
import { AuthGuard } from './guards/auth.guard';

export const routes: Routes = [
  { path: 'login', loadChildren: './login.module#LoginModule' },
  { path: 'publication', loadChildren: './publication.module#PublicationModule', canActivate: [AuthGuard] },
  { path: 'catalog', loadChildren: './catalog.module#CatalogModule' },
  { path: 'summary', loadChildren: './summary.module#SummaryModule', canActivate: [AuthGuard] },
  { path: 'user', loadChildren: './user.module#UserModule' },
  { path: 'parameter', loadChildren: './parameter.module#ParameterModule' },
  { path: 'dashboard', loadChildren: './dashboard.module#DashboardModule', canActivate: [AuthGuard] },
  { path: 'contact', loadChildren: './contact.module#ContactModule', canActivate: [AuthGuard] },
  { path: '**', redirectTo: 'dashboard' },
];

export const routing: ModuleWithProviders = RouterModule.forRoot(routes, { useHash: true });

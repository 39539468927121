import { UserType } from './../shared/models/user.type';
import { Company } from './../shared/models/company';
import { EmailService } from 'app/shared/services/email.service';
import { SummaryService } from './../shared/services/summary.service';
import { LoginService } from './../shared/services/login.service';
import { PublicationService } from './../shared/services/publication.service';
import { Publication } from './../shared/models/publication';
import { Summary } from './../shared/models/summary';
import { Observable } from 'rxjs/Observable';
import { Component, OnInit, Inject, ViewChild, AfterViewInit } from '@angular/core';
import { FormGroup, Validators, FormControl, FormBuilder } from '@angular/forms';
import {
  MatPaginator, MatTableDataSource,
  MatPaginatorIntl, MatDialogRef, MAT_DIALOG_DATA, MatSort,
  MatDialog,
} from '@angular/material';
import { User } from '../shared/models/user';
import { DatePipe } from '@angular/common';
import { SummaryCategory } from '../shared/models/summary.category';
import { Router, ActivatedRoute } from '@angular/router';
import { RequestOptions, Headers, Http, Response } from '@angular/http';
import { environment } from '../../environments/environment';
import { forkJoin } from 'rxjs/observable/forkJoin';
import { filter } from 'rxjs/operators';
import { PublicationModalEditComponent } from '../publication-modal-edit/publication-modal-edit.component';
import { NgModel } from '@angular/forms';
import { ParameterService } from './../shared/services/parameter.service';

@Component({
  selector: 'app-summary-edit',
  templateUrl: './summary-edit.component.html',
  styleUrls: ['./summary-edit.component.scss'],
})
export class SummaryEditComponent implements OnInit, AfterViewInit {

  task: Boolean = false;

  allUsersSelected: Boolean = false;
  allPublicationsSelected: Boolean = false;
  title: string;
  companyList: Company[];

  userFilter: string;
  publicationFilter: string;
  userFilterField: string;

  loading: Boolean = false;
  startDate: Date;
  endDate: Date;
  company: Company;
  companies: Company[];

  userType: UserType;
  userTypes: UserType[];

  summaryId: number;
  summary: Summary;
  summaryUsers: User[];
  summaryPublications: Publication[];
  formGroup: FormGroup;
  summaryCategory: SummaryCategory;
  summaryCategories: SummaryCategory[];
  categoryName: string;
  userIds: string[] = [];
  publicationIds: string[] = [];

  selectedPublications: string[] = [];
  publications: Publication[];
  confirmedPublications: Publication[];
  dsPublications;
  displayedColumnsPublications = [];
  @ViewChild('paginatorPublications') paginatorPublications: MatPaginator;
  @ViewChild('tablePublications', { read: MatSort }) sortPublications: MatSort;
  dsConfirmedPublications;
  displayedColumnsConfirmedPublications = [];
  @ViewChild('paginatorConfirmedPublications') paginatorConfirmedPublications: MatPaginator;
  @ViewChild('tableConfirmedPublications', { read: MatSort }) sortConfirmedPublications: MatSort;

  selectedUsers: string[] = [];
  users: User[];
  allUsers: User[];
  dsUsers;
  displayedColumnsUsers = [];
  @ViewChild('paginatorUsers') paginatorUsers: MatPaginator;
  @ViewChild('tableUsers', { read: MatSort }) sortUsers: MatSort;

  constructor(
    private _formBuilder: FormBuilder,
    private publicationService: PublicationService,
    private loginService: LoginService,
    private summaryService: SummaryService,
    private emailService: EmailService,
    private datePipe: DatePipe,
    private router: Router,
    private http: Http,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private parameterService: ParameterService,
  ) {
    this.formGroup = this._formBuilder.group({
      subject: ['', Validators.compose([Validators.required, Validators.minLength(2)])],
      'summary': ['', Validators.compose([Validators.nullValidator, Validators.minLength(2)])],
      textHeader: ['', Validators.compose([Validators.required, Validators.minLength(2)])],
      'type': ['', Validators.compose([Validators.required, Validators.minLength(2)])],
    });
  }

  ngOnInit() {
    this.summaryId = this.route.snapshot.params['id'];

    this.displayedColumnsUsers = ['name', 'category', 'selected'];
    this.displayedColumnsPublications =
      ['title', 'bank', 'analyst', 'company', 'summary', 'rating', 'date', 'selected'];
    this.displayedColumnsConfirmedPublications = ['title'];

    this.loadData();

    this.loadUsers();
    this.loadPublications();
    this.loadCompanies();
    this.loadUserTypes();
  }

  ngAfterViewInit() {
  }

  loadUserTypes() {
    this.parameterService
      .getUserTypes()
      .subscribe(userTypes => {
        this.userTypes = userTypes;
      });
  }

  loadData() {

    const summaryOperation = this.summaryService.getSummaryById(this.summaryId);
    const summaryUsersOperation = this.summaryService.getSummaryUsers(this.summaryId);
    const summaryPublicationsOperation = this.summaryService.getSummaryPublications(this.summaryId);
    const summaryCategoryOperation = this.summaryService.getSummaryCategories();

    const fj = forkJoin([
      summaryOperation,
      summaryUsersOperation,
      summaryPublicationsOperation,
      summaryCategoryOperation]).onErrorResumeNext();

    fj.finally(() => {

    }).subscribe(
      results => {
        this.summary = results[0];
        this.summaryUsers = results[1];
        this.summaryPublications = results[2];
        this.summaryCategories = results[3];
      }, err => {
        
      }, () => {
        this.selectedUsers = this.summaryUsers.map(u => u.id).map(String);
        this.selectedPublications = this.summaryPublications.map(p => p.id).map(String);
        this.formGroup = this._formBuilder.group({
          'subject': [this.summary.title, Validators.compose([Validators.required, Validators.minLength(2)])],
          'summary': ['', Validators.compose([Validators.nullValidator, Validators.minLength(2)])],
          textHeader: ['', Validators.compose([Validators.required, Validators.minLength(2)])],
          'type': ['', Validators.compose([Validators.required, Validators.minLength(2)])],
        });
      },
    );
  }

  loadUsers() {
    this.publicationService
      .getUsers()
      .subscribe(
        users => {
          this.users = users.filter(user => user.status === 'active');
          this.allUsers = users.filter(user => user.status === 'active');
          this.dsUsers =
            new MatTableDataSource<User>(users.filter(user => user.status === 'active'));
          this.dsUsers.paginator = this.paginatorUsers;
          this.dsUsers.sort = this.sortUsers;
        },
        err => {
          
        },
    );
  }

  loadPublications() {
    this.publicationService
      .getPublications(this.loginService.getUserId())
      .subscribe(
        publications => {
          this.publications = publications.filter(publication => publication.published === true);
          this.dsPublications =
            new MatTableDataSource<Publication>(this.publications);
          this.dsPublications.paginator = this.paginatorPublications;
          this.dsPublications.sort = this.sortPublications;
        },
        err => {
        },
    );
  }

  stepperChange(event) {
    if (event['selectedIndex'] === 2) {
      this.publicationService
        .getPublicationsByIds(this.selectedPublications.join(','))
        .subscribe(publications => {

          this.confirmedPublications = publications;
          this.dsConfirmedPublications =
            new MatTableDataSource<Publication>(this.confirmedPublications);
          this.dsConfirmedPublications.paginator = this.paginatorConfirmedPublications;
          this.dsConfirmedPublications.sort = this.sortConfirmedPublications;
        }, err => {
          
        }, () => {

        });
    }
  }

  onSelectUser(id) {
    const index = this.selectedUsers.indexOf(id.toString());

    if (event.target['checked']) {
      if (index < 0) {
        this.selectedUsers.push(id.toString());
      }
    } else {
      if (index >= 0) {
        this.selectedUsers.splice(index, 1);
      }
    }
  }

  onSelectPublicaton(id) {
    const index = this.selectedPublications.indexOf(id.toString());

    if (event.target['checked']) {
      if (index < 0) {
        this.selectedPublications.push(id.toString());
      }
    } else {
      if (index >= 0) {
        this.selectedPublications.splice(index, 1);
      }
    }
  }

  endDateChange(event) {
    if (this.startDate !== null) {
      this.search();
    }
  }

  startDateChange(event) {
    if (this.endDate !== null) {
      this.search();
    }
  }

  search() {
    this.publicationService
      .searchSumaryPublications(this.buildSearchString())
      .subscribe(publications => {
        this.publications = publications.filter(publication => publication.published === true);
      });
  }

  buildSearchString() {
    let result = '';
    if (this.title) {
      result += `title=${this.title}&`;
    }
    if (this.companyList) {
      this.companyList.forEach(company => {
        result += `companyId=${company.id}&`;
      });
    }
    if (this.startDate && this.endDate) {
      const datePipe = new DatePipe('en');
      result += `date1=${datePipe.transform(this.startDate, 'yyyy-MM-dd')}&`;
      result += `date2=${datePipe.transform(this.endDate, 'yyyy-MM-dd')}`;
    } else {
      result += 'date1=2000-01-01&date2=2099-01-01';
    }

    return result;
  }

  searchPublicationsByDate() {
    const datePipe = new DatePipe('en');
    let result = '';
    result += `date1=${datePipe.transform(this.startDate, 'yyyy-MM-dd')}&`;
    result += `date2=${datePipe.transform(this.endDate, 'yyyy-MM-dd')}`;

    this.publicationService.searchPublications(result)
      .subscribe(
        publications => {
          this.publications = publications;
          this.dsPublications =
            new MatTableDataSource<Publication>(this.publications);
          this.dsPublications.paginator = this.paginatorPublications;
          this.dsPublications.sort = this.sortPublications;
        },
        err => {
        },
        () => {
        },
    );
  }

  mail() {
    const body = { userIds: this.userIds, publicationIds: this.publicationIds };

    this.emailService
      .sendPublications(body)
      .subscribe(
        res => { },
        err => {
          this.task = false;
        },
        () => {
          setTimeout(() => this.router.navigate([`/summary/${this.summary.id}`]), 500);
        });
  }

  dropDownSummaryCategoryChange(event) {
    this.categoryName = event.value['name'];

    if (this.categoryName.toLowerCase().indexOf('semanal') > -1) {
      this.users =
        this.users.filter(user => user.receiveWeeklySummary === true).filter(user => user.status === 'active');
    } else {
      this.users = this.allUsers;
    }

    this.dsUsers =
      new MatTableDataSource<User>(this.users);
    this.dsUsers.paginator = this.paginatorUsers;
    this.dsUsers.sort = this.sortUsers;
    this.selectedUsers = [];
  }

  isSummaryUser(userId: number) {
    return this.selectedUsers.map(Number).filter(user => user === userId);
  }

  isSummaryPublication(publicationId: number) {
    return this.selectedPublications.map(Number).filter(publication => publication === publicationId);
  }

  dropDownUserTypeChange(event) {
    this.userFilter = event.value['name'];

    if (event.value === 'all') {
      this.dsUsers.filter = '';
      if (!this.areAllUsersSelected()) {
        this.allUsersSelected = false;
      }
    } else {
      this.dsUsers.filter = event.value['name'];
    }
    // if (event.value !== undefined) {
    // } else {
    //   this.dsUsers.filter = '';
    // }

    // Observable.combineLatest('IT', 'Servicios')
    //   .map(([h1, h2]) => ({ h1, h2 }))
    //   .subscribe(filter => {
    //     if (!this.dsUsers) { return; }
    //     this.dsUsers.filter = filter;
    //   });
  }

  dropDownCompanyChange(event) {
    // if (event.value === 'all') {
    //   this.dsPublications.filter = '';
    // } else {
    //   this.dsPublications.filter = event.value['name'];
    // }
    this.search();
  }

  areAllUsersSelected() {
    return this.selectedUsers.length === this.users.length;
  }

  selectAllUsers(select: NgModel) {
    // select.update.emit(this.userTypes);
    select.update.emit([]);
  }

  deselectAllUsers(select: NgModel) {
    select.update.emit([]);
  }

  masterToggle() {
    if (!this.users) { return; }

    // if (this.isAllSelected()) {
    //   this.selection.clear();
    // } else if (this.filter.nativeElement.value) {
    //   this.dataSource.renderedData.forEach(data => this.selection.select(data.id));
    // } else {
    // this.users.forEach(user => this.selection.select(user.id.toString()));
    if (!this.allUsersSelected) {
      this.selectedUsers = [];
      this.users.forEach(user => this.selectedUsers.push(user.id.toString()));
      this.allUsersSelected = true;
    } else {
      this.selectedUsers = [];
      this.allUsersSelected = false;
    }
  }

  masterTogglePublications() {
    if (!this.publications) { return; }

    // if (this.isAllSelected()) {
    //   this.selection.clear();
    // } else if (this.filter.nativeElement.value) {
    //   this.dataSource.renderedData.forEach(data => this.selection.select(data.id));
    // } else {
    // this.users.forEach(user => this.selection.select(user.id.toString()));
    if (!this.allPublicationsSelected) {
      this.selectedPublications = [];
      this.publications.forEach(user => this.selectedPublications.push(user.id.toString()));
      this.allPublicationsSelected = true;
    } else {
      this.selectedPublications = [];
      this.allPublicationsSelected = false;
    }
  }

  save() {
    this.create(false);
  }

  create(send) {
    this.task = true;

    let summaryOperation: Observable<Summary>;
    summaryOperation = this.summaryService.updateSummary(this.summaryId, this.summary);
    summaryOperation.subscribe(
      summary => {
        this.summary = summary;
        this.publicationIds = this.selectedPublications;
        this.userIds = this.selectedUsers;
      },
      err => {
        this.task = false;
      },
      () => {
        this.postSummaryPublications(this.summary.id, this.selectedPublications);
      },
    );
  }

  postSummaryPublications(summaryId: number, publicationIds: string[]) {
    const headers = new Headers({ 'Authorization':`Bearer ${localStorage.getItem('token')}` });
    const options = new RequestOptions({ headers });
    const formData = new FormData();
    formData.append('publicationIds', publicationIds.toString());
    this.http.post(`${environment.devApiEndpoint}/summary/${summaryId}/publication`, formData, {headers})
      .catch((error: any) => Observable.throw(error))
      .subscribe(data => { }, err => { this.task = false; }, () => {
        this.postSummaryUsers(this.summary.id, this.selectedUsers);
      });
  }

  postSummaryUsers(summaryId: number, userIds: string[]) {
    const headers = new Headers({ 'Authorization':`Bearer ${localStorage.getItem('token')}` });
    const options = new RequestOptions({ headers });
    const formData = new FormData();
    formData.append('userIds', userIds.toString());
    this.http.post(`${environment.devApiEndpoint}/summary/${summaryId}/user`, formData, {headers})
      .catch((error: any) => Observable.throw(error))
      .subscribe(data => { }, err => { this.task = false; }, () => {
        setTimeout(() => this.router.navigate([`/summary/${this.summary.id}`]), 500);
      });
  }

  filterUsers(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dsUsers.filter = filterValue;
  }

  filterPublications(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dsPublications.filter = filterValue;
  }

  close() {
    this.router.navigate([`/summary/${this.summaryId}`]);
  }

  isUserSelected(userId: number) {
    return (this.selectedUsers.indexOf(userId.toString()) > -1);
  }

  isPublicationelected(publicationId: number) {
    return (this.selectedPublications.indexOf(publicationId.toString()) > -1);
  }

  loadCompanies() {
    this.publicationService
      .getCompanies()
      .subscribe(companies => {
        this.companies = companies.filter(company => company.status === 'active');
      }, err => {
        
      });
  }

  editPublication(publicationId: number): void {
    let p;

    this.publicationService
      .getPublication(publicationId)
      .subscribe(publication => {
        p = publication;
      }, err => {
        
      }, () => {
        const dataModal = { publication: p };

        const dialogRef = this.dialog.open(PublicationModalEditComponent, {
          height: '580px',
          width: '800px',
          data: dataModal,
        });

        dialogRef.afterClosed()
          .pipe(filter(data => data))
          .subscribe(data => {
            let publicationOperation: Observable<Publication>;
            publicationOperation
              = this.publicationService.updatePublication(data['publicationId'], data['publication']);
            publicationOperation.subscribe(
              publication => {
                // this.publication = publication;
                // if (data['publicationFile']) {
                //   this.updateFile(this.publicationId, data['publicationFile']);
                // }
                // if (data['imgFile'] || data['selectedStockImageId'] > 0) {
                //   this.updateImage(this.publicationId, data['imgFile'], data['selectedStockImageId']);
                // }
              },
              err => {
                
              },
              () => {
                this.search();
              },
            );
          },
        );
      });
  }

  clearUserFilters() {
    this.userType = null;
    this.userFilterField = '';
    this.dsUsers.filter = '';
  }

  clearPublicationFilters() {
    this.companyList = [];
    this.startDate = null;
    this.endDate = null;

    this.search();
  }

}

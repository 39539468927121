import { filter } from 'rxjs/operators';
import { UserCategory } from './../shared/models/user.category';
import { UserCompany } from './../shared/models/user.company';
import { UserAnalyst } from './../shared/models/user.analyst';
import { UserBank } from 'app/shared/models/user.bank';
import { Component, OnInit, Inject, HostListener, ViewChild, ElementRef } from '@angular/core';
import { Bank } from '../shared/models/bank';
import { Analyst } from '../shared/models/analyst';
import { Company } from '../shared/models/company';
import { Category } from '../shared/models/category';
import { NewPreferences } from '../shared/models/new/preferences.new';
import { PublicationTag } from '../shared/interfaces/publication.tag.interface';
import { PublicationService } from '../shared/services/publication.service';
import { forkJoin } from 'rxjs/observable/forkJoin';
import { MatDialogRef, MAT_DIALOG_DATA, MatSelect } from '@angular/material';

@Component({
  selector: 'app-dashboard-config-modal',
  templateUrl: './dashboard-config-modal.component.html',
  styleUrls: ['./dashboard-config-modal.component.scss'],
})

export class DashboardConfigModalComponent implements OnInit {

  loadLists: Boolean = false;
  userId: number;

  banks: IBank[];
  analysts: IAnalyst[];
  companies: ICompany[];
  categories: ICategory[];

  userBanks: UserBank[];
  userAnalysts: UserAnalyst[];
  userCompanies: UserCompany[];
  userCategories: UserCategory[];

  setPreferences: Boolean = false;
  preferences: NewPreferences;
  bankIds: number[] = [];
  analystIds: number[] = [];
  companyIds: number[] = [];
  categoryIds: number[] = [];

  searchBankTags: PublicationTag[] = [];
  searchAnalystTags: PublicationTag[] = [];
  searchCompanyTags: PublicationTag[] = [];
  searchCategoryTags: PublicationTag[] = [];

  constructor(private publicationService: PublicationService,
    public dialogRef: MatDialogRef<DashboardConfigModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.userId = data['userId'];
  }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    const analystOperation = this.publicationService.getAnalystsPreference();

    const fj = forkJoin([
      analystOperation
    ]).onErrorResumeNext();

    fj.subscribe(
      results => {
        this.analysts = results[0]['records'];
      }, err => {

      }, () => {
        this.loadPreferences();
      },
    );
  }

  loadPreferences() {
    const userAnalystOperation = this.publicationService.getUserAnalysts(this.userId);
    const fj = forkJoin([
      userAnalystOperation,
    ]).onErrorResumeNext();

    fj.subscribe(
      results => {
        this.userAnalysts = results[0];
        this.getCheckBoxSelected(); 
      }, err => { }, () => { 
        this.loadLists = true; 
      },
    );
  }

  tabClick(event) {
    let tab = event.tab.textLabel;

    switch(tab){
      case 'Analistas':
        this.publicationService.getAnalystsPreference().subscribe(results => {
          this.analysts = results['records'];
          this.publicationService.getUserAnalysts(this.userId).subscribe(data => {
            this.userAnalysts = data;

            for(var i in this.analysts){
              for(var h in this.userAnalysts){
                if(this.analysts[i].id == this.userAnalysts[h]['analystId']){
                  this.analysts[i]['selected'] = true;
                }
              }
            }

          });
        });
        break;
      case 'Bancos':
        this.publicationService.getPreferenceBanks().subscribe(results => {
          this.banks = results;
          this.publicationService.getUserBanks(this.userId).subscribe(data => {
            this.userBanks = data;

            for(var i in this.banks){
              for(var h in this.userBanks){
                if(this.banks[i].id == this.userBanks[h]['bankId']){
                  this.banks[i]['selected'] = true;
                }
              }
            }

          });
        });
        break;
      case 'Empresas':
        this.publicationService.getCompaniesPreference().subscribe(results => {
          this.companies = results['records'];
          this.publicationService.getUserCompanies(this.userId).subscribe(data => {
            this.userCompanies = data;

            for(var i in this.companies){
              for(var h in this.userCompanies){
                if(this.companies[i].id == this.userCompanies[h]['companyId']){
                  this.companies[i]['selected'] = true;
                }
              }
            }

          });
        });
        break;
      case 'Giros':
          this.publicationService.getCategories().subscribe(results => {
            this.categories = results['records'];
            this.publicationService.getUserCategories(this.userId).subscribe(data => {
              this.userCategories = data;

              for(var i in this.categories){
                for(var h in this.userCategories){
                  if(this.categories[i].id == this.userCategories[h]['categoryId']){
                    this.categories[i]['selected'] = true;
                  }
                }
              }

            });
          });
        break;
    }
  }

  getCheckBoxSelected(): void{
    for(var i in this.analysts){
      for(var h in this.userAnalysts){
        if(this.analysts[i].id == this.userAnalysts[h]['analystId']){
          this.analysts[i]['selected'] = true;
        }
      }
    }

    // for(var i in this.banks){
    //   for(var h in this.userBanks){
    //     if(this.banks[i].id == this.userBanks[h]['bankId']){
    //       this.banks[i]['selected'] = true;
    //     }
    //   }
    // }

    // for(var i in this.companies){
    //   for(var h in this.userCompanies){
    //     if(this.companies[i].id == this.userCompanies[h]['companyId']){
    //       this.companies[i]['selected'] = true;
    //     }
    //   }
    // }

    // for(var i in this.categories){
    //   for(var h in this.userCategories){
    //     if(this.categories[i].id == this.userCategories[h]['categoryId']){
    //       this.categories[i]['selected'] = true;
    //     }
    //   }
    // }
  }

  dropDownBankChange(event) {
    this.bankIds.push(event.value['id']);
    this.searchBankTags = this.searchBankTags.filter(item => item.name !== event.value['name']);
    this.searchBankTags.push({ name: event.value['name'], value: event.value['id'] });
  }

  dropDownAnalystChange(event) {
    this.analystIds.push(event.value['id']);
    this.searchAnalystTags = this.searchAnalystTags.filter(item => item.name !== event.value['fullName']);
    this.searchAnalystTags.push({ name: event.value['fullName'], value: event.value['id'] });
  }

  dropDownCompanyChange(event) {
    this.companyIds.push(event.value['id']);
    this.searchCompanyTags = this.searchCompanyTags.filter(item => item.name !== event.value['name']);
    this.searchCompanyTags.push({ name: event.value['name'], value: event.value['id'] });
  }

  dropDownCategoryChange(event) {
    this.categoryIds.push(event.value['id']);
    this.searchCategoryTags = this.searchCategoryTags.filter(item => item.name !== event.value['name']);
    this.searchCategoryTags.push({ name: event.value['name'], value: event.value['id'] });
  }

  save() {

    for(var h in this.userAnalysts){
      if(this.userAnalysts[h]!=undefined && this.userAnalysts[h]!=null){
        let indexId_1 = this.analystIds.indexOf(this.userAnalysts[h]['analystId']);
        if (indexId_1 >= 0) {
          this.analystIds.splice(indexId_1, 1);
        } else {
          this.analystIds.push(this.userAnalysts[h]['analystId']);
        }
      }
    }

    for(var h in this.userBanks){
        if(this.userBanks[h]!=undefined && this.userBanks[h]!=null){
        let indexId = this.bankIds.indexOf(this.userBanks[h]['bankId']);
        if (indexId >= 0) {
          this.bankIds.splice(indexId, 1);
        } else {
          this.bankIds.push(this.userBanks[h]['bankId']);
        }
      }
    }

    for(var h in this.userCompanies){
        if(this.userCompanies[h]!=undefined && this.userCompanies[h]!=null){
        let indexId = this.companyIds.indexOf(this.userCompanies[h]['companyId']);
        if (indexId >= 0) {
          this.companyIds.splice(indexId, 1);
        } else {
          this.companyIds.push(this.userCompanies[h]['companyId']);
        }
      }
    }

    for(var h in this.userCategories){
        if(this.userCategories[h]!=undefined && this.userCategories[h]!=null){
        let indexId = this.categoryIds.indexOf(this.userCategories[h]['categoryId']);
        if (indexId >= 0) {
          this.categoryIds.splice(indexId, 1);
        } else {
          this.categoryIds.push(this.userCategories[h]['categoryId']);
        }
      }
    }

    this.data = {
      setPreferences: this.setPreferences,
      bankIds: this.bankIds,
      analystIds: this.analystIds,
      companyIds: this.companyIds,
      categoryIds: this.categoryIds,
    };

    this.dialogRef.close(this.data);
  }

  removeBankTag(searchTag: any) {
    const index = this.searchBankTags.indexOf(searchTag);
    const indexId = this.bankIds.indexOf(searchTag['value']);

    if (index >= 0) {
      this.searchBankTags.splice(index, 1);
    }

    if (indexId >= 0) {
      this.bankIds.splice(index, 1);
    }

  }

  removeAnalystTag(searchTag: any) {
    const index = this.searchAnalystTags.indexOf(searchTag);
    const indexId = this.analystIds.indexOf(searchTag['value']);

    if (index >= 0) {
      this.searchAnalystTags.splice(index, 1);
    }

    if (indexId >= 0) {
      this.analystIds.splice(index, 1);
    }
  }

  removeCompanyTag(searchTag: any) {
    const index = this.searchCompanyTags.indexOf(searchTag);
    const indexId = this.companyIds.indexOf(searchTag['value']);

    if (index >= 0) {
      this.searchCompanyTags.splice(index, 1);
    }

    if (indexId >= 0) {
      this.companyIds.splice(index, 1);
    }
  }

  removeCategoryTag(searchTag: any) {
    const index = this.searchCategoryTags.indexOf(searchTag);
    const indexId = this.categoryIds.indexOf(searchTag['value']);

    if (index >= 0) {
      this.searchCategoryTags.splice(index, 1);
    }

    if (indexId >= 0) {
      this.categoryIds.splice(index, 1);
    }
  }

  onSelectBank(bankId) {

    const indexId = this.bankIds.indexOf(bankId);

    /* if (index >= 0) {
      this.searchBankTags.splice(index, 1);
    } */

    if (indexId >= 0) {
      this.bankIds.splice(indexId, 1);
    } else {
      this.bankIds.push(bankId);
    }
  }

  onSelectAnalyst(analystId) {
    
    const indexId = this.analystIds.indexOf(analystId);

    if (indexId >= 0) {
      this.analystIds.splice(indexId, 1);
    } else {
      this.analystIds.push(analystId);
    }
  }

  onSelectCompany(companyId) {

    const indexId = this.companyIds.indexOf(companyId);

    if (indexId >= 0) {
      this.companyIds.splice(indexId, 1);
    } else {
      this.companyIds.push(companyId);
    }
  }

  onSelectCategory(categoryId) {

    const indexId = this.categoryIds.indexOf(categoryId);

    if (indexId >= 0) {
      this.categoryIds.splice(indexId, 1);
    } else {
      this.categoryIds.push(categoryId);
    }
  }

  close() {
    this.dialogRef.close();
  }

  isAnalystSelected() {
    this.analysts.map(analyst => {
      analyst.selected = this.userAnalysts.filter(x => x['analystId'] === analyst.id).length > 0;
      if (analyst.selected) {
        this.analystIds.push(analyst.id);        
      }
    });
  }

  isBankSelected() {
    this.banks.map(bank => {
      bank.selected = this.userBanks.filter(x => x['bankId'] === bank.id).length > 0;
      if (bank.selected) {
        this.bankIds.push(bank.id);
      }
    });
  }

  isCompanySelected() {
    this.companies.map(company => {
      company.selected = this.userCompanies.filter(x => x['companyId'] === company.id).length > 0;
      if (company.selected) {
        this.companyIds.push(company.id);
      }
    });
  }

  isCategorySelected() {
    this.categories.map(category => {
      category.selected = this.userCategories.filter(x => x['categoryId'] === category.id).length > 0;
      if (category.selected) {
        this.categoryIds.push(category.id);
      }
    });
  }

}

export interface IAnalyst {
  id: number;
  bankId: number;
  name: string;
  lastname_Paternal: string;
  lastname_Maternal: string;
  email: string;
  status: string;
  selected: Boolean;
}

export interface IBank {
  id: number;
  name: string;
  logo: string;
  status: string;
  selected: Boolean;
}

export interface ICompany {
  id: number;
  name: string;
  categoryId: number;
  subCategoryId: number;
  logo: string;
  status: string;
  selected: Boolean;
}

export interface ICategory {
  id: number;
  name: string;
  status: string;
  selected: Boolean;
}

import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contact-body',
  templateUrl: './contact-body.component.html',
  styleUrls: ['./contact-body.component.scss']
})
export class ContactBodyComponent implements OnInit {

  name: string;
  email: string;
  area: string;
  message: string;
  phone: string;
  extension: string;
  contactFormGroup: FormGroup;

  constructor(
    fb: FormBuilder,
  ) { 
    // this.contactFormGroup = fb.group({
    //   'name': ['', Validators.compose([Validators.required, Validators.minLength(2)])],
    //   'email': ['', Validators.compose([Validators.required, Validators.email])],
    //   'area': ['', Validators.compose([Validators.required, Validators.minLength(2)])],
    //   'phone': ['', Validators.nullValidator],
    //   'extension': ['', Validators.compose([Validators.nullValidator, Validators.minLength(2)])],
    //   'message': ['', Validators.compose([Validators.required, Validators.minLength(2)])],
    // });
  }

  ngOnInit() {
  }

}

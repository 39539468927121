import { environment } from '../../environments/environment';
import { Component, OnInit } from '@angular/core';
import { FormGroup, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { LoginService } from '../shared/services/login.service';
import { LoginModel } from '../shared/models/login';
import { Observable } from 'rxjs/Observable';
import { Router, Routes, ActivatedRoute } from '@angular/router';
import { BaMenuService } from '../theme';
import { PAGES_MENU } from '../app.menu';
import { PAGES_MENU_READER } from '../app.reader.menu';

@Component({
  selector: 'app-login-body',
  templateUrl: './login-body.component.html',
  styleUrls: ['./login-body.component.scss'],
})
export class LoginBodyComponent implements OnInit {

  privacyFileLink: string;
  arcoFileLink: string;
  consentFileLink: string;
  loginBackground: string;
  returnUrl: string;

  loading: Boolean = false;
  form: FormGroup;
  email: AbstractControl;
  password: AbstractControl;
  submitted: boolean = false;
  statusText: string;
  model = new LoginModel('', ''); 

  constructor(
    private _menuService: BaMenuService,
    fb: FormBuilder,
    private route: ActivatedRoute,
    private loginService: LoginService,
    private router: Router) {
    this.form = fb.group({
      'email': ['', Validators.compose([Validators.required, Validators.minLength(6)])],
      'password': ['', Validators.compose([Validators.required, Validators.minLength(8)])],
    });

    this.email = this.form.controls['email'];
    this.password = this.form.controls['password'];

    this.loginBackground = `${environment.assetsEndpoint}/img/background_login.jpg`;

    this.privacyFileLink = `${environment.s3Endpoint}/legal/aviso_privacidad.pdf`;
    this.arcoFileLink = `${environment.s3Endpoint}/legal/ejercicio_derechos_arco_v1.pdf`;
    this.consentFileLink = `${environment.s3Endpoint}/legal/revocacion_consentimiento_v1.2.pdf`;
  }

  ngOnInit() {
    localStorage.removeItem('token');
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  onSubmit(values: Object): void {
    this.loading = true;
    this.submitted = true;
    if (this.form.valid) {
      let loginOperation: Observable<Object>;
      loginOperation = this.loginService.postLogin(this.model).finally(() => { this.loading = false; });
      loginOperation.subscribe(
        loginResponse => {
          localStorage.setItem('token', loginResponse['token']);
          localStorage.setItem('timer', loginResponse['sessionMinutes']);
          this.router.navigate([`dashboard`]);
          this.statusText = '';

          if (this.loginService.isUserAdmin()) {
            this._menuService.updateMenuByRoutes(<Routes>PAGES_MENU);
            if (this.loginService.isTokenExpired()) {
              localStorage.removeItem('token');
              this.router.navigate(['login']);
              // this.router.navigateByUrl(this.returnUrl);
            }
          } else {
            this._menuService.updateMenuByRoutes(<Routes>PAGES_MENU_READER);
            if (this.loginService.isTokenExpired()) {
              localStorage.removeItem('token');
              this.router.navigate(['login']);
              // this.router.navigateByUrl(this.returnUrl);
            }
          }
        }, error => {

          if (error !== undefined) {
            if (error.status === 0) {
              this.statusText = 'El servicio no responde';
            } else {
              this.statusText = error;
            }
          } else {
            this.statusText = 'El servicio no responde';
          }
        },
      );
    }
  }

}

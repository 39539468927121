import { DashboardIntroductionModalComponent } from './../dashboard-introduction-modal/dashboard-introduction-modal.component';
import { LoginService } from './../shared/services/login.service';
import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy, ElementRef } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { PublicationService } from '../shared/services/publication.service';
import { DatePipe } from '@angular/common';
import { User } from '../shared/models/user';
import { UserBank } from '../shared/models/user.bank';
import { UserAnalyst } from '../shared/models/user.analyst';
import { UserCompany } from '../shared/models/user.company';
import { UserCategory } from '../shared/models/user.category';
import { PublicationView } from '../shared/models/publication.view';
import { Status } from '../shared/models/helpers/status';
import { PublicationBookmark } from '../shared/models/publication.bookmark';
import { Location } from '@angular/common';
import {
  MatPaginator, MatTableDataSource, MatPaginatorIntl,
  MatSnackBar, MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSort,
} from '@angular/material';
import { filter } from 'rxjs/operators';
import { UserModalEditComponent } from '../user-modal-edit/user-modal-edit.component';
import { Observable } from 'rxjs/Observable';
import { forkJoin } from 'rxjs/observable/forkJoin';
import { UserModalBankComponent } from '../user-modal-bank/user-modal-bank.component';
import { UserModalAnalystComponent } from '../user-modal-analyst/user-modal-analyst.component';
import { UserModalCompanyComponent } from '../user-modal-company/user-modal-company.component';
import { UserModalCategoryComponent } from '../user-modal-category/user-modal-category.component';

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss'],
})
export class UserDetailComponent implements OnInit, AfterViewInit, OnDestroy {

  thisUserIsAdmin: Boolean;
  userId: number;
  userBanks: UserBank[];
  userAnalysts: UserAnalyst[];
  userCompanies: UserCompany[];
  userCategories: UserCategory[];
  receiveWeeklySummary: Boolean;
  setPreferencesAfterSession: Boolean;
  setIntroductionAfterSession: Boolean;

  loading: Boolean = false;
  user: User;
  statusLabel: string;
  status: Status;

  beginDate: Date;
  endDate: Date;
  publicationViews: PublicationView[];
  publicationBookmarks: PublicationBookmark[];
  dsViews;
  displayedColumnsViews = [];
  @ViewChild('paginatorViews') paginatorViews: MatPaginator;
  @ViewChild('tableViews', { read: MatSort }) sortViews: MatSort;
  @ViewChild('dateInit') startDateInput:ElementRef;
  @ViewChild('dateEnd') endDateInput: ElementRef;

  constructor(
    private publicationService: PublicationService,
    private loginService: LoginService,
    private router: Router,
    private location: Location,
    private route: ActivatedRoute,
    public snackBar: MatSnackBar,
    public dialog: MatDialog) { }

  ngOnInit() {
    this.displayedColumnsViews = ['eye_id', 'title', 'date', 'bookmarked', 'downloaded'];
    this.userId = this.route.snapshot.params['id'];

    this.publicationService
      .getUser(this.userId)
      .subscribe(user => {
        this.user = user;
        this.userId = user.id;
        this.receiveWeeklySummary = user.receiveWeeklySummary;
        this.setPreferencesAfterSession = !user.setPreferencesAfterSession;
        this.setIntroductionAfterSession = !user.setIntroductionAfterSession;
        this.thisUserIsAdmin = (this.user.role === 'Admin') ? true : false;
        this.statusLabel = (this.user.status === 'active') ? 'Activo' : 'Inactivo';
      });
  }

  ngOnDestroy(){
    this.dialog.closeAll();
  }

  ngAfterViewInit() {
    this.searchPublicationViews();
    this.loadBookmarks();
    this.loadPreferences();
  }

  loadPreferences() {
    // Get User's banks
    const userBankOperation = this.publicationService.getUserBanks(this.userId);
    // Get User's analysts
    const userAnalystOperation = this.publicationService.getUserAnalysts(this.userId);
    // Get User's companies
    const userCompanyOperation = this.publicationService.getUserCompanies(this.userId);
    // Get User's categories
    const userCategoryOperation = this.publicationService.getUserCategories(this.userId);


    const fj = forkJoin([
      userBankOperation,
      userAnalystOperation,
      userCompanyOperation,
      userCategoryOperation]).onErrorResumeNext();

    fj.subscribe(
      results => {
        this.userBanks = results[0];
        this.userAnalysts = results[1];
        this.userCompanies = results[2];
        this.userCategories = results[3];
      },
    );
  }

  searchPublicationViews() {
    this.publicationService
      .searchUserPublicationViews(this.userId, this.buildPublicationViewSearchString()).subscribe(
        publicationViews => {
          this.publicationViews = publicationViews;
          this.dsViews =
            new MatTableDataSource<PublicationView>(this.publicationViews);
          this.dsViews.paginator = this.paginatorViews;
          this.dsViews.sort = this.sortViews;
        },
        err => {
        },
    );
  }

  buildPublicationViewSearchString() {
    let result = '';
    const datePipe = new DatePipe('en');
    if (this.beginDate && this.endDate) {
      result += `date1=${datePipe.transform(this.beginDate, 'yyyy-MM-dd')}&date2=${datePipe.transform(this.endDate, 'yyyy-MM-dd')}`;
    } else {
      result += 'date1=2000-01-01&date2=2099-01-01';
    }

    return result;
  }

  validFilterDates() {
    let result = true;
    if (this.beginDate && this.endDate) {
      if (this.endDate >= this.beginDate) {
        result = false;
      }
    }

    return result;
  }

  loadBookmarks() {
    this.publicationService
      .getUserBookmarks(this.userId)
      .subscribe(bookmarks => this.publicationBookmarks = bookmarks);
  }

  clearPublicationViewsFilters() {
    this.beginDate = null;
    this.endDate = null;

    this.searchPublicationViews();
  }

  openDialog() {
    const dialog = this.dialog.open(UserModalEditComponent, {
      height: '500px',
      width: '800px',
      closeOnNavigation: true,
      data: { user: this.user },
    });

    dialog.afterClosed()
      .pipe(filter(data => data))
      .subscribe(data => {
        let userOperation: Observable<User>;
        userOperation = this.publicationService
          .updateUser(this.userId, data['user'])
          .finally(() => this.loading = false);
        userOperation.subscribe(
          user => {
            this.loading = true;
            this.user = user;
          },
          err => {
            this.openSnackBar(err.error.message);
          },
        );
      },
    );
  }

  openBankDialog() {
    const dialog = this.dialog.open(UserModalBankComponent, {
      height: '300px',
      width: '800px',
      closeOnNavigation: true,
      data: { userId: this.userId },
    });

    dialog.afterClosed()
      .pipe(filter(data => data))
      .subscribe(data => {
        let userOperation: Observable<UserBank[]>;
        userOperation = this.publicationService
          .postUserBank(this.userId, data['userBank'])
        userOperation.subscribe(
          userBanks => {
            this.userBanks = userBanks;
          },
          err => {
            this.openSnackBar(err.error.message);
          },
        );
      },
    );
  }

  openAnalystDialog() {
    const dialog = this.dialog.open(UserModalAnalystComponent, {
      height: '300px',
      width: '800px',
      closeOnNavigation: true,
      data: { userId: this.userId },
    });

    dialog.afterClosed()
      .pipe(filter(data => data))
      .subscribe(data => {
        let userOperation: Observable<UserAnalyst[]>;
        userOperation = this.publicationService
          .postUserAnalyst(this.userId, data['userAnalyst']);
        userOperation.subscribe(
          userAnalysts => {
            this.userAnalysts = userAnalysts;
          },
          err => {
            this.openSnackBar(err.error.message);
          },
        );
      },
    );
  }

  openCompanyDialog() {
    const dialog = this.dialog.open(UserModalCompanyComponent, {
      height: '300px',
      width: '800px',
      closeOnNavigation: true,
      data: { userId: this.userId },
    });

    dialog.afterClosed()
      .pipe(filter(data => data))
      .subscribe(data => {
        let userOperation: Observable<UserCompany[]>;
        userOperation = this.publicationService
          .postUserCompany(this.userId, data['userCompany']);
        userOperation.subscribe(
          userCompanies => {
            this.userCompanies = userCompanies;
          },
          err => {
            this.openSnackBar(err.error.message);
          },
        );
      },
    );
  }

  openIntroductionDialog() {
    const dialogRef = this.dialog.open(DashboardIntroductionModalComponent, {
      height: '650px',
      width: '800px',
      closeOnNavigation: true,
      disableClose: true,
      data: { showDoNotShowThisAgain: false },
    });

    dialogRef.afterClosed()
      .pipe(filter(data => data))
      .subscribe(data => {
        if (data['setIntroduction']) {
          const userUpdated = this.user;
          userUpdated.setIntroduction = true;
          this.publicationService
            .updateUser(this.userId, userUpdated)
            .subscribe(user => this.user = user);
        }
      },
    );
  }

  openCategoryDialog() {
    const dialog = this.dialog.open(UserModalCategoryComponent, {
      height: '300px',
      width: '800px',
      closeOnNavigation: true,
      data: { userId: this.userId },
    });

    dialog.afterClosed()
      .pipe(filter(data => data))
      .subscribe(data => {
        let userOperation: Observable<UserCategory[]>;
        userOperation = this.publicationService
          .postUserCategory(this.userId, data['userCategory']);
        userOperation.subscribe(
          userCategories => {
            this.userCategories = userCategories;
          },
          err => {
            this.openSnackBar(err.error.message);
          },
        );
      },
    );
  }

  isActive() {
    if (this.user.status === 'active') {
      return true;
    } else {
      return false;
    }
  }

  statusChange(event) {
    this.status = new Status(null);
    this.status.st = (event.checked) ? 'active' : 'inactive';
    this.publicationService
      .updateStatus(this.user.id, 'user', this.status)
      .subscribe(user => {
        this.user = user;
        this.statusLabel = (this.user.status === 'active') ? 'Activo' : 'Inactivo';
      });
  }

  deleteUserBank(userId, bankId) {
    this.publicationService.deleteUserBank(userId, bankId)
      .subscribe(data => this.loadPreferences());
  }

  deleteUserAnalyst(userId, analystId) {
    this.publicationService.deleteUserAnalyst(userId, analystId)
      .subscribe(data => this.loadPreferences());
  }

  deleteUserCompany(userId, companyId) {
    this.publicationService.deleteUserCompany(userId, companyId)
      .subscribe(data => this.loadPreferences());
  }

  deleteUserCategory(userId, categoryId) {
    this.publicationService.deleteUserCategory(userId, categoryId)
      .subscribe(data => this.loadPreferences());
  }

  isUserAdmin() {
    return this.loginService.isUserAdmin();
  }

  changeReceiveWeeklySummary(event) {
    this.user.receiveWeeklySummary = this.receiveWeeklySummary;

    let userOperation: Observable<User>;
    userOperation = this.publicationService
      .updateUser(this.userId, this.user);
    userOperation.subscribe(
      user => {
        this.user = user;
      },
      err => {
        
      },
      () => {
      },
    );
  }

  changeSetPreferencesAfterSession(event) {

    this.user.setPreferencesAfterSession = !this.setPreferencesAfterSession;
    this.user.setPreferences = !this.setPreferencesAfterSession;

    let userOperation: Observable<User>;
    userOperation = this.publicationService
      .updateUser(this.userId, this.user);
    userOperation.subscribe(
      user => {
        this.user = user;
        this.setPreferencesAfterSession = !this.user.setPreferencesAfterSession;
      },
      err => {
        
      },
      () => {
      },
    );
  }

  changeSetIntroductionAfterSession(event) {

    this.user.setIntroductionAfterSession = !this.setIntroductionAfterSession;
    this.user.setIntroduction = !this.setIntroductionAfterSession;

    let userOperation: Observable<User>;
    userOperation = this.publicationService
      .updateUser(this.userId, this.user);
    userOperation.subscribe(
      user => {
        this.user = user;
        this.setIntroductionAfterSession = !this.user.setIntroductionAfterSession;
      },
      err => {
        
      },
      () => {
      },
    );
  }

  backToList() {
    this.location.back();
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, '', {
      duration: 2000,
    });
  }

  removeAll(type: string){
    if(type === 'start'){
      this.startDateInput.nativeElement.value = '';
    } else {
      this.endDateInput.nativeElement.value = '';
    }
    
  }

}

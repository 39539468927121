import { JwtHelper } from 'angular2-jwt';
import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { Observable } from 'rxjs/Rx';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { environment } from '../../../environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';

@Injectable()
export class LoginService {

  jwtHelper: JwtHelper = new JwtHelper();
  constructor(private http: Http, private _HttpClient: HttpClient, ) { }

  private baseUrl = environment.devApiEndpoint;

  postLogin(body: Object): Observable<Object> {
    const headers = new Headers({ 'Content-type': 'application/json', 'Cache-Control':  'no-cache, no-store, private' });
    const options = new RequestOptions({ headers });

    return this.http.post(`${this.baseUrl}/login`, body, options)
      .map((res: Response) => res.json())
      .catch((error: any) => Observable.throw(error.json().message));
  }

  postRecover(email: string): Observable<Object> {
    const headers = new Headers({ 'Content-type': 'application/json', 'Cache-Control':  'no-cache, no-store, private' });
    const options = new RequestOptions({ headers });

    return this.http.post(`${this.baseUrl}/login/recover?email=${email}`, options)
      .map((res: Response) => res.json())
      .catch((error: any) => Observable.throw(error));
  }

  postReset(body: Object, token: string): Observable<Object> {
    const headers = new HttpHeaders({ 'Authorization':`Bearer ${token}`, 'Cache-Control':  'no-cache, no-store, private' });

    return this._HttpClient.post(`${this.baseUrl}/login/reset`, body, {headers})
      .map((res: Response) => res.json())
      .catch((error: any) => Observable.throw(error));
  }

  // Check user role
  isUserAdmin() {
    const token = localStorage.getItem('token');
    if (token) {
      const decodedToken = this.jwtHelper.decodeToken(token);
      if (decodedToken['Rol'] === 'Admin') {
        return true;
      }
    }

    return false;
  }

  userNameRole() {
    let result: string = '';
    const token = localStorage.getItem('token');
    if (token) {
      const decodedToken = this.jwtHelper.decodeToken(token);
      if (this.isUserAdmin()) {
        result = `${decodedToken['unique_name']} (${decodedToken['Rol']})`;
      } else {
        result = `${decodedToken['unique_name']}`;
      }

      return result;
    }
  }

  isTokenExpired() {
    const token = localStorage.getItem('token');
    if (token) {
      const expiredToken = this.jwtHelper.isTokenExpired(token);
      return expiredToken;
    }

    return false;
  }

  isLoginTokenExpired(tkn: string) {
    const token = tkn;
    if (token) {
      const expiredToken = this.jwtHelper.isTokenExpired(token);
      return expiredToken;
    }

    return false;
  }

  getUserId() {
    let result;
    const token = localStorage.getItem('token');
    if (token) {
      const decodedToken = this.jwtHelper.decodeToken(token);
        result = `${decodedToken['Id']}`;
      }

      return result;
    }

}

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

@Injectable()
export class SharedService {
    // Observable string sources
  private query = new BehaviorSubject<string>('');  

  // Observable string streams
  query$ = this.query.asObservable();

    // Service message commands
  publishData(data: string) {
    this.query.next(data);
  }
}

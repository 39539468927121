export const PAGES_MENU = [
  {
    path: '',
    data: {
      menu: {
        title: 'general.menu.dashboard',
        icon: 'fa fa-home',
        img: 'inicio.png',
        selected: false,
        expanded: false,
        order: 50,
        text: 'Dashboard',
      },
    },
  },
  {
    path: 'publication',
    data: {
      menu: {
        title: 'general.menu.publications',
        icon: 'fa fa-th',
        img: 'publicaciones.png',
        selected: false,
        expanded: false,
        order: 50,
        text: 'Publicaciones',
      },
    },
  },
  {
    path: 'catalog',
    data: {
      menu: {
        title: 'general.menu.catalogs',
        icon: 'fa fa-list',
        img: 'catalogos.png',
        selected: false,
        expanded: false,
        order: 300,
        text: 'Catálogos',
      },
    },
    children: [
      // {
      //   path: 'dashboard',
      //   data: {
      //     menu: {
      //       title: 'general.menu.catalog-dashboard',
      //       icon: 'ion-ios-keypad',
      //       img: 'ion-ios-keypad',
      //       selected: false,
      //       expanded: false,
      //       order: 0,
      //       text: 'Dashboard',
      //     },
      //   },
      // },
      {
        path: 'analyst',
        data: {
          menu: {
            title: 'general.menu.analysts',
            icon: 'fa fa-line-chart',
            img: 'analistas.png',
            selected: false,
            expanded: false,
            order: 10,
            text: 'Analistas',
          },
        },
      },
      {
        path: 'bank',
        data: {
          menu: {
            title: 'general.menu.banks',
            icon: 'fa fa-university',
            img: 'bancos.png',
            selected: false,
            expanded: false,
            order: 20,
            text: 'Bancos',
          },
        },
      },
      {
        path: 'company',
        data: {
          menu: {
            title: 'general.menu.companies',
            icon: 'fa fa-briefcase',
            img: 'empresas.png',
            selected: false,
            expanded: false,
            order: 30,
            text: 'Empresas',
          },
        },
      },
      {
        path: 'category',
        data: {
          menu: {
            title: 'general.menu.categories',
            icon: 'fa fa-tasks',
            img: 'giros.png',
            selected: false,
            expanded: false,
            order: 40,
            text: 'Giros',
          },
        },
      },
    ],
  },
  {
    path: 'summary',
    data: {
      menu: {
        title: 'general.menu.summary',
        icon: 'fa fa-envelope',
        img: 'resumen.png',
        selected: false,
        expanded: false,
        order: 50,
        text: 'Resúmenes',
      },
    },
  },
  {
    path: 'user',
    data: {
      menu: {
        title: 'general.menu.users',
        icon: 'fa fa-user',
        img: 'usuarios.png',
        selected: false,
        expanded: false,
        order: 50,
        text: 'Usuarios',
      },
    },
  },
  {
    path: 'contact',
    data: {
      menu: {
        title: 'general.menu.contact',
        icon: 'fa fa-comment',
        img: 'contacto.png',
        selected: false,
        expanded: false,
        order: 300,
        text: 'Contacto',
      },
    },
  },
  {
    path: 'parameter',
    data: {
      menu: {
        title: 'general.menu.parameters',
        icon: 'fa fa-cog',
        img: 'parametros.png',
        selected: false,
        expanded: false,
        order: 50,
        text: 'Parámetros',
      },
    },
  },
];

import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { Analyst } from '../shared/models/analyst';
import { NewUserAnalyst } from '../shared/models/new/user.analyst.new';
import { FormGroup, Validators, FormControl, FormBuilder } from '@angular/forms';
import { PublicationService } from '../shared/services/publication.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-user-modal-analyst',
  templateUrl: './user-modal-analyst.component.html',
  styleUrls: ['./user-modal-analyst.component.scss']
})
export class UserModalAnalystComponent implements OnInit {

  userId: number;
  analyst: Analyst;
  analysts: Analyst[];
  form: FormGroup;
  model = new Analyst(null, null, null, null, null, null, null,null);
  userAnalyst = new NewUserAnalyst(null, null);

  constructor(
    fb: FormBuilder,
    private publicationService: PublicationService,
    public dialogRef: MatDialogRef<UserModalAnalystComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.userId = data['userId'];

    this.form = fb.group({
      'analyst': ['', Validators.compose([Validators.required])],
    });

  }

  ngOnInit() {
    this.loadAnalysts();
  }

  // Category
  loadAnalysts() {
    this.publicationService.getAnalystsPreference().subscribe(
      //analysts => this.analysts = analysts.filter(analyst => analyst.status === 'active'),
      analysts => this.analysts = analysts['records'],
      err => {
      },
    );
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    this.data = {
      userAnalyst: { userId: this.userId, analystId: this.model.id },
    };
    this.dialogRef.close(this.data);
  }

}

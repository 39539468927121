import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { Company } from '../shared/models/company';
import { NewUserCompany } from '../shared/models/new/user.company.new';
import { FormGroup, Validators, FormControl, FormBuilder } from '@angular/forms';
import { PublicationService } from '../shared/services/publication.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-user-modal-company',
  templateUrl: './user-modal-company.component.html',
  styleUrls: ['./user-modal-company.component.scss']
})
export class UserModalCompanyComponent implements OnInit {

  userId: number;
  company: Company;
  companies: Company[];
  form: FormGroup;
  model = new Company(null, null, null, null, null, null);
  userCompany = new NewUserCompany(null, null);

  constructor(
    fb: FormBuilder,
    private publicationService: PublicationService,
    public dialogRef: MatDialogRef<UserModalCompanyComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.userId = data['userId'];

    this.form = fb.group({
      'company': ['', Validators.compose([Validators.required])],
    });

  }

  ngOnInit() {
    this.loadCompanies();
  }

  // Category
  loadCompanies() {
    this.publicationService.getCompaniesPreference().subscribe(
      //companies => this.companies = companies.filter(company => company.status === 'active'),
      companies => this.companies = companies['records'],
      err => {
      },
    );
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    this.data = {
      userCompany: { userId: this.userId, companyId: this.model.id },
    };
    this.dialogRef.close(this.data);
  }

}

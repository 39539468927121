import { LoginService } from './../shared/services/login.service';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, 
  HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/do';
import { SlimLoadingBarService } from 'ng2-slim-loading-bar';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {

  constructor(private _loadingBar: SlimLoadingBarService, private router: Router, private loginService: LoginService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // start our loader here
    this._loadingBar.start();

    return next.handle(req).do((event: HttpEvent<any>) => {
      // if the event is for http response
      if (event instanceof HttpResponse) {
        // stop our loader here
        this._loadingBar.complete();
      }

      if (this.loginService.isTokenExpired()) {
        jQuery('.cdk-overlay-pane').hide();
        
        this.router.navigate([`/login`]);
        // localStorage.removeItem('token');
      }

    }, (err: any) => {
      // if any error (not for just HttpResponse) we stop our loader bar
      this._loadingBar.complete();
      if (err instanceof HttpErrorResponse) {
        if (err.status === 401) {
          // this.router.navigate([`/login`]);
        }
      }
    });
  }

}

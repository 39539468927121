import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, Validators, FormControl, FormBuilder } from '@angular/forms';
import { NewPublicationType } from '../shared/models/new/publication.type.new';

@Component({
  selector: 'app-parameter-publication-type-modal',
  templateUrl: './parameter-publication-type-modal.component.html',
  styleUrls: ['./parameter-publication-type-modal.component.scss'],
})
export class ParameterPublicationTypeModalComponent implements OnInit {
  @HostListener('wheel', ['$event'])
  handleWheelEvent(event) {
    event.preventDefault();
  }

  form: FormGroup;
  model = new NewPublicationType(null, null);

  constructor(
    fb: FormBuilder,
    public dialogRef: MatDialogRef<ParameterPublicationTypeModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.form = fb.group({
        'name': ['', Validators.compose([Validators.required, Validators.minLength(2)])],
      });
     }

  ngOnInit() {
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    this.model.status = 'active';
    this.data = {
      publicationType: this.model,
    };
    this.dialogRef.close(this.data);
  }

}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-parameter-main',
  templateUrl: './parameter-main.component.html',
  styleUrls: ['./parameter-main.component.scss']
})
export class ParameterMainComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

import { PublicationDownload } from './../shared/models/publication.download';
import { PublicationBookmark } from './../shared/models/publication.bookmark';
import { Component, OnInit, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Company } from '../shared/models/company';
import { Message } from 'primeng/primeng';
import { Analyst } from '../shared/models/analyst';
import { PublicationView } from '../shared/models/publication.view';
import { Bank } from '../shared/models/bank';
import { Publication } from '../shared/models/publication';
import { SubCategory } from '../shared/models/subcategory';
import { Category } from '../shared/models/category';
import { Status } from '../shared/models/helpers/status';
import { Preference } from '../shared/models/helpers/preference';
import { DatePipe, Location } from '@angular/common';
import { PublicationService } from '../shared/services/publication.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoginService } from '../shared/services/login.service';
import { Http, RequestOptions, Headers } from '@angular/http';
import { PublicationTag } from '../shared/interfaces/publication.tag.interface';
import { saveAs as importedSaveAs } from 'file-saver';
import {
  MatPaginator, MatTableDataSource, MatPaginatorIntl,
  MatSnackBar, MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSort,
} from '@angular/material';
import { filter } from 'rxjs/operators';
import { Observable } from 'rxjs/Observable';
import { CatalogCompanyModalEditComponent } from '../catalog-company-modal-edit/catalog-company-modal-edit.component';
import { environment } from 'environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-catalog-company-detail',
  templateUrl: './catalog-company-detail.component.html',
  styleUrls: ['./catalog-company-detail.component.scss'],
})
export class CatalogCompanyDetailComponent implements OnInit, AfterViewInit {

  userId: number;

  bankList: Bank[];
  categoryList: Category[];
  subcategoryList: SubCategory[];
  analystList: Analyst[];
  companyList: Company[] = [];

  publicationBookmark: PublicationBookmark;
  publicationDownload: PublicationDownload;
  statusLabel: string;
  loading: Boolean = false;
  isSelected: Boolean = false;
  displayedColumnsAnalysts = [];
  displayedColumnsViews = [];
  displayedColumnsPublications = [];
  displayedRecommedationsColumns = [];
  title = '';
  id: number;
  rating: number;
  dateRange: Date[];
  publicationViewsDateRange: Date[];
  companyId = 0;
  bank: Bank;
  analyst: Analyst;
  category: Category;
  subcategory: SubCategory;
  company: Company;
  status: Status;
  preference: Preference;
  analysts: Analyst[];
  allAnalysts: Analyst[];
  msgs: Message[] = [];
  statuses: any;
  es: any;
  selectedPublications: string[] = [];
  selectedItems: string[] = [];
  publicationViews: PublicationView[];
  publications: Publication[];
  banks: Bank[];
  categories: Category[];
  subcategories: SubCategory[];
  items: any[];
  selectAllValue: boolean;
  beginDate: Date;
  endDate: Date;
  searchTags: PublicationTag[] = [];
  imageUrl = '';
  dsAnalysts;
  dsViews;
  dsPublications;
  dsRecommendations;
  @ViewChild('paginatorAnalysts') paginatorAnalysts: MatPaginator;
  @ViewChild('paginatorViews') paginatorViews: MatPaginator;
  @ViewChild('paginatorPublications') paginatorPublications: MatPaginator;
  @ViewChild('paginatorRecommendations') paginatorRecommendations: MatPaginator;
  @ViewChild('tablePublications', { read: MatSort }) sortPublications: MatSort;
  @ViewChild('tableAnalysts', { read: MatSort }) sortAnalysts: MatSort;
  @ViewChild('tableViews', { read: MatSort }) sortViews: MatSort;
  @ViewChild('tableRecommendatons', { read: MatSort }) sortRecommendations: MatSort;
  @ViewChild('dateInit') startDateInput:ElementRef;
  @ViewChild('dateEnd') endDateInput: ElementRef;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal,
    private publicationService: PublicationService,
    private http: Http,
    private loginService: LoginService,
    private snackBar: MatSnackBar,
    private location: Location,
    public dialog: MatDialog,
    public _HttpClient: HttpClient) { }

  ngOnInit() {
    this.companyId = this.route.snapshot.params['id'];
    this.userId = this.loginService.getUserId();

    this.companyList.push(new Company(this.companyId, null, null, null, null, null));

    this.displayedColumnsAnalysts = ['eye_id', 'fullName', 'bankName', 'views', 'downloads', 'bookmarks'];
    this.displayedColumnsViews = ['userName', 'userEmail', 'date', 'bookmarked', 'downloaded'];
    if (this.isUserAdmin()) {
      this.displayedColumnsPublications = [
        'eye_id', 'title', 'date', 'bankName',
        'analystName', 'pages', 'categoryName',
        'subCategoryName', 'companyName', 'rating',
        'views', 'published', 'bookmark', 'selected'];
    } else {
      this.displayedColumnsPublications = [
        'eye_id', 'title', 'date', 'bankName',
        'analystName', 'pages', 'categoryName',
        'subCategoryName', 'companyName', 'bookmark', 'selected'];
    }
    this.displayedRecommedationsColumns = [
      'eye_id', 'title', 'company', 'date', 'analystName', 'price', 'currency', 'recommendedAction',
    ];
    // Set the company id
    // Get Company
    this.publicationService
      .getCompany(this.companyId)
      .subscribe(company => {
        this.company = company;
        this.statusLabel = (this.company.status === 'active') ? 'Activo' : 'Inactivo';
        if (this.company.logo === '' || this.company.logo === null) {
          this.imageUrl = `${environment.s3Endpoint}/app/img/no_disponible.png`;
        } else {
          this.imageUrl = `${environment.s3Endpoint}/empresas/${this.companyId}/logo/${this.company.logo}?random=${Math.random()}`;
        }
      },
        err => {
          this.imageUrl = `${environment.s3Endpoint}/app/img/publication/no_disponible.png`;
        },
    );
    //
    // this.loadAnalysts();
    this.loadCategories();
    this.loadSubCategories();
    this.loadBanks();
    this.loadEsLabels();

    // Drop down list menu
    this.items = [
      {
        label: 'Descargar pdfs', icon: 'fa-download', command: () => {
          this.downloadSelected();
        },
      },
    ];
  }

  ngAfterViewInit() {
    this.loadAnalysts();
    this.loadAllAnalysts();
    this.searchPublicationViews();
    this.searchPublications();
    this.loadRecommendations();
  }

  // Get the company's analysts
  loadAnalysts() {
    this.publicationService.getCompanyAnalysts(this.companyId).subscribe(
      analysts => {
        this.analysts = analysts;
        this.dsAnalysts =
          new MatTableDataSource<Analyst>(this.analysts);
        this.dsAnalysts.paginator = this.paginatorAnalysts;
        this.dsAnalysts.sort = this.sortAnalysts;
      },
      err => {
      },
    );
  }

  loadRecommendations() {
    this.publicationService.getCompanyAnalystsLatestPublications(this.companyId)
      .subscribe(publications => {
        this.dsRecommendations =
          new MatTableDataSource<Publication>(publications);
        this.dsRecommendations.paginator = this.paginatorRecommendations;
        this.dsRecommendations.sort = this.sortRecommendations;
      });
  }

  loadAllAnalysts() {
    this.publicationService.getCachedAnalysts().subscribe(
      analysts => {
        this.allAnalysts = analysts['records'];
      },
      err => {
      },
    );
  }

  // Publication
  loadPublications() {
    this.publicationService.getCachedPublications().subscribe(
      publications => {
        if (this.isUserAdmin()) {
          this.publications = publications;
        } else {
          this.publications = publications.filter(publication => publication.published === true);
        }
      },
      err => {
        this.msgs = [];
        this.msgs.push({ severity: 'error', summary: 'Error', detail: err });
      },
    );
  }

  loadBanks() {
    this.publicationService.getCachedBanks().subscribe(
      banks => this.banks = banks['records'],
      err => {
        this.msgs = [];
        this.msgs.push({ severity: 'error', summary: 'Error', detail: err });
      },
    );
  }

  // Category
  loadCategories() {
    this.publicationService.getCategoriesSystem().subscribe(
      categories => this.categories = categories,
      err => {
        this.msgs = [];
        this.msgs.push({ severity: 'error', summary: 'Error', detail: err });
      },
    );
  }

  // Sub category
  loadSubCategories() {
    this.publicationService.getAllSubCategoriesSystem().subscribe(
      subcategories => this.subcategories = subcategories,
      err => {
        this.msgs = [];
        this.msgs.push({ severity: 'error', summary: 'Error', detail: err });
      },
    );
  }

  loadViews() {
    this.publicationService.getCompanyViews(this.companyId).subscribe(
      publicationViews => this.publicationViews = publicationViews,
      err => {
        this.msgs = [];
        this.msgs.push({ severity: 'error', summary: 'Error', detail: err });
      },
    );
  }

  // Calendar labels
  loadEsLabels() {
    this.es = {
      firstDayOfWeek: 1,
      dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
      dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
      dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
      monthNames: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio',
        'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
      monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
      today: 'Hoy',
      clear: 'Borrar',
    };
  }

  isUserAdmin() {
    return this.loginService.isUserAdmin();
  }

  searchPublications() {
    this.publicationService.searchPublications(this.buildSearchString()).subscribe(
      publications => {
        if (this.isUserAdmin()) {
          this.publications = publications;
        } else {
          this.publications = publications.filter(publication => publication.published);
        }
        this.dsPublications =
          new MatTableDataSource<Publication>(this.publications);
        this.dsPublications.paginator = this.paginatorPublications;
        this.dsPublications.sort = this.sortPublications;
        this.selectedItems = [];
      },
      err => {

      },
    );
  }

  getPublicationsExcel() {
    if (this.selectedItems.length <= 0) {
      return this.openSnackBar('Selecciona al menos una publicación');
    }
    
    this.publicationService
      .getPublicationsExcel(this.buildExcelString())
      .subscribe(blob => {
        importedSaveAs(blob, 'Publicaciones.xlsx');
      });
  }

  buildExcelString() {
    let result = '';

    if (this.selectedItems) {
      this.selectedItems.forEach(item => {
        result += `publicationId=${item}&`;
      });
    }

    return result;
  }

  getRecommendationsExcel() {
    this.publicationService
      .getCompanyRecommendationsExcel(this.companyId)
      .subscribe(blob => {
        this.loading = true;
        importedSaveAs(blob, 'Estimados.xlsx');
      }, err => {
        this.loading = false;
      }, () => {
        this.loading = false;
      });
  }

  searchPublicationViews() {
    this.publicationService
      .searchCompanyPublicationViews(this.companyId, this.buildPublicationViewSearchString())
      .finally(() => this.loading = false)
      .subscribe(
        publicationViews => {
          this.publicationViews = publicationViews;
          this.dsViews =
            new MatTableDataSource<PublicationView>(this.publicationViews);
          this.dsViews.paginator = this.paginatorViews;
          this.dsViews.sort = this.sortViews;
        },
        err => {
          this.msgs = [];
          this.msgs.push({ severity: 'error', summary: 'Error', detail: err });
        },
    );
  }

  validFilterDates() {
    let result = true;
    if (this.beginDate && this.endDate) {
      if (this.endDate >= this.beginDate) {
        result = false;
      }
    }

    return result;
  }

  buildPublicationViewSearchString() {
    let result = '';
    if (this.beginDate && this.endDate) {
      const datePipe = new DatePipe('en');
      result += `date1=${datePipe.transform(this.beginDate, 'yyyy-MM-dd')}&`;
      result += `date2=${datePipe.transform(this.endDate, 'yyyy-MM-dd')}`;
    } else {
      result += 'date1=2000-01-01&date2=2099-01-01';
    }

    return result;
  }

  clearPublicationViewsFilters() {
    this.beginDate = null;
    this.endDate = null;

    this.searchPublicationViews();
  }

  buildSearchString() {
    let result = '';

    result += `bookmarkUserId=${this.userId}&page=1&pageSize=200&`;

    if (this.title) {
      result += `title=${this.title}&`;
    }
    if (this.companyList) {
      this.companyList.forEach(company => {
        result += `companyId=${company.id}&`;
      });
    }
    if (this.bankList) {
      this.bankList.forEach(bank => {
        result += `bankId=${bank.id}&`;
      });
    }
    if (this.analystList) {
      this.analystList.forEach(analyst => {
        result += `analystId=${analyst.id}&`;
      });
    }
    if (this.categoryList) {
      this.categoryList.forEach(category => {
        result += `categoryId=${category.id}&`;
      });
    }
    if (this.subcategoryList) {
      this.subcategoryList.forEach(subcategory => {
        result += `subcategoryId=${subcategory.id}&`;
      });
    }
    // if (this.dateRange) {
    //   let x = 1;
    //   const datePipe = new DatePipe('en');
    //   this.dateRange.forEach(date => {
    //     result += `date${x}=${datePipe.transform(date, 'yyyy-MM-dd')}&`;
    //     if (x === 1) {
    //       x = 2;
    //     }
    //   });
    // } else {
    result += 'date1=2000-01-01&date2=2099-01-01';
    // }

    return result;
  }

  buildDownloadSearchStringList() {
    let result: any[] = [];
    const publicationsWithFile = this.publicationsWithFile();
    this.selectedItems.forEach(id => {
      if (publicationsWithFile.indexOf(id) > -1) {
        result.push(id);
      }
    });    
    return result;
  }

  downloadSelected() {
    const searchString = this.buildDownloadSearchStringList();
    if (searchString.length === 0) {
      this.openSnackBar('Las publicaciones seleccionadas no tienen ningún archivo asociado');
    } else {
      for(var i in searchString){
        this.publicationService.downloadFile(searchString[i]).finally(() => this.loading = false).subscribe(
          blob => {
            importedSaveAs(blob, `Documento.pdf`);
          }, err => {
          }, () => {
            let publicationDownloadOperation: Observable<Object>;
            publicationDownloadOperation = this.publicationService.postDownload(
              searchString[i], this.publicationDownload);
          },
        );
      }
    }
  }

  // downloadSelected() {
  //   const searchString = this.buildDownloadSearchStringList();
  //   if (searchString.length === 0) {
  //     this.openSnackBar('Las publicaciones seleccionadas no tienen ningún archivo asociado');
  //   } else {
  //     this.publicationService.downloadFiles(searchString).subscribe(
  //       blob => {
  //         importedSaveAs(blob, 'Publicaciones.zip');
  //         this.selectedPublications.forEach(id => {
  //           this.publicationDownload = new PublicationDownload(
  //             parseInt(id), this.loginService.getUserId(), new Date());
  //           let publicationDownloadOperation: Observable<Object>;
  //           publicationDownloadOperation = this.publicationService.postDownload(
  //             parseInt(id), this.publicationDownload);

  //           publicationDownloadOperation.subscribe(
  //             publicationDownload => {
  //               // this.getDownloadCount();
  //             },
  //             err => {
  //             },
  //           );
  //         });
  //       },
  //     );
  //   }
  // }

  buildDownloadSearchString() {
    let result = '';
    const publicationsWithFile = this.publicationsWithFile();
    
    this.selectedItems.forEach(id => {
      if (publicationsWithFile.indexOf(id) > -1) {
        result += `list=${id}&`;
      }      
    });
    

    return result;
  }

  publicationsWithFile() {
    return this.publications.filter(publication => publication.hasFile).map(m => m.id).map(String);
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, '', {
      duration: 2000,
    });
  }

  clearPublications() {
    // Clear filter fields
    this.id = null;
    this.title = null;
    this.bank = null;
    this.bankList = [];
    this.analyst = null;
    this.analystList = [];
    this.category = null;
    this.categoryList = [];
    this.subcategory = null;
    this.subcategoryList = [];
    this.rating = null;
    this.searchTags = [];
    this.selectedItems = [];

    this.searchPublications();
  }

  clearPublicationViews() {
    this.publicationViewsDateRange = null;
    this.publicationService
      .searchCompanyPublicationViews(this.companyId, this.buildPublicationViewSearchString()).subscribe(
        publicationViews => this.publicationViews = publicationViews,
        err => {
          this.msgs = [];
          this.msgs.push({ severity: 'error', summary: 'Error', detail: err });
        },
    );
  }

  refreshData() {
    this.clearPublications();
  }

  selectAllPublications() {
    this.selectedItems = [];
    if (this.selectAllValue) {
      this.publications.forEach(publication => {
        this.selectedItems.push(publication['id'].toString());
      });
      this.selectedPublications = this.selectedItems;
    } else {
      this.selectedPublications = [];
    }
  }

  toInt(num: string) {
    return +num;
  }

  // Chip events
  dropDownBankChange() {
    this.searchTags = this.searchTags.filter(item => item.name !== 'bank');
    this.searchTags.push({ name: 'bank', value: this.bankList.map(bank => bank.name).join(', ') });
  }

  dropDownAnalystChange() {
    this.searchTags = this.searchTags.filter(item => item.name !== 'analyst');
    this.searchTags.push({ name: 'analyst', value: this.analystList.map(analyst => analyst['fullName']).join(', ') });
  }

  dropDownCategoryChange() {
    this.searchTags = this.searchTags.filter(item => item.name !== 'category');
    this.searchTags.push({ name: 'category', value: this.categoryList.map(category => category.name).join(', ') });
  }

  dropDownSubCategoryChange() {
    this.searchTags = this.searchTags.filter(item => item.name !== 'subcategory');
    this.searchTags
      .push({ name: 'subcategory', value: this.subcategoryList.map(subcategory => subcategory.name).join(', ') });
  }

  rateChange() {
    this.searchTags = this.searchTags.filter(item => item.name !== 'rating');
    this.searchTags.push({ name: 'rating', value: this.rating });
  }

  titleChange(e) {
    if (this.title != '') {
      this.searchTags = this.searchTags.filter(item => item.name !== 'title');
      this.searchTags.push({ name: 'title', value: this.title });
    }
  }

  calendarChange() {
    let result = '';
    this.searchTags = this.searchTags.filter(item => item.name !== 'date');
    let x = 1;
    const datePipe = new DatePipe('en');
    this.dateRange.forEach(date => {
      result += `${datePipe.transform(date, 'MM/dd/yyyy')} - `;
      if (x === 1) {
        x = 2;
      }
    });
    this.searchTags.push({ name: 'date', value: result });
  }

  removeTag(searchTag: any) {
    const index = this.searchTags.indexOf(searchTag);

    if (index >= 0) {
      this.searchTags.splice(index, 1);
    }

    switch (searchTag.name) {
      case 'bank':
        this.searchTags = this.searchTags.filter(item => item.name !== 'bank');
        this.bank = null;
        this.bankList = [];
        break;
      case 'company':
        this.searchTags = this.searchTags.filter(item => item.name !== 'company');
        break;
      case 'analyst':
        this.searchTags = this.searchTags.filter(item => item.name !== 'analyst');
        this.analyst = null;
        this.analystList = [];
        break;
      case 'category':
        this.searchTags = this.searchTags.filter(item => item.name !== 'category');
        this.category = null;
        this.categoryList = [];
        break;
      case 'subcategory':
        this.searchTags = this.searchTags.filter(item => item.name !== 'subcategory');
        this.subcategory = null;
        this.subcategoryList = [];
        break;
      case 'rating':
        this.searchTags = this.searchTags.filter(item => item.name !== 'rating');
        this.rating = 0;
        break;
      case 'title':
        this.searchTags = this.searchTags.filter(item => item.name !== 'title');
        this.title = '';
        break;
      case 'date':
        this.searchTags = this.searchTags.filter(item => item.name !== 'date');
        this.dateRange = null;
        break;
    }

    this.publicationService.searchPublications(this.buildSearchString()).subscribe(
      publications => {
        if (this.isUserAdmin()) {
          this.publications = publications;
        } else {
          this.publications = publications.filter(publication => publication.published);
        }
        this.dsPublications =
          new MatTableDataSource<Publication>(this.publications);
        this.dsPublications.paginator = this.paginatorPublications;
        this.dsPublications.sort = this.sortPublications;
        this.selectedItems = [];
      },
      err => {
      },
    );
  }

  // onChange(event) {
  //   if (!(event.keyCode === 8 || event.keyCode === 46)) {
  //     return false;
  //   }
  // }

  // onSelectRow(row) {
  // }

  onSelectPublicaton(id) {
    const index = this.selectedItems.indexOf(id.toString());

    if (event.target['checked']) {
      if (index < 0) {
        this.selectedItems.push(id.toString());
      }
    } else {
      if (index >= 0) {
        this.selectedItems.splice(index, 1);
      }
    }
  }

  openDialog() {
    const dialog = this.dialog.open(CatalogCompanyModalEditComponent, {
      height: '500px',
      width: '800px',
      closeOnNavigation: true,
      data: { company: this.company },
    });

    dialog.afterClosed()
      .pipe(filter(data => data))
      .subscribe(data => {
        let companyOperation: Observable<Company>;
        companyOperation = this.publicationService
          .updateCompany(this.companyId, data['company']);
        companyOperation.subscribe(
          company => {
            if (data['imgFile']) {
              this.postLogo(this.company.id, data['imgFile']);
            }
            this.company = company;
          },
          err => {
          },
        );
      },
    );
  }

  postLogo(companyid: number, file: any) {
    const headers = new HttpHeaders({ 'Authorization':'Bearer '+localStorage.getItem('token'), 'Cache-Control':  'no-cache, no-store, private' });
    const formData = new FormData();
    formData.append('file', file);
    formData.append('type', 'company');
    formData.append('id', companyid.toString());
    this._HttpClient.post(`${environment.devApiEndpoint}/file/logo`, formData, {headers})
      .map(response => response)
      .subscribe(result => {
        this.publicationService
        .getCompany(this.companyId)
        .subscribe(company => {
          this.company = company;
          this.statusLabel = (this.company.status === 'active') ? 'Activo' : 'Inactivo';
          if (this.company.logo === '' || this.company.logo === null) {
            this.imageUrl = `${environment.s3Endpoint}/app/img/no_disponible.png`;
          } else {
            this.imageUrl = `${environment.s3Endpoint}/empresas/${this.companyId}/logo/${this.company.logo}?random=${Math.random()}`;
          }
        },
        err => {
        },
        () => {
          this.imageUrl = `${environment.s3Endpoint}/empresas/${this.companyId}/logo/${this.company.logo}?random=${Math.random()}`;
        },
      );
      });
  }

  isActive() {
    if (this.company.status === 'active') {
      return true;
    } else {
      return false;
    }
  }

  statusChange(event) {
    this.status = new Status(null);
    this.status.st = (event.checked) ? 'active' : 'inactive';
    this.publicationService
      .updateStatus(this.company.id, 'company', this.status)
      .subscribe(company => {
        this.company = company;
        this.statusLabel = (this.company.status === 'active') ? 'Activo' : 'Inactivo';
      });
  }

  preferenceChange(event) {
    this.preference = new Preference(null);
    this.preference.pf = event.checked;
    this.publicationService
      .updatePreference(this.company.id, 'company', this.preference)
      .subscribe(company => {
        this.company = company;
      });
  }

  backToList() {
    this.location.back();
  }

  // Bookmark
  postBookmark(publicationId) {
    this.publicationBookmark = new PublicationBookmark(
      publicationId, this.loginService.getUserId(), new Date());
    this.publicationService
      .postBookmark(publicationId, this.publicationBookmark)
      .subscribe(res => {
        this.searchPublications();
        // this.isBookmarked = true;
        // this.getBookmarkCount();
        // this.getViews();
        // this.openSnackBar('Agregado a favoritos');
      },
    );
  }

  deleteBookmark(publicationId) {
    this.publicationService
      .deleteBookmark(publicationId, this.loginService.getUserId())
      .subscribe(res => {
        this.searchPublications();
        // this.getBookmarkCount();
        // this.getViews();
        // this.openSnackBar('Borrado de favoritos');
      },
    );
  }

  removeAll(type: string){
    if(type === 'start'){
      this.startDateInput.nativeElement.value = '';
    } else {
      this.endDateInput.nativeElement.value = '';
    }
    
  }

}

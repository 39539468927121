import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { Category } from '../shared/models/category';
import { NewUserCategory } from '../shared/models/new/user.category.new';
import { FormGroup, Validators, FormControl, FormBuilder } from '@angular/forms';
import { PublicationService } from '../shared/services/publication.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-user-modal-category',
  templateUrl: './user-modal-category.component.html',
  styleUrls: ['./user-modal-category.component.scss']
})
export class UserModalCategoryComponent implements OnInit {

  userId: number;
  category: Category;
  categories: Category[];
  form: FormGroup;
  model = new Category(null, null, null, null);
  userCategory = new NewUserCategory(null, null);

  constructor(
    fb: FormBuilder,
    private publicationService: PublicationService,
    public dialogRef: MatDialogRef<UserModalCategoryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.userId = data['userId'];

    this.form = fb.group({
      'category': ['', Validators.compose([Validators.required])],
    });

  }

  ngOnInit() {
    this.loadCategories();
  }

  // Category
  loadCategories() {
    //this.publicationService.getCategoriesPreferences().subscribe(
    this.publicationService.getCategories().subscribe(
      //categories => this.categories = categories.filter(category => category.status === 'active'),
      categories => this.categories = categories['records'],
      err => {
      },
    );
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    this.data = {
      userCategory: { userId: this.userId, categoryId: this.model.id },
    };
    this.dialogRef.close(this.data);
  }

}

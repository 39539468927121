import { CatalogBankModalComponent } from './../catalog-bank-modal/catalog-bank-modal.component';
import { LoginService } from '../shared/services/login.service';
import { Http, RequestOptions, Headers } from '@angular/http';
import { Bank } from '../shared/models/bank';
import { Component, OnInit, Input, OnChanges, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { PublicationService } from '../shared/services/publication.service';
import { forEach } from '@angular/router/src/utils/collection';
import { PublicationTag } from '../shared/interfaces/publication.tag.interface';
import { filter } from 'rxjs/operators';
import {
  MatPaginator, MatTableDataSource, MatPaginatorIntl,
  MatSnackBar, MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSort, MatDialogConfig,
} from '@angular/material';
import { Router } from '@angular/router';
import { saveAs as importedSaveAs } from 'file-saver';
import { Status } from '../shared/models/helpers/status';
import { Preference } from '../shared/models/helpers/preference';
import { environment } from '../../environments/environment';
import { Sort } from '@angular/material';
import { HttpClient, HttpHeaders } from '@angular/common/http';

// paginacion
import { ActivatedRoute } from '@angular/router';
import { debounceTime, distinctUntilChanged, startWith, tap, delay } from 'rxjs/operators';
import { merge } from 'rxjs/observable/merge';
import { fromEvent } from 'rxjs/observable/fromEvent';
import { BankData } from '../shared/services/bankdata.service';

@Component({
  selector: 'app-catalog-bank',
  templateUrl: './catalog-bank.component.html',
  styleUrls: ['./catalog-bank.component.scss'],
})
export class CatalogBankComponent implements OnInit, AfterViewInit, OnDestroy {

  modalConfig: any;
  assetsEndpoint: string = environment.assetsEndpoint;
  devApiEndpoint: string = environment.devApiEndpoint;

  showFilters: Boolean = true;
  st: Status;
  pf: Preference;
  loading: Boolean = false;
  bank: Bank;
  status: any;
  banks: Bank[];
  statuses: any;
  name = '';
  id: number;
  searchTags: PublicationTag[] = [];
  datasource;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  data: any[];
  displayedColumns = [];
  sortFill: string = 'name';
  sortOrder: Boolean = false;

  // paginacion
  dataSourceB: BankData;
  totalbank = 0;

  constructor(
    private publicationService: PublicationService,
    private loginService: LoginService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private http: Http,
    private router: Router,
    public _HttpClient: HttpClient) {
    this.modalConfig = new MatDialogConfig();
    this.modalConfig.height = '350px';
    this.modalConfig.width = '800px';
    this.modalConfig.closeOnNavigation = true;
  }

  ngOnInit() {

    if (this.isUserAdmin()) {
      this.displayedColumns = ['eye_id', 'name', 'views', 'bookmarks', 'downloads', 'preferences', 'statusF'];
    } else {
      this.displayedColumns = ['name'];
    }
    this.loadStatuses();
    this.dataSourceB = new BankData(this.publicationService, this.loginService);
    this.loadBankPage();
  }

  ngOnDestroy(){
    this.dialog.closeAll();
  }

  ngAfterViewInit() {
    
        merge(this.sort.sortChange, this.paginator.page)
        .pipe(
            tap(() => this.loadBankPage()),
        )
        .subscribe();
  }

  // paginacion
  loadBankPage() {
      this.setOrder(this.sort.active, this.sort.direction);
      this.dataSourceB.loadBanks(this.buildSearchString());
      this.getTotalBank();

  }

  setOrder(fill, order) {
    this.sortOrder = (order == 'desc') ? true : false;
    switch (fill) {
      case 'name':
        this.sortFill = 'Bank';
        break;
      case 'views':
        this.sortFill = 'Views';
        break;
      case 'bookmarks':
        this.sortFill = 'Bookmarks';
        break;
      case 'downloads':
        this.sortFill = 'Downloads';
        break;
      case 'statusF':
        this.sortFill = 'Status';
        break;
      case 'preferences':
        this.sortFill = 'Preferences';
        break;
      default:
        this.sortFill = 'name';
        this.sortOrder = false;
        break;
    }
  }

  getTotalBank() {
      this.publicationService.getTotalB(this.buildSearchString()).subscribe(
        total => this.totalbank = total,
      );
  }

  // Image
  getLogoUrl(bankId, url) {
    if (url) {
      return `${environment.s3Endpoint}/bancos/${bankId}/logo/${url}`;
    } else {
      return `${environment.s3Endpoint}/app/img/no_disponible.png`;
    }
  }

  // Bank
  loadBanks() {
    this.publicationService.getCachedBanks().subscribe(
      banks => {
        this.data = banks,
        this.banks = banks;
          this.datasource =
          new MatTableDataSource<Bank>(this.data);
        this.datasource.paginator = this.paginator;
        this.datasource.sort = this.sort;
      },
      err => {
      },
    );
  }

  // Status
  loadStatuses() {
    this.statuses = [
      { label: 'Activo', value: 'active' },
      { label: 'Inactivo', value: 'inactive' },
    ];
  }

  showCreateModal() {
    const dialog = this.dialog.open(CatalogBankModalComponent, this.modalConfig);

    dialog.afterClosed()
      .pipe(filter(data => data))
      .subscribe(data => {
        let bankOperation: Observable<Bank>;
        bankOperation = this.publicationService.addBank(data['bank']);
        bankOperation.subscribe(
          bank => {
            this.bank = bank;
            if (data['imgFile']['size'] > 0) {
              this.postLogo(this.bank.id, data['imgFile']);
            }
            setTimeout(() => this.router.navigate([`/catalog/bank/${this.bank['id']}`]), 1000);
          },
          err => {
            this.openSnackBar(err.error.message);
          },
        );
      },
    );
  }

  postLogo(bankid: number, file: any) {
    const headers = new HttpHeaders({ 'Authorization':'Bearer '+localStorage.getItem('token'), 'Cache-Control':  'no-cache, no-store, private' });
    const formData = new FormData();
    formData.append('file', file);
    formData.append('type', 'bank');
    formData.append('id', bankid.toString());
    this._HttpClient.post(`${this.devApiEndpoint}/file/logo`, formData, {headers})
      .map(response => response['ok'])
      .subscribe(result => { }, err => { }, () => {
        setTimeout(() => this.router.navigate([`/catalog/bank/${this.bank['id']}`]), 500);
      });
  }

  clear() {

    // Clear filter fields
    this.id = null;
    this.name = '';
    this.status = null;
    this.bank = null;
    this.searchTags = [];

    this.loadBankPage();
  }

  refreshData() {
    this.clear();
  }

  // Search
  search() {

    this.loadBankPage();
  }

  buildSearchString() {
    const page = this.paginator.pageIndex + 1;
    const pageSize = (this.paginator.pageSize == undefined) ? 20 : this.paginator.pageSize;
    let result = `page=${page}&PageSize=${pageSize}&OrderBy=${this.sortFill}&OrderDesc=${this.sortOrder}`;
    if (this.id) {
      result += `&id=${this.id}`;
    }
    if (this.name) {
      result += `&name=${this.name}`;
    }
    if (this.status) {
      result += `&status=${this.status.value}`;
    }

    return result;
  }

  isUserAdmin() {
    return this.loginService.isUserAdmin();
  }

  // Chip events
  dropDownStatusChange() {
    this.searchTags = this.searchTags.filter(item => item.name !== 'status');
    this.searchTags.push({ name: 'status', value: this.status.label });
  }

  nameChange(e) {
    if (this.name !== '') {
      this.searchTags = this.searchTags.filter(item => item.name !== 'name');
      this.searchTags.push({ name: 'name', value: this.name });
    }    
  }


  removeTag(searchTag: any) {
    const index = this.searchTags.indexOf(searchTag);

    if (index >= 0) {
      this.searchTags.splice(index, 1);
    }

    switch (searchTag.name) {
      case 'name':
        this.searchTags = this.searchTags.filter(item => item.name !== 'name');
        this.name = null;
        break;
      case 'status':
        this.searchTags = this.searchTags.filter(item => item.name !== 'status');
        this.status = null;
        break;
      case 'id':
        this.searchTags = this.searchTags.filter(item => item.name !== 'id');
        this.id = null;
        break;
    }

    this.loadBankPage();
  }

  onChange(event) {
    if (!(event.keyCode === 8 || event.keyCode === 46)) {
      return false;
    }

  }

  getExcel() {


    this.publicationService
      .getBanksExcel(this.buildSearchString())
      .subscribe(blob => {
        importedSaveAs(blob, 'Bancos.xlsx');
      });
  }

  statusChange(id, event) {
    this.st = new Status(null);
    this.st.st = (event.checked) ? 'active' : 'inactive';
    this.publicationService
      .updateStatus(id, 'bank', this.st)
      .subscribe(data => this.search());
  }

  preferenceChange(id, event) {
    this.pf = new Preference(null);
    this.pf.pf = (event.checked) ? true : false;
    this.publicationService
      .updatePreference(id, 'bank', this.pf)
      .subscribe(data => this.search());
  }

  toggleFilters() {
    this.showFilters = (this.showFilters) ? false : true;
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, '', {
      duration: 2000,
    });
  }

  sortData(sort: Sort) {
    const data = this.data.slice();

    if (!sort.active || sort.direction === '') {
      this.datasource =
        new MatTableDataSource<Bank>(this.banks);
      // this.datasource.paginator = this.paginator;
      // this.datasource.sort = this.sort;
      return;
    }

    const d = this.data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'name': return this.compare(a.name.toLowerCase(), b.name.toLowerCase(), isAsc);
        case 'views': return this.compare(+a['views'], +b['views'], isAsc);
        case 'bookmarks': return this.compare(+a['bookmarks'], +b['bookmarks'], isAsc);
        case 'downloads': return this.compare(+a['downloads'], +b['downloads'], isAsc);
        case 'statusF': return this.compare(a.statusF.toLowerCase(), b.statusF.toLowerCase(), isAsc);
        case 'preferences': return this.compare(a.preference.toLowerCase(), b.preference.toLowerCase(), isAsc);
        default: return 0;
      }
    });

    this.datasource =
      new MatTableDataSource<Bank>(d);
    // this.datasource.paginator = this.paginator;
    // this.datasource.sort = sort;
  }

  compare(a, b, isAsc) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  compareString(a: string, b: string, isAsc) {
    // return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    const operation = a.toLowerCase().localeCompare(b.toLowerCase());

    if (operation === 0) {
      return a.localeCompare(b);
    }
    return operation * (isAsc ? 1 : -1);
  }
}

import { PublicationService } from './../shared/services/publication.service';
import { Location } from '@angular/common';
import { LoginService } from '../shared/services/login.service';
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable()
export class PublishedGuard implements CanActivate {

    isPublished: Boolean;

    constructor(private router: Router,
        private loginService: LoginService,
        private location: Location,
        private publicationService: PublicationService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.loginService.isUserAdmin()) {
            return true;
        }
        
        this.publicationService.getPublication(route.params['id']).subscribe(publication => {
            this.isPublished = publication.published;
            if (this.isPublished) {
                this.router.navigate([`/publication/${route.params['id']}/preview`]);
            } else {
                this.location.back();
            }
        }, err => {
            this.location.back();
        });

        // this.router.navigate([`/publication/${route.params['id']}/preview`]);
        return false;
    }
}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PublicationBookmark } from '../models/publication.bookmark';
import { ResponseContentType } from '@angular/http';
import { PublicationImage } from '../models/publication.image';
import { PublicationFile } from '../models/publication.file';
import { StockImage } from '../models/stockImage';
import { PublicationView } from '../models/publication.view';
import { PublicationDownload } from '../models/publication.download';
import { RecommendedAction } from '../models/recommendedAction';
import { Currency } from '../models/currency';
import { Category } from '../models/category';

import { Injectable } from '@angular/core';
import { RequestOptions } from '@angular/http';
import { Observable } from 'rxjs/Rx';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { Bank } from '../models/bank';
import { Company } from '../models/company';
import { SubCategory } from '../models/subcategory';
import { PublicationTargetPrice } from '../models/publication.targetprice';
import { User } from '../models/user';
import { UserBank } from '../models/user.bank';
import { UserAnalyst } from '../models/user.analyst';
import { UserCompany } from '../models/user.company';
import { UserCategory } from '../models/user.category';
import 'rxjs/add/operator/publishReplay';
import { environment } from '../../../environments/environment';
import { Publication } from '../models/publication';
import { Analyst } from '../models/analyst';
import { integer } from 'aws-sdk/clients/cloudfront';

@Injectable()
export class PublicationService {

    _publications: Observable<any> = null;
    _banks: Observable<any> = null;
    _analysts: Observable<any> = null;
    _companies: Observable<any> = null;
    _banksP: Observable<any> = null;
    _analystsP: Observable<any> = null;
    _companiesP: Observable<any> = null;
    _totalPublication: Observable<any> = null;
    _totalAnalyst: Observable<any> = null;
    _totalBank: Observable<any> = null;
    _totalCompany: Observable<any> = null;
    _totalCategory: Observable<any> = null;

    getCachedBanks() {
        // const token = localStorage.getItem('token');
        // headers.append('Authorization', `Bearer ${token}`);
        // const options = new RequestOptions({ headers: headers });

        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private', 
        'Authorization':'Bearer '+localStorage.getItem('token') });

        //if (!this._banks) {
            //this._banks = this.http.get(`${this.baseUrl}/bank/list`)
            this._banks = this.http.get(`${this.baseUrl}/bank/search?page=1&PageSize=1000&OrderBy=name&OrderDesc=false&status=active`, {headers})
                // .map((res: Response) => res.json())
                //.do(() => this.console('Fetched banks'))
                .publishReplay(1)
                .refCount()
                .catch((error: any) => Observable.throw( 'Error retrieving bank list'));
        //}

        return this._banks;
    }

    getCachedBanksSystem() {
        // const token = localStorage.getItem('token');
        // headers.append('Authorization', `Bearer ${token}`);
        // const options = new RequestOptions({ headers: headers });

        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private', 
        'Authorization':'Bearer '+localStorage.getItem('token') });

        //if (!this._banks) {
            this._banks = this.http.get(`${this.baseUrl}/bank/list`, {headers})
                // .map((res: Response) => res.json())
                //.do(() => this.console('Fetched banks'))
                .publishReplay(1)
                .refCount()
                .catch((error: any) => Observable.throw( 'Error retrieving bank list'));
        //}

        return this._banks;
    }

    getCachedAnalysts() {
        // const token = localStorage.getItem('token');
        // headers.append('Authorization', `Bearer ${token}`);
        // const options = new RequestOptions({ headers: headers });

        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private', 
        'Authorization':'Bearer '+localStorage.getItem('token') });

        //if (!this._analysts) {
            //this._analysts = this.http.get(`${this.baseUrl}/analyst/list`)
            this._analysts = this.http.get(`${this.baseUrl}/analyst/search?page=1&PageSize=1000&OrderBy=name&OrderDesc=false&status=active`, {headers})
                // .map((res: Response) => res.json())
                //.do(() => this.console('Fetched analysts'))
                .publishReplay(1)
                .refCount()
                .catch((error: any) => Observable.throw(error || 'Error retrieving analyst list'));
        //}

        return this._analysts;
    }

    getCachedAnalystsSystem() {
        // const token = localStorage.getItem('token');
        // headers.append('Authorization', `Bearer ${token}`);
        // const options = new RequestOptions({ headers: headers });

        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private', 
        'Authorization':'Bearer '+localStorage.getItem('token') });

        //if (!this._analysts) {
            this._analysts = this.http.get(`${this.baseUrl}/analyst/list`, {headers})
                // .map((res: Response) => res.json())
                //.do(() => this.console('Fetched analysts'))
                .publishReplay(1)
                .refCount()
                .catch((error: any) => Observable.throw(error || 'Error retrieving analyst list'));
        //}

        return this._analysts;
    }

    getCachedCompanies() {
        // const token = localStorage.getItem('token');
        // headers.append('Authorization', `Bearer ${token}`);
        // const options = new RequestOptions({ headers: headers });

        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private', 
        'Authorization':'Bearer '+localStorage.getItem('token') });

        //if (!this._companies) {
            //this._companies = this.http.get(`${this.baseUrl}/company/list`)
            this._companies = this.http.get(`${this.baseUrl}/company/search?page=1&PageSize=1000&OrderBy=name&OrderDesc=false&status=active`, {headers})
                // .map((res: Response) => console.log(res.json()))
                //.do(() => this.console('Fetched companies'))
                .publishReplay(1)
                .refCount()
                .catch((error: any) => Observable.throw( 'Error retrieving company list'));
        //}

        return this._companies;
    }

    getCachedCompaniesSystem() {
        // const token = localStorage.getItem('token');
        // headers.append('Authorization', `Bearer ${token}`);
        // const options = new RequestOptions({ headers: headers });

        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private', 
        'Authorization':'Bearer '+localStorage.getItem('token') });

        //if (!this._companies) {
            this._companies = this.http.get(`${this.baseUrl}/company/list`, {headers})
                // .map((res: Response) => console.log(res.json()))
                //.do(() => this.console('Fetched companies'))
                .publishReplay(1)
                .refCount()
                .catch((error: any) => Observable.throw( 'Error retrieving company list'));
        //}

        return this._companies;
    }

    getCachedPublications() {
        const headers = new HttpHeaders({ 'Content-type': 'application/json',
        'Cache-Control':  'no-cache, no-store, private', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT' });
        const token = localStorage.getItem('token');
        headers.append('Authorization', `Bearer ${token}`);
        // const options = new RequestOptions({ headers: headers });

        if (!this._publications) {
            this._publications = this.http.get(`${this.baseUrl}/publication`, { headers })
                // .map((res: Response) => res.json())
                //.do(() => this.console('Fetched publications'))
                .publishReplay(1)
                .refCount()
                .catch((error: any) => Observable.throw( 'Error retrieving publication list'));
        }

        return this._publications;
    }

    clearCache() {
        this._analysts = null;
        this._banks = null;
        this._companies = null;
        this._publications = null;
    }

    constructor(private http: HttpClient) { }

    private baseUrl = environment.devApiEndpoint;

    searchPublications(searchString: string): Observable<Publication[]> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private', 
        'Authorization':'Bearer '+localStorage.getItem('token') });
        return this.http.get(`${this.baseUrl}/publication/search?${searchString}`, { headers })
            .map(res => {
                this._totalCompany = res['totalRecords'];
                return res['records'];
            })
            .catch((error: any) => Observable.throw( 'Server error'));
    }

    searchSumaryPublications(searchString: string): Observable<Publication[]> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private', 
        'Authorization':'Bearer '+localStorage.getItem('token') });
        return this.http.get(`${this.baseUrl}/publication/search/summary?${searchString}`, {headers})
            // .map((res: Response) => res.json())
            .catch((error: any) => Observable.throw( 'Server error'));
    }

    getPublications(searchString: string): Observable<Publication[]> {
        //const bodyString = JSON.stringify({ userId });
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private', 
        'Authorization':'Bearer '+localStorage.getItem('token') });

        return this.http.get(`${this.baseUrl}/publication/allpublications?${searchString}`, { headers })
            // .map((res: Response) => res.json())
            .publishReplay(1)
            .refCount()
            .catch((error: any) => Observable.throw( 'Server error'));
    }

    getPublicationsByIds(searchString: string): Observable<Publication[]> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private', 
        'Authorization':'Bearer '+localStorage.getItem('token') });
        // const params = new URLSearchParams();
        // params.append('ids', ids.toString());
        // const options = new RequestOptions({ headers: headers });
        return this.http.get(`${this.baseUrl}/publication/ids?ids=${searchString}`, { headers })
            // .map((res: Response) => res.json())
            .catch((error: any) => Observable.throw( 'Error retrieving publication list'));
    }

    getPublicationsExcel(searchString: string): Observable<Blob> {
        const headers = new HttpHeaders({ 'Content-type': 'blob', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private', 
        'Authorization':'Bearer '+localStorage.getItem('token') });
        return this.http.get(`${this.baseUrl}/publication/excel?${searchString}`, { responseType: 'blob', headers })
            // .map((res: Response) => res.blob())
            .catch((error: any) => Observable.throw( 'Error retrieving file'));
    }

    searchExcel(searchString: string): Observable<Blob> {
        const headers = new HttpHeaders({ 'Content-type': 'blob', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private',  
        'Authorization':'Bearer '+localStorage.getItem('token') });
        return this.http.get(`${this.baseUrl}/publication/searchexcel?${searchString}`, { responseType: 'blob', headers })
            // .map((res: Response) => res.json())
            .catch((error: any) => Observable.throw( 'Server error'));
    }

    getTopPublications(searchString: string): Observable<Publication[]> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private', 
        'Authorization':'Bearer '+localStorage.getItem('token') });
        return this.http.get(`${this.baseUrl}/publication/top?${searchString}`, { headers })
            // .map((res: Response) => res.json())
            .catch((error: any) => Observable.throw( 'Error retrieving publication list'));
    }

    getPublication(id: number): Observable<Publication> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private', 
        'Authorization':'Bearer '+localStorage.getItem('token') });
        return this.http.get(`${this.baseUrl}/publication/${id.toString()}`, { headers })
            // .map((res: Response) => res.json())
            .publishReplay(1)
            .refCount()
            .catch((error: any) => Observable.throw(error));
    }

    addPublication(body: Object): Observable<Publication> {
        this.clearCache();
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private', 
        'Authorization':'Bearer '+localStorage.getItem('token') });
        // const options = new RequestOptions({ headers: headers });

        return this.http.post(`${this.baseUrl}/publication/new`, body, { headers })
            // .map((res: Response) => res.json())
            .catch((error: any) => Observable.throw( 'Error saving the publication'));
    }

    updatePublication(id: number, body: Object): Observable<Publication> {
        this.clearCache();
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private', 
        'Authorization':'Bearer '+localStorage.getItem('token') });
        // const options = new RequestOptions({ headers: headers });

        return this.http.put(`${this.baseUrl}/publication/${id.toString()}`, body, { headers })
            // .map((res: Response) => res.json())
            .catch((error: any) => Observable.throw( 'Error updating the publication'));
    }

    publishPublication(id: number): Observable<Publication> {
        this.clearCache();
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private', 
        'Authorization':'Bearer '+localStorage.getItem('token') });
        // const options = new RequestOptions({ headers: headers });

        return this.http.post(`${this.baseUrl}/publication/${id}/publish`, null, { headers })
            // .map((res: Response) => res.json())
            .catch((error: any) => Observable.throw( 'Error publishing the publication'));
    }

    massPublish(body: Object): Observable<any> {
        this.clearCache();
        const headers = new HttpHeaders({ 'Authorization':`Bearer ${localStorage.getItem('token')}` });

        return this.http.post(`${this.baseUrl}/publication/masspublish`, body, { headers })
            // .map((res: Response) => res.json())
            .catch((error: any) => Observable.throw( 'Error during the mass publish'));
    }

    // Bank
    getBanks(): Observable<Bank[]> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private',  
        'Authorization':'Bearer '+localStorage.getItem('token') });
        return this.http.get(`${this.baseUrl}/bank`, { headers })
            // .map((res: Response) => res.json())
            // .do(banks => console.log(banks))
            .catch((error: any) => Observable.throw( 'Error retrieving bank list'));
    }

    getTopBanks(searchString: string): Observable<Bank[]> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private',  
        'Authorization':'Bearer '+localStorage.getItem('token') });
        return this.http.get(`${this.baseUrl}/bank/top?${searchString}`, { headers })
            // .map((res: Response) => res.json())
            .catch((error: any) => Observable.throw( 'Error retrieving bank list'));
    }

    getBanksExcel(searchString: string): Observable<Blob> {
        const headers = new HttpHeaders({ 'Content-type': 'blob', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private', 
        'Authorization':'Bearer '+localStorage.getItem('token') });
        return this.http.get(`${this.baseUrl}/bank/excel?${searchString}`, { responseType: 'blob', headers })
            // .map((res: Response) => res.blob())
            .catch((error: any) => Observable.throw( 'Error retrieving file'));
    }

    getBankRecommendationsExcel(bankId: number): Observable<Blob> {
        const headers = new HttpHeaders({ 'Content-type': 'blob', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private', 
        'Authorization':'Bearer '+localStorage.getItem('token') });
        return this.http.get(`${this.baseUrl}/bank/${bankId}/recommendations/excel`, { responseType: 'blob', headers })
            // .map((res: Response) => res.blob())
            .catch((error: any) => Observable.throw( 'Error retrieving file'));
    }

    getBank(id: number): Observable<Bank> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private', 
        'Authorization':'Bearer '+localStorage.getItem('token') });
        return this.http.get(`${this.baseUrl}/bank/${id.toString()}`, { headers })
            // .map(response => res)
            .catch((error: any) => Observable.throw(error));
    }

    getBankAnalysts(id: number): Observable<Analyst[]> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private', 
        'Authorization':'Bearer '+localStorage.getItem('token') });
        return this.http.get(`${this.baseUrl}/bank/${id.toString()}/analysts`, { headers })
            // .map((res: Response) => res.json())
            .catch((error: any) => Observable.throw( 'Error retrieving analyst list'));
    }

    getBankRecommendations(id: number): Observable<Publication[]> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private',  
        'Authorization':'Bearer '+localStorage.getItem('token') });
        return this.http.get(`${this.baseUrl}/bank/${id.toString()}/publications`, { headers })
            // .map((res: Response) => res.json())
            .catch((error: any) => Observable.throw( 'Error retrieving publication list'));
    }

    getBankViews(id: number): Observable<PublicationView[]> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private',  
        'Authorization':'Bearer '+localStorage.getItem('token') });
        return this.http.get(`${this.baseUrl}/bank/${id.toString()}/search/view`, { headers })
            // .map((res: Response) => res.json())
            .catch((error: any) => Observable.throw( 'Error retrieving view list'));
    }

    searchBanks(searchString: string): Observable<Bank[]> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private', 
        'Authorization':'Bearer '+localStorage.getItem('token') });
        return this.http.get(`${this.baseUrl}/bank/search?${searchString}`, { headers })
            // .map((res: Response) => res.json())
            .catch((error: any) => Observable.throw( 'Error retrieving bank list'));
    }

    addBank(body: Object): Observable<Bank> {
        this.clearCache();
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private',  
        'Authorization':'Bearer '+localStorage.getItem('token') });
        // const options = new RequestOptions({ headers: headers });

        return this.http.post(`${this.baseUrl}/bank`, body, { headers })
            // .map((res: Response) => res.json())
            .catch((error: any) => Observable.throw(error));
    }

    updateBank(bankId: number, body: Object): Observable<Company> {
        this.clearCache();
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private',  
        'Authorization':'Bearer '+localStorage.getItem('token') });
        // const options = new RequestOptions({ headers: headers });

        return this.http.put(`${this.baseUrl}/bank/${bankId}`, body, { headers })
            // .map((res: Response) => res.json())
            .catch((error: any) => Observable.throw(error));
    }

    // Analyst
    getAnalysts(): Observable<Analyst[]> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private',  
        'Authorization':'Bearer '+localStorage.getItem('token') });
        return this.http.get(`${this.baseUrl}/analyst`, { headers })
            // .map((res: Response) => res.json())
            .catch((error: any) => Observable.throw( 'Error retrieving analyst list'));
    }

    getTopAnalysts(searchString: string): Observable<Analyst[]> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private',  
        'Authorization':'Bearer '+localStorage.getItem('token') });
        return this.http.get(`${this.baseUrl}/analyst/top?${searchString}`, { headers })
            // .map((res: Response) => res.json())
            .catch((error: any) => Observable.throw( 'Error retrieving analyst list'));
    }

    getAnalyst(id: number): Observable<Analyst> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private',  
        'Authorization':'Bearer '+localStorage.getItem('token') });
        return this.http.get(`${this.baseUrl}/analyst/${id.toString()}`, { headers })
            // .map((res: Response) => res.json())
            .catch((error: any) => Observable.throw( 'Error retrieving analyst'));
    }

    getAnalystPublications(id: number): Observable<Publication[]> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private',  
        'Authorization':'Bearer '+localStorage.getItem('token') });
        return this.http.get(`${this.baseUrl}/analyst/${id.toString()}/publications`, { headers })
            // .map((res: Response) => res.json())
            .catch((error: any) => Observable.throw( 'Error retrieving publications'));
    }

    getAnalystRecommendations(id: number): Observable<any[]> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private',  
        'Authorization':'Bearer '+localStorage.getItem('token') });
        return this.http.get(`${this.baseUrl}/analyst/${id.toString()}/recommendations`, { headers })
            // .map((res: Response) => res.json())
            .catch((error: any) => Observable.throw( 'Error retrieving recomemndations'));
    }

    getAnalystsExcel(searchString: string): Observable<Blob> {
        const headers = new HttpHeaders({ 'Content-type': 'blob', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private',  
        'Authorization':'Bearer '+localStorage.getItem('token') });
        return this.http.get(`${this.baseUrl}/analyst/excel?${searchString}`, { responseType: 'blob', headers })
            // .map((res: Response) => res.blob())
            .catch((error: any) => Observable.throw( 'Error retrieving file'));
    }

    getAnalystsExcelNoAnalytics(searchString: string): Observable<Blob> {
        const headers = new HttpHeaders({ 'Content-type': 'blob', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private',  
        'Authorization':'Bearer '+localStorage.getItem('token') });
        return this.http.get(`${this.baseUrl}/analyst/excel/noanalytics?${searchString}`, { responseType: 'blob', headers })
            // .map((res: Response) => res.blob())
            .catch((error: any) => Observable.throw( 'Error retrieving file'));
    }

    getAnalystRecommendationsExcel(analystId: number): Observable<Blob> {
        const headers = new HttpHeaders({ 'Content-type': 'blob', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private', 
        'Authorization':'Bearer '+localStorage.getItem('token') });
        return this.http.get(`${this.baseUrl}/analyst/${analystId}/recommendations/excel`, { responseType: 'blob', headers })
            // .map((res: Response) => res.blob())
            .catch((error: any) => Observable.throw( 'Error retrieving file'));
    }

    searchAnalysts(searchString: string): Observable<Analyst[]> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private',  
        'Authorization':'Bearer '+localStorage.getItem('token') });
        return this.http.get(`${this.baseUrl}/analyst/search?${searchString}`, { headers })
            // .map((res: Response) => res.json())
            .catch((error: any) => Observable.throw( 'Error retrieving analyst list'));
    }

    addAnalyst(body: Object): Observable<Analyst> {
        this.clearCache();
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private',  
        'Authorization':'Bearer '+localStorage.getItem('token') });
        // const options = new RequestOptions({ headers: headers });

        return this.http.post(`${this.baseUrl}/analyst`, body, { headers })
            // .map((res: Response) => res.json())
            .catch((error: any) => Observable.throw(error));
    }

    updateAnalyst(analystId: number, body: Object): Observable<Analyst> {
        this.clearCache();
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private',  
        'Authorization':'Bearer '+localStorage.getItem('token') });
        // const options = new RequestOptions({ headers: headers });

        return this.http.put(`${this.baseUrl}/analyst/${analystId}`, body, { headers })
            // .map((res: Response) => res.json())
            .catch((error: any) => Observable.throw(error));
    }

      // Category
      getTotalCat (searchString: string): Observable<number> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private', 
        'Authorization':'Bearer '+localStorage.getItem('token') });
        return this.http.get(`${this.baseUrl}/category/search?${searchString}`, { headers })
            .map(res => {
                return res['totalRecords'];
            })
            .catch((error: any) => Observable.throw( 'Server error'));
    } 
    findCategory(searchString: string): Observable<Bank[]> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private', 
        'Authorization':'Bearer '+localStorage.getItem('token') });
        return this.http.get(`${this.baseUrl}/category/search?${searchString}`, { headers })
            .map(res => {
                this._totalCategory = res['totalRecords'];
                return res['records'];
            })
            .catch((error: any) => Observable.throw( 'Server error'));
    } 

    getCategories(): Observable<Category[]> {
        ///category/search?page=1&PageSize=1000&OrderBy=name&OrderDesc=false&status=active
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private',  
        'Authorization':'Bearer '+localStorage.getItem('token') });
        //return this.http.get(`${this.baseUrl}/category`, { headers })
        return this.http.get(`${this.baseUrl}/category/search?page=1&PageSize=1000&OrderBy=name&OrderDesc=false&status=active`, { headers })
            // .map((res: Response) => res.json())
            .catch((error: any) => Observable.throw( 'Error retrieving category list'));
    }

    getCategoriesSystem(): Observable<Category[]> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private', 
        'Authorization':'Bearer '+localStorage.getItem('token') });
        return this.http.get(`${this.baseUrl}/category`, { headers })
            // .map((res: Response) => res.json())
            .catch((error: any) => Observable.throw( 'Error retrieving category list'));
    }

    getCategoriesSubcategoriesCartesian(): Observable<any[]> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private',  
        'Authorization':'Bearer '+localStorage.getItem('token') });
        return this.http.get(`${this.baseUrl}/category/sub/cartesian`)
            // .map((res: Response) => res.json())
            .catch((error: any) => Observable.throw( 'Error retrieving list'));
    }

    getCategoriesExcel(searchString: string): Observable<Blob> {
        const headers = new HttpHeaders({ 'Content-type': 'blob', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private',  
        'Authorization':'Bearer '+localStorage.getItem('token') });

        return this.http.get(`${this.baseUrl}/category/excel?${searchString}`, { responseType: 'blob', headers })
            // .map((res: Response) => res.blob())
            .catch((error: any) => Observable.throw( 'Error retrieving file'));
    }

    searchCategories(searchString: string): Observable<Category[]> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private', 
        'Authorization':'Bearer '+localStorage.getItem('token') });
        return this.http.get(`${this.baseUrl}/category/search?${searchString}`, { headers })
            // .map((res: Response) => res.json())
            .catch((error: any) => Observable.throw( 'Error retrieving category list'));
    }

    postCategory(body: Object): Observable<Boolean> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private', 
        'Authorization':'Bearer '+localStorage.getItem('token') });
        // const options = new RequestOptions({ headers: headers });

        return this.http.post(`${this.baseUrl}/category`, body, { headers })
            // .map((res: HttpResponse<Object>) => res.ok)
            .catch((error: any) => Observable.throw(error));
    }

    postSubcategory(id: number, body: Object): Observable<Boolean> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private',  
        'Authorization':'Bearer '+localStorage.getItem('token') });
        // const options = new RequestOptions({ headers: headers });

        return this.http.post(`${this.baseUrl}/category/${id.toString()}/sub`, body, { headers })
            // .map((res: HttpResponse<Object>) => res.ok)
            .catch((error: any) => Observable.throw(error));
    }

    // SubCategory By category id
    getSubCategories(id: number): Observable<SubCategory[]> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private',  
        'Authorization':'Bearer '+localStorage.getItem('token') });
        return this.http.get(`${this.baseUrl}/category/sub/${id.toString()}`, { headers })
            // .map((res: Response) => res.json())
            .catch((error: any) => Observable.throw( 'Error retrieving subcategory list'));
    }

    // SubCategory
    getAllSubCategories(): Observable<SubCategory[]> {
        //category/sub/search?page=1&PageSize=1000&OrderBy=name&OrderDesc=false&status=active
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private',  
        'Authorization':'Bearer '+localStorage.getItem('token') });
        //return this.http.get(`${this.baseUrl}/category/sub`, { headers })
        return this.http.get(`${this.baseUrl}/category/sub/search?page=1&PageSize=1000&OrderBy=name&OrderDesc=false&status=active`, { headers })
            // .map((res: Response) => res.json())
            .catch((error: any) => Observable.throw( 'Error retrieving subcategory list'));
    }

    getAllSubCategoriesSystem(): Observable<SubCategory[]>{
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private',  
        'Authorization':'Bearer '+localStorage.getItem('token') });
        return this.http.get(`${this.baseUrl}/category/sub`, { headers })
            // .map((res: Response) => res.json())
            .catch((error: any) => Observable.throw( 'Error retrieving subcategory list'));
    }

    getSubcategoriesExcel(searchString: string): Observable<Blob> {
        const headers = new HttpHeaders({ 'Content-type': 'blob', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private',  
        'Authorization':'Bearer '+localStorage.getItem('token') });
        return this.http.get(`${this.baseUrl}/category/sub/excel?${searchString}`, { responseType: 'blob', headers })
            // .map((res: Response) => res.blob())
            .catch((error: any) => Observable.throw( 'Error retrieving file'));
    }

    searchSubCategories(searchString: string): Observable<SubCategory[]> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private',  
        'Authorization':'Bearer '+localStorage.getItem('token') });
        return this.http.get(`${this.baseUrl}/category/sub/search?${searchString}`, { headers })
            // .map((res: Response) => res.json())
            .catch((error: any) => Observable.throw( 'Error retrieving subcategory list'));
    }

    // Company
    getCompanies(): Observable<Company[]> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private',  
        'Authorization':'Bearer '+localStorage.getItem('token') });
        return this.http.get(`${this.baseUrl}/company`, { headers })
            // .map((res: Response) => res.json())
            .catch((error: any) => Observable.throw( 'Error retrieving company list'));
    }

    getCompaniesExcel(searchString: string): Observable<Blob> {
        const headers = new HttpHeaders({ 'Content-type': 'blob', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private',  
        'Authorization':'Bearer '+localStorage.getItem('token') });
        return this.http.get(`${this.baseUrl}/company/excel?${searchString}`, { responseType: 'blob', headers })
            // .map((res: Response) => res.blob())
            .catch((error: any) => Observable.throw( 'Error retrieving file'));
    }

    getCompaniesExcelNoAnalytics(searchString: string): Observable<Blob> {
        const headers = new HttpHeaders({ 'Content-type': 'blob', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private', 
        'Authorization':'Bearer '+localStorage.getItem('token') });
        return this.http.get(`${this.baseUrl}/company/excel/noanalytics?${searchString}`, { responseType: 'blob', headers })
            // .map((res: Response) => res.blob())
            .catch((error: any) => Observable.throw( 'Error retrieving file'));
    }

    getTopCompanies(searchString: string): Observable<Company[]> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private',  
        'Authorization':'Bearer '+localStorage.getItem('token') });
        return this.http.get(`${this.baseUrl}/company/top?${searchString}`, { headers })
            // .map((res: Response) => res.json())
            .catch((error: any) => Observable.throw( 'Error retrieving company list'));
    }

    getCompany(id: number): Observable<Company> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private', 
        'Authorization':'Bearer '+localStorage.getItem('token') });
        return this.http.get(`${this.baseUrl}/company/${id.toString()}`, { headers })
            // .map((res: Response) => res.json())
            .catch((error: any) => Observable.throw( 'Error retrieving company'));
    }

    getCompanyAnalysts(id: number): Observable<Analyst[]> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private', 
        'Authorization':'Bearer '+localStorage.getItem('token') });
        return this.http.get(`${this.baseUrl}/company/${id.toString()}/analysts`, { headers })
            // .map((res: Response) => res.json())
            .catch((error: any) => Observable.throw( 'Error retrieving analyst list'));
    }

    getCompanyAnalystsLatestPublications(id: number): Observable<Publication[]> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private',  
        'Authorization':'Bearer '+localStorage.getItem('token') });
        return this.http.get(`${this.baseUrl}/company/${id.toString()}/analysts/publications/latest`, { headers })
            // .map((res: Response) => res.json())
            .catch((error: any) => Observable.throw( 'Error retrieving publication list'));
    }

    getCompanyRecommendationsExcel(companyId: number): Observable<Blob> {
        const headers = new HttpHeaders({ 'Content-type': 'blob', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private',  
        'Authorization':'Bearer '+localStorage.getItem('token') });
        return this.http.get(`${this.baseUrl}/company/${companyId}/recommendations/excel`, { responseType: 'blob', headers })
            // .map((res: Response) => res.blob())
            .catch((error: any) => Observable.throw( 'Error retrieving file'));
    }

    getCompanyViews(id: number): Observable<PublicationView[]> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private',  
        'Authorization':'Bearer '+localStorage.getItem('token') });
        return this.http.get(`${this.baseUrl}/company/${id.toString()}/search/view`, { headers })
            // .map((res: Response) => res.json())
            .catch((error: any) => Observable.throw( 'Error retrieving view list'));
    }

    addCompany(body: Object): Observable<Company> {
        this.clearCache();
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private',  
        'Authorization':'Bearer '+localStorage.getItem('token') });
        // const options = new RequestOptions({ headers: headers });

        return this.http.post(`${this.baseUrl}/company`, body, { headers })
            // .map((res: Response) => res.json())
            .catch((error: any) => Observable.throw(error));
    }

    updateCompany(companyId: number, body: Object): Observable<Company> {
        this.clearCache();
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private',  
        'Authorization':'Bearer '+localStorage.getItem('token') });
        // const options = new RequestOptions({ headers: headers });

        return this.http.put(`${this.baseUrl}/company/${companyId}`, body, { headers })
            // .map((res: Response) => res.json())
            .catch((error: any) => Observable.throw(error));
    }

    searchCompanies(searchString: string): Observable<Company[]> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private',  
        'Authorization':'Bearer '+localStorage.getItem('token') });
        return this.http.get(`${this.baseUrl}/company/search?${searchString}`, { headers })
            // .map((res: Response) => res.json())
            .catch((error: any) => Observable.throw( 'Error retrieving company list'));
    }

    // Currency
    getCurrencies(): Observable<Currency[]> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private', 
        'Authorization':'Bearer '+localStorage.getItem('token') });
        return this.http.get(`${this.baseUrl}/currency`, { headers })
            // .map((res: Response) => res.json())
            .catch((error: any) => Observable.throw( 'Error retrieving currency list'));
    }

    // RecommendedAction
    getRecommendedActions(): Observable<RecommendedAction[]> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private',  
        'Authorization':'Bearer '+localStorage.getItem('token') });
        return this.http.get(`${this.baseUrl}/recommendedaction`, { headers })
            // .map((res: Response) => res.json())
            .catch((error: any) => Observable.throw( 'Error retrieving recommended action list'));
    }

    // Target Price
    postTargetPrice(id: number, body: Object): Observable<PublicationTargetPrice> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private',  
        'Authorization':'Bearer '+localStorage.getItem('token') });
        // const options = new RequestOptions({ headers: headers });

        return this.http.post(`${this.baseUrl}/publication/${id.toString()}/targetprice`, body, { headers })
            // .map((res: Response) => res.json())
            .catch((error: any) => Observable.throw( 'Error saving target price'));
    }

    updateTargetPrice(id: number, body: Object): Observable<PublicationTargetPrice> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private',  
        'Authorization':'Bearer '+localStorage.getItem('token') });
        // const options = new RequestOptions({ headers: headers });

        return this.http.put(`${this.baseUrl}/publication/${id.toString()}/targetprice`, body, { headers })
            // .map((res: Response) => res.json())
            .catch((error: any) => Observable.throw( 'Error saving target price'));
    }

    getTargetPrice(id: number): Observable<PublicationTargetPrice> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private',  
        'Authorization':'Bearer '+localStorage.getItem('token') });
        return this.http.get(`${this.baseUrl}/publication/${id.toString()}/targetprice`, { headers })
            // .map((res: Response) => res.json())
            .catch(() => Observable.throw('Error getting target price'));
    }

    // Publication View
    postView(id: number, body: Object): Observable<PublicationView> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private',  
        'Authorization':'Bearer '+localStorage.getItem('token') });
        // const options = new RequestOptions({ headers: headers });

        return this.http.post(`${this.baseUrl}/publication/${id.toString()}/view`, body, { headers })
            // .map((res: Response) => res.json())
            .catch((error: any) => Observable.throw( 'Error saving view entry'));
    }

    getViews(id: number): Observable<PublicationView[]> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private',  
        'Authorization':'Bearer '+localStorage.getItem('token') });
        return this.http.get(`${this.baseUrl}/publication/${id.toString()}/view`, { headers })
            // .map((res: Response) => res.json())
            .catch((error: any) => Observable.throw( 'Error retrieving view list'));
    }

    getViewsCount(): Observable<number> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private',  
        'Authorization':'Bearer '+localStorage.getItem('token') });
        return this.http.get(`${this.baseUrl}/publication/view/count`, { headers })
            // .map((res: Response) => res.json())
            .catch((error: any) => Observable.throw( 'Error retrieving view count'));
    }

    getViewCount(publicationId: number): Observable<number> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private',  
        'Authorization':'Bearer '+localStorage.getItem('token') });
        return this.http.get(`${this.baseUrl}/publication/${publicationId}/view/count`, { headers })
            // .map((res: Response) => res.json())
            .catch((error: any) => Observable.throw( 'Error retrieving view count'));
    }

    getViewGraph(publicationId: number, year: number): Observable<number[]> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private', 
        'Authorization':'Bearer '+localStorage.getItem('token') });
        return this.http.get(`${this.baseUrl}/publication/${publicationId}/view/graph/${year}`, { headers })
            // .map((res: Response) => res.json())
            .catch((error: any) => Observable.throw( 'Error retrieving view graph data'));
    }

    searchPublicationViews(publicationId: number, searchString: string): Observable<PublicationView[]> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private',  
        'Authorization':'Bearer '+localStorage.getItem('token') });
        return this.http.get(`${this.baseUrl}/publication/${publicationId}/view/search?${searchString}`, { headers })
            // .map((res: Response) => res.json())
            .catch((error: any) => Observable.throw( 'Error retrieving view list'));
    }

    searchBankPublicationViews(bankId: number, searchString: string): Observable<PublicationView[]> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private', 
        'Authorization':'Bearer '+localStorage.getItem('token') });
        return this.http.get(`${this.baseUrl}/bank/${bankId}/view/search?${searchString}`, { headers })
            // .map((res: Response) => res.json())
            .catch((error: any) => Observable.throw( 'Error retrieving view list'));
    }

    searchCompanyPublicationViews(companyId: number, searchString: string): Observable<PublicationView[]> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private',  
        'Authorization':'Bearer '+localStorage.getItem('token') });
        return this.http.get(`${this.baseUrl}/company/${companyId}/view/search?${searchString}`, { headers })
            // .map((res: Response) => res.json())
            .catch((error: any) => Observable.throw( 'Error retrieving view list'));
    }

    searchAnalystPublicationViews(analystId: number, searchString: string): Observable<PublicationView[]> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private',  
        'Authorization':'Bearer '+localStorage.getItem('token') });
        return this.http.get(`${this.baseUrl}/analyst/${analystId}/view/search?${searchString}`, { headers })
            // .map((res: Response) => res.json())
            .catch((error: any) => Observable.throw( 'Error retrieving view list'));
    }

    // Publication Download
    postDownload(id: number, body: Object): Observable<Object> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private',  
        'Authorization':'Bearer '+localStorage.getItem('token') });
        
        return this.http.post(`${this.baseUrl}/publication/${id}/download`, body, { headers })
            .catch((error: any) => Observable.throw(error));
    }

    getDownloads(id: number): Observable<PublicationDownload[]> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private', 
        'Authorization':'Bearer '+localStorage.getItem('token') });
        return this.http.get(`${this.baseUrl}/publication/${id.toString()}/downloads`, { headers })
            // .map((res: Response) => res.json())
            .catch((error: any) => Observable.throw( 'Error retrieving download list'));
    }

    getDownloadsCount(): Observable<number> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private',  
        'Authorization':'Bearer '+localStorage.getItem('token') });
        return this.http.get(`${this.baseUrl}/publication/download/count`, { headers })
            // .map((res: Response) => res.json())
            .catch((error: any) => Observable.throw( 'Error retrieving download count'));
    }

    getDownloadCount(publicationId: number): Observable<number> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private', 
        'Authorization':'Bearer '+localStorage.getItem('token') });
        return this.http.get(`${this.baseUrl}/publication/${publicationId}/download/count`, { headers })
            // .map((res: Response) => res.json())
            .catch((error: any) => Observable.throw( 'Error retrieving download count'));
    }

    getDownloadGraph(publicationId: number, year: number): Observable<number[]> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private',  
        'Authorization':'Bearer '+localStorage.getItem('token') });
        return this.http.get(`${this.baseUrl}/publication/${publicationId}/download/graph/${year}`, { headers })
            // .map((res: Response) => res.json())
            .catch((error: any) => Observable.throw( 'Error retrieving download graph data'));
    }

    // Publication Bookmark
    postBookmark(id: number, body: Object): Observable<Object> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private',  
        'Authorization':'Bearer '+localStorage.getItem('token') });
        // const options = new RequestOptions({ headers: headers });

        return this.http.post(`${this.baseUrl}/publication/${id}/bookmark`, body, { headers })
            // .map(response => response.ok)
            // .map((res: HttpResponse<Object>) => res.ok)
            .catch((error: any) => Observable.throw(error));
    }

    deleteBookmark(publicationId: number, userId: number): Observable<Object> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private',  
        'Authorization':'Bearer '+localStorage.getItem('token') });
        return this.http.delete(`${this.baseUrl}/publication/${publicationId}/bookmark/${userId}`, { headers })
            // .map(response => response.ok);
            // .map((res: HttpResponse<Object>) => res.ok);
            .catch((error: any) => Observable.throw(error));
    }

    getBookmarks(publicationId: number): Observable<PublicationBookmark[]> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private', 
        'Authorization':'Bearer '+localStorage.getItem('token') });
        return this.http.get(`${this.baseUrl}/publication/${publicationId.toString()}/bookmarks`, { headers })
            // .map((res: Response) => res.json())
            .catch((error: any) => Observable.throw( 'Error retrieving bookmark list'));
    }

    getBookmark(publicationId: number, userId: number): Observable<PublicationBookmark> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private', 
        'Authorization':'Bearer '+localStorage.getItem('token') });
        return this.http.get(`${this.baseUrl}/publication/${publicationId.toString()}/bookmark/${userId}`, { headers })
            // .map((res: Response) => res.json());
            .catch((error: any) => Observable.throw( 'Error retrieving bookmark record'));
    }

    getBookmarksCount(): Observable<number> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private',  
        'Authorization':'Bearer '+localStorage.getItem('token') });
        return this.http.get(`${this.baseUrl}/publication/bookmark/count`, { headers })
            // .map((res: Response) => res.json())
            .catch((error: any) => Observable.throw( 'Error retrieving bookmark count'));
    }

    getBookmarkCount(publicationId: number): Observable<number> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private',  
        'Authorization':'Bearer '+localStorage.getItem('token') });
        return this.http.get(`${this.baseUrl}/publication/${publicationId}/bookmark/count`, { headers })
            // .map((res: Response) => res.json())
            .catch((error: any) => Observable.throw( 'Error retrieving bookmark count'));
    }

    getBookmarkGraph(publicationId: number, year: number): Observable<number[]> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private',  
        'Authorization':'Bearer '+localStorage.getItem('token') });
        return this.http.get(`${this.baseUrl}/publication/${publicationId}/bookmark/graph/${year}`, { headers })
            // .map((res: Response) => res.json())
            .catch((error: any) => Observable.throw( 'Error retrieving bookmark graph data'));
    }

    // Stock Images
    getStockImages(): Observable<StockImage[]> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private',  
        'Authorization':'Bearer '+localStorage.getItem('token') });
        return this.http.get(`${this.baseUrl}/stockimage`, { headers })
            // .map((res: Response) => res.json())
            .catch((error: any) => Observable.throw( 'Error stock image list'));
    }

    // File
    getPublicationFile(id: number): Observable<PublicationFile> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private',  
        'Authorization':'Bearer '+localStorage.getItem('token') });
        return this.http.get(`${this.baseUrl}/publication/${id}/file`, { headers })
            // .map((res: Response) => res.json())
            .catch((error: any) => Observable.throw(error));
    }

    postFile(body: Object): Observable<PublicationFile[]> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private',  
        'Authorization':'Bearer '+localStorage.getItem('token') });

        return this.http.post(`${this.baseUrl}/file`, { body, headers })
            // .map((res: Response) => res.json())
            .catch((error: any) => Observable.throw( 'Error posting file'));
    }

    downloadFile(id): Observable<Blob> {
        const headers = new HttpHeaders({ 'Content-type': 'blob', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private',  
        'Authorization':'Bearer '+localStorage.getItem('token') });
        return this.http.get(`${this.baseUrl}/file/${id}`, { responseType: 'blob', headers });
        // .map(res => res.blob());
    }

    downloadFiles(searchString: string): Observable<Blob> {
        const headers = new HttpHeaders({ 'Content-type': 'blob', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private', 
        'Authorization':'Bearer '+localStorage.getItem('token') });
        return this.http.get(`${this.baseUrl}/publication/file/all?${searchString}`, { responseType: 'blob', headers });
        // .map(res => res.blob());
    }

    // Image
    getPublicationImage(id: number): Observable<PublicationImage> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private',  
        'Authorization':'Bearer '+localStorage.getItem('token') });
        return this.http.get(`${this.baseUrl}/publication/${id}/image`, { headers })
            // .map((res: Response) => res.json())
            .catch(() => Observable.throw('Error retrieving publication image'));
    }

    getPublicationImageById(id: number): Observable<File> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private', 
        'Authorization':'Bearer '+localStorage.getItem('token') });
        return this.http.get(`${this.baseUrl}/file/image/${id}`, { responseType: 'blob', headers })
            // .map((res: Response) => res.blob())
            .catch((error: any) => Observable.throw( 'Error retrieving publication image'));
    }

    // User
    getUsers(): Observable<User[]> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private',  
        'Authorization':'Bearer '+localStorage.getItem('token') });
        return this.http.get(`${this.baseUrl}/user`, { headers })
            // .map((res: Response) => res.json())
            .catch((error: any) => Observable.throw( 'Error retrieving user list'));
    }

    getUser(id: number): Observable<User> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private', 
        'Authorization':'Bearer '+localStorage.getItem('token') });
        return this.http.get(`${this.baseUrl}/user/${id}`, { headers })
            // .map((res: Response) => res.json())
            .catch((error: any) => Observable.throw( 'Error retrieving user'));
    }

    getUserBanks(id: number): Observable<UserBank[]> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private',  
        'Authorization':'Bearer '+localStorage.getItem('token') });

        return this.http.get(`${this.baseUrl}/user/${id}/bank`, { headers })
            // .map((res: Response) => res.json())
            .catch((error: any) => Observable.throw( 'Error retrieving banks list'));
    }

    getUserAnalysts(id: number): Observable<UserAnalyst[]> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private',  
        'Authorization':'Bearer '+localStorage.getItem('token') });

        return this.http.get(`${this.baseUrl}/user/${id}/analyst`, { headers })
            // .map((res: Response) => res.json())
            .catch((error: any) => Observable.throw( 'Error retrieving analyst list'));
    }

    getUserCompanies(id: number): Observable<UserCompany[]> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private',  
        'Authorization':'Bearer '+localStorage.getItem('token') });

        return this.http.get(`${this.baseUrl}/user/${id}/company`, { headers })
            // .map((res: Response) => res.json())
            .catch((error: any) => Observable.throw( 'Error retrieving company list'));
    }

    getUserCategories(id: number): Observable<UserCategory[]> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private',  
        'Authorization':'Bearer '+localStorage.getItem('token') });

        return this.http.get(`${this.baseUrl}/user/${id}/category`, { headers })
            // .map((res: Response) => res.json())
            .catch((error: any) => Observable.throw( 'Error retrieving category list'));
    }

    searchUser(searchString: string): Observable<User[]> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private',  
        'Authorization':'Bearer '+localStorage.getItem('token') });
        return this.http.get(`${this.baseUrl}/user/search?${searchString}`, { headers })
            // .map((res: Response) => res.json())
            .catch((error: any) => Observable.throw( 'Error retrieving user list'));
    }

    getUserExcel(searchString: string): Observable<Blob> {
        const headers = new HttpHeaders({ 'Content-type': 'blob', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private',  
        'Authorization':'Bearer '+localStorage.getItem('token') });
        return this.http.get(`${this.baseUrl}/user/excel?${searchString}`, { responseType: 'blob', headers })
            // .map((res: Response) => res.blob())
            .catch((error: any) => Observable.throw( 'Error retrieving file'));
    }

    getTopUsers(searchString: string): Observable<User[]> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private',  
        'Authorization':'Bearer '+localStorage.getItem('token') });
        return this.http.get(`${this.baseUrl}/user/top?${searchString}`, { headers })
            // .map((res: Response) => res.json())
            .catch((error: any) => Observable.throw( 'Error retrieving user list'));
    }

    getUserBookmarks(userId: number): Observable<PublicationBookmark[]> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private',  
        'Authorization':'Bearer '+localStorage.getItem('token') });
        return this.http.get(`${this.baseUrl}/user/${userId}/bookmarks`, { headers })
            // .map((res: Response) => res.json())
            .catch((error: any) => Observable.throw(error));
    }

    getUserPreferencesBankPublications(userId: number): Observable<Publication[]> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private',  
        'Authorization':'Bearer '+localStorage.getItem('token') });
        return this.http.get(`${this.baseUrl}/user/${userId}/preferences/bank/publications`, { headers })
            // .map((res: Response) => res.json())
            .catch((error: any) => Observable.throw( 'Error retrieving publication list'));
    }

    getUserPreferencesAnalystPublications(userId: number): Observable<Publication[]> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private',  
        'Authorization':'Bearer '+localStorage.getItem('token') });
        return this.http.get(`${this.baseUrl}/user/${userId}/preferences/analyst/publications`, { headers })
            // .map((res: Response) => res.json())
            .catch((error: any) => Observable.throw( 'Error retrieving publication list'));
    }

    getUserPreferencesCompanyPublications(userId: number): Observable<Publication[]> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private',
        'Authorization':'Bearer '+localStorage.getItem('token') });
        return this.http.get(`${this.baseUrl}/user/${userId}/preferences/company/publications`, { headers })
            // .map((res: Response) => res.json())
            .catch((error: any) => Observable.throw( 'Error retrieving publication list'));
    }

    getUserPreferencesCategoryPublications(userId: number): Observable<Publication[]> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private', 
        'Authorization':'Bearer '+localStorage.getItem('token') });
        return this.http.get(`${this.baseUrl}/user/${userId}/preferences/caegory/publications`, { headers })
            // .map((res: Response) => res.json())
            .catch((error: any) => Observable.throw( 'Error retrieving publication list'));
    }

    getUserMostVisitedPublications(userId: number): Observable<Publication[]> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private', 
        'Authorization':'Bearer '+localStorage.getItem('token') });
        return this.http.get(`${this.baseUrl}/user/${userId}/publications/top`, { headers })
            // .map((res: Response) => res.json())
            .catch((error: any) => Observable.throw( 'Error retrieving publication list'));
    }

    searchUserPublicationViews(userId: number, searchString: string): Observable<PublicationView[]> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private',  
        'Authorization':'Bearer '+localStorage.getItem('token') });
        return this.http.get(`${this.baseUrl}/user/${userId}/view/search?${searchString}`, { headers })
            // .map((res: Response) => res.json())
            .catch((error: any) => Observable.throw( 'Error retrieving view list'));
    }

    searchUserBookmarkedPublications(userId: number, searchString: string): Observable<PublicationBookmark[]> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private',  
        'Authorization':'Bearer '+localStorage.getItem('token') });

        return this.http.get(`${this.baseUrl}/user/${userId}/bookmarks/search?${searchString}`, { headers })
            // .map((res: Response) => res.json())
            .catch((error: any) => Observable.throw( 'Server error'));
    }

    searchDashboard(userId: number, searchString: string): Observable<Publication[]> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private',  
        'Authorization':'Bearer '+localStorage.getItem('token') });
        return this.http.get(`${this.baseUrl}/user/${userId}/bp/search?${searchString}`, { headers })
            // .map((res: Response) => res.json())
            .catch((error: any) => Observable.throw( 'Server error'));
    }

    postUser(body: Object): Observable<User> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private',  
        'Authorization':'Bearer '+localStorage.getItem('token') });
        // const options = new RequestOptions({ headers: headers });

        return this.http.post(`${this.baseUrl}/user`, body, { headers })
            // .map((res: Response) => res.json())
            .catch((error: any) => Observable.throw(error));
    }

    postUserBank(userId: number, body: Object): Observable<UserBank[]> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private',  
        'Authorization':'Bearer '+localStorage.getItem('token') });
        // const options = new RequestOptions({ headers: headers });

        return this.http.post(`${this.baseUrl}/user/${userId}/bank`, body, { headers })
            // .map((res: Response) => res.json())
            .catch((error: any) => Observable.throw(error));
    }

    postUserAnalyst(userId: number, body: Object): Observable<UserAnalyst[]> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private',  
        'Authorization':'Bearer '+localStorage.getItem('token') });
        // const options = new RequestOptions({ headers: headers });

        return this.http.post(`${this.baseUrl}/user/${userId}/analyst`, body, { headers })
            // .map((res: Response) => res.json())
            .catch((error: any) => Observable.throw(error));
    }

    postUserCompany(userId: number, body: Object): Observable<UserCompany[]> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private', 
        'Authorization':'Bearer '+localStorage.getItem('token') });
        // const options = new RequestOptions({ headers: headers });

        return this.http.post(`${this.baseUrl}/user/${userId}/company`, body, { headers })
            // .map((res: Response) => res.json())
            .catch((error: any) => Observable.throw(error));
    }

    postUserCategory(userId: number, body: Object): Observable<UserCategory[]> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private',  
        'Authorization':'Bearer '+localStorage.getItem('token') });
        // const options = new RequestOptions({ headers: headers });

        return this.http.post(`${this.baseUrl}/user/${userId}/category`, body, { headers })
            // .map((res: Response) => res.json())
            .catch((error: any) => Observable.throw(error));
    }

    deleteUserBank(userId: number, bankId: number): Observable<any> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private',  
        'Authorization':'Bearer '+localStorage.getItem('token') });

        return this.http.delete(`${this.baseUrl}/user/${userId}/bank/${bankId}`, { headers })
            // .map((res: HttpResponse<Object>) => res.ok);
            .catch((error: any) => Observable.throw(error));
    }

    deleteUserAnalyst(userId: number, analystId: number): Observable<any> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private',  
        'Authorization':'Bearer '+localStorage.getItem('token') });

        return this.http.delete(`${this.baseUrl}/user/${userId}/analyst/${analystId}`, { headers })
            // .map((res: HttpResponse<Object>) => res.ok)
            .catch((error: any) => Observable.throw(error));
    }

    deleteUserCompany(userId: number, companyId: number): Observable<any> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private',  
        'Authorization':'Bearer '+localStorage.getItem('token') });

        return this.http.delete(`${this.baseUrl}/user/${userId}/company/${companyId}`, { headers })
            // .map((res: HttpResponse<Object>) => res.ok)
            .catch((error: any) => Observable.throw(error));
    }

    deleteUserCategory(userId: number, categoryId: number): Observable<any> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private',  
        'Authorization':'Bearer '+localStorage.getItem('token') });

        return this.http.delete(`${this.baseUrl}/user/${userId}/category/${categoryId}`, { headers })
            // .map((res: HttpResponse<Object>) => res.ok)
            .catch((error: any) => Observable.throw(error));
    }

    updateUser(userId: number, body: Object): Observable<User> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private',  
        'Authorization':'Bearer '+localStorage.getItem('token') });
        // const options = new RequestOptions({ headers: headers });

        return this.http.put(`${this.baseUrl}/user/${userId}`, body, { headers })
            // .map((res: Response) => res.json())
            .catch((error: any) => Observable.throw(error || 'Error updating user'));
    }

    // All

    updateStatus(id: number, table: string, body: Object): Observable<any> {
        this.clearCache();

        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private', 
        'Authorization':'Bearer '+localStorage.getItem('token') });
        // const options = new RequestOptions({ headers: headers });

        return this.http.put(`${this.baseUrl}/helper/${table}/${id}/status`, body, { headers });
        // .map((res: Response) => res.json());
        // .catch((error: any) => Observable.throw('Error updating status'));
    }

    updatePreference(id: number, table: string, body: Object): Observable<any> {
        this.clearCache();

        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private',  
        'Authorization':'Bearer '+localStorage.getItem('token') });
        // const options = new RequestOptions({ headers: headers });

        return this.http.put(`${this.baseUrl}/helper/${table}/${id}/preference`, body, { headers });
        // .map((res: Response) => res.json());
        // .catch((error: any) => Observable.throw('Error updating status'));
    }

    deleteUserAnalysts(userId: number): Observable<Object> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private',  
        'Authorization':'Bearer '+localStorage.getItem('token') });
        return this.http.delete(`${this.baseUrl}/user/${userId}/analyst/`, { headers })
            // .map(response => response.ok);
            // .map((res: HttpResponse<Object>) => res.ok);
            .catch((error: any) => Observable.throw(error));
    }

    deleteUserBanks(userId: number): Observable<Object> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private',  
        'Authorization':'Bearer '+localStorage.getItem('token') });
        return this.http.delete(`${this.baseUrl}/user/${userId}/bank/`, { headers })
            // .map(response => response.ok);
            // .map((res: HttpResponse<Object>) => res.ok);
            .catch((error: any) => Observable.throw(error));
    }

    deleteUserCompanies(userId: number): Observable<Object> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private',  
        'Authorization':'Bearer '+localStorage.getItem('token') });
        return this.http.delete(`${this.baseUrl}/user/${userId}/company/`, { headers })
            // .map(response => response.ok);
            // .map((res: HttpResponse<Object>) => res.ok);
            .catch((error: any) => Observable.throw(error));
    }

    deleteUserCategories(userId: number): Observable<Object> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private', 
        'Authorization':'Bearer '+localStorage.getItem('token') });
        return this.http.delete(`${this.baseUrl}/user/${userId}/category/`, { headers })
            // .map(response => response.ok);
            // .map((res: HttpResponse<Object>) => res.ok);
            .catch((error: any) => Observable.throw(error));
    }


    // Publicaciones

    findPublications(searchString: string): Observable<Publication[]> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private',  
        'Authorization':'Bearer '+localStorage.getItem('token') });
        //return this.http.get(`${this.baseUrl}/publication/search?${searchString}`, { headers })
        return this.http.get(`${this.baseUrl}/publication/search?${searchString}`, { headers })
            .map(res => {
                this._totalPublication = res['totalRecords'];
                return res['records'];
            })
            .catch((error: any) => Observable.throw( 'Server error'));
    } 

    getTotalP (searchString: string): Observable<number> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private',  
        'Authorization':'Bearer '+localStorage.getItem('token') });
        return this.http.get(`${this.baseUrl}/publication/search?${searchString}`, { headers })
            .map(res => {
                return res['totalRecords'];
            })
            .catch((error: any) => Observable.throw( 'Server error'));
    }
    
    getTotalD (searchString: string): Observable<number> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT', 
        'Cache-Control':  'no-cache, no-store, private', 
        'Authorization':'Bearer '+localStorage.getItem('token') });
        return this.http.get(`${this.baseUrl}/publication/search?${searchString}`, { headers })
            .map(res => {
                return res['pageTotalDonload'];
            })
            .catch((error: any) => Observable.throw( 'Server error'));
    }

    getTotalV (searchString: string): Observable<number> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private',  
        'Authorization':'Bearer '+localStorage.getItem('token') });
        return this.http.get(`${this.baseUrl}/publication/search?${searchString}`, { headers })
            .map(res => {
                return res['pageTotalViews'];
            })
            .catch((error: any) => Observable.throw( 'Server error'));
    }

    // Analistas

    findAnalyst(searchString: string): Observable<Analyst[]> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private', 
        'Authorization':'Bearer '+localStorage.getItem('token') });
        return this.http.get(`${this.baseUrl}/analyst/search?${searchString}`, { headers })
            .map(res => {
                this._totalAnalyst = res['totalRecords'];
                return res['records'];
            })
            .catch((error: any) => Observable.throw( 'Server error'));
    } 

    getTotalA (searchString: string): Observable<number> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private',  
        'Authorization':'Bearer '+localStorage.getItem('token') });
        return this.http.get(`${this.baseUrl}/analyst/search?${searchString}`, { headers })
            .map(res => {
                return res['totalRecords'];
            })
            .catch((error: any) => Observable.throw( 'Server error'));
    } 

    // Banks

    findBank(searchString: string): Observable<Bank[]> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private',  
        'Authorization':'Bearer '+localStorage.getItem('token') });
        return this.http.get(`${this.baseUrl}/bank/search?${searchString}`, { headers })
            .map(res => {
                this._totalBank = res['totalRecords'];
                return res['records'];
            })
            .catch((error: any) => Observable.throw( 'Server error'));
    } 

    getTotalB (searchString: string): Observable<number> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private',  
        'Authorization':'Bearer '+localStorage.getItem('token') });
        return this.http.get(`${this.baseUrl}/bank/search?${searchString}`, { headers })
            .map(res => {
                return res['totalRecords'];
            })
            .catch((error: any) => Observable.throw( 'Server error'));
    } 

    // Companies

    findCompany(searchString: string): Observable<Company[]> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private',  
        'Authorization':'Bearer '+localStorage.getItem('token') });
        return this.http.get(`${this.baseUrl}/company/search?${searchString}`, { headers })
            .map(res => {
                this._totalCompany = res['totalRecords'];
                return res['records'];
            })
            .catch((error: any) => Observable.throw( 'Server error'));
    } 

    getTotalC (searchString: string): Observable<number> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private',  
        'Authorization':'Bearer '+localStorage.getItem('token') });
        return this.http.get(`${this.baseUrl}/company/search?${searchString}`, { headers })
            .map(res => {
                return res['totalRecords'];
            })
            .catch((error: any) => Observable.throw( 'Server error'));
    }

    getPreferenceBanks() {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private', 
        'Authorization':'Bearer '+localStorage.getItem('token') });
        //if (!this._banksP) {
            this._banksP = this.http.get(`${this.baseUrl}/bank/preferences`, { headers })
                //.do(() => this.console('Fetched banks'))
                .publishReplay(1)
                .refCount()
                .catch((error: any) => Observable.throw( 'Error retrieving bank preference'));
        //}

        return this._banksP;
    }

    getPreferenceAnalysts() {

        // if (!this._analystsP) {
        //     this._analystsP = this.http.get(`${this.baseUrl}/analyst/preferences`)
        //         .do(() => this.console('Fetched analysts'))
        //         .publishReplay(1)
        //         .refCount()
        //         .catch((error: any) => Observable.throw(error || 'Error retrieving analyst preference'));
        // }
        //this.http.get(`${this.baseUrl}/analyst/search?page=1&PageSize=1000&OrderBy=name&OrderDesc=false&status=active`)
        //this._analystsP = this.http.get(`${this.baseUrl}/analyst/preferences`)
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private',  
        'Authorization':'Bearer '+localStorage.getItem('token') });
        this._analystsP = this.http.get(`${this.baseUrl}/analyst/search?page=1&PageSize=1000&OrderBy=name&OrderDesc=false&preferences=true`, { headers })
            //.do(() => this.console('Fetched analysts'))
            .publishReplay(1)
            .refCount()
            .catch((error: any) => Observable.throw(error || 'Error retrieving analyst preference'));
        
        return this._analystsP;
    }

    getAnalystsPreference() {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private',  
        'Authorization':'Bearer '+localStorage.getItem('token') });
        this._analystsP = this.http.get(`${this.baseUrl}/analyst/GetAll?page=1&PageSize=1000&OrderBy=name&OrderDesc=false&preferences=true`, { headers })
            //.do(() => this.console('Fetched analysts'))
            .publishReplay(1)
            .refCount()
            .catch((error: any) => Observable.throw(error || 'Error retrieving analyst preference'));
        
        return this._analystsP;
    }

    getPreferenceCompanies() {
        //if (!this._companiesP) {
            //this._companiesP = this.http.get(`${this.baseUrl}/company/preferences`)
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private',  
        'Authorization':'Bearer '+localStorage.getItem('token') });
        this._companiesP = this.http.get(`${this.baseUrl}/company/search?page=1&PageSize=1000&OrderBy=name&OrderDesc=false&preferences=true`, { headers })
            //.do(() => this.console('Fetched companies'))
            .publishReplay(1)
            .refCount()
            .catch((error: any) => Observable.throw( 'Error retrieving company preference'));
        //}

        return this._companiesP;
    }
    getCompaniesPreference() {
        //if (!this._companiesP) {
            //this._companiesP = this.http.get(`${this.baseUrl}/company/preferences`)
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private',  
        'Authorization':'Bearer '+localStorage.getItem('token') });
        this._companiesP = this.http.get(`${this.baseUrl}/company/GetAll?page=1&PageSize=1000&OrderBy=name&OrderDesc=false&preferences=true`, { headers })
            //.do(() => this.console('Fetched companies'))
            .publishReplay(1)
            .refCount()
            .catch((error: any) => Observable.throw( 'Error retrieving company preference'));
        //}

        return this._companiesP;
    }

    getCategoriesPreferences() {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private',  
        'Authorization':'Bearer '+localStorage.getItem('token') });
        //return this.http.get(`${this.baseUrl}/category`, { headers })
        return this.http.get(`${this.baseUrl}/category/search?page=1&PageSize=1000&OrderBy=name&OrderDesc=false&preferences=true`, { headers })
            // .map((res: Response) => res.json())
            .catch((error: any) => Observable.throw( 'Error retrieving category list'));
    }

}

import { environment } from '../../environments/environment';
import { EmailAddress } from './../shared/models/email/email.address';
import { Component, OnInit } from '@angular/core';
import { FormGroup, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { LoginService } from '../shared/services/login.service';
import { LoginModel } from '../shared/models/login';
import { Observable } from 'rxjs/Observable';
import { Router, Routes } from '@angular/router';
import { BaMenuService } from '../theme';
import { PAGES_MENU } from '../app.menu';
import { PAGES_MENU_READER } from '../app.reader.menu';
import { EmailMessage } from '../shared/models/email/email.message';
import { Location } from '@angular/common';

@Component({
  selector: 'app-login-reset-password',
  templateUrl: './login-reset-password.component.html',
  styleUrls: ['./login-reset-password.component.scss'],
})
export class LoginResetPasswordComponent implements OnInit {

  enableButton: Boolean = true;
  privacyFileLink: string;
  arcoFileLink: string;
  consentFileLink: string;
  loginBackground: string;
  
  loading: Boolean = false;
  submitted: boolean = false;
  form: FormGroup;
  email: AbstractControl;
  sEmail: string;
  statusText: string;
  emailAddress: EmailAddress;
  emailMessage: EmailMessage;
  model = new LoginModel('', '');

  constructor(
    private _menuService: BaMenuService,
    fb: FormBuilder,
    private loginService: LoginService,
    private location: Location,
    private router: Router) {
    this.form = fb.group({
      'email': ['', Validators.compose([Validators.required, Validators.minLength(6)])],
    });

    this.loginBackground = `${environment.assetsEndpoint}/img/background_login.jpg`;

    this.privacyFileLink = `${environment.s3Endpoint}/legal/aviso_privacidad.pdf`;
    this.arcoFileLink = `${environment.s3Endpoint}/legal/ejercicio_derechos_arco_v1.pdf`;
    this.consentFileLink = `${environment.s3Endpoint}/legal/revocacion_consentimiento_v1.2.pdf`;

    this.email = this.form.controls['email'];
  }

  onSubmit(values: Object): void {
    this.enableButton = false;
    this.statusText = '';
    this.loading = true;
    this.submitted = true;
    if (this.form.valid) {
      let loginOperation: Observable<Object>;
      loginOperation = this.loginService
        .postRecover(this.sEmail)
        .finally(() => { this.loading = false; });
      loginOperation.subscribe(
        loginResponse => {
          if (loginResponse['statusText'] === 'Ok') {
            this.statusText = 'Se le ha enviado un correo con instrucciones';
          } else {
            this.statusText = 'Error';
          }
          // this.router.navigate([`dashboard`]);          
          // if (this.loginService.isUserAdmin()) {
          //   this._menuService.updateMenuByRoutes(<Routes>PAGES_MENU);
          //   if (this.loginService.isTokenExpired()) {
          //     localStorage.removeItem('token');
          //     this.router.navigate(['login']);
          //   }
          // } else {
          //   this._menuService.updateMenuByRoutes(<Routes>PAGES_MENU_READER);
          //   if (this.loginService.isTokenExpired()) {
          //     localStorage.removeItem('token');
          //     this.router.navigate(['login']);
          //   }
          // }
        }, error => {
          if (error.status === 0) {
            this.statusText = 'El servicio no responde';
          }
        }, () => {
          this.enableButton = true;
        },
      );
    }
  }

  ngOnInit() {
  }

}

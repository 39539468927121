// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  ///devApiEndpoint: 'http://localhost:52602/api',
  //assetsEndpoint: 'http://localhost:52602/',
  devApiEndpoint: 'https://femsa-analistas.com/api',
  assetsEndpoint: 'https://femsa-analistas.com/',
  s3Endpoint: 'https://femsa-analistas.com/femsa-analistas'
};

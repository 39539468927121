import { PublicationType } from './../shared/models/publication.type';
import { ParameterService } from './../shared/services/parameter.service';
import { Publication } from '../shared/models/publication';
import { PublicationTargetPrice } from '../shared/models/publication.targetprice';
import { Company } from '../shared/models/company';
import { Bank } from '../shared/models/bank';
import { Category } from '../shared/models/category';
import { SubCategory } from '../shared/models/subcategory';
import { Analyst } from '../shared/models/analyst';
import { StockImage } from '../shared/models/stockImage';
import { RecommendedAction } from '../shared/models/recommendedAction';
import { Currency } from '../shared/models/currency';
import { Component, OnInit, Inject, ElementRef, HostListener } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { PublicationService } from '../shared/services/publication.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ViewChild } from '@angular/core';
import { environment } from '../../environments/environment';
//import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';

@Component({
  selector: 'app-publication-modal-edit',
  templateUrl: './publication-modal-edit.component.html',
  styleUrls: ['./publication-modal-edit.component.scss'],
})
export class PublicationModalEditComponent implements OnInit {

  @HostListener('wheel', ['$event'])
  handleWheelEvent(event) {
    event.preventDefault();
  }
  
  publicationType: PublicationType;
  publicationTypes: PublicationType[];
  devApiEndpoint: string = `${environment.s3Endpoint}/app/img/stock`;
  d: any;
  imgSrc: string;
  thumbnailSrc: string;
  minDate = new Date();
  fileSrc: string;
  selectedStockImageId = 0;
  publication: Publication;

  imageFileName: string;
  companies: Company[];
  categories: Category[];
  subcategories: SubCategory[];
  banks: Bank[];
  analysts: Analyst[];
  currencies: Currency[];
  recommendedActions: RecommendedAction[];
  stockImages: StockImage[];
  isLinear = false;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  summaryFormGroup: FormGroup;
  @ViewChild('imgFileInput')
  imgFile: any;
  @ViewChild('publicationFileInput')
  publicationFile: any;
  @ViewChild('divFile')
  divFile: ElementRef;
  @ViewChild('divImgFile')
  divImgFile: ElementRef;
  @ViewChild('dateInit') startDateInput:ElementRef;

  constructor(private publicationService: PublicationService,
    private _formBuilder: FormBuilder,
    private parameterService: ParameterService,
    public dialogRef: MatDialogRef<PublicationModalEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
    /*public _HttpClient: HttpClient*/) {
    this.publication = this.data['publication'];
    this.publication.subcategoryId = this.publication['subCategoryId'];
  }

  ngOnInit() {
    this.firstFormGroup = this._formBuilder.group({
      title: [this.publication.title, Validators.required],
      type: ['', Validators.required],
      date: [this.publication.date, Validators.required],
      pages: [this.publication.pages, Validators.required],
      rating: [this.publication.rating, Validators.required],
      analyst: [this.publication.analystId, Validators.required],
      bank: [this.publication.bankId, Validators.required],
      company: [this.publication.companyId, Validators.required],
      category: [this.publication.categoryId, Validators.required],
      subCategory: [this.publication.subcategoryId, Validators.required],
    });
    this.summaryFormGroup = this._formBuilder.group({
      summary: [this.publication.summary, Validators.required],
      'selectedFile': [{ value: '', disabled: true }, Validators.required],
    });

    this.loadBanks();
    this.loadAnalysts();
    this.loadCategories();
    this.loadSubCategories();
    this.loadCompanies();
    this.loadCurrencies();
    this.loadRecommededActions();
    this.loadStockImages();
    this.loadPublicationFile();
    this.loadPublicationTypes();
    this.loadPublicationImg();
  }

  removeAll(){
    this.startDateInput.nativeElement.value = '';
  }

  loadPublicationTypes() {
    this.parameterService
    .getPublicationTypes()
    .subscribe(publicationTypes => {
      this.publicationTypes = publicationTypes;
    });
  }

  loadPublicationFile() {
    this.publicationService
    .getPublicationFile(this.publication.id)
    .subscribe(publicationFile => {
      this.fileSrc = publicationFile.url;
    }, err => {
      
    });
  }

  loadPublicationImg() {
    this.publicationService
    .getPublicationImage(this.publication.id)
    .subscribe(publicationImg => {
      this.imgSrc = publicationImg.url;
      this.thumbnailSrc = `${environment.s3Endpoint}/publicaciones/${this.publication.id}/img/${publicationImg.url}`;
    }, err => {
      
    });
  }

  loadBanks() {
    this.publicationService.getCachedBanks().subscribe(
      banks => this.banks = banks['records'],
      err => {
      },
    );
  }

  // Analyst
  loadAnalysts() {
    this.publicationService.getBankAnalysts(this.publication['bankId']).subscribe(
      analysts => this.analysts = analysts.filter(analyst => analyst.status === 'active'),
      err => {
      },
    );
  }

  // Category
  loadCategories() {
    this.publicationService.getCategories().subscribe(
      categories => this.categories = categories['records'],
      err => {
      },
    );
  }

  // Company
  loadCompanies() {
    this.publicationService.getCachedCompaniesSystem().subscribe(
      companies => this.companies = companies.filter(c => c.status === 'active'),
      err => {
      },
    );
  }

  // Sub category
  loadSubCategories() {
    this.publicationService.getSubCategories(this.publication['categoryId']).subscribe(      
      subcategories => this.subcategories = subcategories,
      err => {
      },
    );
  }

  // Currency
  loadCurrencies() {
    this.publicationService.getCurrencies().subscribe(
      currencies => this.currencies = currencies,
      err => {
        
      },
    );
  }

  // Recommeded Actions
  loadRecommededActions() {
    this.publicationService.getRecommendedActions().subscribe(
      recommendedActions => this.recommendedActions = recommendedActions,
      err => {
        
      },
    );
  }

  // Stock Images
  loadStockImages() {
    this.publicationService.getStockImages().subscribe(
      stockImages => this.stockImages = stockImages,
      err => {
        
      },
    );
  }

  bankChange() {
    this.publicationService.getBankAnalysts(this.publication['bankId'])
    .subscribe(analysts => this.analysts = analysts);
  }

  selectImage(id: number) {
    this.selectedStockImageId = id;
  }

  save() {
    this.data = { 
      publication: this.publication,
      publicationId: this.publication.id,
      imgFile: this.imgFile.nativeElement.files[0] != undefined ? this.imgFile.nativeElement.files[0] : true,
      publicationFile: this.publicationFile.nativeElement.files[0],
      selectedStockImageId: this.selectedStockImageId,
    };
    this.dialogRef.close(this.data);
  }

  close() {
    this.dialogRef.close();
  }

  fileChangeEvent(fileInput: any) {
    if (fileInput.target.files && fileInput.target.files[0]) {
      // const reader = new FileReader();

      // reader.onload = ((e) => {
      //   this.imgSrc = e.target['result'];
      // });
      const file = fileInput.target.files[0];
      this.imgSrc = file.name;
      const img = this.divImgFile.nativeElement;
      img.getElementsByTagName('span')[0].innerText = '';
      img.getElementsByTagName('span')[0].innerHTML = '';

      // reader.readAsDataURL(fileInput.target.files[0]);
    }
  }

  publicationFileChangeEvent(fileInput: any) {
    if (fileInput.target.files && fileInput.target.files[0]) {
      const file = fileInput.target.files[0];
      this.fileSrc = file.name;
      
      const img = this.divFile.nativeElement;
      img.getElementsByTagName('span')[0].innerText = '';
      img.getElementsByTagName('span')[0].innerHTML = '';

    }
  }

  categoryChange() {
    this.publicationService.getSubCategories(this.publication['categoryId']).subscribe(
      subcategories => this.subcategories = subcategories.filter(subcategory => subcategory.status === 'active'),
      err => {
      },
    );
  }

}

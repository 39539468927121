import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-publication-main',
  templateUrl: './publication-main.component.html',
  styleUrls: ['./publication-main.component.scss']
})
export class PublicationMainComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

export class NewSummary {
    constructor(
        public title: string,
        public description: string,
        public status: string,
        public textHeader: string,
        public date: Date,
        public userId: number,
        public summaryCategoryId: number,
    ) { }
}

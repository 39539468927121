import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, Validators, FormControl, FormBuilder } from '@angular/forms';
import { UserType } from '../shared/models/user.type';

@Component({
  selector: 'app-parameter-user-typ-edit',
  templateUrl: './parameter-user-typ-edit.component.html',
  styleUrls: ['./parameter-user-typ-edit.component.scss'],
})
export class ParameterUserTypEditComponent implements OnInit {

  form: FormGroup;
  model: UserType;

  constructor(
    fb: FormBuilder,
    public dialogRef: MatDialogRef<ParameterUserTypEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.model = data['userType'];
      
      this.form = fb.group({
        'name': ['', Validators.compose([Validators.required, Validators.minLength(2)])],
      });
     }

  ngOnInit() {
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    this.model.status = 'active';
    this.data = {
      id: this.model.id,
      userType: this.model,
    };
    this.dialogRef.close(this.data);
  }

}

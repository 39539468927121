import { LoginService } from '../shared/services/login.service';
import { Http } from '@angular/http';
import { SubCategory } from '../shared/models/subcategory';
import { Company } from '../shared/models/company';
import { Bank } from '../shared/models/bank';
import { Category } from '../shared/models/category';
import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { CatalogCompanyModalComponent } from '../catalog-company-modal/catalog-company-modal.component';
import { PublicationService } from '../shared/services/publication.service';
import { PublicationTag } from '../shared/interfaces/publication.tag.interface';
import { Router } from '@angular/router';
import {
  MatPaginator, MatTableDataSource, MatSnackBar, MatDialog, MatSort, MatDialogConfig,
} from '@angular/material';
import { filter } from 'rxjs/operators';
import { saveAs as importedSaveAs } from 'file-saver';
import { Status } from '../shared/models/helpers/status';
import { Preference } from '../shared/models/helpers/preference';
import { environment } from '../../environments/environment';
import { Sort } from '@angular/material';
import { tap } from 'rxjs/operators';
import { merge } from 'rxjs/observable/merge';
import { CompanyData } from '../shared/services/companydata.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-catalog-company',
  templateUrl: './catalog-company.component.html',
  styleUrls: ['./catalog-company.component.scss'],
})
export class CatalogCompanyComponent implements OnInit, AfterViewInit, OnDestroy {

  modalConfig: any;

  assetsEndpoint: string = environment.assetsEndpoint;
  devApiEndpoint: string = environment.devApiEndpoint;

  categoryList: Category[];
  subCategoryList: SubCategory[];

  showFilters: Boolean = true;
  st: Status;
  pf: Preference;
  loading: Boolean;
  datasource;
  category: Category;
  company: Company;
  status: any;
  categories: Category[];
  subCategories: SubCategory[];
  companies: Company[];
  rowsOnPage = 20;
  rowsOnPageOptions: any;
  statuses: any;
  companyName = '';
  id: number;
  searchTags: PublicationTag[] = [];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  data: any[];
  displayedColumns = [];
  sortFill: string = 'name';
  sortOrder: boolean = false;

  // paginacion
  dataSourceC: CompanyData;
  totalcompany = 0;

  constructor(
    private publicationService: PublicationService,
    private http: Http,
    public snackBar: MatSnackBar,
    private loginService: LoginService,
    public router: Router,
    public dialog: MatDialog,
    public _HttpClient: HttpClient) { 
      this.modalConfig = new MatDialogConfig();
      this.modalConfig.height = '500px';
      this.modalConfig.width = '800px';
      this.modalConfig.closeOnNavigation = true;
    }

  ngOnInit() {
    if (this.isUserAdmin()) {
      this.displayedColumns = ['eye_id', 'name',
        'categoryName', 'subCategoryName', 'views', 'bookmarks', 'downloads', 'preferences', 'statusF'];
    } else {
      this.displayedColumns = ['eye_id', 'name',
        'categoryName', 'subCategoryName'];
    }
    this.loadCategories();
    this.loadSubcategories();
    this.loadStatuses();
    this.loadRowsOnPageOptions();
    this.dataSourceC = new CompanyData(this.publicationService, this.loginService);
    this.loadCompanyPage();
  }

  ngOnDestroy(){
    this.dialog.closeAll();
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

        merge(this.sort.sortChange, this.paginator.page)
        .pipe(
            tap(() => this.loadCompanyPage()),
        )
        .subscribe();
  }

  // paginacion
  loadCompanyPage() {

      this.setOrder(this.sort.active, this.sort.direction);
      this.dataSourceC.loadCompanys(this.buildSearchString());
      this.getTotalCompanys();

  }

  setOrder(fill, order) {
    this.sortOrder = (order == 'desc') ? true : false;
    switch (fill) {
      case 'name':
        this.sortFill = 'Company';
        break;
      case 'categoryName':
        this.sortFill = 'Category';
        break;
      case 'subCategoryName':
        this.sortFill = 'Subcategory';
        break;
      case 'views':
        this.sortFill = 'Views';
        break;
      case 'bookmarks':
        this.sortFill = 'Bookmarks';
        break;
      case 'downloads':
        this.sortFill = 'Downloads';
        break;
      case 'statusF':
        this.sortFill = 'Status';
        break;
        case 'preferences':
          this.sortFill = 'Preferences';
          break;
      default:
        this.sortFill = 'Company';
        this.sortOrder = false;
        break;
    }
  }

  getTotalCompanys() {
      this.publicationService.getTotalC(this.buildSearchString()).subscribe(
        total => this.totalcompany = total,
      );
  }

  // Image
  getLogoUrl(companyId, url) {
    if (url) {
      return `${environment.s3Endpoint}/empresas/${companyId}/logo/${url}`;
    } else {
      return `${environment.s3Endpoint}/app/img/no_disponible.png`;
    }
  }

  // Company
  loadCompanies() {
    this.publicationService.getCompanies().subscribe(
      companies => {
        if (this.isUserAdmin()) {
          this.data = companies;
        } else {
          this.data = companies.filter(company => company.status === 'active');
        }
        this.datasource =
          new MatTableDataSource<Company>(this.data);
        this.datasource.paginator = this.paginator;
        this.datasource.sort = this.sort;
      },
      () => {
      },
    );
  }

  // Category
  loadCategories() {
    this.publicationService.getCategoriesSystem().subscribe(
      categories => this.categories = categories,
      () => {
      },
    );
  }

  loadSubcategories() {
    this.publicationService
      .getAllSubCategoriesSystem()
      .subscribe(subCategories => {
        this.subCategories = subCategories;
      });
  }

  // Status
  loadStatuses() {
    this.statuses = [
      { label: 'Activo', value: 'active' },
      { label: 'Inactivo', value: 'inactive' },
    ];
  }

  // Rows per page
  loadRowsOnPageOptions() {
    this.rowsOnPageOptions = [
      { label: '5', value: 5 },
      { label: '10', value: 10 },
      { label: '30', value: 30 },
    ];
  }

  // Filter search
  search() {
    // this.publicationService.searchCompanies(this.buildSearchString()).subscribe(
    //   companies => {
    //     if (this.isUserAdmin()) {
    //       this.data = companies;
    //     } else {
    //       this.data = companies.filter(company => company.status === 'active');
    //     }
    //     this.datasource =
    //       new MatTableDataSource<Company>(this.data);
    //     this.datasource.paginator = this.paginator;
    //     this.datasource.sort = this.sort;
    //   },
    //   err => {
    //   },
    //   () => {
    //   },
    // );
    this.loadCompanyPage();
  }

  buildSearchString() {
    //const page = (this.paginator.pageIndex <= 0) ? 1 : this.paginator.pageIndex;
    const page = this.paginator.pageIndex + 1;
    const pageSize = (this.paginator.pageSize == undefined) ? 20 : this.paginator.pageSize;
    let result = `page=${page}&PageSize=${pageSize}&OrderBy=${this.sortFill}&OrderDesc=${this.sortOrder}`;
    if (this.id) {
      result += `&id=${this.id}`;
    }
    if (this.companyName) {
      result += `&name=${this.companyName}`;
    }
    if (this.categoryList) {
      this.categoryList.forEach(category => {
        result += `&categoryId=${category.id}`;
      });
    }
    if (this.subCategoryList) {
      this.subCategoryList.forEach(subcategory => {
        result += `&subcategoryId=${subcategory.id}`;
      });
    }
    if (this.status) {
      result += `&status=${this.status.value}`;
    }

    if (!this.loginService.isUserAdmin()) {
      result += `&status=active`;
    }

    return result;
  }

  // Check user
  isUserAdmin() {
    return this.loginService.isUserAdmin();
  }

  // Create modal
  showCreateModal() {
    const dialog = this.dialog.open(CatalogCompanyModalComponent, this.modalConfig);

    dialog.afterClosed()
      .pipe(filter(data => data))
      .subscribe(data => {
        let companyOperation: Observable<Company>;
        companyOperation = this.publicationService.addCompany(data['company']);
        companyOperation.subscribe(
          company => {
            this.company = company;
            if (data['imgFile']) {
              this.postLogo(this.company.id, data['imgFile']);
            } else {
              setTimeout(() => this.router.navigate([`/catalog/company/${this.company['id']}`]), 1500);
            }
          },
          err => {
            this.openSnackBar(err.error.message);
          },
        );
      },
    );
  }

  postLogo(companyid: number, file: any) {
    const headers = new HttpHeaders({ 'Authorization':'Bearer '+localStorage.getItem('token'), 'Cache-Control':  'no-cache, no-store, private' });
    const formData = new FormData();
    formData.append('file', file);
    formData.append('type', 'company');
    formData.append('id', companyid.toString());
    this._HttpClient.post(`${this.devApiEndpoint}/file/logo`, formData, {headers})
      .map(response => response)
      .subscribe(() => { 

      }, 
      err => { 
      }, 
      () => {
        setTimeout(() => this.router.navigate([`/catalog/company/${this.company['id']}`]), 1500);
      });
  }

  // Reset list
  clear() {
    this.loading = true;
    // this.publicationService.getCompanies().subscribe(
    //   companies => {
    //     if (this.isUserAdmin()) {
    //       this.data = companies;
    //     } else {
    //       this.data = companies.filter(company => company.status === 'active');
    //     }
    //     this.datasource =
    //       new MatTableDataSource<Company>(this.data);
    //     this.datasource.paginator = this.paginator;
    //     this.datasource.sort = this.sort;
    //   },
    //   err => {
    //   },
    // );
    // Clear filter fields
    this.companyName = '';
    this.category = null;
    this.categoryList = [];
    this.subCategoryList = [];
    this.status = null;
    this.searchTags = [];

    this.loadCompanyPage();
  }

  refreshData() {
    this.clear();
  }

  getExcel() {
    if (this.isUserAdmin()) {
      this.publicationService
        .getCompaniesExcel(this.buildSearchString())
        .subscribe(blob => {
          importedSaveAs(blob, 'Empresas.xlsx');
        });
    } else {
      this.publicationService
        .getCompaniesExcelNoAnalytics(this.buildSearchString())
        .subscribe(blob => {
          importedSaveAs(blob, 'Empresas.xlsx');
        });
    }
  }

  // Chip events
  dropDownCategoryChange() {
    this.searchTags = this.searchTags.filter(item => item.name !== 'category');
    this.searchTags.push({ name: 'category', value: this.categoryList.map(category => category.name).join(', ') });
  }

  dropDownSubCategoryChange() {
    this.searchTags = this.searchTags.filter(item => item.name !== 'subcategory');
    this.searchTags
      .push({ name: 'subcategory', value: this.subCategoryList.map(subcategory => subcategory.name).join(', ') });
  }

  dropDownStatusChange() {
    this.searchTags = this.searchTags.filter(item => item.name !== 'status');
    this.searchTags.push({ name: 'status', value: this.status.label });
  }

  nameChange() {
    if (this.companyName != '') {
      this.searchTags = this.searchTags.filter(item => item.name !== 'name');
      this.searchTags.push({ name: 'name', value: this.companyName });
    }
  }


  removeTag(searchTag: any) {
    const index = this.searchTags.indexOf(searchTag);

    if (index >= 0) {
      this.searchTags.splice(index, 1);
    }

    switch (searchTag.name) {
      case 'name':
        this.searchTags = this.searchTags.filter(item => item.name !== 'company');
        this.companyName = '';
        break;
      case 'status':
        this.searchTags = this.searchTags.filter(item => item.name !== 'status');
        this.status = null;
        break;
      case 'category':
        this.searchTags = this.searchTags.filter(item => item.name !== 'category');
        this.category = null;
        this.categoryList = null;
        break;
      case 'subcategory':
        this.searchTags = this.searchTags.filter(item => item.name !== 'subcategory');
        this.subCategoryList = null;
        break;
    }
    // this.publicationService.searchCompanies(this.buildSearchString()).subscribe(
    //   companies => {
    //     if (this.isUserAdmin()) {
    //       this.data = companies;
    //     } else {
    //       this.data = companies.filter(company => company.status === 'active');
    //     }
    //     this.datasource =
    //       new MatTableDataSource<Company>(this.data);
    //     this.datasource.paginator = this.paginator;
    //     this.datasource.sort = this.sort;
    //   },
    //   err => {
    //   },
    // );
    this.loadCompanyPage();
  }

  onChange(event) {
    if (!(event.keyCode === 8 || event.keyCode === 46)) {
      return false;
    }

  }

  statusChange(id, event) {
    this.st = new Status(null);
    this.st.st = (event.checked) ? 'active' : 'inactive';
    this.publicationService
      .updateStatus(id, 'company', this.st)
      .subscribe(() => this.search());
  }

  preferenceChange(id, event) {
    this.pf = new Preference(null);
    this.pf.pf = (event.checked) ? true : false;
    this.publicationService
      .updatePreference(id, 'company', this.pf)
      .subscribe(() => this.search());
  }

  toggleFilters() {
    this.showFilters = (this.showFilters) ? false : true;
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, '', {
      duration: 2000,
    });
  }

  sortData(sort: Sort) {
    const data = this.data.slice();

    if (!sort.active || sort.direction === '') {
      this.datasource =
        new MatTableDataSource<Bank>(data);      
      return;
    }

    const d = this.data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'name': return this.compare(a.name.toLowerCase(), b.name.toLowerCase(), isAsc);
        case 'views': return this.compare(+a['views'], +b['views'], isAsc);
        case 'bookmarks': return this.compare(+a['bookmarks'], +b['bookmarks'], isAsc);
        case 'downloads': return this.compare(+a['downloads'], +b['downloads'], isAsc);
        case 'statusF': return this.compare(a.statusF.toLowerCase(), b.statusF.toLowerCase(), isAsc);
        case 'categoryName': return this.compare(a.categoryName.toLowerCase(), b.categoryName.toLowerCase(), isAsc);
        case 'subCategoryName': return this.compare(a.subCategoryName.toLowerCase(), 
        b.subCategoryName.toLowerCase(), isAsc);
        default: return 0;
      }
    });

    this.datasource =
      new MatTableDataSource<Bank>(d);    
  }

  compare(a, b, isAsc) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  compareString() {}

}

import { ParameterService } from './../shared/services/parameter.service';
import { PublicationFile } from '../shared/models/publication.file';
import { StockImage } from '../shared/models/stockImage';
import { RecommendedAction } from '../shared/models/recommendedAction';
import { Currency } from '../shared/models/currency';
import { SubCategory } from '../shared/models/subcategory';
import { Company } from '../shared/models/company';
import { Analyst } from '../shared/models/analyst';
import { Bank } from './../shared/models/bank';
import { Publication } from '../shared/models/publication';
import { PublicationTargetPrice } from '../shared/models/publication.targetprice';
import { PublicationService } from '../shared/services/publication.service';
import { EmitterService } from '../emitter.service';
import { Component, OnInit, Inject, ElementRef, HostListener } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';
import { FormGroup, Validators, FormControl, NgForm, FormBuilder } from '@angular/forms';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { Observable } from 'rxjs/Rx';
import { Category } from './../shared/models/category';
import { Router } from '@angular/router';
import { Message } from 'primeng/primeng';
import { ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { environment } from '../../environments/environment';
import { LoginService } from '../shared/services/login.service';
import { PublicationType } from '../shared/models/publication.type';
import { NewPublication } from '../shared/models/new/publication.new';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-publication-modal',
  templateUrl: './publication-modal.component.html',
  styleUrls: ['./publication-modal.component.scss'],
})
export class PublicationModalComponent implements OnInit {
  @HostListener('wheel', ['$event'])
  handleWheelEvent(event) {
    event.preventDefault();
  }
  
  publicationType: PublicationType;
  publicationTypes: PublicationType[];
  imgSrc: string;
  fileSrc: string;
  goToPreview: Boolean = false;
  devApiEndpoint: string = `${environment.s3Endpoint}/app/img/stock`;
  hasTargetPrice: Boolean = false;
  selectedStockImageId = 0;
  company: Company;
  bank: Bank;
  analyst: Analyst;
  category: Category;
  subcategory: SubCategory;
  currency: Currency;
  recommendedAction: RecommendedAction;
  stockImage: StockImage;
  banks: Bank[];
  analysts: Analyst[];
  categories: Category[];
  companies: Company[];
  subcategories: SubCategory[];
  currencies: Currency[];
  recommendedActions: RecommendedAction[];
  stockImages: StockImage[];
  uploadedFiles: any[] = [];
  uploadedImages: any[] = [];
  imageFile: any;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  summaryFormGroup: FormGroup;
  targetPriceFormGroup: FormGroup;
  @ViewChild('imgFileInput')
  imgFile: ElementRef;
  @ViewChild('divFile')
  divFile: ElementRef;
  @ViewChild('divImgFile')
  divImgFile: ElementRef;
  @ViewChild('publicationFileInput')
  publicationFile: any;
  minDate = new Date();

  publicationImgModel = new PublicationFile(null, null, null, '', '');
  publicationTargetPrice = new PublicationTargetPrice(null, null, null, null);
  publication = 
  new NewPublication(null, null, null, null, null, null, null, null, null, null, new Date(), null, null, null, null);

  constructor(
    private activeModal: NgbActiveModal,
    public sanitizer: DomSanitizer,
    private publicationService: PublicationService,
    private loginService: LoginService,
    private parameterService: ParameterService,
    private router: Router,
    private _formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<PublicationModalComponent>,
    public _HttpClient: HttpClient,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  // preview() {
  //   this.publication.published = false;
  //   this.postPublication(true);
  // }

  // publish() {
  //   this.publication.published = true;
  //   this.postPublication();
  // }
  postPublication(preview: boolean = false) {
    this.publication.analystId = this.publication.analystId['id'];
    this.publication.bankId = this.publication.bankId['id'];
    this.publication.categoryId = this.publication.categoryId['id'];
    this.publication.companyId = this.publication.companyId['id'];
    this.publication.subcategoryId = this.publication.subcategoryId['id'];
    this.publication.userId = this.loginService.getUserId();

    let publicationOperation: Observable<Publication>;
    publicationOperation = this.publicationService.addPublication(this.publication);
    publicationOperation.subscribe(
      publication => {
        this.publication = publication;
        if (this.hasTargetPrice) {
          this.postTargetPrice(this.publication['id']);
        }
        this.postFile(this.publication['id']);
        this.postImage(this.publication['id']);
        // this.targetPricepublication = new PublicationTargetPrice(null, null, null, null, null);
        this.activeModal.close();
        if (preview) {
          setTimeout(() => this.router.navigate([`/publication/${this.publication['id']}/preview`]), 500);
        } else {
          setTimeout(() => this.router.navigate([`/publication/${this.publication['id']}`]), 500);
        }
      },
      err => {
        
      },
    );
  }

  // Post Target Price
  postTargetPrice(id: number) {
    this.publicationTargetPrice.publicationId = id;
    this.publicationTargetPrice.currencyId = this.publicationTargetPrice.currencyId['id'];
    this.publicationTargetPrice.recommendedActionId = this.publicationTargetPrice.recommendedActionId['id'];

    let publicationTargetPriceOperation: Observable<PublicationTargetPrice>;
    publicationTargetPriceOperation = this.publicationService.postTargetPrice(id, this.publicationTargetPrice);
    publicationTargetPriceOperation.subscribe();
  }

  createImageFromBlob(image: Blob) {
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      this.imageFile = reader.result;
    }, false);

    if (image) {
      reader.readAsDataURL(image);
    }
  }

  // Post Publication Image
  postImage(id: number) {
    const headers = new HttpHeaders({'Authorization':'Bearer '+localStorage.getItem('token'), 'Cache-Control': 'no-cache, no-store, private' });

    const formData = new FormData();
    if (this.selectedStockImageId > 0) {
      this.publicationService.getPublicationImageById(this.selectedStockImageId)
        .subscribe(data => {
          this.imageFile = data;
          this.createImageFromBlob(data);
          formData.append('file', this.imageFile, `publication${id}.jpg`);
          formData.append('id', id.toString());
          this._HttpClient.post(`${environment.devApiEndpoint}/file/image`, formData, {headers})
            .map((res: Response) => res.json())
            .catch((error: any) => Observable.throw(error.json().error || 'Error posting the image'))
            .subscribe();
        });
    } else {
      this.imageFile = this.uploadedImages[0];
      formData.append('file', this.imageFile);
      formData.append('id', id.toString());
      this._HttpClient.post(`${environment.devApiEndpoint}/file/image`, formData, {headers})
        .map((res: Response) => res.json())
        .catch((error: any) => Observable.throw(error.json().error || 'Error posting the image'))
        .subscribe();
    }
  }

  // Post Publication File
  postFile(id: number) {
    const headers = new HttpHeaders({ 'Authorization':'Bearer '+localStorage.getItem('token'), 'Cache-Control':  'no-cache, no-store, private' });

    const formData = new FormData();
    formData.append('file', this.uploadedFiles[0]);
    formData.append('id', id.toString());
    this._HttpClient.post(`${environment.devApiEndpoint}/file`, formData, {headers})
      .map((res: Response) => res.json())
      .catch((error: any) => Observable.throw(error.json().error || 'Error posting the file'))
      .subscribe();
  }

  fileSelected(event) {
    for (const file of event.files) {
      this.uploadedFiles.push(file);
    }
  }

  imageSelected(event) {
    for (const file of event.files) {
      this.uploadedImages.push(file);
    }
  }

  selectImage(id: number) {
    this.selectedStockImageId = id;
  }

  

  ngOnInit() {
    this.firstFormGroup = this._formBuilder.group({
      title: [this.publication.title, Validators.required],
      type: ['', Validators.required],
      date: [this.publication.date, Validators.required],
      pages: [this.publication.pages, Validators.required],
      rating: [this.publication.rating, Validators.required],
      analyst: [this.publication.analystId, Validators.required],
      bank: [this.publication.bankId, Validators.required],
      company: [this.publication.companyId, Validators.required],
      category: [this.publication.categoryId, Validators.required],
      subCategory: [this.publication.subcategoryId, Validators.required],
      hasTargetPrice: [this.hasTargetPrice, Validators.nullValidator],
    });
    this.summaryFormGroup = this._formBuilder.group({
      'summary': [this.publication.summary, Validators.required],
      'selectedFile': [{ value: '', disabled: true }, Validators.nullValidator],
    });
    this.targetPriceFormGroup = this._formBuilder.group({
      price: [this.publicationTargetPrice.price, Validators.required],
      currency: [this.publicationTargetPrice.currencyId, Validators.required],
      recommendedAction: [this.publicationTargetPrice.recommendedActionId, Validators.required],
    });
    this.loadPublicationTypes();
    this.loadCompanies();
    this.loadBanks();
    this.loadCategories();
    this.loadCurrencies();
    this.loadRecommededActions();
    this.loadStockImages();
  }

  // Company
  loadCompanies() {
    this.publicationService.getCachedCompanies().subscribe(
      companies => this.companies = companies['records'],
      err => {
        
      },
    );
  }

  // Bank
  loadBanks() {
    this.publicationService.getCachedBanks().subscribe(
      banks => this.banks = banks['records'],
      err => {
        
      },
    );
  }

  // Currency
  loadCurrencies() {
    this.publicationService.getCurrencies().subscribe(
      currencies => this.currencies = currencies,
      err => {
        
      },
    );
  }

  loadPublicationTypes() {
    this.parameterService
    .getPublicationTypes()
    .subscribe(publicationTypes => {
      this.publicationTypes = publicationTypes;
    });
  }

  // Recommeded Actions
  loadRecommededActions() {
    this.publicationService.getRecommendedActions().subscribe(
      recommendedActions => this.recommendedActions = recommendedActions,
      err => {
        
      },
    );
  }

  // Category
  loadCategories() {
    this.publicationService.getCategories().subscribe(
      categories => this.categories = categories['records'],
      err => {
        
      },
    );
  }

  categoryChange() {
    this.publicationService.getSubCategories(this.publication['categoryId']).subscribe(
      subcategories => this.subcategories = subcategories.filter(subcategory => subcategory.status === 'active'),
      err => {
        
      },
    );
  }

  // Stock Images
  loadStockImages() {
    this.publicationService.getStockImages().subscribe(
      stockImages => this.stockImages = stockImages,
      err => {
        
      },
    );
  }

  bankChange() {
    this.publicationService.getBankAnalysts(this.publication['bankId'])
      .subscribe(analysts => {
        this.analysts = analysts.filter(analyst => analyst.status === 'active');
      });
  }

  preview() {
    this.goToPreview = true;

    this.save();
  }

  save() {
    this.publication.analystId = this.publication['analystId'];
    this.publication.bankId = this.publication['bankId'];
    this.publication.companyId = this.publication['companyId'];
    this.publication.categoryId = this.publication['categoryId'];
    this.publication.subcategoryId = this.publication['subcategoryId'];
    this.publication.published = (this.goToPreview) ? false : true;
    this.publication.userId = this.loginService.getUserId();
    this.publication.status = 'active';

    this.publicationTargetPrice.currencyId = this.publicationTargetPrice['currencyId'];
    this.publicationTargetPrice.recommendedActionId = this.publicationTargetPrice['recommendedActionId'];

    this.data = {
      preview: this.goToPreview,
      hasTargetPrice: this.hasTargetPrice,
      publication: this.publication,
      publicationTargetPrice: this.publicationTargetPrice,
      imgFile: this.imgFile.nativeElement.files[0],
      publicationFile: this.publicationFile.nativeElement.files[0],
      selectedStockImageId: this.selectedStockImageId,
    };
    this.dialogRef.close(this.data);
  }

  close() {
    this.dialogRef.close();
  }

  fileChangeEvent(fileInput: any) {
    if (fileInput.target.files && fileInput.target.files[0]) {
      // const reader = new FileReader();

      // reader.onload = ((e) => {
      //   this.imgSrc = e.target['result'];
      // });
      const file = fileInput.target.files[0];
      this.imgSrc = file.name;
      const img = this.divImgFile.nativeElement;
      this.selectedStockImageId = 0;

      img.getElementsByTagName('span')[0].innerText = '';
      img.getElementsByTagName('span')[0].innerHTML = '';

      // reader.readAsDataURL(fileInput.target.files[0]);
    }
  }

  publicationFileChangeEvent(fileInput: any) {
    if (fileInput.target.files && fileInput.target.files[0]) {
      const file = fileInput.target.files[0];
      this.fileSrc = file.name;
      
      const img = this.divFile.nativeElement;
      img.getElementsByTagName('span')[0].innerText = '';
      img.getElementsByTagName('span')[0].innerHTML = '';

    }
  }

}

import { CatalogSubcategoryModalComponent } from './../catalog-subcategory-modal/catalog-subcategory-modal.component';
import { CatalogCategoryModalComponent } from './../catalog-category-modal/catalog-category-modal.component';
import { Component, OnInit, ViewChild, AfterViewInit, ViewChildren, OnDestroy } from '@angular/core';
import { Category } from '../shared/models/category';
import { SubCategory } from '../shared/models/subcategory';
import { PublicationService } from '../shared/services/publication.service';
import { LoginService } from '../shared/services/login.service';
import { DataSource } from '@angular/cdk/collections';
import {
  MatPaginator, MatTableDataSource, MatPaginatorIntl,
  MatSnackBar, MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSort, SortDirection,
} from '@angular/material';
import { filter } from 'rxjs/operators';
import { NewCategory } from '../shared/models/new/category.new';
import { Status } from '../shared/models/helpers/status';
import { Observable } from 'rxjs/Rx';
import { NewSubcategory } from '../shared/models/new/subcategory.new';
import { Router } from '@angular/router';
import { PublicationTag } from '../shared/interfaces/publication.tag.interface';
import { forkJoin } from 'rxjs/observable/forkJoin';
import { saveAs as importedSaveAs } from 'file-saver';
import { Sort } from '@angular/material';
import { CategorydataService } from '../../app/shared/services/categorydata.service';
import { Analyst } from 'app/shared/models/analyst';



@Component({
  selector: 'app-catalog-category',
  templateUrl: './catalog-category.component.html',
  styleUrls: ['./catalog-category.component.scss'],
})
export class CatalogCategoryComponent implements OnInit, AfterViewInit, OnDestroy {
  [x: string]: any;

  showCategoryFilters: Boolean = true;
  showSubcategoryFilters: Boolean = true;
  st: Status;
  fCategory: string;
  fSubCategory: string;
  category: Category;
  loading: Boolean = false;
  categories: Category;
  model: NewCategory;
  newSubcategory: NewSubcategory;
  status: any;
  statusSC: any;
  dataC: any[];
  dataSC: any[];
  datasourceCategories;
  datasourceSubcategories: any;
  displayedCategoryColumns = [];
  displayedSubcategoryColumns = [];
  @ViewChild('paginatorCategories') paginatorCategories: MatPaginator;
  @ViewChild('paginatorSubcategories') paginatorSubcategories: MatPaginator;
  @ViewChild('table', { read: MatSort }) sortCategories: MatSort;
  @ViewChild('tableSC', { read: MatSort }) sortSubcategories: MatSort;
  statuses: any;
  searchTags: PublicationTag[] = [];
  searchTagsSC: PublicationTag[] = [];
  direction: SortDirection;
  sortOrder: Boolean = false;
  @ViewChild(MatSort) sort: MatSort;
  sortFill: string = 'name';
  showFilters: Boolean = true;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  records: any;
  
  // paginacion
  dataSourceC: CategorydataService;
  totalcat = 0;

  private readonly totalcategory = this.totalcategory;

  constructor(
    private publicationService: PublicationService,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    public loginService: LoginService) { }

  ngOnInit() {
    this.model = new NewCategory(null, null);
    this.newSubcategory = new NewSubcategory(null, null, null);
    if (this.isUserAdmin()) {
      this.displayedCategoryColumns = ['name', 'statusF'];
      this.displayedSubcategoryColumns = ['categoryName', 'name', 'statusF'];
    } else {
      this.displayedCategoryColumns = ['name'];
      this.displayedSubcategoryColumns = ['categoryName', 'name'];
    }
    // this.publicationService.getCategories()
    //   .subscribe(categories => this.categories = categories);
    this.loadStatuses();
  }

  ngOnDestroy(){
    this.dialog.closeAll();
  }

  ngAfterViewInit() {
    this.loadData();
  }


  // Categories
  loadData() {

    // const subcategoryOperation = this.publicationService.getAllSubCategories();
    // const categoryOperation = this.publicationService.getCategories();

    const subcategoryOperation = this.publicationService.getAllSubCategoriesSystem();
    const categoryOperation = this.publicationService.getCategoriesSystem();

    const fj = forkJoin([
      categoryOperation,
      subcategoryOperation]).onErrorResumeNext();

    fj.subscribe(
      results => {
        this.dataC = results[0];
        this.datasourceCategories =
          new MatTableDataSource<Category>(this.dataC);
        this.datasourceCategories.paginator = this.paginatorCategories;
        this.datasourceCategories.sort = this.sortCategories;

        this.dataSC = results[1];
        this.datasourceSubcategories =
          new MatTableDataSource<any>(this.dataSC);
        this.datasourceSubcategories.paginator = this.paginatorSubcategories;
        this.datasourceSubcategories.sort = this.sortSubcategories;
      },
    );
  }

  // Status
  loadStatuses() {
    this.statuses = [
      { label: 'Activo', value: 'active' },
      { label: 'Inactivo', value: 'inactive' },
    ];
  }

  // Modal
  openModal() {
    const dialog = this.dialog.open(CatalogCategoryModalComponent, {
      closeOnNavigation: true,
    });

    dialog.afterClosed()
      .pipe(filter(name => name))
      .subscribe(name => {
        this.model.name = name;
        this.model.status = 'active';
        let categoryOperation: Observable<Boolean>;
        categoryOperation = this.publicationService.postCategory(this.model);
        categoryOperation.subscribe(
          data => {
            this.loadData();
          },
          err => {
            this.openSnackBar(err);
          },
        );
      },
    );
  }

  openSubcategoryModal() {
    const dialog = this.dialog.open(CatalogSubcategoryModalComponent, {
      closeOnNavigation: true,
      data: {
        categories: this.dataC,
      },
    });

    dialog.afterClosed()
      .pipe(filter(data => data))
      .subscribe(data => {
        this.newSubcategory.categoryid = data['categoryid'];
        this.newSubcategory.name = data['name'];
        this.newSubcategory.status = 'active';
        let subcategoryOperation: Observable<Boolean>;
        subcategoryOperation = this.publicationService.postSubcategory(data['categoryid'], this.newSubcategory);
        subcategoryOperation.subscribe(
          response => {
            this.loadData();
          },
          err => {
            this.openSnackBar(err);
          },
        );
      },
    );
  }

  isUserAdmin() {
    return this.loginService.isUserAdmin();
  }

  statusChange(element, event, table: string) {

    let newStatus = event.checked == false ? 'inactive' : 'active';

    var newElement = {
      st: element.status == null ? 'active' : newStatus
    };

    if (event.checked) {
      element.status = 'active';
      element.statusF = 'Activo';
    } else {
      element.status = 'inactive';
      element.statusF = 'Inactivo';
    }

    this.st = new Status(null);
    this.st.st = (event.checked) ? 'active' : 'inactive';
    this.publicationService
      .updateStatus(element.id, table, newElement)
      .subscribe(data => this.loadCategoryPage());
  }

  fCategoryChange(e) {
    if (this.fCategory != '') {
      this.searchTags = this.searchTags.filter(item => item.name !== 'category');
      this.searchTags.push({ name: 'category', value: this.fCategory });
    }    
  }

  fSubCategoryChange(e) {
    if (this.fSubCategory != '') {
      this.searchTagsSC = this.searchTagsSC.filter(item => item.name !== 'subcategory');
      this.searchTagsSC.push({ name: 'subcategory', value: this.fSubCategory });
    }
  }

  dropDownCategoryChange() {
    this.searchTagsSC = this.searchTagsSC.filter(item => item.name !== 'category');
    this.searchTagsSC.push({ name: 'category', value: this.category.name });
  }

  dropDownStatusChange() {
    this.searchTags = this.searchTags.filter(item => item.name !== 'status');
    this.searchTags.push({ name: 'status', value: this.status.label });
  }

  dropDownStatusSCChange() {
    this.searchTagsSC = this.searchTagsSC.filter(item => item.name !== 'status');
    this.searchTagsSC.push({ name: 'status', value: this.statusSC.label });
  }

  searchCategories() {
    this.loading = true;
    this.publicationService.searchCategories(this.buildSearchString()).finally(() => this.loading = false).subscribe(
      categories => {
        this.dataC = categories['records'];
        this.datasourceCategories =
          new MatTableDataSource<CategorydataService>([]);
        this.datasourceCategories.paginator = this.paginatorCategories;
        this.datasourceCategories.sort = this.sortCategories;
      }
    );
    
  }
  // Search
  search(type: string) {
    if (type == 'cat') {

      this.publicationService
      .searchCategories(this.buildSearchString())
      .subscribe(
        analysts => {
          if (this.isUserAdmin()) {
            this.data = analysts;
          } else {
            this.data = analysts.filter(analyst => analyst.statusF === 'active');
          }
          
          //if(this.data.length > 0){
            let arrData: any[] = [];
            let i: any = 0;
            for (i in this.data.records) {
              arrData.push({'id': this.data.records[i].id, 'name': this.data.records[i].name, 'statusF': this.data.records[i].statusF, 'status': this.data.records[i].status});
            }
            this.datasourceCategories =
              new MatTableDataSource<Category>(arrData);
            this.datasourceCategories.paginator = this.paginator;
            this.datasourceCategories.sort = this.sort;
          // } else {
          //   this.datasourceCategories =
          //     new MatTableDataSource<Category>([]);
          //   this.datasourceCategories.paginator = this.paginator;
          //   this.datasourceCategories.sort = this.sort;
          // }
          
        },
        err => {
        });

    } else {

      this.publicationService
      .searchSubCategories(this.buildSearchStringSC())
      .subscribe(
        analysts => {
          this.dataSC = analysts;
          
          var i: any = 0;
          if(Object.keys(analysts)[4] == undefined || Object.keys(analysts)[4] != 'records'){
            this.datasourceSubcategories =
              new MatTableDataSource<SubCategory>([]);
            this.datasourceSubcategories.paginator = this.paginator;
            this.datasourceSubcategories.sort = this.sort;
            return false;
          } else {
            this.datasourceSubcategories =
              new MatTableDataSource<SubCategory>(analysts['records']);
            this.datasourceSubcategories.paginator = this.paginator;
            this.datasourceSubcategories.sort = this.sort;
          }
        },
        err => {
        });

    }
    //this.loadCategoryPage();
  }
  // paginacion
  loadCategoryPage() {
    this.setOrder(this.sort.active, this.sort.direction);
    // this.dataSourceC.loadCategorys(this.buildSearchString());
    this.getTotalCategory();
}
setOrder(fill, order) {
  this.sortOrder = (order == 'desc') ? true : false;
  switch (fill) {
    case 'name':
      this.sortFill = 'Bank';
      break;
    case 'views':
      this.sortFill = 'Views';
      break;
    case 'bookmarks':
      this.sortFill = 'Bookmarks';
      break;
    case 'downloads':
      this.sortFill = 'Downloads';
      break;
    case 'status':
      this.sortFill = 'Status';
      break;
    default:
      this.sortFill = 'name';
      this.sortOrder = true;
      break;
  }
}

getTotalCategory() {
  this.publicationService.getTotalCat(this.buildSearchString()).subscribe(
    total => this.totalcat = total,
  );
}

  buildSearchString() {
    const page = this.paginator.pageIndex + 1;
    const pageSize = (this.paginator.pageSize == undefined) ? 20 : this.paginator.pageSize;
    let result = `page=${page}&PageSize=${pageSize}&OrderBy=${this.sortFill}&OrderDesc=${this.sortOrder}&`;
    if (this.fCategory) {
      result += `name=${this.fCategory}&`;
    }
    if (this.status) {
      result += `status=${this.status.value}&`;
    }

    return result;
  }

  clearCategories() {
    this.fCategory = null;
    this.searchTags = [];
    this.status = null;

    this.loadCategories();
  }

  loadCategories() {
    this.publicationService.getCategoriesSystem()
      .subscribe(categories => {
        this.datasourceCategories =
          new MatTableDataSource<Category>(categories);
        this.datasourceCategories.paginator = this.paginatorCategories;
        this.datasourceCategories.sort = this.sortCategories;
      });
  }

  loadSubCategories() {
    this.publicationService.getAllSubCategoriesSystem()
      .subscribe(subcategories => {
        this.datasourceSubcategories =
          new MatTableDataSource<any>(subcategories);
        this.datasourceSubcategories.paginator = this.paginatorCategories;
        this.datasourceSubcategories.sort = this.sortCategories;
      });
  }

  removeTag(searchTag: any) {
    const index = this.searchTags.indexOf(searchTag);

    if (index >= 0) {
      this.searchTags.splice(index, 1);
    }
    switch (searchTag.name) {
      case 'category':
        this.searchTags = this.searchTags.filter(item => item.name !== 'category');
        this.fCategory = null;
        break;
      case 'status':
        this.searchTags = this.searchTags.filter(item => item.name !== 'status');
        this.status = null;
        break;
    }

    this.searchCategories();
  }

  searchSubCategories() {
    this.loading = true;
    this.publicationService.searchSubCategories(this.buildSearchStringSC()).subscribe(
      subcategories => {

        this.dataSC = subcategories;
        this.datasourceSubcategories =
          new MatTableDataSource<any>(this.dataSC);
        this.datasourceSubcategories.paginator = this.paginatorSubcategories;
        this.datasourceSubcategories.sort = this.sortSubcategories;
      },
      err => {
      },
    );
  }

  buildSearchStringSC() {
    const page = this.paginator.pageIndex + 1;
    const pageSize = (this.paginator.pageSize == undefined) ? 20 : this.paginator.pageSize;
    let result = `page=${page}&PageSize=${pageSize}&OrderBy=${this.sortFill}&OrderDesc=${this.sortOrder}&`;
    if (this.fSubCategory) {
      result += `name=${this.fSubCategory}&`;
    }
    if (this.category) {
      result += `categoryId=${this.category.id}&`;
    }
    if (this.statusSC) {
      result += `status=${this.statusSC.value}&`;
    }

    return result;
  }

  clearSubCategories() {
    this.fSubCategory = null;
    this.searchTagsSC = [];
    this.category = null;
    this.statusSC = null;

    this.loadSubCategories();
  }

  removeTagSC(searchTag: any) {
    const index = this.searchTagsSC.indexOf(searchTag);

    if (index >= 0) {
      this.searchTagsSC.splice(index, 1);
    }
    switch (searchTag.name) {
      case 'subcategory':
        this.searchTagsSC = this.searchTagsSC.filter(item => item.name !== 'subcategory');
        this.fSubCategory = null;
        break;
      case 'category':
        this.searchTags = this.searchTags.filter(item => item.name !== 'category');
        this.category = null;
        break;
      case 'status':
        this.searchTagsSC = this.searchTagsSC.filter(item => item.name !== 'status');
        this.statusSC = null;
        break;
    }

    this.searchSubCategories();
  }

  getCategoriesExcel() {
    this.loading = true;
    this.publicationService
      .getCategoriesExcel(this.buildSearchString())
      .finally(() => this.loading = false)
      .subscribe(blob => {
        importedSaveAs(blob, 'Giros.xlsx');
      });
  }

  getSubcategoriesExcel() {
    this.loading = true;
    this.publicationService
      .getSubcategoriesExcel(this.buildSearchStringSC())
      .finally(() => this.loading = false)
      .subscribe(blob => {
        importedSaveAs(blob, 'Subgiros.xlsx');
      });
  }
  
  toggleFilters() {
    this.showFilters = (this.showFilters) ? false : true;
  }

  toggleCategoryFilters() {
    this.showCategoryFilters = (this.showCategoryFilters) ? false : true;
  }

  toggleSubcategoryFilters() {
    this.showSubcategoryFilters = (this.showSubcategoryFilters) ? false : true;
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, '', {
      duration: 2000,
    });
  }

  sortData(sort: Sort) {
    const data = this.dataC.slice();

    if (!sort.active || sort.direction === '') {
      this.datasourceCategories =
        new MatTableDataSource<Category>(data);
      return;
    }

    const d = this.dataC.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'name': return this.compare(a.name.toLowerCase(), b.name.toLowerCase(), isAsc);       
        case 'statusF': return this.compare(a.statusF.toLowerCase(), b.statusF.toLowerCase(), isAsc);        
        default: return 0;
      }
    });

    this.datasourceCategories =
      new MatTableDataSource<Category>(d);
  }

  sortDataSC(sort: Sort) {
    const data = this.dataSC.slice();

    if (!sort.active || sort.direction === '') {
      this.datasourceSubcategories =
        new MatTableDataSource<any>(data);
      return;
    }

    const d = this.dataSC.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'name': return this.compare(a.name.toLowerCase(), b.name.toLowerCase(), isAsc);        
        case 'categoryName': return this.compare(a.categoryName.toLowerCase(), b.categoryName.toLowerCase(), isAsc);
        case 'statusF': return this.compare(a.statusF.toLowerCase(), b.statusF.toLowerCase(), isAsc);
        default: return 0;
      }
    });

    this.datasourceSubcategories =
      new MatTableDataSource<any>(d);
  }

  compare(a, b, isAsc) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  compareString(a: string, b: string, isAsc) {}

}

export interface ISubcategoriesData {
  page: number;
  records: any[];
  pageSize: number;
  totalPages: number;
  totalRecords: number;
}

//arrData.push({'id': record.id, 'name': record.name, 'statusF': record.statusF, 'status': record.status, 'categoryName': record.categoryName});

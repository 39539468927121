import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
@Component({
  selector: 'app-catalog-category-modal',
  templateUrl: './catalog-category-modal.component.html',
  styleUrls: ['./catalog-category-modal.component.scss'],
})
export class CatalogCategoryModalComponent implements OnInit {

  name: string;
  
  constructor(
    public dialogRef: MatDialogRef<CatalogCategoryModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
  }

  save() {
    this.dialogRef.close(this.name);
  }

  close() {
    this.dialogRef.close();
  }

}

import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, Validators, FormControl, FormBuilder } from '@angular/forms';
import { NewCurrency } from '../shared/models/new/currency.new';

@Component({
  selector: 'app-parameter-currency-modal',
  templateUrl: './parameter-currency-modal.component.html',
  styleUrls: ['./parameter-currency-modal.component.scss'],
})
export class ParameterCurrencyModalComponent implements OnInit {
  @HostListener('wheel', ['$event'])
  handleWheelEvent(event) {
    event.preventDefault();
  }

  form: FormGroup;
  model = new NewCurrency(null, null);

  constructor(
    fb: FormBuilder,
    public dialogRef: MatDialogRef<ParameterCurrencyModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.form = fb.group({
      'name': ['', Validators.compose([Validators.required, Validators.minLength(2)])],
    });
  }


  ngOnInit() {
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    this.model.status = 'active';
    this.data = {
      currency: this.model,
    };
    this.dialogRef.close(this.data);
  }

}

import { PublicationDownload } from './../shared/models/publication.download';
import { BaMenuService } from './../theme/services/baMenu/baMenu.service';
import { GlobalState } from './../global.state';
import { PublicationBookmark } from './../shared/models/publication.bookmark';
import { Analyst } from '../shared/models/analyst';
import { Company } from '../shared/models/company';
import { Component, OnInit, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { Publication } from '../shared/models/publication';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { PublicationService } from '../shared/services/publication.service';
import { Message } from 'primeng/primeng';
import { saveAs as importedSaveAs } from 'file-saver';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoginService } from '../shared/services/login.service';
import { Http, RequestOptions, Headers } from '@angular/http';
import { PublicationTag } from '../shared/interfaces/publication.tag.interface';
import { SubCategory } from '../shared/models/subcategory';
import { Category } from '../shared/models/category';
import { Status } from '../shared/models/helpers/status';
import { Preference } from '../shared/models/helpers/preference';
import { PublicationView } from '../shared/models/publication.view';
import { Bank } from '../shared/models/bank';
import { DatePipe, Location } from '@angular/common';
import {
  MatPaginator, MatTableDataSource, MatPaginatorIntl,
  MatSnackBar, MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSort, MatDialogConfig,
} from '@angular/material';
import { Sort } from '@angular/material';
import { filter } from 'rxjs/operators';
import { Observable } from 'rxjs/Observable';
import { CatalogAnalystModalEditComponent } from '../catalog-analyst-modal-edit/catalog-analyst-modal-edit.component';
@Component({
 
  selector: 'app-catalog-analyst-detail',
  templateUrl: './catalog-analyst-detail.component.html',
  styleUrls: ['./catalog-analyst-detail.component.scss'],
})
export class CatalogAnalystDetailComponent implements OnInit, AfterViewInit {

  modalConfig: any;
  categoryList: Category[];
  subcategoryList: SubCategory[];
  analystList: Analyst[] = [];

  userId: number;
  statusLabel: string;
  publicationBookmark: PublicationBookmark;
  publicationDownload: PublicationDownload;
  active: Boolean = false;
  loading: Boolean = false;
  title = '';
  id: number;
  rating: number;
  dateRange: Date[];
  publicationViewsDateRange: Date[];
  analystId = 0;
  analyst: Analyst;
  bank: Bank;
  category: Category;
  subcategory: SubCategory;
  company: Company;
  publications: Publication[];
  msgs: Message[] = [];
  status: Status;
  preference: Preference;
  statuses: any;
  sortBy = 'id';
  sortOrder = 'asc';
  es: any;
  selectedPublications: string[] = [];
  selectedItems: string[] = [];
  publicationViews: PublicationView[];
  banks: Bank[];
  categories: Category[];
  subcategories: SubCategory[];
  items: any[];
  selectAllValue: boolean;
  searchTags: PublicationTag[] = [];
  recommendations: any[];
  beginDate: Date;
  endDate: Date;
  dsViews;
  dsPublications;
  dsRecommendations;
  isSelected: Boolean = false;
  displayedColumnsViews = [];
  displayedColumnsPublications = [];
  displayedColumnsRecommendations = [];
  @ViewChild('paginatorViews') paginatorViews: MatPaginator;
  @ViewChild('paginatorPublications') paginatorPublications: MatPaginator;
  @ViewChild('paginatorRecommendations') paginatorRecommendations: MatPaginator;
  @ViewChild('tablePublications', { read: MatSort }) sortPublications: MatSort;
  @ViewChild('tableViews', { read: MatSort }) sortViews: MatSort;
  @ViewChild('tableRecommendations', { read: MatSort }) sortRecommendations: MatSort;
  @ViewChild('dateInit') startDateInput:ElementRef;
  @ViewChild('dateEnd') endDateInput: ElementRef;

  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal,
    private publicationService: PublicationService,
    private http: Http,
    private loginService: LoginService,
    private snackBar: MatSnackBar,
    private location: Location,
    private _service: BaMenuService,
    private _state: GlobalState,
    public dialog: MatDialog) {
    this.modalConfig = new MatDialogConfig();
    this.modalConfig.height = '500px';
    this.modalConfig.width = '800px';
    this.modalConfig.closeOnNavigation = true;
  }

  ngOnInit() {
    this.analystId = this.route.snapshot.params['id'];
    this.userId = this.loginService.getUserId();

    this.analystList.push(new Analyst(this.analystId, null, null, null, null, null, null, null));

    this.publicationService
      .getAnalyst(this.analystId)
      .subscribe(analyst => {
        this.analyst = analyst;
        this.statusLabel = (this.analyst.status === 'active') ? 'Activo' : 'Inactivo';
      });

    this.displayedColumnsViews = ['userName', 'userEmail', 'date', 'bookmarked', 'downloaded'];
    if (this.isUserAdmin()) {
      this.displayedColumnsPublications = [
        'eye_id', 'title', 'date', 'bankName',
        'analystName', 'pages', 'categoryName',
        'subCategoryName', 'companyName', 'rating',
        'views', 'published', 'bookmark', 'selected'];
    } else {
      this.displayedColumnsPublications = [
        'eye_id', 'title', 'date', 'bankName',
        'analystName', 'pages', 'categoryName',
        'subCategoryName', 'companyName', 'bookmark', 'selected'];
    }
    this.displayedColumnsRecommendations = ['eye_id', 'company', 'priceF', 'currency', 'recommendedAction'];

    this.loadCategories();
    this.loadSubCategories();

  }

  ngAfterViewInit() {
    this.searchPublicationViews();
    this.searchPublications();
    this.loadRecommendations();
    
    const menu = {
      title: 'general.menu.analysts',
      icon: 'ion-arrow-graph-up-right',
      selected: false,
      expanded: false,
      order: 10,
      text: 'Analistas',
    };

    this._state.notifyDataChanged('menu.activeLink', menu);
  }

  loadRecommendations() {
    this.publicationService.getAnalystRecommendations(this.analystId)
      .subscribe(recommendations => {
        if (this.isUserAdmin()) {
          this.recommendations = recommendations;
        } else {
          this.recommendations = recommendations.filter(recommendation => recommendation.published === true);
        }
        //this.recommendations = recommendations;
        this.dsRecommendations =
          new MatTableDataSource<Analyst>(this.recommendations);
        this.dsRecommendations.paginator = this.paginatorRecommendations;
        this.dsRecommendations.sort = this.sortRecommendations;

      });
  }

  // Category
  loadCategories() {
    this.publicationService.getCategoriesSystem().subscribe(
      categories => this.categories = categories,
      err => {
        this.msgs = [];
        this.msgs.push({ severity: 'error', summary: 'Error', detail: err });
      },
    );
  }

  // Sub category
  loadSubCategories() {
    this.publicationService.getAllSubCategoriesSystem().subscribe(
      subcategories => this.subcategories = subcategories,
      err => {
        this.msgs = [];
        this.msgs.push({ severity: 'error', summary: 'Error', detail: err });
      },
    );
  }

  isUserAdmin() {
    return this.loginService.isUserAdmin();
  }

  searchPublications() {
    this.publicationService.searchPublications(this.buildSearchString()).subscribe(
      publications => {
        if (this.isUserAdmin()) {
          this.publications = publications;
        } else {
          this.publications = publications.filter(publication => publication.published);
        }
        this.dsPublications =
          new MatTableDataSource<Publication>(this.publications);
        this.dsPublications.paginator = this.paginatorPublications;
        this.dsPublications.sort = this.sortPublications;
        this.selectedItems = [];
      },
      err => {

      },
    );
  }

  searchPublicationViews() {
    this.publicationService
      .searchAnalystPublicationViews(this.analystId, this.buildPublicationViewSearchString()).subscribe(
        publicationViews => {
          this.publicationViews = publicationViews;
          this.dsViews =
            new MatTableDataSource<PublicationView>(this.publicationViews);
          this.dsViews.paginator = this.paginatorViews;
          this.dsViews.sort = this.sortViews;
        },
        err => {
          this.msgs = [];
          this.msgs.push({ severity: 'error', summary: 'Error', detail: err });
        },
    );
  }

  buildPublicationViewSearchString() {
    let result = '';
    if (this.beginDate && this.endDate) {
      const datePipe = new DatePipe('en');
      result += `date1=${datePipe.transform(this.beginDate, 'yyyy-MM-dd')}&`;
      result += `date2=${datePipe.transform(this.endDate, 'yyyy-MM-dd')}`;
    } else {
      result += 'date1=2000-01-01&date2=2099-01-01';
    }

    return result;
  }

  clearPublicationViewsFilters() {
    this.beginDate = null;
    this.endDate = null;

    this.searchPublicationViews();
  }

  buildSearchString() {
    let result = '';

    result += `bookmarkUserId=${this.userId}&page=1&pageSize=200&`;
    if (this.title) {
      result += `title=${this.title}&`;
    }
    if (this.analystList) {
      this.analystList.forEach(analyst => {
        result += `analystId=${analyst.id}&`;
      });
    }
    if (this.categoryList) {
      this.categoryList.forEach(category => {
        result += `categoryId=${category.id}&`;
      });
    }
    if (this.subcategoryList) {
      this.subcategoryList.forEach(subcategory => {
        result += `subcategoryId=${subcategory.id}&`;
      });
    }
    // if (this.startDate && this.endDate) {
    //   const datePipe = new DatePipe('en');
    //   result += `date1=${datePipe.transform(this.startDate, 'yyyy-MM-dd')}&`;
    //   result += `date2=${datePipe.transform(this.endDate, 'yyyy-MM-dd')}`;
    // } else {
    result += 'date1=2000-01-01&date2=2099-01-01';
    // }
    if (!this.isUserAdmin()) {
      result += `&published=true`;
    }

    return result;
  }

  buildDownloadSearchStringList() {
    let result: any[] = [];
    const publicationsWithFile = this.publicationsWithFile();
    this.selectedItems.forEach(id => {
      if (publicationsWithFile.indexOf(id) > -1) {
        result.push(id);
      }
    });    
    return result;
  }

  downloadSelected() {
    const searchString = this.buildDownloadSearchStringList();
    if (searchString.length === 0) {
      this.openSnackBar('Las publicaciones seleccionadas no tienen ningún archivo asociado');
    } else {
      for(var i in searchString){
        this.publicationService.downloadFile(searchString[i]).finally(() => this.loading = false).subscribe(
          blob => {
            importedSaveAs(blob, `Documento.pdf`);
          }, err => {
          }, () => {
            let publicationDownloadOperation: Observable<Object>;
            publicationDownloadOperation = this.publicationService.postDownload(
              searchString[i], this.publicationDownload);
          },
        );
      }
    }
  }

  // downloadSelected() {
  //   const searchString = this.buildDownloadSearchString();
  //   if (searchString === '') {
  //     this.openSnackBar('Las publicaciones seleccionadas no tienen ningún archivo asociado');
  //   } else {
  //     this.publicationService.downloadFiles(searchString).subscribe(
  //       blob => {
  //         importedSaveAs(blob, 'Publicaciones.zip');
  //         this.selectedPublications.forEach(id => {
  //           this.publicationDownload = new PublicationDownload(
  //             parseInt(id), this.loginService.getUserId(), new Date());
  //           let publicationDownloadOperation: Observable<Object>;
  //           publicationDownloadOperation = this.publicationService.postDownload(
  //             parseInt(id), this.publicationDownload);

  //           publicationDownloadOperation.subscribe(
  //             publicationDownload => {
  //               // this.getDownloadCount();
  //             },
  //             err => {
  //               this.console(err);
  //             },
  //           );
  //         });
  //       },
  //     );
  //   }
  // }

  buildDownloadSearchString() {
    let result = '';
    const publicationsWithFile = this.publicationsWithFile();
    
    this.selectedItems.forEach(id => {
      if (publicationsWithFile.indexOf(id) > -1) {
        result += `list=${id}&`;
      }      
    });

    return result;
  }

  publicationsWithFile() {
    return this.publications.filter(publication => publication.hasFile).map(m => m.id).map(String);
  }

  clearPublications() {
    // Clear filter fields
    this.id = null;
    this.title = '';
    this.bank = null;
    this.company = null;
    this.category = null;
    this.categoryList = [];
    this.subcategory = null;
    this.subcategoryList = [];
    this.rating = null;
    this.searchTags = [];
    this.selectedItems = [];

    this.searchPublications();
  }

  clearPublicationViews() {
    this.publicationViewsDateRange = null;
    this.publicationService
      .searchAnalystPublicationViews(this.analystId, this.buildPublicationViewSearchString()).subscribe(
        publicationViews => this.publicationViews = publicationViews,
        err => {
          this.msgs = [];
          this.msgs.push({ severity: 'error', summary: 'Error', detail: err });
        },
    );
  }

  refreshData() {
    this.clearPublications();
  }

  selectAllPublications() {
    this.selectedItems = [];
    if (this.selectAllValue) {
      this.publications.forEach(publication => {
        this.selectedItems.push(publication['id'].toString());
      });
      this.selectedPublications = this.selectedItems;
    } else {
      this.selectedPublications = [];
    }
  }

  toInt(num: string) {
    return +num;
  }

  // Chip events
  dropDownBankChange() {
    this.searchTags = this.searchTags.filter(item => item.name !== 'bank');
    this.searchTags.push({ name: 'bank', value: this.bank.name });
  }

  dropDownCompanyChange() {
    this.searchTags = this.searchTags.filter(item => item.name !== 'company');
    this.searchTags.push({ name: 'company', value: this.company.name });
  }

  dropDownAnalystChange() {
    this.searchTags = this.searchTags.filter(item => item.name !== 'analyst');
    this.searchTags.push({ name: 'analyst', value: this.analyst.name });
  }

  dropDownCategoryChange() {
    this.searchTags = this.searchTags.filter(item => item.name !== 'category');
    this.searchTags.push({ name: 'category', value: this.categoryList.map(category => category.name).join(', ') });
  }

  dropDownSubCategoryChange() {
    this.searchTags = this.searchTags.filter(item => item.name !== 'subcategory');
    this.searchTags
      .push({ name: 'subcategory', value: this.subcategoryList.map(subcategory => subcategory.name).join(', ') });
  }

  rateChange() {
    this.searchTags = this.searchTags.filter(item => item.name !== 'rating');
    this.searchTags.push({ name: 'rating', value: this.rating });
  }

  rateCancel() {
    this.searchTags = this.searchTags.filter(item => item.name !== 'rating');
  }

  titleChange(e) {
// tslint:disable-next-line: triple-equals
    if (this.title != '') {
      this.searchTags = this.searchTags.filter(item => item.name !== 'title');
      this.searchTags.push({ name: 'title', value: this.title });
    }
  }

  calendarChange() {
    let result = '';
    this.searchTags = this.searchTags.filter(item => item.name !== 'date');
    let x = 1;
    const datePipe = new DatePipe('en');
    this.dateRange.forEach(date => {
      result += `${datePipe.transform(date, 'MM/dd/yyyy')} - `;
      if (x === 1) {
        x = 2;
      }
    });
    this.searchTags.push({ name: 'date', value: result });
  }

  removeTag(searchTag: any) {
    const index = this.searchTags.indexOf(searchTag);

    if (index >= 0) {
      this.searchTags.splice(index, 1);
    }

    switch (searchTag.name) {
      // case 'bank':
      //   this.searchTags = this.searchTags.filter(item => item.name !== 'bank');
      //   this.bank = null;
      //   this.bankList = null;
      //   break;
      // case 'company':
      //   this.searchTags = this.searchTags.filter(item => item.name !== 'company');
      //   this.company = null;
      //   this.companyList = null;
      //   break;
      // case 'analyst':
      //   this.searchTags = this.searchTags.filter(item => item.name !== 'analyst');
      //   this.analyst = null;
      // this.analystList = null;
      // break;
      case 'category':
        this.searchTags = this.searchTags.filter(item => item.name !== 'category');
        this.category = null;
        this.categoryList = null;
        break;
      case 'subcategory':
        this.searchTags = this.searchTags.filter(item => item.name !== 'subcategory');
        this.subcategory = null;
        this.subcategoryList = null;
        break;
      // case 'rating':
      //   this.searchTags = this.searchTags.filter(item => item.name !== 'rating');
      //   this.rating = 0;
      //   break;
      case 'title':
        this.searchTags = this.searchTags.filter(item => item.name !== 'title');
        this.title = '';
        break;
      // case 'date1':
      //   this.searchTags = this.searchTags.filter(item => item.name !== 'date1');
      //   this.startDate = null;
      //   break;
      // case 'date2':
      //   this.searchTags = this.searchTags.filter(item => item.name !== 'date2');
      //   this.endDate = null;
      //   break;
    }

    this.publicationService
      .searchPublications(this.buildSearchString())
      .subscribe(
        publications => {
          if (this.isUserAdmin()) {
            this.publications = publications;
          } else {
            this.publications = publications.filter(publication => publication.published);
          }
          this.dsPublications =
            new MatTableDataSource<Publication>(this.publications);
          this.dsPublications.paginator = this.paginatorPublications;
          this.dsPublications.sort = this.sortPublications;
          this.selectedItems = [];
        },
        err => {
        },
    );
  }

  onChange(event) {
    if (!(event.keyCode === 8 || event.keyCode === 46)) {
      return false;
    }
  }

  onSelectPublicaton(id) {
    const index = this.selectedItems.indexOf(id.toString());

    if (event.target['checked']) {
      if (index < 0) {
        this.selectedItems.push(id.toString());
      }
    } else {
      if (index >= 0) {
        this.selectedItems.splice(index, 1);
      }
    }
  }

  validFilterDates() {
    let result = true;
    if (this.beginDate && this.endDate) {
      if (this.endDate >= this.beginDate) {
        result = false;
      }
    }

    return result;
  }

  getPublicationsExcel() {
    if (this.selectedItems.length <= 0) {
      return this.openSnackBar('Selecciona al menos una publicación');
    }
    this.publicationService
      .getPublicationsExcel(this.buildExcelString())
      .subscribe(blob => {
        importedSaveAs(blob, 'Publicaciones.xlsx');
      });
  }

  buildExcelString() {
    let result = '';

    if (this.selectedItems) {
      this.selectedItems.forEach(item => {
        result += `publicationId=${item}&`;
      });
    }

    return result;
  }

  getRecommendationsExcel() {
    this.publicationService
      .getAnalystRecommendationsExcel(this.analystId)
      .subscribe(blob => {
        importedSaveAs(blob, 'Estimados.xlsx');
      }, err => {
      }, () => {
      });
  }

  openDialog() {
    this.modalConfig.data = { analyst: this.analyst };

    const dialog = this.dialog.open(CatalogAnalystModalEditComponent, this.modalConfig);

    dialog.afterClosed()
      .pipe(filter(data => data))
      .subscribe(data => {
        let analystOperation: Observable<Analyst>;
        analystOperation = this.publicationService
          .updateAnalyst(this.analystId, data['analyst']);
        analystOperation.subscribe(
          analyst => {
            this.analyst = analyst;
          },
          err => {
            this.snackBar.open(err.error.message);
            this.publicationService
              .getAnalyst(this.analystId)
              .subscribe(analyst => {
                this.analyst = analyst;
                this.statusLabel = (this.analyst.status === 'active') ? 'Activo' : 'Inactivo';
              });
            setTimeout(() => this.snackBar.dismiss(), 2000);
          },
        );
      },
    );
  }

  isActive() {
    if (this.analyst.status === 'active') {
      return true;
    } else {
      return false;
    }
  }

  statusChange(event) {
    this.status = new Status(null);
    this.status.st = (event.checked) ? 'active' : 'inactive';
    this.publicationService
      .updateStatus(this.analyst.id, 'analyst', this.status)
      .subscribe(analyst => {
        this.analyst = analyst;
        this.statusLabel = (this.analyst.status === 'active') ? 'Activo' : 'Inactivo';
      });
  }

  preferenceChange(event) {
    this.preference = new Preference(null);
    this.preference.pf = event.checked;
    this.publicationService 
      .updatePreference(this.analyst.id, 'analyst', this.preference)
      .subscribe(analyst => {
        this.analyst = analyst;
      });
  }

  backToList() {
    this.location.back();
  }

  // Bookmark
  postBookmark(publicationId) {
    this.publicationBookmark = new PublicationBookmark(
      publicationId, this.loginService.getUserId(), new Date());
    this.publicationService
      .postBookmark(publicationId, this.publicationBookmark)
      .subscribe(res => {
        this.searchPublications();
        // this.isBookmarked = true;
        // this.getBookmarkCount();
        // this.getViews();
        // this.openSnackBar('Agregado a favoritos');
      },
    );
  }

  deleteBookmark(publicationId) {
    this.publicationService
      .deleteBookmark(publicationId, this.loginService.getUserId())
      .subscribe(res => {
        this.searchPublications();
        // this.getBookmarkCount();
        // this.getViews();
        // this.openSnackBar('Borrado de favoritos');
      },
    );
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, '', {
      duration: 2000,
    });
  }

  removeAll(type: string){
    if(type === 'start'){
      this.startDateInput.nativeElement.value = '';
    } else {
      this.endDateInput.nativeElement.value = '';
    }
    
  }

}

export class NewPublication {
    constructor(
        public title: string,
        public userId: number,
        public categoryId: number,
        public subcategoryId: number,
        public companyId: number,
        public bankId: number,
        public analystId: number,
        public summary: string,
        public summaryFile: string,
        public status: string,
        public date: Date,
        public rating: number,
        public pages: number,
        public published: boolean,
        public publicationTypeId: number,
    ) { }
}



import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { Observable } from 'rxjs/Observable';
import { Analyst } from '../models/analyst';
import { PublicationService } from '../services/publication.service';
import { LoginService } from '../services/login.service';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { catchError, finalize } from 'rxjs/operators';
import { of } from 'rxjs/observable/of';

export class AnalystData implements DataSource<Analyst> {

    private analystSubject = new BehaviorSubject<Analyst[]>([]);

    private loadingSubject = new BehaviorSubject<boolean>(false);

     loading$ = this.loadingSubject.asObservable();

    constructor(private publicationService: PublicationService,
        private loginService: LoginService) {

    }

    loadAnalysts(searchString) {

        this.loadingSubject.next(true);

        this.publicationService.findAnalyst(searchString).pipe(
            catchError(() => of([])),
            finalize(() => this.loadingSubject.next(false)),
        )
        .subscribe(analyst => this.analystSubject.next(analyst));

    }

    connect(collectionViewer: CollectionViewer): Observable<Analyst[]> {
        return this.analystSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.analystSubject.complete();
        this.loadingSubject.complete();
    }

}

import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-catalog-subcategory-modal',
  templateUrl: './catalog-subcategory-modal.component.html',
  styleUrls: ['./catalog-subcategory-modal.component.scss'],
})
export class CatalogSubcategoryModalComponent implements OnInit {
  @HostListener('wheel', ['$event'])
  handleWheelEvent(event) {
    event.preventDefault();
  }

  category: any;
  name: string;
  d: any;

  constructor(
    public dialogRef: MatDialogRef<CatalogSubcategoryModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.data['categories'] = this.data['categories'].filter(data => data.status === 'active');
  }

  save() {
    this.d = { name: this.name, categoryid: this.category['id'] };
    this.dialogRef.close(this.d);
  }

  close() {
    this.dialogRef.close();
  }

}

import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { PublicationTargetPrice } from '../shared/models/publication.targetprice';
import { PublicationService } from '../shared/services/publication.service';
import { Currency } from '../shared/models/currency';
import { RecommendedAction } from '../shared/models/recommendedAction';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-publication-targetprice-modal-edit',
  templateUrl: './publication-targetprice-modal-edit.component.html',
  styleUrls: ['./publication-targetprice-modal-edit.component.scss']
})
export class PublicationTargetpriceModalEditComponent implements OnInit {
  @HostListener('wheel', ['$event'])
  handleWheelEvent(event) {
    event.preventDefault();
  }

  publicationId: number;
  publicationTargetPrice: PublicationTargetPrice;
  currencies: Currency[];
  recommendedActions: RecommendedAction[];
  form: FormGroup;

  constructor(public dialogRef: MatDialogRef<PublicationTargetpriceModalEditComponent>, fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any, private publicationService: PublicationService) { 
      this.form = fb.group({
        'price': ['', Validators.required],
        'currency': ['', Validators.required],
        'recommendedAction': ['', Validators.required],
      });
     }

  ngOnInit() {
    this.publicationId = this.data['publicationId'];
    if (this.data['publicationTargetPrice']) {
      this.publicationTargetPrice = this.data['publicationTargetPrice'];
    } else {
      this.publicationTargetPrice = new PublicationTargetPrice(null, this.publicationId, null, null);
    }
    this.currencies = this.data['currencies'];
    this.recommendedActions = this.data['recommendedActions'];
  }

  save() {
    this.data = { 
      publicationTargetPrice: { 
        price: this.publicationTargetPrice.price, 
        publicationid: this.publicationId, 
        currencyid: this.publicationTargetPrice['currencyId'],
        recommendedactionid: this.publicationTargetPrice['recommendedActionId'],
      },
    };
    //updateTargetPrice
    this.publicationService.updateTargetPrice(this.publicationId, this.data.publicationTargetPrice).subscribe(
      result => {
        this.publicationTargetPrice.price = result['priceF'];
        this.publicationTargetPrice.currencyId = result['currency'].id;
        this.dialogRef.close(this.data);
      }
    );
    // this.publicationService.updateTargetPrice(this.publicationId, this.data.publicationTargetPrice);
    // this.dialogRef.close(this.data.publicationTargetPrice);
  }

  close() {
    this.dialogRef.close();
  }

}

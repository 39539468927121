import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, Validators, FormControl, FormBuilder } from '@angular/forms';
import { RecommendedAction } from '../shared/models/recommendedAction';

@Component({
  selector: 'app-parameter-recommended-action-edit',
  templateUrl: './parameter-recommended-action-edit.component.html',
  styleUrls: ['./parameter-recommended-action-edit.component.scss'],
})
export class ParameterRecommendedActionEditComponent implements OnInit {

  form: FormGroup;
  model: RecommendedAction;

   constructor(
    fb: FormBuilder,
    public dialogRef: MatDialogRef<ParameterRecommendedActionEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.model = data['recommendedAction'];

    this.form = fb.group({
      'name': ['', Validators.compose([Validators.required, Validators.minLength(2)])],
    });
  }

  close() {
    this.dialogRef.close();
  }

  ngOnInit() {
  }

  save() {
    this.data = {
      id: this.model.id,
      recommendedAction: this.model,
    };
    this.dialogRef.close(this.data);
  }

}

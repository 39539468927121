import { StockImage } from '../shared/models/stockImage';
import { LoginService } from '../shared/services/login.service';
import { EmailService } from '../shared/services/email.service';
import { RequestOptions, Headers } from '@angular/http';
import { Router } from '@angular/router';
import { SubCategory } from '../shared/models/subcategory';
import { Company } from '../shared/models/company';
import { PublicationBookmark } from '../shared/models/publication.bookmark';
import { Bank } from '../shared/models/bank';
import { Category } from '../shared/models/category';
import { Analyst } from '../shared/models/analyst';
import { Component, OnInit, ViewChild, AfterViewInit, Inject, ElementRef, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { PublicationModalComponent } from '../publication-modal/publication-modal.component';
import { PublicationSendModalComponent } from '../publication-send-modal/publication-send-modal.component';
import { Publication } from '../shared/models/publication';
import { PublicationTargetPrice } from '../shared/models/publication.targetprice';
import { PublicationService } from '../shared/services/publication.service';
import { DatePipe } from '@angular/common';
import { saveAs as importedSaveAs } from 'file-saver';
import { PublicationDownload } from '../shared/models/publication.download';
import {
  MatPaginator, MatTableDataSource, MatSnackBar, MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSort, MatMenuTrigger,
} from '@angular/material';
import { filter } from 'rxjs/operators';
import { Http, Response } from '@angular/http';
import { environment } from '../../environments/environment';
import { forkJoin } from 'rxjs/observable/forkJoin';
import { SlimLoadingBarService } from 'ng2-slim-loading-bar';
// paginacion
import { ActivatedRoute } from '@angular/router';
import { tap } from 'rxjs/operators';
import { merge } from 'rxjs/observable/merge';
import { PublicationData } from '../shared/services/publicationdata.service';
import { map } from 'rxjs/operator/map';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { jsonpCallbackContext } from '@angular/common/http/src/module';

@Component({
  selector: 'app-publication-send-info',
  templateUrl: './publication-send-info.component.html',
})
export class SendInfoComponent {

  constructor(
    public dialogRef: MatDialogRef<SendInfoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  close(): void {
    this.dialogRef.close({ ok: false });
  }

  send() {
    this.dialogRef.close({ ok: true });
  }

}

@Component({
 
  selector: 'app-publication-list',
  templateUrl: './publication-list.component.html',
  styleUrls: ['./publication-list.component.scss'],
})
export class PublicationListComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('dateInit') startDateInput:ElementRef;
  @ViewChild('dateEnd') endDateInput: ElementRef;
  arraySent: string[] = [];
  analystList: Analyst[];
  bankList: Bank[];
  companyList: Company[];
  categoryList: Category[];
  subcategoryList: SubCategory[];
  sortFill: string = 'Title';
  sortOrder: Boolean = true;
  ImgLoadUrl: string = ''
  showFilters: Boolean = true;
  publicationBookmark: PublicationBookmark;
  startDate: Date;
  endDate: Date;
  stockImages: StockImage[];
  uploadedFiles: any[] = [];
  uploadedImages: any[] = [];
  imageFile: any;
  loading: Boolean = false;
  role: string = 'guest';
  publications: Publication[];
  filterQuery = '';
  title: string;
  id: number;
  publication: Publication;
  bank: Bank;
  analyst: Analyst;
  category: Category;
  subcategory: SubCategory;
  company: Company;
  banks: Bank[];
  analysts: Analyst[];
  categories: Category[];
  subcategories: SubCategory[];
  companies: Company[];
  es: any;
  dateRange: Date[];
  rating: number;
  selectedPublications: string[] = [];
  selectedItems: string[] = [];
  items: any[];
  selectAllValue: boolean;
  viewCount = 0;
  downloadCount = 0;
  searchTags: { name: string, value: any }[];
  publicationDownload: PublicationDownload;
  isSelected: Boolean = false;
  displayedColumnsPublications = [];
  userId: number;
  userBankPublications: Publication[];
  public isIE: any = !(window['ActiveXObject']) && "ActiveXObject" in window;
  //$event.preventDefault()

  dsPublications;
  @ViewChild('paginatorPublications') paginatorPublications: MatPaginator;
  @ViewChild('tablePublications', { read: MatSort }) sortPublications: MatSort;


  // paginacion
  dataSource: PublicationData;
  totalpublications = 0;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    public dialog: MatDialog,
    private publicationService: PublicationService,
    private http: Http,
    private loginService: LoginService,
    private emailService: EmailService,
    private snackBar: MatSnackBar,
    private router: Router,
    public _HttpClient: HttpClient) {
  }

  ngOnInit() {
    //alert(this.isIE);
    this.searchTags = [];

    this.loading = true;
    if (this.isUserAdmin()) {
      this.displayedColumnsPublications = [
        'eye_id', 'title', 'date', 'bankName',
        'analystName', 'pages', 'categoryName',
        'subCategoryName', 'companyName', 'rating',
        'views', 'published', 'bookmark', 'selected'];
    } else {
      this.displayedColumnsPublications = [
        'eye_id', 'title', 'date', 'bankName',
        'analystName', 'pages', 'categoryName',
        'subCategoryName', 'companyName', 'bookmark', 'selected'];
    }
    //
    this.ImgLoadUrl = `${environment.s3Endpoint}/app/img/imgLoading32.svg`;
    //set filters
    this.SetDateFilterIni();
    // Load data
    this.loadData();
    //this.getViewCount();
    //this.getDownloadCount();
    //this.dataSource = new PublicationData(this.publicationService, this.loginService);
    //this.loadPublicationPage(true);
    
  }

  removeAll(type: string){
    if(type === 'start'){
      this.startDateInput.nativeElement.value = '';
    } else {
      this.endDateInput.nativeElement.value = '';
    }
    
  }

  // ngOnDestroy(){
  //   this.dialog.closeAll();
  // }

  ngAfterViewInit() {
    
    this.loadPublications();
    
    /*this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

        merge(this.sort.sortChange, this.paginator.page)
        .pipe(
            tap(() => this.loadPublicationPage()),
        )
        .subscribe();*/
      
  }

  loadPublicationPage(bool: boolean = false) {
    this.setOrder(this.sort.active, this.sort.direction);
    this.dataSource.loadPublications(this.buildSearchString());
    this.getTotalPublications();
    if(bool){
      this.getViewCount();
      this.getDownloadCount();
    }
  }

  setOrder(fill, order) {
// tslint:disable-next-line: triple-equals
    this.sortOrder = (order == 'asc') ? true : false;
    switch (fill) {
      case 'title':
        this.sortFill = 'Title';
        break;
      case 'date':
        this.sortFill = 'Date';
        break;
      case 'bankName':
        this.sortFill = 'Bank';
        break;
      case 'analystName':
        this.sortFill = 'Analyst';
        break;
      case 'pages':
        this.sortFill = 'Pages';
        break;
      case 'categoryName':
        this.sortFill = 'Category';
        break;
      case 'subCategoryName':
        this.sortFill = 'Subcategory';
        break;
      case 'companyName':
        this.sortFill = 'Company';
        break;
      case 'views':
        this.sortFill = 'Views';
        break;
      case 'rating':
        this.sortFill = 'Rating';
        break;
      default:
        this.sortFill = 'Title';
        this.sortOrder = false;
        break;
    }

  }

  getTotalPublications() {
        this.publicationService.getTotalP(this.buildSearchString()).subscribe(
          total => this.totalpublications = total,
        );
  }

  getDownloadsAndViewCountBySearch(){
    this.publicationService.getTotalD(this.buildSearchString()).subscribe(
      total => this.downloadCount = total,
    );
    this.publicationService.getTotalV(this.buildSearchString()).subscribe(
      total => this.viewCount = total,
    );
  }

  loadData() {
    // Get Banks
    const banksOperation = this.publicationService.getCachedBanksSystem();
    // Get Analysts
    const analystsOperation = this.publicationService.getCachedAnalystsSystem();
    // Get Categories
    const categoriesOperation = this.publicationService.getCategoriesSystem();
    // Get all Subcategories
    const subcategoriesOperation = this.publicationService.getAllSubCategoriesSystem();
    // Get Companies
    const companyOperation = this.publicationService.getCachedCompaniesSystem();


    const fj = forkJoin([
      banksOperation,
      analystsOperation,
      categoriesOperation,
      subcategoriesOperation,
      companyOperation,
    ]).onErrorResumeNext().finally(() => this.loading = false);

    fj.subscribe(
      results => {
        this.banks = results[0];
        this.analysts = results[1];
        this.categories = results[2];
        this.subcategories = results[3];
        this.companies = results[4];
      },
    );
  }

  isUserAdmin() {
    return this.loginService.isUserAdmin();
  }

  // Publication
  loadPublications() {
    this.downloadCount = null;
    this.viewCount = null;
    this.totalpublications = null;
    this.loading = true;
    this.publicationService.getPublications(this.buildSearchString()).subscribe(
      publications => {
        /*if (this.isUserAdmin()) {
          this.publications = publications;
        } else {
          this.publications = publications.filter(publication => publication.published);
        }*/
        this.totalpublications = publications.length;
        this.dsPublications = new MatTableDataSource<Publication>(publications);
        this.dsPublications.paginator = this.paginator;
        this.dsPublications.sort = this.sort;
        var views = 0;
        var downs = 0;
        publications.forEach(function(publication){
          views += publication["publicationViewsCount"];
          downs += publication["publicationDownloadCount"]
        });
        this.viewCount = views;
        this.downloadCount = downs;
        
      },
      () => {
        this.loading = false;
      },
    );
  }

  showCreateModal() {
    const dialogRef = this.dialog.open(PublicationModalComponent, {
      height: '600px',
      width: '800px',
      closeOnNavigation: true,
    });

    dialogRef.afterClosed()
      .pipe(filter(data => data))
      .subscribe(data => {
        let publicationOperation: Observable<Publication>;
        publicationOperation = this.publicationService.addPublication(data['publication']);
        publicationOperation.subscribe(
          publication => {
            this.publication = publication;
            if (data['hasTargetPrice']) {
              setTimeout(() => this.postTargetPrice(this.publication['id'], data['publicationTargetPrice']));
            }
            if (data['publicationFile']) {
              this.postFile(publication['id'], data['publicationFile']);
            }
            if (data['imgFile'] || data['selectedStockImageId'] > 0) {
              this.postImage(publication['id'], data['imgFile'], data['selectedStockImageId']);
            }
            this.loading = true;
            if (data['preview']) {
              this.router.navigate([`/publication/${this.publication['id']}/preview`]);
            } else {
              this.router.navigate([`/publication/${this.publication['id']}`]);
            }
          },
          err => {
            
          },
          () => {
          },
        );
      },
    );
  }

  closeSendModal() {

  }

  ngOnDestroy(){
    this.dialog.closeAll();
  }

  sendPublications() {
    const dialogRef = this.dialog.open(SendInfoComponent, {
      width: '600px',
      closeOnNavigation: true,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.ok) {
        this.filterSelectedPublications(this.selectedItems);
        if (this.arraySent.length > 0) {
          this.router.navigate(
            ['/summary/send'],
            { queryParams: { publicaciones: this.arraySent } });
        }
      }
    });

  }

  filterSelectedPublications(ids: string[]) {
    const publishedArray =
      this.publications.filter(publication => publication.published).map(p => p.id).map(String);
    ids.forEach(id => {
      //if (publishedArray.indexOf(id) > -1) {
        this.arraySent.push(id);
      //}
    });
  }

  postTargetPrice(id: number, publicationTargetPrice: any) {
    publicationTargetPrice['publicationId'] = id;
    publicationTargetPrice['price'] = +publicationTargetPrice['price'];

    let publicationTargetPriceOperation: Observable<PublicationTargetPrice>;
    publicationTargetPriceOperation = this.publicationService.postTargetPrice(id, publicationTargetPrice);
    publicationTargetPriceOperation.subscribe();
  }

  postImage(id: number, file: any, selectedStockImageId: number) {
    const headers = new HttpHeaders({'Authorization':'Bearer '+localStorage.getItem('token') });
    const formData = new FormData();
    if (selectedStockImageId > 0) {
      this.publicationService.getPublicationImageById(selectedStockImageId)
        .subscribe(data => {
          this.imageFile = data;
          this.createImageFromBlob(data);
          formData.append('file', this.imageFile, `publication${id}.jpg`);
          formData.append('id', id.toString());
          this._HttpClient.post(`${environment.devApiEndpoint}/file/image`, formData, {headers})
            .map((res: Response) => res)
            .catch((error: any) => Observable.throw(error || 'Error posting the image'))
            .subscribe();
        });
    } else {
      this.imageFile = file;
      formData.append('file', this.imageFile);
      formData.append('id', id.toString());
      this._HttpClient.post(`${environment.devApiEndpoint}/file/image`, formData, {headers})
        //.map((res: any) => res.json())
        .catch((error: any) => Observable.throw(error || 'Error posting the image - File -Error TS#4432'))
        .subscribe();
    }
  }

  // Post Publication File
  postFile(id: number, file: any) {
    const headers = new HttpHeaders({'Authorization':'Bearer '+localStorage.getItem('token')});
    const formData = new FormData();
    formData.append('file', file);
    formData.append('id', id.toString());
    this._HttpClient.post(`${environment.devApiEndpoint}/file`, formData, {headers})
      //.map((res: any) => res.json())
      .catch((error: any) => Observable.throw(error.json().error || 'Error posting the file'))
      .subscribe();
  }

  postPublication() {
    this.publication.analystId = this.publication.analystId['id'];
    this.publication.bankId = this.publication.bankId['id'];
    this.publication.categoryId = this.publication.categoryId['id'];
    this.publication.companyId = this.publication.companyId['id'];
    this.publication.subcategoryId = this.publication.subcategoryId['id'];
    this.publication.userId = this.loginService.getUserId();

    let publicationOperation: Observable<Publication>;
    publicationOperation = this.publicationService.addPublication(this.publication);
    publicationOperation.subscribe(
      publication => {
        this.publication = publication;
      },
      err => {
        
      },
    );
  }

  search() {
    this.paginator.pageIndex = 0;
    this.loadPublicationPage();
  }

  publishSelected() {
    const headers = new HttpHeaders({ 'Authorization':`Bearer ${localStorage.getItem('token')}` });
    const formData = new FormData();
    formData.append('ids', this.selectedItems.toString());
    this._HttpClient.post(`${environment.devApiEndpoint}/publication/masspublish`, formData, {headers})
      .catch((error: any) => Observable.throw(error.json().error || 'Error posting the file'))
      .subscribe(() => {
        this.loadPublications();
        this.selectedItems = [];
        this.clear();
        this.selectAllValue = false;
      });
  }

  unpublishSelected() {
    const headers = new HttpHeaders({ 'Authorization':`Bearer ${localStorage.getItem('token')}` });
    const formData = new FormData();
    formData.append('ids', this.selectedItems.toString());
    this._HttpClient.post(`${environment.devApiEndpoint}/publication/massunpublish`, formData, {headers})
      .catch((error: any) => Observable.throw(error.json().error || 'Error during the publication'))
      .subscribe(() => {
        this.loadPublications();
        this.selectedItems = [];
        this.clear();
        this.selectAllValue = false;
      });
  }

  sendSelected() {
    const dialogRef = this.dialog.open(PublicationSendModalComponent, {
      height: '600px',
      width: '800px',
      closeOnNavigation: true,
      data: { publications: this.selectedItems },
    });

    dialogRef.afterClosed()
      .pipe(filter(data => data))
      .subscribe(data => {
        const body = { userIds: data['userIds'], publicationIds: data['publicationIds'] };

        this.emailService
          .sendPublications(body)
          .subscribe(() => { },
            err => {
              
            }, () => { });
      },
    );
  }

  downloadSelected() {
    //const searchString = this.buildDownloadSearchString();
    const searchString = this.buildDownloadSearchStringList();
    if (searchString.length === 0) {
      this.openSnackBar('Las publicaciones seleccionadas no tienen ningún archivo asociado');
    } else {
      // this.publicationService.downloadFiles(searchString).subscribe(
      //   blob => {
      //     importedSaveAs(blob, 'Publicaciones.zip');
      //     this.selectedPublications.forEach(id => {

      //       this.publicationDownload = new PublicationDownload(
      //         parseInt(id), this.loginService.getUserId(), new Date());
      //       let publicationDownloadOperation: Observable<Object>;
      //       publicationDownloadOperation = this.publicationService.postDownload(
      //         parseInt(id), this.publicationDownload);
      //       publicationDownloadOperation.subscribe(
      //         () => {
      //           this.getDownloadCount();
      //         },
      //         err => {
      //           
      //         },
      //       );
            
      //     });
      //   },
      // );
      for(var i in searchString){
        this.publicationService.downloadFile(searchString[i]).finally(() => this.loading = false).subscribe(
          blob => {
            importedSaveAs(blob, `Documento.pdf`);
          }, err => {
            
          }, () => {
            let publicationDownloadOperation: Observable<Object>;
            publicationDownloadOperation = this.publicationService.postDownload(
              searchString[i], this.publicationDownload);
    
            // publicationDownloadOperation.subscribe(
            //   publicationDownload => {
            //     this.getDownloadCount();
            //     this.openSnackBar('Descargar registrada');
            //   },
            //   err => {
            //     
            //   },
            // );
          },
        );
      }
    }
  }

  getViewCount() {
    this.publicationService.getViewsCount().subscribe(
      count => this.viewCount = count,
    );
  }

  getDownloadCount() {
    this.publicationService.getDownloadsCount().subscribe(
      count => this.downloadCount = count,
    );
  }

  getViewsByPublicationId(id: number) {
    let count = 0;
    this.publicationService.getViews(id).subscribe(
      data => count = data.length,
    );
    return count;
  }

  buildSearchString() {
    const page = this.paginator.pageIndex + 1;
// tslint:disable-next-line: triple-equals
    const pageSize = (this.paginator.pageSize == undefined) ? 20 : this.paginator.pageSize;
    let result = `bookmarkUserId=${this.loginService.getUserId()}&page=${page}&PageSize=${pageSize}
    &OrderBy=${this.sortFill}&OrderDesc=${this.sortOrder}&`;
    if (this.id) {
      result += `id=${this.id}&`;
    }
    if (this.title) {
      result += `title=${this.title}&`;
    }
    if (this.bankList) {
      this.bankList.forEach(bank => {
        result += `bankId=${bank.id}&`;
      });
    }
    if (this.analystList) {
      this.analystList.forEach(analyst => {
        analyst.ids.split(',').forEach(analystid => {
          result += `analystId=${analystid}&`;
        });
        // result += `analystId=${analyst.id}&`;

      });
    }
    if (this.categoryList) {
      this.categoryList.forEach(category => {
        result += `categoryId=${category.id}&`;
      });
    }
    if (this.subcategoryList) {
      this.subcategoryList.forEach(subcategory => {
        result += `subcategoryId=${subcategory.id}&`;
      });
    }
    if (this.companyList) {
      this.companyList.forEach(company => {
        result += `companyId=${company.id}&`;
      });
    }
    if (this.rating) {
      result += `rating=${this.rating}&`;
    }
    if (this.startDate && this.endDate) {
      const datePipe = new DatePipe('en');
      result += `date1=${datePipe.transform(this.startDate, 'yyyy-MM-dd')}&`;
      result += `date2=${datePipe.transform(this.endDate, 'yyyy-MM-dd')}`;
    } else {
      result += 'date1=2000-01-01&date2=2099-01-01';
    }
    if (!this.isUserAdmin()) {
      result += `&published=true`;
    }
    return result;
  }

  buildDownloadSearchString() {
    let result = '';
    const publicationsWithFile = this.publicationsWithFile();
    this.selectedItems.forEach(id => {
      if (publicationsWithFile.indexOf(id) > -1) {
        result += `list=${id}&`;
      }
    });    
    return result;
  }

  buildDownloadSearchStringList() {
    let result: any[] = [];
    const publicationsWithFile = this.publicationsWithFile();
    this.selectedItems.forEach(id => {
      if (publicationsWithFile.indexOf(id) > -1) {
        result.push(id);
      }
    });    
    return result;
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, '', {
      duration: 2000,
    });
  }

  publicationsWithFile() {
    const publications = this.dataSource.getRecords();
    return publications.filter(publication => publication.hasFile === true).map(m => m.id).map(String);
  }

  clear() {
    
    // Clear filter fields
    this.id = null;
    this.title = '';
    this.bank = null;
    this.analyst = null;
    this.category = null;
    this.subcategory = null;
    this.company = null;
    this.bankList = undefined;
    this.analystList = undefined;
    this.categoryList = undefined;
    this.subcategoryList = undefined;
    this.companyList = undefined;
    this.rating = null;
    this.startDate = null;
    this.endDate = null;
    this.sortFill = 'Date';
    this.sortOrder = true;

    this.searchTags = [];

    this.loadPublicationPage(true);
  }

  refreshData() {
    this.clear();
  }

  searchPreferencesPublications() {
    this.search();
    this.getDownloadsAndViewCountBySearch();
    this.publicationService.searchDashboard(this.userId, this.buildSearchString()).subscribe(
      publications => {
        this.userBankPublications = publications.filter(publication => publication.published);
        this.dsPublications =
          new MatTableDataSource<Publication>(this.userBankPublications);
        this.dsPublications.paginator = this.paginatorPublications;
        this.dsPublications.sort = this.sortPublications;
      },
      (error) => {
        // console.log(error);
      },
    );
  }

  checkExceldata() {
    let bool = false;
    if (this.title !== undefined && this.title !== null && this.title !== '') {
      bool = true;
    } else {
      bool = false;
    }

    if (this.bankList !== undefined) {
      if (this.bankList.length === 0) {
        bool = false;
      } else {
        bool = true;
      }
    }

    if (this.analystList !== undefined) {
      if (this.analystList.length === 0) {
        bool = false;
      } else {
        bool = true;
      }
    }

    if (this.categoryList !== undefined) {
      if (this.categoryList.length === 0) {
        bool = false;
      } else {
        bool = true;
      }
    }

    if (this.subcategoryList !== undefined) {
      if (this.subcategoryList.length === 0) {
        bool = false;
      } else {
        bool = true;
      }
    }

    if (this.companyList !== undefined) {
      if (this.companyList.length === 0) {
        bool = false;
      } else {
        bool = true;
      }
    }

    if (bool) {
      this.searchExcel();
    } else {
      this.getPublicationsExcel();
    }
  }

  searchExcel() {
    this.search();
    this.getDownloadsAndViewCountBySearch();
    this.paginator.pageSize = 10000;
    this.publicationService.searchExcel(this.buildSearchString()).subscribe(blob => {
      importedSaveAs(blob, 'Publicaciones.xlsx');
    });
    this.paginator.pageSize = 20;
  }

  selectAllPublications() {
    this.selectedItems = [];
    if (this.selectAllValue) {
      this.publications.forEach(publication => {
        this.selectedItems.push(publication['id'].toString());
      });
      this.selectedPublications = this.selectedItems;
    } else {
      this.selectedPublications = [];
    }
  }

  // Chip events
  dropDownBankChange() {
    this.searchTags = this.searchTags.filter(item => item.name !== 'bank');
    this.searchTags.push({ name: 'bank', value: this.bankList.map(bank => bank.name).join(', ') });
    if (this.bankList.length === 0) { this.bankList = undefined; }
  }

  dropDownCompanyChange() {
    this.searchTags = this.searchTags.filter(item => item.name !== 'company');
    this.searchTags.push({ name: 'company', value: this.companyList.map(company => company.name).join(', ') });
    if (this.companyList.length === 0) { this.companyList = undefined; }
  }

  dropDownAnalystChange() {
    this.searchTags = this.searchTags.filter(item => item.name !== 'analyst');
    this.searchTags.push({ name: 'analyst', value: this.analystList.map(analyst => analyst['fullName']).join(', ') });
    if (this.analystList.length === 0) { this.analystList = undefined; }
  }

  dropDownCategoryChange() {
    this.searchTags = this.searchTags.filter(item => item.name !== 'category');
    this.searchTags.push({ name: 'category', value: this.categoryList.map(category => category.name).join(', ') });
    if (this.categoryList.length === 0) { this.categoryList = undefined; }
  }

  dropDownSubCategoryChange() {
    this.searchTags = this.searchTags.filter(item => item.name !== 'subcategory');
    this.searchTags
      .push({ name: 'subcategory', value: this.subcategoryList.map(subcategory => subcategory.name).join(', ') });
      if (this.subcategoryList.length === 0) { this.subcategoryList = undefined; }
  }

  rateChange() {
    this.searchTags = this.searchTags.filter(item => item.name !== 'rating');
    this.searchTags.push({ name: 'rating', value: this.rating });
  }

  rateCancel() {
    this.searchTags = this.searchTags.filter(item => item.name !== 'rating');
  }

  titleChange() {
// tslint:disable-next-line: triple-equals
    if (this.title != '') {
      this.searchTags = this.searchTags.filter(item => item.name !== 'title');
      this.searchTags.push({ name: 'title', value: this.title });
    }
  }

  idChange() {
    this.searchTags = this.searchTags.filter(item => item.name !== 'id');
    this.searchTags.push({ name: 'id', value: this.id });
  }

  startDateChange() {
    this.searchTags = this.searchTags.filter(item => item.name !== 'date1');
    const datePipe = new DatePipe('en');
    this.searchTags.push({ name: 'date1', value: datePipe.transform(this.startDate, 'yyyy-MM-dd') });
  }

  endDateChange() {
    this.searchTags = this.searchTags.filter(item => item.name !== 'date2');
    const datePipe = new DatePipe('en');
    this.searchTags.push({ name: 'date2', value: datePipe.transform(this.endDate, 'yyyy-MM-dd') });
  }



  SetDateFilterIni(){
    var fecha = new Date();    
    this.endDate = new Date();

    fecha.setFullYear(fecha.getFullYear() - 3);
    this.startDate = fecha;
    //--

    this.startDateChange();
    this.endDateChange();
  }






  removeTag(searchTag: any) {    
    const index = this.searchTags.indexOf(searchTag);

    if (index >= 0) {
      this.searchTags.splice(index, 1);
    }

    switch (searchTag.name) {
      case 'bank':
        this.searchTags = this.searchTags.filter(item => item.name !== 'bank');
        this.bank = null;
        this.bankList = null;
        break;
      case 'company':
        this.searchTags = this.searchTags.filter(item => item.name !== 'company');
        this.company = null;
        this.companyList = null;
        break;
      case 'analyst':
        this.searchTags = this.searchTags.filter(item => item.name !== 'analyst');
        this.analyst = null;
        this.analystList = null;
        break;
      case 'category':
        this.searchTags = this.searchTags.filter(item => item.name !== 'category');
        this.category = null;
        this.categoryList = null;
        break;
      case 'subcategory':
        this.searchTags = this.searchTags.filter(item => item.name !== 'subcategory');
        this.subcategory = null;
        this.subcategoryList = null;
        break;
      case 'rating':
        this.searchTags = this.searchTags.filter(item => item.name !== 'rating');
        this.rating = 0;
        break;
      case 'id':
        this.searchTags = this.searchTags.filter(item => item.name !== 'id');
        this.id = null;
        break;
      case 'title':
        this.searchTags = this.searchTags.filter(item => item.name !== 'title');
        this.title = '';
        break;
      case 'date1':
        this.searchTags = this.searchTags.filter(item => item.name !== 'date1');
        this.startDate = null;
        break;
      case 'date2':
        this.searchTags = this.searchTags.filter(item => item.name !== 'date2');
        this.endDate = null;
        break;
    }

    this.loadPublicationPage();
  }

  onChange(event) {
    if (!(event.keyCode === 8 || event.keyCode === 46)) {
      return false;
    }

  }

  onSelectPublicaton(id) {
    const index = this.selectedItems.indexOf(id.toString());

    if (event.target['checked']) {
      if (index < 0) {
        this.selectedItems.push(id.toString());
      }
    } else {
      if (index >= 0) {
        this.selectedItems.splice(index, 1);
      }
    }
  }

  getPublicationsExcel() {
    if (this.selectedItems.length <= 0) {
      return this.openSnackBar('Selecciona al menos una publicación');
    }

    var today = new Date();
    var objData = {
      date: today,
      publicationid: this.selectedItems[0],
      userid: this.loginService.getUserId()
    };
    
    let publicationDownloadOperation: Observable<Object>;
    publicationDownloadOperation = this.publicationService.postDownload(parseInt(this.selectedItems[0]), objData);

    publicationDownloadOperation.subscribe(
      () => {
        this.getDownloadCount();
      },
      err => {
        
      },
    );

    this.publicationService
      .getPublicationsExcel(this.buildExcelString())
      .subscribe(blob => {
        importedSaveAs(blob, 'Publicaciones.xlsx');
      });
  }

  buildExcelString() {
    let result = '';

    if (this.selectedItems) {
      this.selectedItems.forEach(item => {
        result += `publicationId=${item}&`;
      });
    }

    return result;
  }

  createImageFromBlob(image: Blob) {
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      this.imageFile = reader.result;
    }, false);

    if (image) {
      reader.readAsDataURL(image);
    }
  }

  // Bookmark
  postBookmark(publicationId) {
    this.publicationBookmark = new PublicationBookmark(
      publicationId, this.loginService.getUserId(), new Date());
    this.publicationService
      .postBookmark(publicationId, this.publicationBookmark)
      .subscribe(() => {
        this.search();
        // this.isBookmarked = true;
        // this.getBookmarkCount();
        // this.getViews();
        // this.openSnackBar('Agregado a favoritos');
      },
    );
  }

  deleteBookmark(publicationId) {
    this.publicationService
      .deleteBookmark(publicationId, this.loginService.getUserId())
      .subscribe(() => {
        this.search();
        // this.getBookmarkCount();
        // this.getViews();
        // this.openSnackBar('Borrado de favoritos');
      },
    );
  }

  toggleFilters() {
    this.showFilters = (this.showFilters) ? false : true;
  }

}

import { JwtHelper } from 'angular2-jwt';
import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions, ResponseContentType } from '@angular/http';
import { Observable } from 'rxjs/Rx';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { environment } from '../../../environments/environment';

@Injectable()
export class SearchService {

    constructor(private http: Http) { }

    private baseUrl = environment.devApiEndpoint;

    search(searchString: string): Observable<Object[]> {
        return this.http.get(`${this.baseUrl}/search?query=${searchString}`)
            .map((res: Response) => res.json())
            .catch((error: any) => Observable.throw(error.json().error || 'Error retrieving result list'));
    }

}

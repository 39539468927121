import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RepeatPipe } from './shared/pipes/repeat.pipe';
import { PipesModule } from './pipes.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
  ],
  declarations: [BreadcrumbComponent],
  providers: [
    RepeatPipe,
  ],
  exports: [
    BreadcrumbComponent,
  ],
})
export class BreadcrumbModule { 
  static forRoot() {
    return {
        ngModule: PipesModule,
        providers: [],
    };
 }
}

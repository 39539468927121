import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Parameter } from './../parameter';
import { StockImage } from './../models/stockImage';
import { Currency } from './../models/currency';
import { SummaryCategory } from './../models/summary.category';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { environment } from '../../../environments/environment';
import { UserType } from 'app/shared/models/user.type';
import { PublicationType } from '../models/publication.type';

@Injectable()
export class ParameterService {

    constructor(private http: HttpClient) { }

    private baseUrl = environment.devApiEndpoint;

    getParameters(): Observable<Parameter[]> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 'Pragma': 'no-cache', 'Cache-control': 'no-cache, no-store, private',
        'Authorization':'Bearer '+localStorage.getItem('token') });
        // const options = new RequestOptions({ headers: headers });

        return this.http.get(`${this.baseUrl}/parameter`, { headers })
            // .map((res: Response) => res.json())
            .catch((error: any) => Observable.throw(error || 'Error retrieving parameter list'));
    }

    getUserTypes(): Observable<UserType[]> {
        const headers = new HttpHeaders({
            'Content-type': 'application/json', 'Pragma': 'no-cache', 'Cache-control': 'no-cache, no-store, private', 
            'Authorization':'Bearer '+localStorage.getItem('token') });
        // const options = new RequestOptions({ headers: headers });

        return this.http.get(`${this.baseUrl}/parameter/user/types`, { headers })
            // .map((res: Response) => res.json())
            .catch((error: any) => Observable.throw(error || 'Error retrieving user type list'));
    }

    getPublicationTypes(): Observable<PublicationType[]> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private',
        'Authorization':'Bearer '+localStorage.getItem('token') });
        // const options = new RequestOptions({ headers: headers });

        return this.http.get(`${this.baseUrl}/parameter/publication/types`, { headers })
            // .map((res: Response) => res.json())
            .catch((error: any) => Observable.throw(error || 'Error retrieving publication type list'));
    }

    getSummaryCategories(): Observable<SummaryCategory[]> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private', 
        'Authorization':'Bearer '+localStorage.getItem('token') });
        // const options = new RequestOptions({ headers: headers });

        return this.http.get(`${this.baseUrl}/summary/categories`, { headers })
            // .map((res: Response) => res.json())
            .catch((error: any) => Observable.throw(error || 'Error retrieving summary category list'));
    }

    getCurrencies(): Observable<Currency[]> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private', 
        'Authorization':'Bearer '+localStorage.getItem('token') });
        // const options = new RequestOptions({ headers: headers });

        return this.http.get(`${this.baseUrl}/parameter/currencies`, { headers })
            // .map((res: Response) => res.json())
            .catch((error: any) => Observable.throw(error || 'Error retrieving currency list'));
    }

    getRecommendedActions(): Observable<Currency[]> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private', 
        'Authorization':'Bearer '+localStorage.getItem('token') });
        // const options = new RequestOptions({ headers: headers });

        return this.http.get(`${this.baseUrl}/parameter/recommendedaction`, { headers })
            // .map((res: Response) => res.json())
            .catch((error: any) => Observable.throw(error || 'Error retrieving recommended action list'));
    }

    getSockImages(): Observable<StockImage[]> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private', 
        'Authorization':'Bearer '+localStorage.getItem('token') });
        // const options = new RequestOptions({ headers: headers });

        return this.http.get(`${this.baseUrl}/parameter/stockimages`, { headers })
            // .map((res: Response) => res.json())
            .catch((error: any) => Observable.throw(error || 'Error retrieving stock image list'));
    }

    updateParameter(parameterId: number, body: Object): Observable<boolean> {
        const bodyString = JSON.stringify(body);
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private', 
        'Authorization':'Bearer '+localStorage.getItem('token') });
        // const options = new RequestOptions({ headers: headers });

        return this.http.put(`${this.baseUrl}/parameter/${parameterId}`, body, { headers })
            // .map(response => response.ok)
            .catch((error: any) => Observable.throw(error || 'Error saving the parameter'));
    }

    postStockImage(body: Object): Observable<boolean> {
        const bodyString = JSON.stringify(body);
        const headers = new HttpHeaders({ 'Authorization':'Bearer '+localStorage.getItem('token'), 'Cache-Control':  'no-cache, no-store, private', });

        return this.http.post(`${this.baseUrl}/parameter/stockimage`, body, { headers })
            // .map(response => response.ok)
            .catch((error: any) => Observable.throw(error || 'Error saving the stock image'));
    }

    postRecommendedAction(body: Object): Observable<boolean> {
        const bodyString = JSON.stringify(body);
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private', 
        'Authorization':'Bearer '+localStorage.getItem('token') });
        // const options = new RequestOptions({ headers: headers });

        return this.http.post(`${this.baseUrl}/parameter/recommendedaction`, body, { headers })
            // .map(response => response.ok)
            .catch((error: any) => Observable.throw(error.json().message));
    }

    updateRecommendedAction(recommendedActionId: number, body: Object): Observable<boolean> {
        const bodyString = JSON.stringify(body);
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private', 
        'Authorization':'Bearer '+localStorage.getItem('token') });
        // const options = new RequestOptions({ headers: headers });

        return this.http.put(`${this.baseUrl}/parameter/recommendedaction/${recommendedActionId}`, body, { headers })
            // .map(response => response.ok)
            .catch((error: any) => Observable.throw(error || 'Error saving the recommended action'));
    }

    postCurrency(body: Object): Observable<boolean> {
        const bodyString = JSON.stringify(body);
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private', 
        'Authorization':'Bearer '+localStorage.getItem('token') });
        // const options = new RequestOptions({ headers: headers });

        return this.http.post(`${this.baseUrl}/parameter/currencies`, body, { headers })
            // .map(response => response.ok)
            .catch((error: any) => Observable.throw(error.json().message));
    }

    updateCurrency(currecyId: number, body: Object): Observable<boolean> {
        const bodyString = JSON.stringify(body);
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private', 
        'Authorization':'Bearer '+localStorage.getItem('token') });
        // const options = new RequestOptions({ headers: headers });

        return this.http.put(`${this.baseUrl}/parameter/currencies/${currecyId}`, body, { headers })
            // .map(response => response.ok)
            .catch((error: any) => Observable.throw(error || 'Error saving the currency'));
    }

    postUserType(body: Object): Observable<boolean> {
        const bodyString = JSON.stringify(body);
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private',
        'Authorization':'Bearer '+localStorage.getItem('token') });
        // const options = new RequestOptions({ headers: headers });

        return this.http.post(`${this.baseUrl}/parameter/user/types`, body, { headers })
            // .map(response => response.ok)
            .catch((error: any) => Observable.throw(error.json().message));
    }

    updateUserType(userTypeId: number, body: Object): Observable<boolean> {
        const bodyString = JSON.stringify(body);
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private',
        'Authorization':'Bearer '+localStorage.getItem('token') });
        // const options = new RequestOptions({ headers: headers });

        return this.http.put(`${this.baseUrl}/parameter/user/type/${userTypeId}`, body, { headers })
            // .map(response => response.ok)
            .catch((error: any) => Observable.throw(error || 'Error saving the user type'));
    }

    postPublicationType(body: Object): Observable<boolean> {
        const bodyString = JSON.stringify(body);
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private', 
        'Authorization':'Bearer '+localStorage.getItem('token') });
        // const options = new RequestOptions({ headers: headers });

        return this.http.post(`${this.baseUrl}/parameter/publication/types`, body, { headers })
            // .map(response => response.ok)
            .catch((error: any) => Observable.throw(error.json().message));
    }

    updatePublicationType(publicationTypeId: number, body: Object): Observable<boolean> {
        const bodyString = JSON.stringify(body);
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private', 
        'Authorization':'Bearer '+localStorage.getItem('token') });
        // const options = new RequestOptions({ headers: headers });

        return this.http.put(`${this.baseUrl}/parameter/publication/type/${publicationTypeId}`, body, { headers })
            // .map(response => response.ok)
            .catch((error: any) => Observable.throw(error || 'Error saving the publication type'));
    }

    postSummaryCategory(body: Object): Observable<boolean> {
        const bodyString = JSON.stringify(body);
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private', 
        'Authorization':'Bearer '+localStorage.getItem('token') });
        // const options = new RequestOptions({ headers: headers });

        return this.http.post(`${this.baseUrl}/parameter/summary/category`, body, { headers })
            // .map(response => response.ok)
            .catch((error: any) => Observable.throw(error.json().message));
    }

    updateSummaryCategory(summaryCategoryId: number, body: Object): Observable<boolean> {
        const bodyString = JSON.stringify(body);
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private',
        'Authorization':'Bearer '+localStorage.getItem('token') });
        // const options = new RequestOptions({ headers: headers });

        return this.http.put(`${this.baseUrl}/parameter/summary/category/${summaryCategoryId}`, body, { headers })
            // .map(response => response.ok)
            .catch((error: any) => Observable.throw(error || 'Error saving the user type'));
    }

    deleteRecommendedAction(recommendedActionId: number): Observable<boolean> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private', 
        'Authorization':'Bearer '+localStorage.getItem('token') });

        return this.http.delete(`${this.baseUrl}/parameter/recommendedaction/${recommendedActionId}`, { headers })
            .catch((error: any) => Observable.throw(error.json().message));
    }

    deleteCurrency(currencyId: number): Observable<boolean> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private', 
        'Authorization':'Bearer '+localStorage.getItem('token') });

        return this.http.delete(`${this.baseUrl}/parameter/currencies/${currencyId}`, { headers })
            .catch((error: any) => Observable.throw(error.json().message));
    }

    deleteUserType(userTypeId: number): Observable<boolean> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private', 
        'Authorization':'Bearer '+localStorage.getItem('token') });

        return this.http.delete(`${this.baseUrl}/parameter/user/type/${userTypeId}`, { headers })
            .catch((error: any) => Observable.throw(error.json().message));
    }

    deletePublicationType(publicationTypeId: number): Observable<boolean> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private', 
        'Authorization':'Bearer '+localStorage.getItem('token') });

        return this.http.delete(`${this.baseUrl}/parameter/publication/type/${publicationTypeId}`, { headers })
            .catch((error: any) => Observable.throw(error.json().message));
    }

    deleteSummaryCategory(summaryCategoryId: number): Observable<boolean> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private', 
        'Authorization':'Bearer '+localStorage.getItem('token') });

        return this.http.delete(`${this.baseUrl}/parameter/summary/category/${summaryCategoryId}`, { headers })
            .catch((error: any) => Observable.throw(error.json().message));
    }
}

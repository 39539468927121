import { LoginService } from '../shared/services/login.service';
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Location } from '@angular/common';

@Injectable()
export class UserGuard implements CanActivate {

    constructor(private router: Router, private loginService: LoginService, private _location: Location) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.loginService.isUserAdmin()) {
            return true;
        }

        
        const userId = this.loginService.getUserId();          

        if (userId == route.params['id']) {
            return true;
        }
        this._location.back();
        return false;
    }
}

import { BreadcrumbModule } from './breadcrumb.module';
import { NgModule } from '@angular/core';
import { NgaModule } from './theme/nga.module';
import { Routes, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { UserListComponent } from './user-list/user-list.component';
import { UserDetailComponent } from './user-detail/user-detail.component';
import { AdminGuard } from './guards/admin.guard';
import { AuthGuard } from './guards/auth.guard';
import { UserMainComponent } from './user-main/user-main.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppTranslationModule } from './app.translation.module';
import {
  MatButtonModule, MatCheckboxModule, MatInputModule, MatSelectModule, MatTabsModule,
  MatCardModule, MatToolbarModule, MatIconModule, MatGridListModule, MatTableModule,
  MatPaginatorModule, MatPaginatorIntl, MatNativeDateModule, MatSnackBarModule, MatDialogModule,
  MatStepperModule, MatProgressSpinnerModule, MatChipsModule, MatMenuModule,
} from '@angular/material';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatListModule } from '@angular/material/list';
import { MatSortModule } from '@angular/material/sort';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatPaginatorIntlEs } from './shared/helpers/paginator.helper';
import { FlexLayoutModule } from '@angular/flex-layout';
import { UserModalComponent } from './user-modal/user-modal.component';
import { UserModalEditComponent } from './user-modal-edit/user-modal-edit.component';
import { UserModalBankComponent } from './user-modal-bank/user-modal-bank.component';
import { UserModalAnalystComponent } from './user-modal-analyst/user-modal-analyst.component';
import { UserModalCompanyComponent } from './user-modal-company/user-modal-company.component';
import { UserModalCategoryComponent } from './user-modal-category/user-modal-category.component';
import { UserGuard } from './guards/user.guard';

const routes: Routes = [
  {
    path: 'user', component: UserMainComponent, children: [
      {
        path: '', component: UserListComponent, canActivate: [AuthGuard, UserGuard], data: {
          breadcrumb: 'Usuarios',
        },
      },
      {
        path: ':id', component: UserDetailComponent, canActivate: [AuthGuard, UserGuard], data: {
          breadcrumb: 'Usuario',
        },
      },
    ],
  },
];
@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    NgaModule,
    AppTranslationModule,
    FormsModule,
    ReactiveFormsModule,
    BreadcrumbModule,
    // Flex
    FlexLayoutModule,
    // Material
    MatButtonModule,
    MatCheckboxModule,
    MatInputModule,
    MatSelectModule,
    MatTabsModule,
    MatCardModule,
    MatToolbarModule,
    MatIconModule,
    MatGridListModule,
    MatTableModule,
    MatPaginatorModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSnackBarModule,
    MatDialogModule,
    MatStepperModule,
    MatProgressSpinnerModule,
    MatChipsModule,
    MatSortModule,
    MatMenuModule,
    MatListModule,
    MatSlideToggleModule,
    MatExpansionModule,
  ],
  declarations: [
    UserListComponent,
    UserDetailComponent,
    UserMainComponent,
    UserModalComponent,
    UserModalEditComponent,
    UserModalBankComponent,
    UserModalAnalystComponent,
    UserModalCompanyComponent,
    UserModalCategoryComponent,
  ],
  entryComponents: [
    UserModalComponent,
    UserModalEditComponent,
    UserModalBankComponent,
    UserModalAnalystComponent,
    UserModalCompanyComponent,
    UserModalCategoryComponent,
  ],
  providers: [
    AdminGuard,
    UserGuard,
  ],
})
export class UserModule { }

import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatPaginator, MatSort, MatSnackBar } from '@angular/material';
import { Publication } from '../shared/models/publication';
import { PublicationService } from '../shared/services/publication.service';
import { LoginService } from '../shared/services/login.service';
import { tap } from 'rxjs/operators';
import { merge } from 'rxjs/observable/merge';
import { PublicationData } from '../shared/services/publicationdata.service';
import { saveAs as importedSaveAs } from 'file-saver';
import { PublicationDownload } from 'app/shared/models/publication.download';
import { Observable } from 'rxjs';

@Component({
 
  selector: 'app-publication-index',
  templateUrl: './publication-index.component.html',
  styleUrls: ['./publication-index.component.scss'],
})
export class PublicationIndexComponent implements OnInit, AfterViewInit {

    publication: Publication;
    dataSource: PublicationData;
    
    totalpublications = 0;
    viewCount = 0;
    downloadCount = 0;
    displayedColumns = [];
    selectedItems: string[] = [];
    selectedPublications: string[] = [];
    publicationDownload: PublicationDownload;

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    constructor(private route: ActivatedRoute,
                private publicationService: PublicationService,
                private loginService: LoginService,
                private snackBar: MatSnackBar ) {

    }

    ngOnInit() {

        if (this.isUserAdmin()) {
          this.displayedColumns = [
            'eye_id', 'title', 'date', 'bankName',
            'analystName', 'pages', 'categoryName',
            'subCategoryName', 'companyName', 'rating',
            'views', 'published', 'bookmark', 'selected'];
        } else {
          this.displayedColumns = [
            'eye_id', 'title', 'date', 'bankName',
            'analystName', 'pages', 'categoryName',
            'subCategoryName', 'companyName', 'bookmark', 'selected'];
        }

        this.publication = this.route.snapshot.data['publication'];
        this.dataSource = new PublicationData(this.publicationService, this.loginService);
        this.loadPublicationPage();
        this.getViewCount();
        this.getDownloadCount();

    }

    ngAfterViewInit() {

        this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

        merge(this.sort.sortChange, this.paginator.page)
        .pipe(
            tap(() => this.loadPublicationPage()),
        )
        .subscribe();

    }

    loadPublicationPage() {
        this.dataSource.loadPublications(this.buildSearchString());
        this.getTotalPublications();

    }

    getTotalPublications() {
        this.publicationService.getTotalP(this.buildSearchString()).subscribe(
          total => this.totalpublications = total,
        );
    }

    getViewCount() {
        this.publicationService.getViewsCount().subscribe(
          count => this.viewCount = count,
        );
      }

      getDownloadCount() {
        this.publicationService.getDownloadsCount().subscribe(
          count => this.downloadCount = count,
        );
      }

    isUserAdmin() {
      return this.loginService.isUserAdmin();
    }

    downloadSelected() {
      const searchString = this.buildDownloadSearchString();
      if (searchString === '') {
        this.openSnackBar('Las publicaciones seleccionadas no tienen ningún archivo asociado');
      } else {
        this.publicationService.downloadFiles(searchString).subscribe(
          blob => {
            importedSaveAs(blob, 'Publicaciones.zip');
            this.selectedPublications.forEach(id => {
              this.publicationDownload = new PublicationDownload(
                parseInt(id), this.loginService.getUserId(), new Date());
              let publicationDownloadOperation: Observable<Object>;
              publicationDownloadOperation = this.publicationService.postDownload(
                parseInt(id), this.publicationDownload);
  
              publicationDownloadOperation.subscribe(
                () => {
                  this.getDownloadCount();
                },
                err => {
                  
                },
              );
            });
          },
        );
      }
    }
    clear() {
      
    }
    getPublicationsExcel() {
      if (this.selectedItems.length <= 0) {
        return this.openSnackBar('Selecciona al menos una publicación');
      }
      this.publicationService
        .getPublicationsExcel(this.buildExcelString())
        .subscribe(blob => {
          importedSaveAs(blob, 'Publicaciones.xlsx');
        });
    }
    buildExcelString() {
      let result = '';
  
      if (this.selectedItems) {
        this.selectedItems.forEach(item => {
          result += `publicationId=${item}&`;
        });
      }
  
      return result;
    }
    openSnackBar(message: string) {
      this.snackBar.open(message, '', {
        duration: 2000,
      });
    }
    buildDownloadSearchString() {
      let result = '';
      const publicationsWithFile = this.publicationsWithFile();
      this.selectedItems.forEach(id => {
        if (publicationsWithFile.indexOf(id) > -1) {
          result += `list=${id}&`;
        }
      });    
      return result;
    }
    publicationsWithFile() {
      const publications = this.dataSource.getRecords();
      return publications.filter(publication => publication.hasFile === true).map(m => m.id).map(String);
    }
    

    buildSearchString() {
// tslint:disable-next-line: triple-equals
        const page = (this.paginator.pageIndex == 0) ? 1 : this.paginator.pageIndex;
// tslint:disable-next-line: triple-equals        
        const pageSize = (this.paginator.pageSize == undefined) ? 10 : this.paginator.pageSize;
        let result = `bookmarkUserId=${this.loginService.getUserId()}&page=${page}&PageSize=${pageSize}`;
        // if (this.id) {
        //   result += `id=${this.id}&`;
        // }
        // if (this.title) {
        //   result += `title=${this.title}&`;
        // }
        // if (this.bankList) {
        //   this.bankList.forEach(bank => {
        //     result += `bankId=${bank.id}&`;
        //   });
        // }
        // if (this.analystList) {
        //   this.analystList.forEach(analyst => {
        //     result += `analystId=${analyst.id}&`;
        //   });
        // }
        // if (this.categoryList) {
        //   this.categoryList.forEach(category => {
        //     result += `categoryId=${category.id}&`;
        //   });
        // }
        // if (this.subcategoryList) {
        //   this.subcategoryList.forEach(subcategory => {
        //     result += `subcategoryId=${subcategory.id}&`;
        //   });
        // }
        // if (this.companyList) {
        //   this.companyList.forEach(company => {
        //     result += `companyId=${company.id}&`;
        //   });
        // }
        // if (this.rating) {
        //   result += `rating=${this.rating}&`;
        // }
        // if (this.startDate && this.endDate) {
        //   const datePipe = new DatePipe('en');
        //   result += `date1=${datePipe.transform(this.startDate, 'yyyy-MM-dd')}&`;
        //   result += `date2=${datePipe.transform(this.endDate, 'yyyy-MM-dd')}`;
        // } else {
        //   result += 'date1=2000-01-01&date2=2099-01-01';
        // }
        if (!this.isUserAdmin()) {
          result += `&published=false`;
        }

        return result;
      }

    
}

import { Globals } from './../../../shared/globals';
import { Component } from '@angular/core';

import { GlobalState } from '../../../global.state';

@Component({
  selector: 'ba-content-top',
  styleUrls: ['./baContentTop.scss'],
  templateUrl: './baContentTop.html',
})
export class BaContentTop {
  activePageTitle: string = '';

  constructor(private _state: GlobalState, private _globals: Globals) {
    // this._state.subscribe('menu.activeLink', (activeLink) => {
    //   if (activeLink) {
    //     this.activePageTitle = activeLink.text;
    //   }
    // });

    this.activePageTitle = this._globals.pageTitle;
  }

}

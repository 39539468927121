import { Analyst } from '../shared/models/analyst';
import { Analyst as NewAnalyst } from '../shared/models/new/analyst.new';
import { Bank } from '../shared/models/bank';
import { PublicationService } from '../shared/services/publication.service';
import { EmitterService } from '../emitter.service';
import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';
import { FormGroup, AbstractControl, Validators, FormControl, NgForm, FormBuilder } from '@angular/forms';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { Observable } from 'rxjs/Rx';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-catalog-analyst-modal',
  templateUrl: './catalog-analyst-modal.component.html',
  styleUrls: ['./catalog-analyst-modal.component.scss'],
})
export class CatalogAnalystModalComponent implements OnInit {

  @HostListener('wheel', ['$event'])
  handleWheelEvent(event) {
    event.preventDefault();
  }

  analyst: Analyst;
  bank: Bank;
  banks: Bank[];
  form: FormGroup;
  model = new NewAnalyst('', null, '', '', '', '');

  constructor(
    fb: FormBuilder,
    public sanitizer: DomSanitizer,
    private publicationService: PublicationService,
    public dialogRef: MatDialogRef<CatalogAnalystModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.form = fb.group({
      'bank': ['', Validators.compose([Validators.required])],
      'name': ['', Validators.compose([Validators.required, Validators.minLength(2)])],
      'lastname_Paternal': ['', Validators.compose([Validators.required, Validators.minLength(2)])],
      'lastname_Maternal': ['', Validators.nullValidator],
      'email': ['', Validators.nullValidator],
    });

  }

  ngOnInit() {
    this.loadBanks();
  }

  // Category
  loadBanks() {
    this.publicationService.getCachedBanks().subscribe(
      banks => this.banks = banks['records'],
      err => {
      },
    );
  }

  save() {
    this.model.status = 'active';
    this.data = {
      analyst: this.model,
    };
    this.dialogRef.close(this.data);
  }

  close() {
    this.dialogRef.close();
  }

}

import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, Validators, FormControl, FormBuilder } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-dashboard-introduction-modal',
  templateUrl: './dashboard-introduction-modal.component.html',
  styleUrls: ['./dashboard-introduction-modal.component.scss'],
})
export class DashboardIntroductionModalComponent implements OnInit {
  @HostListener('wheel', ['$event'])
  handleWheelEvent(event) {
    event.preventDefault();
  }

  setIntroduction: Boolean;
  showDoNotShowThisAgain: Boolean = true;

  constructor(fb: FormBuilder,
    public sanitizer: DomSanitizer,
    public dialogRef: MatDialogRef<DashboardIntroductionModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { 
      // this.showDoNotShowThisAgain = data['showDoNotShowThisAgain'];
    }

  ngOnInit() {
  }

  save() {
    this.data = {
      setIntroduction: this.setIntroduction,
    };
    this.dialogRef.close(this.data);
  }

  close() {
    this.dialogRef.close();
  }

}

import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, Validators, FormControl, FormBuilder } from '@angular/forms';
import { SummaryCategory } from '../shared/models/summary.category';

@Component({
  selector: 'app-parameter-summary-category-edit',
  templateUrl: './parameter-summary-category-edit.component.html',
  styleUrls: ['./parameter-summary-category-edit.component.scss'],
})
export class ParameterSummaryCategoryEditComponent implements OnInit {

  form: FormGroup;
  model: SummaryCategory;

  constructor(
    fb: FormBuilder,
    public dialogRef: MatDialogRef<ParameterSummaryCategoryEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.model = data['summaryCategory'];

      this.form = fb.group({
        'name': ['', Validators.compose([Validators.required, Validators.minLength(2)])],
      });
     }

  ngOnInit() {
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    this.model.status = 'active';
    this.data = {
      id: this.model.id,
      summaryCategory: this.model,
    };
    this.dialogRef.close(this.data);
  }

}


import { BreadcrumbModule } from './breadcrumb.module';
import { NgModule } from '@angular/core';
import { NgaModule } from './theme/nga.module';
import { Routes, RouterModule } from '@angular/router';
import { AdminGuard } from './guards/admin.guard';
import { AuthGuard } from './guards/auth.guard';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatButtonModule, MatCheckboxModule, MatInputModule, MatSelectModule, MatTabsModule,
  MatCardModule, MatToolbarModule, MatIconModule, MatGridListModule, MatTableModule,
  MatPaginatorModule, MatPaginatorIntl, MatNativeDateModule, MatSnackBarModule, MatDialogModule,
  MatStepperModule, MatProgressSpinnerModule, MatChipsModule, MatMenuModule,
} from '@angular/material';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatListModule } from '@angular/material/list';
import { MatSortModule } from '@angular/material/sort';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ParameterMainComponent } from './parameter-main/parameter-main.component';
import { ParameterListComponent } from './parameter-list/parameter-list.component';
import { ParameterUserTypeModalComponent } from './parameter-user-type-modal/parameter-user-type-modal.component';
import { ParameterSummaryCategoryModalComponent }
  from './parameter-summary-category-modal/parameter-summary-category-modal.component';
import { ParameterPublicationTypeModalComponent }
  from './parameter-publication-type-modal/parameter-publication-type-modal.component';
import { ParameterCurrencyModalComponent } from './parameter-currency-modal/parameter-currency-modal.component';
import { ParameterRecommendedActionModalComponent }
  from './parameter-recommended-action-modal/parameter-recommended-action-modal.component';
import { ParameterStockImageModalComponent } from './parameter-stock-image-modal/parameter-stock-image-modal.component';
import { ParameterPublicationTypeEditComponent } 
from './parameter-publication-type-edit/parameter-publication-type-edit.component';
import { ParameterRecommendedActionEditComponent } 
from './parameter-recommended-action-edit/parameter-recommended-action-edit.component';
import { ParameterSummaryCategoryEditComponent } 
from './parameter-summary-category-edit/parameter-summary-category-edit.component';
import { ParameterUserTypEditComponent } from './parameter-user-typ-edit/parameter-user-typ-edit.component';
import { ParameterCurrencyEditComponent } from './parameter-currency-edit/parameter-currency-edit.component';
import { ParameterStockimageEditComponent } from './parameter-stockimage-edit/parameter-stockimage-edit.component';

const routes: Routes = [
  {
    path: 'parameter', component: ParameterMainComponent, children: [
      { path: '', component: ParameterListComponent, canActivate: [AdminGuard], data: {
        breadcrumb: 'Parámetros',
      } },
    ],
  },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    NgaModule,
    FormsModule,
    ReactiveFormsModule,
    BreadcrumbModule,
    // Flex
    FlexLayoutModule,
    // Material
    MatButtonModule,
    MatCheckboxModule,
    MatInputModule,
    MatSelectModule,
    MatTabsModule,
    MatCardModule,
    MatToolbarModule,
    MatIconModule,
    MatGridListModule,
    MatTableModule,
    MatPaginatorModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSnackBarModule,
    MatDialogModule,
    MatStepperModule,
    MatProgressSpinnerModule,
    MatChipsModule,
    MatSortModule,
    MatMenuModule,
    MatListModule,
    MatSlideToggleModule,
    MatExpansionModule,
  ],
  declarations: [
    ParameterMainComponent,
    ParameterListComponent,
    ParameterUserTypeModalComponent,
    ParameterSummaryCategoryModalComponent,
    ParameterPublicationTypeModalComponent,
    ParameterCurrencyModalComponent,
    ParameterRecommendedActionModalComponent,
    ParameterStockImageModalComponent,
    ParameterPublicationTypeEditComponent,
    ParameterRecommendedActionEditComponent,
    ParameterSummaryCategoryEditComponent,
    ParameterUserTypEditComponent,
    ParameterCurrencyEditComponent,
    ParameterStockimageEditComponent],
  entryComponents: [
    ParameterUserTypeModalComponent,
    ParameterSummaryCategoryModalComponent,
    ParameterPublicationTypeModalComponent,
    ParameterCurrencyModalComponent,
    ParameterRecommendedActionModalComponent,
    ParameterStockImageModalComponent,
    ParameterPublicationTypeEditComponent,
    ParameterRecommendedActionEditComponent,
    ParameterSummaryCategoryEditComponent,
    ParameterUserTypEditComponent,
    ParameterCurrencyEditComponent,
    ParameterStockimageEditComponent,
  ],
})
export class ParameterModule { }

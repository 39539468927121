export const PAGES_MENU_READER = [
  {
    path: '',
    data: {
      menu: {
        title: 'general.menu.dashboard',
        icon: 'fa fa-home',
        img: 'inicio.png',
        selected: false,
        expanded: false,
        order: 50,
        text: 'Dashboard',
      },
    },
  },
  {
    path: 'publication',
    data: {
      menu: {
        title: 'general.menu.publications',
        icon: 'fa fa-th',
        img: 'publicaciones.png',
        selected: false,
        expanded: false,
        order: 50,
        text: 'Publicaciones',
      },
    },
  },
  {
    path: 'catalog',
    data: {
      menu: {
        title: 'general.menu.recommendations',
        icon: 'fa fa-list',
        img: 'catalogos.png',
        selected: false,
        expanded: false,
        order: 300,
        text: 'Catálogos',
      },
    },
    children: [
      {
        path: 'analyst',
        data: {
          menu: {
            title: 'general.menu.by-analyst',
            icon: 'fa fa-line-chart',
            img: 'analistas.png',
            selected: false,
            expanded: false,
            order: 0,
            text: 'Analistas',
          },
        },
      },
      {
        path: 'company',
        data: {
          menu: {
            title: 'general.menu.by-company',
            icon: 'fa fa-briefcase',
            img: 'empresas.png',
            selected: false,
            expanded: false,
            order: 10,
            text: 'Empresas',
          },
        },
      },
    ],
  },
  {
    path: 'contact',
    data: {
      menu: {
        title: 'general.menu.contact',
        icon: 'fa fa-comment',
        img: 'contacto.png',
        selected: false,
        expanded: false,
        order: 300,
        text: 'Contacto',
      },
    },
  },
  //   children: [
  //     {
  //       path: 'analyst',
  //       data: {
  //         menu: {
  //           title: 'general.menu.analysts',
  //           icon: 'ion-arrow-graph-up-right',
  //           img: 'ion-arrow-graph-up-right',
  //           selected: false,
  //           expanded: false,
  //           order: 0,
  //           text: 'Analistas',
  //         },
  //       },
  //     },
  //     {
  //       path: 'bank',
  //       data: {
  //         menu: {
  //           title: 'general.menu.banks',
  //           icon: 'ion-cash',
  //           img: 'ion-cash',
  //           selected: false,
  //           expanded: false,
  //           order: 0,
  //           text: 'Bancos',
  //         },
  //       },
  //     },
  //     {
  //       path: 'company',
  //       data: {
  //         menu: {
  //           title: 'general.menu.companies',
  //           icon: 'ion-briefcase',
  //           img: 'ion-briefcase',
  //           selected: false,
  //           expanded: false,
  //           order: 10,
  //           text: 'Empresas',
  //         },
  //       },
  //     },
  //     {
  //       path: 'category',
  //       data: {
  //         menu: {
  //           title: 'general.menu.categories',
  //           icon: 'ion-ios-paper',
  //           img: 'ion-ios-paper',
  //           selected: false,
  //           expanded: false,
  //           order: 10,
  //           text: 'Giros',
  //         },
  //       },
  //     },
  //   ],
  // },
];

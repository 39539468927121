import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, Validators, FormControl, FormBuilder } from '@angular/forms';
import { NewUserType } from '../shared/models/new/user.type.new';

@Component({
  selector: 'app-parameter-user-type-modal',
  templateUrl: './parameter-user-type-modal.component.html',
  styleUrls: ['./parameter-user-type-modal.component.scss'],
})
export class ParameterUserTypeModalComponent implements OnInit {
  @HostListener('wheel', ['$event'])
  handleWheelEvent(event) {
    event.preventDefault();
  }

  form: FormGroup;
  model = new NewUserType(null, null);

  constructor(
    fb: FormBuilder,
    public dialogRef: MatDialogRef<ParameterUserTypeModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.form = fb.group({
        'name': ['', Validators.compose([Validators.required, Validators.minLength(2)])],
      });
     }

  ngOnInit() {
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    this.model.status = 'active';
    this.data = {
      userType: this.model,
    };
    this.dialogRef.close(this.data);
  }

}

import { SummaryModalComponent } from './../summary-modal/summary-modal.component';
import { Summary } from './../shared/models/summary';
import { LoginService } from './../shared/services/login.service';
import { SummaryService } from './../shared/services/summary.service';
import { Component, OnInit, AfterViewInit, ViewChild, OnDestroy } from '@angular/core';
import {
  MatPaginator, MatTableDataSource, MatPaginatorIntl,
  MatSnackBar, MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSort,
} from '@angular/material';
import { filter } from 'rxjs/operators';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';
import { RequestOptions, Headers, Http, Response } from '@angular/http';
import { environment } from '../../environments/environment';
import { EmailService } from 'app/shared/services/email.service';
import { SummaryCategory } from 'app/shared/models/summary.category';
import { DatePipe } from '@angular/common';
import { PublicationTag } from 'app/shared/interfaces/publication.tag.interface';
import { saveAs as importedSaveAs } from 'file-saver';

@Component({
  selector: 'app-summary-list',
  templateUrl: './summary-list.component.html',
  styleUrls: ['./summary-list.component.scss'],
})
export class SummaryListComponent implements OnInit, AfterViewInit {

  showFilters: Boolean = true;
  id: number;
  title: string;
  startDate: Date;
  endDate: Date;
  searchTags: PublicationTag[] = [];

  loading: Boolean = false;
  userIds: string[] = [];
  publicationIds: string[] = [];
  summaryCategory: SummaryCategory;
  summaryCategories: SummaryCategory[];

  summary: Summary;
  summaries: Summary[];
  dsSummaries;
  displayedColumnsSummaries = [];
  @ViewChild('paginatorSummaries') paginatorSummaries: MatPaginator;
  @ViewChild('tableSummaries', { read: MatSort }) sortSummaries: MatSort;

  constructor(
    private loginService: LoginService,
    private summaryService: SummaryService,
    public dialog: MatDialog,
    private http: Http,
    private router: Router,
    private emailService: EmailService,
  ) { }

  ngOnInit() {
    this.displayedColumnsSummaries = ['eye_id', 'title', 'description', 'categoryName', 'date', 'statusF'];
  }

  ngOnDestroy(){
    this.dialog.closeAll();
  }

  ngAfterViewInit() {
    this.loadSummaries();
    this.loadSummaryCategories();
  }

  loadSummaries() {
    this.summaryService
      .getSummaries()
      .finally(() => this.loading = false)
      .subscribe(summaries => {
        this.loading = true;
        this.summaries = summaries;
        this.dsSummaries =
          new MatTableDataSource<Summary>(this.summaries);
        this.dsSummaries.paginator = this.paginatorSummaries;
        this.dsSummaries.sort = this.sortSummaries;
      }, err => { });
  }

  loadSummaryCategories() {
    this.summaryService
      .getSummaryCategories()
      .subscribe(getSummaryCategories => {
        this.summaryCategories = getSummaryCategories;
      });
  }

  showCreateModal() {
    const dialog = this.dialog.open(SummaryModalComponent, {
      height: '700px',
      width: '800px',
    });

    dialog.afterClosed()
      .pipe(filter(data => data))
      .subscribe(data => {
        let summaryOperation: Observable<Summary>;
        summaryOperation = this.summaryService.postSummary(data['summary']);
        summaryOperation.subscribe(
          summary => {
            this.summary = summary;
            this.publicationIds = data['publicationIds'];
            this.userIds = data['userIds'];

            this.postSummaryPublications(summary.id, data['publicationIds']);
            this.postSummaryUsers(summary.id, data['userIds']);
            this.loading = true;
            if (data['send'] === true) {
              this.send();
            } else {
              setTimeout(() => this.router.navigate([`/summary/${this.summary.id}`]), 500);
            }
          },
          err => {
            this.loading = false;
          },
        );
      },
    );
  }

  send() {

    const body = { userIds: this.userIds, publicationIds: this.publicationIds };

    this.emailService
      .sendPublications(body)
      .subscribe(
        res => {},
        err => { 
          this.loading = false; 
        },
        () => { 
        this.loading = false;
        setTimeout(() => this.router.navigate([`/summary/${this.summary.id}`]), 500);
      });
  }

  postSummaryPublications(summaryId: number, publicationIds: string[]) {
    const headers = new Headers({ 'Content-type': 'application/json' });
    const options = new RequestOptions({ headers });
    const formData = new FormData();
    formData.append('publicationIds', publicationIds.toString());
    this.http.post(`${environment.devApiEndpoint}/summary/${summaryId}/publication`, formData, null)
      .catch((error: any) => Observable.throw(error.json().error || 'Error saving the summary publications'))
      .subscribe(data => { });
  }

  postSummaryUsers(summaryId: number, userIds: string[]) {
    const headers = new Headers({ 'Content-type': 'application/json' });
    const options = new RequestOptions({ headers });
    const formData = new FormData();
    formData.append('userIds', userIds.toString());
    this.http.post(`${environment.devApiEndpoint}/summary/${summaryId}/user`, formData, null)
      .catch((error: any) => Observable.throw(error.json().error || 'Error saving the summary users'))
      .subscribe(data => { });
  }

  search() {
    this.summaryService.searchSummaries(this.buildSearchString()).subscribe(
      summaries => {
        this.loading = true;
        this.summaries = summaries;
        this.dsSummaries =
          new MatTableDataSource<Summary>(this.summaries);
        this.dsSummaries.paginator = this.paginatorSummaries;
        this.dsSummaries.sort = this.sortSummaries;
        this.loading = false;
      },
      err => {
        this.loading = false;
      },
      () => {
        this.loading = false;
      },
    );
  }

  buildSearchString() {
    let result = '';
    if (this.id) {
      result += `id=${this.id}&`;
    }
    if (this.title) {
      result += `title=${this.title}&`;
    }
    if (this.summaryCategory) {
      result += `summaryCategoryId=${this.summaryCategory.id}&`;
    }
    if (this.startDate && this.endDate) {
      const datePipe = new DatePipe('en');
      result += `date1=${datePipe.transform(this.startDate, 'yyyy-MM-dd')}&`;
      result += `date2=${datePipe.transform(this.endDate, 'yyyy-MM-dd')}`;
    } else {
      result += 'date1=2000-01-01&date2=2099-01-01';
    }

    return result;
  }

  clear() {
    this.summaryService.getSummaries().subscribe(
      summaries => {
        this.loading = true;
        this.summaries = summaries;
        this.dsSummaries =
          new MatTableDataSource<Summary>(this.summaries);
        this.dsSummaries.paginator = this.paginatorSummaries;
        this.dsSummaries.sort = this.sortSummaries;
      },
      err => {
        this.loading = false;
      },
      () => {
        this.loading = false;
      },
    );

    // Clear filter fields
    this.id = null;
    this.title = '';
    this.summaryCategory = null;
    this.startDate = null;
    this.endDate = null;

    this.searchTags = [];
  }

  refreshData() {
    this.clear();
  }

  titleChange(e) {
    if (this.title != '') {
      this.searchTags = this.searchTags.filter(item => item.name !== 'title');
      this.searchTags.push({ name: 'title', value: this.title }); 
    }    
  }

  dropDownSummaryCategoryChange() {
    this.searchTags = this.searchTags.filter(item => item.name !== 'summaryCategory');
    this.searchTags.push({ name: 'summaryCategory', value: this.summaryCategory.name });
  }

  startDateChange(event) {
    this.searchTags = this.searchTags.filter(item => item.name !== 'date1');
    const datePipe = new DatePipe('en');
    this.searchTags.push({ name: 'date1', value: datePipe.transform(this.startDate, 'yyyy-MM-dd') });
  }

  endDateChange(event) {
    this.searchTags = this.searchTags.filter(item => item.name !== 'date2');
    const datePipe = new DatePipe('en');
    this.searchTags.push({ name: 'date2', value: datePipe.transform(this.endDate, 'yyyy-MM-dd') });
  }

  removeTag(searchTag: any) {
    const index = this.searchTags.indexOf(searchTag);

    if (index >= 0) {
      this.searchTags.splice(index, 1);
    }

    switch (searchTag.name) {
      case 'summaryCategory':
        this.searchTags = this.searchTags.filter(item => item.name !== 'summaryCategory');
        this.summaryCategory = null;
        break;
      case 'id':
        this.searchTags = this.searchTags.filter(item => item.name !== 'id');
        this.id = null;
        break;
      case 'title':
        this.searchTags = this.searchTags.filter(item => item.name !== 'title');
        this.title = '';
        break;
      case 'date1':
        this.searchTags = this.searchTags.filter(item => item.name !== 'date1');
        this.startDate = null;
        break;
      case 'date2':
        this.searchTags = this.searchTags.filter(item => item.name !== 'date2');
        this.endDate = null;
        break;
    }

    this.summaryService.getSummaries().subscribe(
      summaries => {
        this.loading = true;
        this.summaries = summaries;
        this.dsSummaries =
          new MatTableDataSource<Summary>(this.summaries);
        this.dsSummaries.paginator = this.paginatorSummaries;
        this.dsSummaries.sort = this.sortSummaries;
      },
      err => {
        this.loading = false;
      },
      () => {
        this.loading = false;
      },
    );

  }

  onChange(event) {
    if (!(event.keyCode === 8 || event.keyCode === 46)) {
      return false;
    }

  }

  toggleFilters() {
    this.showFilters = (this.showFilters) ? false : true;
  }

  create() {
    this.router.navigate(['/summary/create']);
  }

  getExcel() {
    this.summaryService
      .getSummariesExcel(this.buildSearchString())
      .subscribe(blob => {
        importedSaveAs(blob, 'Resúmenes.xlsx');
      });
  }

}

import { LoginService } from '../shared/services/login.service';
import { Http, RequestOptions, Headers } from '@angular/http';
import { Company } from '../shared/models/company';
import { Bank } from '../shared/models/bank';
import { Analyst } from '../shared/models/analyst';
import { Component, OnInit, Input, OnChanges, ViewChild, AfterViewInit,ElementRef, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EmitterService } from '../emitter.service';
import { PublicationService } from '../shared/services/publication.service';
import { forEach } from '@angular/router/src/utils/collection';
import { CatalogAnalystModalComponent } from '../catalog-analyst-modal/catalog-analyst-modal.component';
import { PublicationTag } from '../shared/interfaces/publication.tag.interface';
import { filter } from 'rxjs/operators';
import {
  MatPaginator, MatTableDataSource, MatPaginatorIntl,
  MatSnackBar, MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSort, MatDialogConfig,
} from '@angular/material';
import { Router } from '@angular/router';
import { Status } from '../shared/models/helpers/status';
import { saveAs as importedSaveAs } from 'file-saver';

//paginacion
import {ActivatedRoute} from "@angular/router";
import {debounceTime, distinctUntilChanged, startWith, tap, delay} from 'rxjs/operators';
import {merge} from "rxjs/observable/merge";
import {fromEvent} from 'rxjs/observable/fromEvent';
import {AnalystData} from "../shared/services/analystdata.service";
import { Preference } from '../shared/models/helpers/preference';

@Component({
  selector: 'app-catalog-analyst',
  templateUrl: './catalog-analyst.component.html',
  styleUrls: ['./catalog-analyst.component.scss'],
})
export class CatalogAnalystComponent implements OnInit, AfterViewInit, OnDestroy {

  modalConfig: any;

  showFilters: Boolean = true;
  st: Status;
  loading: Boolean;
  analyst: Analyst;
  bank: Bank;
  status: any;
  analysts: Analyst[];
  banks: Bank[];
  statuses: any;
  name = '';
  id: number;
  searchTags: PublicationTag[] = [];
  datasource;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  data: any[];
  displayedColumns = [];
  sortFill: string = "Analyst";
  sortOrder:  Boolean = false;

  //paginacion
  dataSourceA: AnalystData;
  totalanalyst = 0;


  constructor(
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private publicationService: PublicationService,
    private http: Http,
    private loginService: LoginService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router) {
      this.modalConfig = new MatDialogConfig();
      this.modalConfig.height = '500px';
      this.modalConfig.width = '800px';
      this.modalConfig.closeOnNavigation = true;
    }

  ngOnInit() {
    if (this.isUserAdmin()) {
      this.displayedColumns = ['eye_id', 'fullName', 'bankName', 'views', 'bookmarks', 'downloads', 'preferences', 'statusF'];
    } else {
      this.displayedColumns = ['eye_id', 'fullName', 'bankName'];
    }
    this.dataSourceA = new AnalystData(this.publicationService, this.loginService);
    this.loadAnalystPage();

    //this.loadBanks();
    this.loadStatuses();
  }

  ngOnDestroy(){
    this.dialog.closeAll();
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

        merge(this.sort.sortChange, this.paginator.page)
        .pipe(
            tap(() => this.loadAnalystPage())
        )
        .subscribe();
  }

  pf: Preference;
  preferenceChange(id, event) {
    this.pf = new Preference(null);
    this.pf.pf = (event.checked) ? true : false;
    this.publicationService
      .updatePreference(id, 'analyst', this.pf)
      .subscribe(data => this.search());
  }

  //paginacion
  loadAnalystPage() {
      this.setOrder(this.sort.active, this.sort.direction);
      this.dataSourceA.loadAnalysts(this.buildSearchString());
      this.getTotalAnalysts();

  }
  setOrder(fill, order) {
    this.sortOrder = (order == "desc") ? true : false;
    switch (fill) {
      case "fullName":
        this.sortFill = "Analyst";
        break;
      case "bankName":
        this.sortFill = "Bank";
        break;
      case "views":
        this.sortFill = "Views";
        break;
      case "bookmarks":
        this.sortFill = "Bookmarks";
        break;
      case "downloads":
        this.sortFill = "Downloads";
        break;
      case "statusF":
        this.sortFill = "Status";
        break;
        case "preferences":
          this.sortFill = "Preferences";
          break;
      default:
        this.sortFill = "Analyst";
        this.sortOrder = false;
        break;
    }
  }

  getTotalAnalysts() {
      this.publicationService.getTotalA(this.buildSearchString()).subscribe(
        total => this.totalanalyst = total,
      );
  }


  // Bank
  loadBanks() {
    this.publicationService.getBanks().subscribe(
      banks => this.banks = banks.filter(bank => bank.status === 'active'),
      err => {
      },
    );
  }

  // Status
  loadStatuses() {
    this.statuses = [
      { label: 'Activo', value: 'active' },
      { label: 'Inactivo', value: 'inactive' },
    ];
  }

  showCreateModal() {
    const dialog = this.dialog.open(CatalogAnalystModalComponent, this.modalConfig);

    dialog.afterClosed()
      .pipe(filter(data => data))
      .subscribe(data => {
        let analystOperation: Observable<Analyst>;
        analystOperation = this.publicationService.addAnalyst(data['analyst']);
        analystOperation.subscribe(
          analyst => {
            this.analyst = analyst;
            setTimeout(() => this.router.navigate([`/catalog/analyst/${this.analyst['id']}`]), 500);
          },
          err => {
            this.openSnackBar(err.error.message);
            setTimeout(() => this.snackBar.dismiss(), 2000);
          },
        );
      },
    );
  }

  clear() {
    // this.loading = true;
    // this.publicationService.getAnalysts().finally(() => this.loading = false).subscribe(
    //   analysts => {
    //     if (this.isUserAdmin()) {
    //       this.data = analysts;
    //     } else {
    //       this.data = analysts.filter(analyst => analyst.status === 'active');
    //     }
    //     this.datasource =
    //       new MatTableDataSource<Analyst>(this.data);
    //     this.datasource.paginator = this.paginator;
    //     this.datasource.sort = this.sort;
    //   },
    //   err => {
    //   },
    // );
    // Clear filter fields
    this.id = null;
    this.name = '';
    this.status = null;
    this.bank = null;
    this.searchTags = [];
    this.paginator.pageIndex = 1

    this.loadAnalystPage();
  }

  refreshData() {
    this.clear();
  }

  search() {
    // this.publicationService
    // .searchAnalysts(this.buildSearchString())
    // .subscribe(
    //   analysts => {
    //     if (this.isUserAdmin()) {
    //       this.data = analysts;
    //     } else {
    //       this.data = analysts.filter(analyst => analyst.status === 'active');
    //     }
    //     this.datasource =
    //       new MatTableDataSource<Analyst>(this.data);
    //     this.datasource.paginator = this.paginator;
    //     this.datasource.sort = this.sort;
    //   },
    //   err => {
    //   },
    // );

    this.loadAnalystPage();
  }

  buildSearchString() {
    let page = (this.paginator.pageIndex <= 0)? 1 : this.paginator.pageIndex;
    let pageSize = (this.paginator.pageSize == undefined)? 10 : this.paginator.pageSize;
    let result = `page=${page}&PageSize=${pageSize}&OrderBy=${this.sortFill}&OrderDesc=${this.sortOrder}`;
    if (this.id) {
      result += `&id=${this.id}`;
    }
    if (this.name) {
      result += `&fullName=${this.name}`;
    }
    if (this.bank) {
      result += `&bankId=${this.bank['id']}`;
    }
    if (this.status) {
      result += `&status=${this.status.value}`;
    }

    if (!this.loginService.isUserAdmin()) {
      result += `&status=active`;
    }

    return result;
  }

  isUserAdmin() {
    return this.loginService.isUserAdmin();
  }

  // Chip events
  dropDownBankChange() {
    this.searchTags = this.searchTags.filter(item => item.name !== 'bank');
    this.searchTags.push({ name: 'bank', value: this.bank.name });
  }

  dropDownStatusChange() {
    this.searchTags = this.searchTags.filter(item => item.name !== 'status');
    this.searchTags.push({ name: 'status', value: this.status.label });
  }

  nameChange(e) {
    if (this.name != '') {
      this.searchTags = this.searchTags.filter(item => item.name !== 'name');
      this.searchTags.push({ name: 'name', value: this.name });
    }    
  }


  removeTag(searchTag: any) {
    const index = this.searchTags.indexOf(searchTag);

    if (index >= 0) {
      this.searchTags.splice(index, 1);
    }
    switch (searchTag.name) {
      case 'bank':
        this.searchTags = this.searchTags.filter(item => item.name !== 'bank');
        this.bank = null;
        break;
      case 'name':
        this.searchTags = this.searchTags.filter(item => item.name !== 'name');
        this.name = null;
        break;
      case 'status':
        this.searchTags = this.searchTags.filter(item => item.name !== 'status');
        this.status = null;
        break;
      case 'id':
        this.searchTags = this.searchTags.filter(item => item.name !== 'id');
        this.id = null;
        break;
    }
    // this.publicationService.searchAnalysts(this.buildSearchString()).finally(() => this.loading = false).subscribe(
    //   analysts => {
    //     if (this.isUserAdmin()) {
    //       this.data = analysts;
    //     } else {
    //       this.data = analysts.filter(analyst => analyst.status === 'active');
    //     }
    //     this.datasource =
    //       new MatTableDataSource<Analyst>(this.data);
    //     this.datasource.paginator = this.paginator;
    //     this.datasource.sort = this.sort;
    //   },
    //   err => {
    //   },
    // );
    this.loadAnalystPage();
  }

  onChange(event) {
    if (!(event.keyCode === 8 || event.keyCode === 46)) {
      return false;
    }

  }

  statusChange(id, event) {
    this.st = new Status(null);
    this.st.st = (event.checked) ? 'active' : 'inactive';
    this.publicationService
      .updateStatus(id, 'analyst', this.st)
      .subscribe(data => this.search());
  }

  getExcel() {
    if (this.isUserAdmin()) {
      this.publicationService
      .getAnalystsExcel(this.buildSearchString())
      .subscribe(blob => {
        importedSaveAs(blob, 'Analistas.xlsx');
      });
    } else {
      this.publicationService
      .getAnalystsExcelNoAnalytics(this.buildSearchString())
      .subscribe(blob => {
        importedSaveAs(blob, 'Analistas.xlsx');
      });
    }
  }

  toggleFilters() {
    this.showFilters = (this.showFilters) ? false : true;
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, '', {
      duration: 2000,
    });
  }

}

import { LoginService } from '../shared/services/login.service';
import { Message } from 'primeng/primeng';
import { PublicationService } from '../shared/services/publication.service';
import { ActivatedRoute } from '@angular/router';
import { PublicationImage } from '../shared/models/publication.image';
import { PublicationTargetPrice } from '../shared/models/publication.targetprice';
import { Publication } from '../shared/models/publication';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { PublicationView } from '../shared/models/publication.view';
import { PublicationDownload } from '../shared/models/publication.download';
import { PublicationFile } from '../shared/models/publication.file';
import { PublicationBookmark } from '../shared/models/publication.bookmark';
import { PublicationModalEditComponent } from '../publication-modal-edit/publication-modal-edit.component';
import { saveAs as importedSaveAs } from 'file-saver';
import { Location } from '@angular/common';
import { environment } from '../../environments/environment';
import {
  MatPaginator, MatTableDataSource, MatPaginatorIntl,
  MatSnackBar, MatDialog, MatDialogRef, MAT_DIALOG_DATA,
} from '@angular/material';
import { filter } from 'rxjs/operators';
import { Http, Response } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-publication-body-preview',
  templateUrl: './publication-body-preview.component.html',
  styleUrls: ['./publication-body-preview.component.scss'],
})
export class PublicationBodyPreviewComponent implements OnInit {

  loading: Boolean = false;
  publicationId = 0;
  imageUrl = '';
  fileLinkUrl = '';
  publication: Publication;
  targetPrice: PublicationTargetPrice;
  publicationImage: PublicationImage;
  publicationFile: PublicationFile;
  publicationBookmark: PublicationBookmark;
  msgs: Message[] = [];
  private publicationView: PublicationView;
  private publicationDownload: PublicationDownload;
  isBookmarked: boolean = false;
  hasFile: boolean = false;
  viewCount: number;
  downloadCount: number;
  bookmarkCount: number;
  hasTargetPrice: Boolean = false;
  imageFile: any;
  tmpImg: HTMLImageElement;
  public showDownloadPDF: boolean = true;
  public showDownloadDocx: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private publicationService: PublicationService,
    private router: Router,
    private loginService: LoginService,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    private location: Location,
    private http: Http,
    public _HttpClient: HttpClient) { }

    public TimesTP: number = 0;
    fnGettargetPrice(){
      this.publicationService
        .getTargetPrice(this.route.snapshot.params['id'])
        .subscribe(targetPrice => {
          this.targetPrice = targetPrice;
          this.hasTargetPrice = true;
        },
        err => {
          this.TimesTP++;
          if(this.TimesTP <= 25){
            this.fnGettargetPrice();
          } else {
            this.hasTargetPrice = false;
          }
        });
    }

  ngOnInit() {
    // Set the publication id
    this.publicationId = this.route.snapshot.params['id'];
    // Get Publication
    this.publicationService
      .getPublication(this.route.snapshot.params['id'])
      .subscribe(publication => this.publication = publication);
    // Get Publication Target Price
    // this.publicationService
    //   .getTargetPrice(this.route.snapshot.params['id'])
    //   .subscribe(targetPrice => {
    //     this.targetPrice = targetPrice;
    //     this.hasTargetPrice = true;
    //   },
    //   err => {
    //     this.hasTargetPrice = false;
    //   });
    this.fnGettargetPrice();
    // Get Image
    //this.getImageUrl();
    // Save the view
    this.publicationView = new PublicationView(
      this.route.snapshot.params['id'], this.loginService.getUserId(), new Date());
    this.postView();
    this.publicationDownload = new PublicationDownload(
      this.route.snapshot.params['id'], this.loginService.getUserId(), new Date());
    // this.getViews();
    
    // Get the publication´s file name
    this.publicationService
      .getPublicationFile(this.route.snapshot.params['id'])
      .subscribe(publicationFile => this.publicationFile = publicationFile);
    // Create the publication bookmark
    this.setBookmarked();
    // Get widget values
    this.getDownloadCount();
    this.getBookmarkCount();
    // Check if the publication has a file attached
    this.checkFile();
    this.getImageUrl();
  }

  postView() {
    let publicationViewOperation: Observable<PublicationView>;
    publicationViewOperation = this.publicationService.postView(
      this.route.snapshot.params['id'], this.publicationView);

    publicationViewOperation.subscribe(
      publicationView => {
        this.publicationView = new PublicationView(
          this.route.snapshot.params['id'], this.loginService.getUserId(), new Date());
      },
      err => {
      }, () => {
        this.getViewCount();
      },
    );
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, '', {
      duration: 2000,
    });
  }

  checkFile() {
    this.publicationService.getPublicationFile(this.publicationId).subscribe(
      publicationFile => {
        let extension = publicationFile.name.split('.')[1];
        if(extension === 'docx' || extension === 'doc'){
          this.showDownloadPDF = false;
          this.showDownloadDocx = true;
        } else {
          this.showDownloadPDF = true;
          this.showDownloadDocx = false;
        }
        if(publicationFile['publicationId'] == this.publicationId){
          this.hasFile = true;
        } else {
          this.hasFile = false;
        }
        //this.fileLinkUrl = `${environment.s3Endpoint}/publicaciones/${this.publicationId}/files/publication_${this.publicationId}`;
        this.fileLinkUrl = `${environment.s3Endpoint}/publicaciones/${this.publicationId}/files/${this.publicationFile.url}`;
      },
      err => {
        this.checkFile();
      },
    );
  }

  downloadFile() {
    this.loading = true;
    this.publicationService.downloadFile(this.publicationId).finally(() => this.loading = false).subscribe(
      blob => {
        importedSaveAs(blob, this.publicationFile.name);
        //importedSaveAs(blob, this.publication.title+'.pdf');
      }, err => {
      }, () => {
        let publicationDownloadOperation: Observable<Object>;
        publicationDownloadOperation = this.publicationService.postDownload(
          this.route.snapshot.params['id'], this.publicationDownload);

        publicationDownloadOperation.subscribe(
          publicationDownload => {
            this.getDownloadCount();
            this.openSnackBar('Descargar registrada');
          },
          err => {
          },
        );
      },
    );
  }

  setBookmarked() {
    this.publicationService.getBookmark(this.publicationId, this.loginService.getUserId()).subscribe(
      publicationBookmark => {
        this.publicationBookmark = publicationBookmark;
        this.isBookmarked = true;
      },
      err => {
        this.publicationBookmark = new PublicationBookmark(
          this.route.snapshot.params['id'], this.loginService.getUserId(), new Date());
        this.isBookmarked = false;
      },
    );
  }

  // Bookmark
  postBookmark() {
    this.publicationService
      .postBookmark(this.publicationId, this.publicationBookmark)
      .subscribe(res => { this.isBookmarked = true; this.getBookmarkCount(); });
  }

  deleteBookmark() {
    this.publicationService
      .deleteBookmark(this.publicationId, this.loginService.getUserId())
      .subscribe(res => {
        this.isBookmarked = false;
        this.publicationBookmark = new PublicationBookmark(
          this.route.snapshot.params['id'], this.loginService.getUserId(), new Date());
        this.getBookmarkCount();
      },
    );
  }

  // Widgets
  getViewCount() {
    this.publicationService.getViewCount(this.publicationId).subscribe(
      viewCount => this.viewCount = viewCount,
    );
  }

  getDownloadCount() {
    this.publicationService.getDownloadCount(this.publicationId).subscribe(
      downloadCount => this.downloadCount = downloadCount,
    );
  }

  getBookmarkCount() {
    this.publicationService.getBookmarkCount(this.publicationId).subscribe(
      bookmarkCount => this.bookmarkCount = bookmarkCount,
    );
  }

  isUserAdmin() {
    return this.loginService.isUserAdmin();
  }

  // Publish Publication
  publish() {
    this.publicationService
      .publishPublication(this.publicationId)
      .subscribe(publication => {
        this.msgs = [];
        this.msgs.push({ severity: 'success', summary: 'Correcto', detail: 'Publicación actualizada' });
        this.publicationService
          .getPublication(this.route.snapshot.params['id'])
          .subscribe(publication2 => this.publication = publication);
      });
  }

  // Image
  public TimesImg: number = 0;
  getImageUrl() {
    this.imageUrl = `${environment.s3Endpoint}/app/img/imgLoading32.svg`;

    const loaded = async () => {
      this.publicationService
        .getPublicationImage(this.publicationId)
        .subscribe(publicationImage => {
          this.publicationImage = publicationImage;
          this.imageUrl = `${environment.s3Endpoint}/publicaciones/${this.publicationId}/img/${this.publicationImage.url}?random=${Math.random()}`;
        }, err => {
          //this.imageUrl = `${environment.s3Endpoint}/app/img/no_disponible.png`;
          this.TimesImg++;
          if(this.TimesImg <= 60){
            this.getImageUrl();
          } else {
            this.imageUrl = `${environment.s3Endpoint}/app/img/no_disponible.png`;
          }
        },
      );
    };

    if (this.tmpImg) {
      this.tmpImg.onload = null;
    }

    this.tmpImg = new Image();
    this.tmpImg.onload = loaded;
    this.tmpImg.src = this.imageUrl;

  }

  openFile() {
    //window.open(this.fileLinkUrl, '_blank');
    window.open(`${environment.s3Endpoint}/publicaciones/${this.publicationId}/files/publication_${this.publicationId}.pdf`, '_blank');
  }

  // Navigate back to list
  backToList() {
    // this.router.navigate(['/publication']);
    this.location.back();
  }

  openDialog(): void {
    let dataModal;
    if (this.targetPrice) {
      dataModal = { publication: this.publication, publicationTargetPrice: this.targetPrice };
    } else {
      dataModal = { publication: this.publication };
    }
    const dialogRef = this.dialog.open(PublicationModalEditComponent, {
      height: '600px',
      width: '800px',
      closeOnNavigation: true,
      data: dataModal,
    });

    dialogRef.afterClosed()
      .pipe(filter(data => data))
      .subscribe(data => {
        let publicationOperation: Observable<Publication>;
        publicationOperation = this.publicationService.updatePublication(this.publicationId, data['publication']);
        publicationOperation.subscribe(
          publication => {
            this.publication = publication;
            if (data['publicationFile']) {
              this.updateFile(this.publicationId, data['publicationFile']);
            }
            if (data['imgFile']) {
              this.updateImage(this.publicationId, data['imgFile'], data['selectedStockImageId']);
            }
          },
          err => {
          },
        );
      },
    );
  }

  updateFile(id: number, file: any) {
    const headers = new HttpHeaders({ 'Authorization':'Bearer '+localStorage.getItem('token'), 'Cache-Control':  'no-cache, no-store, private' });
    const formData = new FormData();
    formData.append('file', file);
    formData.append('id', id.toString());
    this._HttpClient.post(`${environment.devApiEndpoint}/file`, formData, {headers})
      .map((res: Response) => res.json())
      .catch((error: any) => Observable.throw(error.json().error || 'Error updating publication\'s file'))
      .subscribe(result => {
        this.publicationService
          .getPublicationFile(this.publicationId)
          .subscribe(publicationFile => {
            this.publicationFile = publicationFile;
          },
            err => {
            });
      });
  }

  // Post Publication Image
  updateImage(id: number, file: any, selectedStockImageId: number) {
    const headers = new HttpHeaders({ 'Authorization':'Bearer '+localStorage.getItem('token'), 'Cache-Control':  'no-cache, no-store, private' });
    const formData = new FormData();
    if (selectedStockImageId > 0) {
      this.publicationService.getPublicationImageById(selectedStockImageId)
        .subscribe(data => {
          this.imageFile = data;
          this.createImageFromBlob(data);
          formData.append('file', this.imageFile, `publication${id}.jpg`);
          formData.append('id', id.toString());
          this._HttpClient.post(`${environment.devApiEndpoint}/file/image`, formData, {headers})
            .map(response => response)
            .subscribe(result => {
              this.getImageUrl();
            });
        });
    } else {
      formData.append('file', file);
      formData.append('id', id.toString());
      this._HttpClient.post(`${environment.devApiEndpoint}/file/image`, formData, {headers})
        .map(response => response)
        .subscribe(result => {
          this.getImageUrl();
        });
    }
  }

  createImageFromBlob(image: Blob) {
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      this.imageFile = reader.result;
    }, false);

    if (image) {
      reader.readAsDataURL(image);
    }
  }

}

import { Component, OnInit, Inject, HostListener } from '@angular/core';
import {
  MatDialogRef, MAT_DIALOG_DATA,
} from '@angular/material';

@Component({
  selector: 'app-summary-modal-send',
  templateUrl: './summary-modal-send.component.html',
  styleUrls: ['./summary-modal-send.component.scss'],
})
export class SummaryModalSendComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<SummaryModalSendComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
  }

  send() {
    this.data = {
      send: true,
    };
    this.dialogRef.close(this.data);
  }

  close() {
    this.data = {
      send: false,
    };
    this.dialogRef.close(this.data);
  }

}

import { SubCategory } from '../shared/models/subcategory';
import { Company } from '../shared/models/company';
import { Company as NewCompany } from '../shared/models/new/company.new';
import { PublicationService } from '../shared/services/publication.service';
import { Component, ElementRef, EventEmitter, 
  Input, OnChanges, OnInit, Output, Inject, ViewChild, HostListener } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { FormGroup, AbstractControl, Validators, FormControl, NgForm, FormBuilder } from '@angular/forms';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { Observable } from 'rxjs/Rx';
import { Category } from '../shared/models/category';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-catalog-company-modal',
  templateUrl: './catalog-company-modal.component.html',
  styleUrls: ['./catalog-company-modal.component.scss'],
})
export class CatalogCompanyModalComponent implements OnInit {
  @HostListener('wheel', ['$event'])
  handleWheelEvent(event) {
    event.preventDefault();
  }

  imgSrc: string;
  @ViewChild('divImgFile')
  divImgFile: ElementRef;

  company: Company;
  category: Category;
  subcategory: SubCategory;
  categories: Category[];
  subcategories: SubCategory[];
  form: FormGroup;
  submitted: boolean = false;
  @ViewChild('imgFileInput')
  imgFile: any;

  model = new NewCompany('', null, null, '', '');

  constructor(
    fb: FormBuilder,
    public sanitizer: DomSanitizer,
    private publicationService: PublicationService,
    public dialogRef: MatDialogRef<CatalogCompanyModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.form = fb.group({
      'name': ['', Validators.compose([Validators.required, Validators.minLength(2)])],
      'category': ['', Validators.compose([Validators.required])],
      'subcategory': ['', Validators.compose([Validators.required])],
      'selectedImage': [{ value: '', disabled: true }, Validators.compose([Validators.nullValidator])],
    });
  }

  ngOnInit() {
    this.loadCategories();
  }

  // Category
  loadCategories() {
    this.publicationService.getCategories().subscribe(
      categories => this.categories = categories['records'].filter(categories => categories.status === 'active'),
      err => {
      },
    );
  }

  categoryChange() {
    this.publicationService.getSubCategories(this.model.categoryid).subscribe(
      subcategories => this.subcategories = subcategories.filter(subcategories => subcategories.status === 'active'),
      err => {
      },
    );
  }

  save() {
    this.model.status = 'active';
    this.data = {
      company: this.model,
      imgFile: this.imgFile.nativeElement.files[0],
    };
    this.dialogRef.close(this.data);
  }

  close() {
    this.dialogRef.close(this.data);
  }

  fileChangeEvent(fileInput: any) {
    if (fileInput.target.files && fileInput.target.files[0]) {
      const file = fileInput.target.files[0];
      this.imgSrc = file.name;
      
      const img = this.divImgFile.nativeElement;
      img.getElementsByTagName('span')[0].innerText = '';
      img.getElementsByTagName('span')[0].innerHTML = '';

    }
  }

}

import { environment } from './../../environments/environment';
import { StockImage } from './../shared/models/stockImage';
import { NewStockImage } from './../shared/models/new/stockimage.new';
import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, Validators, FormControl, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-parameter-stockimage-edit',
  templateUrl: './parameter-stockimage-edit.component.html',
  styleUrls: ['./parameter-stockimage-edit.component.scss'],
})
export class ParameterStockimageEditComponent implements OnInit {

  stockImage: StockImage;
  imgUrl: string;
  form: FormGroup;
  imgSrc: string;
  @ViewChild('imgFileInput')
  imgFile: ElementRef;

  constructor(
    fb: FormBuilder,
    public dialogRef: MatDialogRef<ParameterStockimageEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.stockImage = data['stockImage'];
    this.form = fb.group({
      'name': ['', Validators.compose([Validators.required, Validators.minLength(2)])],
      'selectedImg': [{ value: '', disabled: true }, Validators.compose([Validators.required, Validators.minLength(2)])],
    });
  }

  ngOnInit() {
    this.imgUrl = `${environment.s3Endpoint}/app/img/stock/${this.stockImage.url}`;
    this.imgSrc = this.stockImage.url;
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    this.data = {
      stockImage: this.stockImage,
      imgFile: this.imgFile.nativeElement.files[0],
      stockImageId: this.stockImage.id,
      stockImageName: this.stockImage.name,
    };
    this.dialogRef.close(this.data);
  }

  fileChangeEvent(fileInput: any) {
    if (fileInput.target.files && fileInput.target.files[0]) {
      const reader = new FileReader();

      reader.onload = ((e) => {
        this.imgUrl = e.target['result'];
      });
      const file = fileInput.target.files[0];
      this.imgSrc = file.name;
      // const img = this.divImgFile.nativeElement;
      // img.getElementsByTagName('span')[0].innerText = '';
      // img.getElementsByTagName('span')[0].innerHTML = '';

      reader.readAsDataURL(fileInput.target.files[0]);
    }
  }

}

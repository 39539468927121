import { BreadcrumbModule } from './breadcrumb.module';
import { AdminGuard } from './guards/admin.guard';
import { AuthGuard } from './guards/auth.guard';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CatalogMainComponent } from './catalog-main/catalog-main.component';
import { CatalogCompanyComponent } from './catalog-company/catalog-company.component';
import { CatalogAnalystComponent } from './catalog-analyst/catalog-analyst.component';
import { Routes, RouterModule } from '@angular/router';
import { NgaModule } from './theme/nga.module';
import { FormsModule } from '@angular/forms';
import { NgbRatingModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule as AngularFormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import {
  CalendarModule, ButtonModule, InputTextModule, DropdownModule, PanelModule, RatingModule,
  FieldsetModule, GrowlModule, InputTextareaModule, FileUploadModule, CheckboxModule,
  TabViewModule, ToolbarModule, InputMaskModule, SplitButtonModule, ChipsModule,
} from 'primeng/primeng';
import {
  MatButtonModule, MatCheckboxModule, MatInputModule, MatSelectModule, MatTabsModule,
  MatCardModule, MatToolbarModule, MatIconModule, MatGridListModule, MatTableModule,
  MatPaginatorModule, MatPaginatorIntl, MatNativeDateModule, MatSnackBarModule, MatDialogModule,
  MatChipsModule, MatProgressSpinnerModule, MatMenuModule,
} from '@angular/material';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSortModule } from '@angular/material/sort';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatPaginatorIntlEs } from './shared/helpers/paginator.helper';
import { ChartsModule } from 'ng2-charts';
import { DataTableModule } from 'angular2-datatable';
import { CatalogBankComponent } from './catalog-bank/catalog-bank.component';
import { CatalogCompanyModalComponent } from './catalog-company-modal/catalog-company-modal.component';
import { CatalogCompanyDetailComponent } from './catalog-company-detail/catalog-company-detail.component';
import { CatalogBankDetailComponent } from './catalog-bank-detail/catalog-bank-detail.component';
import { CatalogAnalystDetailComponent } from './catalog-analyst-detail/catalog-analyst-detail.component';
import { CatalogBankModalComponent } from './catalog-bank-modal/catalog-bank-modal.component';
import { CatalogAnalystModalComponent } from './catalog-analyst-modal/catalog-analyst-modal.component';
import { CatalogCategoryComponent } from './catalog-category/catalog-category.component';
import { CatalogCategoryModalComponent } from './catalog-category-modal/catalog-category-modal.component';
import { CatalogSubcategoryModalComponent } from './catalog-subcategory-modal/catalog-subcategory-modal.component';
import { CatalogCompanyModalEditComponent } from './catalog-company-modal-edit/catalog-company-modal-edit.component';
import { CatalogBankModalEditComponent } from './catalog-bank-modal-edit/catalog-bank-modal-edit.component';
import { CatalogAnalystModalEditComponent } from './catalog-analyst-modal-edit/catalog-analyst-modal-edit.component';
import { CatalogDashboardComponent } from './catalog-dashboard/catalog-dashboard.component';

const routes: Routes = [
  {
    path: 'catalog', component: CatalogMainComponent, children: [
      { path: 'analyst', component: CatalogAnalystComponent, canActivate: [AuthGuard], data: {
        breadcrumb: 'Analistas',
      }}, 
      { path: 'analyst/:id', component: CatalogAnalystDetailComponent, canActivate: [AuthGuard], 
      data: {
        breadcrumb: 'Analista',
      } },
      { path: 'bank', component: CatalogBankComponent, canActivate: [AuthGuard, AdminGuard], data: {
        breadcrumb: 'Bancos',
      } },
      { path: 'bank/:id', component: CatalogBankDetailComponent, canActivate: [AuthGuard, AdminGuard], data: {
        breadcrumb: 'Banco',
      } },
      { path: 'company', component: CatalogCompanyComponent, canActivate: [AuthGuard], data: {
        breadcrumb: 'Empresas',
      } },
      { path: 'company/:id', component: CatalogCompanyDetailComponent, canActivate: [AuthGuard], data: {
        breadcrumb: 'Empresa',
      } },
      { path: 'category', component: CatalogCategoryComponent, canActivate: [AuthGuard, AdminGuard], data: {
        breadcrumb: 'Giros',
      } },
    ],
  },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    NgaModule,
    AngularFormsModule,
    DataTableModule,
    ReactiveFormsModule,
    BreadcrumbModule,
    // Flex
    FlexLayoutModule,
    // PrimeNG
    CalendarModule,
    ButtonModule,
    InputTextModule,
    DropdownModule,
    PanelModule,
    RatingModule,
    FieldsetModule,
    GrowlModule,
    InputTextareaModule,
    FileUploadModule,
    CheckboxModule,
    TabViewModule,
    ToolbarModule,
    InputMaskModule,
    SplitButtonModule,
    ChipsModule,
    // Material
    MatButtonModule,
    MatCheckboxModule,
    MatInputModule,
    MatSelectModule,
    MatTabsModule,
    MatCardModule,
    MatToolbarModule,
    MatIconModule,
    MatGridListModule,
    MatTableModule,
    MatPaginatorModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSnackBarModule,
    MatDialogModule,
    MatChipsModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    MatSlideToggleModule,
    MatTooltipModule,
  ],
  declarations: [
    CatalogMainComponent,
    CatalogCompanyComponent,
    CatalogAnalystComponent,
    CatalogBankComponent,
    CatalogCompanyModalComponent,
    CatalogCompanyDetailComponent,
    CatalogBankDetailComponent,
    CatalogAnalystDetailComponent,
    CatalogBankModalComponent,
    CatalogAnalystModalComponent,
    CatalogCategoryComponent,
    CatalogCategoryModalComponent,
    CatalogSubcategoryModalComponent,
    CatalogCompanyModalEditComponent,
    CatalogBankModalEditComponent,
    CatalogAnalystModalEditComponent,
    CatalogDashboardComponent,
  ],
  entryComponents: [
    CatalogCompanyModalComponent,
    CatalogBankModalComponent,
    CatalogAnalystModalComponent,
    CatalogCategoryModalComponent,
    CatalogSubcategoryModalComponent,
    CatalogCompanyModalEditComponent,
    CatalogBankModalEditComponent,
    CatalogAnalystModalEditComponent,
  ],
  providers: [
    NgbActiveModal,
    [{ provide: MatPaginatorIntl, useClass: MatPaginatorIntlEs }],
  ],
})
export class CatalogModule { }

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Publication } from './../models/publication';
import { SummaryCategory } from './../models/summary.category';

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { environment } from '../../../environments/environment';
import { Summary } from '../models/summary';
import { User } from '../models/user';

@Injectable()
export class SummaryService {

    constructor(private http: HttpClient) { }

    private baseUrl = environment.devApiEndpoint;

    getSummaries(): Observable<Summary[]> {
        // const headers = new Headers({ 'Content-type': 'application/json' });
        // const options = new RequestOptions({ headers: headers });
        // const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        // 'Pragma': 'no-cache', 'Cache-control': 'no-cache' });
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private',
        'Authorization':'Bearer '+localStorage.getItem('token') });

        return this.http.get(`${this.baseUrl}/summary`, { headers })
            // .map((res: Response) => res.json())
            .catch((error: any) => Observable.throw(error.json().error || 'Error retrieving summary list'));
    }

    
    getSummaryById(summaryId: number): Observable<Summary> {
        // const headers = new Headers({ 'Content-type': 'application/json' });
        // const options = new RequestOptions({ headers: headers });
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private',
        'Authorization':'Bearer '+localStorage.getItem('token') });

        return this.http.get(`${this.baseUrl}/summary/${summaryId}`, { headers })
        // .map((res: Response) => res.json())
        .catch((error: any) => Observable.throw(error.json().error || 'Error retrieving summary'));
    }
    
    getSummaryPublications(summaryId: number): Observable<Publication[]> {
        // const headers = new Headers({ 'Content-type': 'application/json' });
        // const options = new RequestOptions({ headers: headers });
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private', 
        'Authorization':'Bearer '+localStorage.getItem('token') });

        return this.http.get(`${this.baseUrl}/summary/${summaryId}/publication`, { headers })
            // .map((res: Response) => res.json())
            .catch((error: any) => Observable.throw(error.json().error || 'Error retrieving summary list'));
    }

    getSummaryUsers(summaryId: number): Observable<User[]> {
        // const headers = new Headers({ 'Content-type': 'application/json' });
        // const options = new RequestOptions({ headers: headers });
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private', 
        'Authorization':'Bearer '+localStorage.getItem('token') });
        
        return this.http.get(`${this.baseUrl}/summary/${summaryId}/user`, { headers })
            // .map((res: Response) => res.json())
            .catch((error: any) => Observable.throw(error.json().error || 'Error retrieving summary list'));
    }

    postSummary(body: Object): Observable<Summary> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private', 
        'Authorization':'Bearer '+localStorage.getItem('token') });
        // const options = new RequestOptions({ headers: headers });

        return this.http.post(`${this.baseUrl}/summary`, body, { headers })
            // .map((res: Response) => res.json())
            .catch((error: any) => Observable.throw(error));
    }

    postSummaryView(summaryId: number, publicationId: number): Observable<Boolean> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private', 
        'Authorization':'Bearer '+localStorage.getItem('token') });        

        return this.http
        .post(`${this.baseUrl}/summary/${summaryId}/publication/${publicationId}`, {}, { headers })
            // .map((res: Response) => res.json())
            .catch((error: any) => Observable.throw(error));
    }

    updateSummary(summaryId: number, body: Object): Observable<Summary> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private', 
        'Authorization':'Bearer '+localStorage.getItem('token') });
        // const options = new RequestOptions({ headers: headers });

        return this.http.put(`${this.baseUrl}/summary/${summaryId}`, body, { headers })
            // .map((res: Response) => res.json())
            .catch((error: any) => Observable.throw(error));
    }

    getSummaryCategories(): Observable<SummaryCategory[]> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private', 
        'Authorization':'Bearer '+localStorage.getItem('token') });

        return this.http.get(`${this.baseUrl}/summary/categories`, { headers })
            // .map((res: Response) => res.json())
            .catch((error: any) => Observable.throw(error));
    }

    sendSummary(summaryId: number): Observable<Summary> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private', 
        'Authorization':'Bearer '+localStorage.getItem('token') });
        // const options = new RequestOptions({ headers: headers });

        return this.http.post(`${this.baseUrl}/summary/${summaryId}`, { headers })
        // .map((res: Response) => res.json())
        .catch((error: any) => Observable.throw(error));
    }

    searchSummaries(searchString: string): Observable<Summary[]> {
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        'Cache-Control':  'no-cache, no-store, private', 
        'Authorization':'Bearer '+localStorage.getItem('token') });

        return this.http.get(`${this.baseUrl}/summary/search?${searchString}`, { headers })
            // .map((res: Response) => res.json())
            .catch((error: any) => Observable.throw(error.json().error || 'Error retrieving summary list'));
    }

    getSummariesExcel(searchString: string): Observable<Blob> {
        const headers = new HttpHeaders({ 'Authorization':'Bearer '+localStorage.getItem('token'), 'Cache-Control':  'no-cache, no-store, private' });

        return this.http.get(`${this.baseUrl}/summary/excel?${searchString}`, { responseType: 'blob', headers })
            // .map((res: Response) => res.blob())
            .catch((error: any) => Observable.throw(error.json().error || 'Error retrieving file'));
    }

}

import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, Validators, FormControl, FormBuilder } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { PublicationService } from '../shared/services/publication.service';
import { NewUser } from '../shared/models/new/user.new';
import { ParameterService } from 'app/shared/services/parameter.service';
import { UserType } from 'app/shared/models/user.type';

@Component({
  selector: 'app-user-modal',
  templateUrl: './user-modal.component.html',
  styleUrls: ['./user-modal.component.scss'],
})
export class UserModalComponent implements OnInit {
  @HostListener('wheel', ['$event'])
  handleWheelEvent(event) {
    event.preventDefault();
  }

  roles: any;
  userType: UserType;
  userTypes: UserType[];

  form: FormGroup;
  model = new NewUser(null, null, null, null, null, null);

  constructor(fb: FormBuilder,
    public sanitizer: DomSanitizer,
    private parameterService: ParameterService,
    private publicationService: PublicationService,
    public dialogRef: MatDialogRef<UserModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.form = fb.group({
      'name': ['', Validators.compose([Validators.required, Validators.minLength(2)])],
      'category': ['', Validators.nullValidator],
      'lastname_Paternal': ['', Validators.compose([Validators.required, Validators.minLength(2)])],
      'lastname_Maternal': ['', Validators.nullValidator],
      'email': ['', Validators.compose([Validators.required, Validators.email])],
      'role': ['', Validators.required],
    });
  }

  ngOnInit() {
    this.loadRoles();
    this.loadUserTypes();
  }

  loadRoles() {
    this.roles = [
      { label: 'Administrador', value: 'Admin' },
      { label: 'Lector', value: 'Reader' },
    ];
  }

  loadUserTypes() {
    this.parameterService
    .getUserTypes()
    .subscribe(userTypes => this.userTypes = userTypes);
  }

  save() {
    this.data = {
      user: this.model,
    };
    this.dialogRef.close(this.data);
  }

  close() {
    this.dialogRef.close();
  }

}

import { Component, OnInit, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
// import { DatePipe } from '@angular/common';
import {
  MatPaginator, MatTableDataSource, MatSort,
} from '@angular/material';
import { PublicationService } from '../shared/services/publication.service';
import { LoginService } from '../shared/services/login.service';
import { Publication } from '../shared/models/publication';
import { Company } from '../shared/models/company';
import { Analyst } from '../shared/models/analyst';
import { User } from '../shared/models/user';
import { DatePipe } from '@angular/common';
import { RepeatPipe } from 'app/shared/pipes/repeat.pipe';
import { Router, Routes, ActivatedRoute } from '@angular/router';
import { Idle } from 'idlejs/dist';

@Component({
  selector: 'nga-dashboard-body',
  templateUrl: './dashboard-body.component.html',
  styleUrls: ['./dashboard-body.component.scss'],
})
export class DashboardBodyComponent implements OnInit, AfterViewInit {

  userId: number;
  loading: Boolean;
  publications: Publication[];

  beginDate: any;
  endDate: any;

  dsMostVisitedPublications;
  displayedColumnsMostVisitedPublications = [];
  @ViewChild('paginatorMostVisitedPublications') paginatorMostVisitedPublications: MatPaginator;
  @ViewChild('tableMostVisitedPublications', { read: MatSort }) sortMostVisitedPublications: MatSort;

  dsTopPublications;
  displayedColumnsTopPublications = [];
  @ViewChild('paginatorTopPublications') paginatorTopPublications: MatPaginator;
  @ViewChild('tableTopPublications', { read: MatSort }) sortTopPublications: MatSort;

  dsTopUsers;
  displayedColumnsTopUsers = [];
  @ViewChild('paginatorTopUsers') paginatorTopUsers: MatPaginator;
  @ViewChild('tableTopUsers', { read: MatSort }) sortTopUsers: MatSort;

  dsTopCompanies;
  displayedColumnsTopCompanies = [];
  @ViewChild('paginatorTopCompanies') paginatorTopCompanies: MatPaginator;
  @ViewChild('tableTopCompanies', { read: MatSort }) sortTopCompanies: MatSort;

  dsTopAnalysts;
  displayedColumnsTopAnalysts = [];
  @ViewChild('paginatorTopAnalysts') paginatorTopAnalysts: MatPaginator;
  @ViewChild('tableTopAnalysts', { read: MatSort }) sortTopAnalysts: MatSort;

  dsTopCategories;
  displayedColumnsTopCategories = [];
  @ViewChild('paginatorTopCategories') paginatorTopCategories: MatPaginator;
  @ViewChild('tableTopCategories', { read: MatSort }) sortTopCategories: MatSort;
  @ViewChild('dateInit') startDateInput:ElementRef;
  @ViewChild('dateEnd') endDateInput: ElementRef;

  constructor(
    private publicationService: PublicationService,
    private loginService: LoginService,
    private route: ActivatedRoute,
    private router: Router) {
    this.getUserId();
  }

  ngOnInit() {
    this.displayedColumnsMostVisitedPublications = ['eye_id', 'title', 'date',
      'bank', 'analyst', 'pages', 'category', 'subCategory', 'company', 'rating'];

    this.displayedColumnsTopPublications = ['eye_id_top', 'title', 'views', 'downloads', 'bookmarks'];

    this.displayedColumnsTopUsers = ['eye_id_users', 'user', 'views', 'downloads', 'bookmarks'];

    this.displayedColumnsTopCompanies = ['eye_id_companies',
      'company', 'views', 'downloads', 'bookmarks'];

    this.displayedColumnsTopAnalysts = ['eye_id_analysts',
      'analyst', 'views', 'downloads', 'bookmarks'];

    this.displayedColumnsTopCategories = ['eye_id_categories',
      'category', 'views', 'downloads', 'bookmarks'];

      const idle = new Idle()
      .whenNotInteractive()
      .within(parseInt(localStorage.getItem('timer')))
      .do(() => {
        localStorage.removeItem('token');
        localStorage.removeItem('timer');
        this.router.navigate(['login']);
      })
      .start();
  }

  ngAfterViewInit() {
    this.loadMostVisitedPublications();
    this.loadTopPublications();
    this.loadTopUsers();
    this.loadTopCompanies();
    this.loadTopAnalysts();
    this.loadTopCategories();
  }

  search() {
    this.loadTopPublications();
    this.loadTopAnalysts();
    this.loadTopUsers();
    this.loadTopCompanies();
  }

  clear() {
    this.beginDate = null;
    this.endDate = null;

    this.loadTopPublications();
    this.loadTopAnalysts();
    this.loadTopUsers();
    this.loadTopCompanies();
  }

  loadMostVisitedPublications() {
    this.publicationService
      .getUserMostVisitedPublications(this.userId)
      .finally(() => this.loading = false)
      .subscribe(publications => {
        this.loading = true;
        this.publications = publications;
        this.dsMostVisitedPublications =
          new MatTableDataSource<Publication>(this.publications);
        this.dsMostVisitedPublications.paginator = this.paginatorMostVisitedPublications;
        this.dsMostVisitedPublications.sort = this.sortMostVisitedPublications;
      });
  }

  loadTopPublications() {
    this.publicationService
      .getTopPublications(this.buildSearchString())
      .finally(() => this.loading = false)
      .subscribe(publications => {
        this.loading = true;
        this.dsTopPublications =
          new MatTableDataSource<Publication>(publications);
        this.dsTopPublications.paginator = this.paginatorTopPublications;
        this.dsTopPublications.sort = this.sortTopPublications;
      });
  }

  loadTopUsers() {
    this.publicationService
      .getTopUsers(this.buildSearchString())
      .finally(() => this.loading = false)
      .subscribe(users => {
        this.loading = true;
        this.dsTopUsers =
          new MatTableDataSource<User>(users);
        this.dsTopUsers.paginator = this.paginatorTopUsers;
        this.dsTopUsers.sort = this.sortTopUsers;
      });
  }

  loadTopCompanies() {
    this.publicationService
      .getTopCompanies(this.buildSearchString())
      .finally(() => this.loading = false)
      .subscribe(companies => {
        this.loading = true;
        this.dsTopCompanies =
          new MatTableDataSource<Company>(companies);
        this.dsTopCompanies.paginator = this.paginatorTopCompanies;
        this.dsTopCompanies.sort = this.sortTopCompanies;
      });
  }

  loadTopAnalysts() {
    this.publicationService
      .getTopAnalysts(this.buildSearchString())
      .finally(() => this.loading = false)
      .subscribe(analysts => {
        this.loading = true;
        this.dsTopAnalysts =
          new MatTableDataSource<Analyst>(analysts);
        this.dsTopAnalysts.paginator = this.paginatorTopAnalysts;
        this.dsTopAnalysts.sort = this.sortTopAnalysts;
      });
  }

  loadTopCategories() {
    this.publicationService
      .getTopAnalysts(this.buildSearchString())
      .finally(() => this.loading = false)
      .subscribe(analysts => {
        this.loading = true;
        this.dsTopAnalysts =
          new MatTableDataSource<Analyst>(analysts);
        this.dsTopAnalysts.paginator = this.paginatorTopAnalysts;
        this.dsTopAnalysts.sort = this.sortTopAnalysts;
      });
  }

  getUserId() {
    this.userId = this.loginService.getUserId();
  }

  validFilterDates() {
    let result = true;
    if (this.beginDate && this.endDate) {
      result = false;
    }

    return result;
  }

  buildSearchString() {
    let result = `bookmarkUserId=${this.loginService.getUserId()}&`;
    if (this.beginDate && this.endDate) {
      const datePipe = new DatePipe('en');
        result = `startDate=${datePipe.transform(this.beginDate, 'yyyy-MM-dd')}
        &endDate=${datePipe.transform(this.endDate, 'yyyy-MM-dd')}`;
    } else {
      result = 'startDate=2000-01-01&endDate=2099-01-01';
    }

    return result;
  }

  removeAll(type: string){
    if(type === 'start'){
      this.startDateInput.nativeElement.value = '';
    } else {
      this.endDateInput.nativeElement.value = '';
    }
    
  }

}

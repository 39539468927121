import { PublishedGuard } from './guards/published.guard';
import { BreadcrumbModule } from './breadcrumb.module';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PublicationListComponent, SendInfoComponent } from './publication-list/publication-list.component';
import { PublicationIndexComponent } from './publication-index/publication-index.component';
import { Routes, RouterModule } from '@angular/router';
import { NgaModule } from './theme/nga.module';
import { PublicationMainComponent } from './publication-main/publication-main.component';
import { PublicationService } from './shared/services/publication.service';
import { FormsModule } from '@angular/forms';
import { AppTranslationModule } from './app.translation.module';
import { NgbRatingModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DataFilterPipe } from './shared/pipes/datafilter.pipe';
import { DataTableModule } from 'angular2-datatable';
// import { DataFilterPipe } from './components/dataTables/data-filter.pipe';
import { NgbDropdownModule, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { FlexLayoutModule } from '@angular/flex-layout';
import {
  CalendarModule, ButtonModule, InputTextModule, DropdownModule, PanelModule, RatingModule,
  FieldsetModule, GrowlModule, InputTextareaModule, FileUploadModule, CheckboxModule,
  TabViewModule, ToolbarModule, InputMaskModule, SplitButtonModule, ChipsModule, ChartModule,
} from 'primeng/primeng';
import {
  MatButtonModule, MatCheckboxModule, MatInputModule, MatSelectModule, MatTabsModule,
  MatCardModule, MatToolbarModule, MatIconModule, MatGridListModule, MatTableModule,
  MatPaginatorModule, MatPaginatorIntl, MatNativeDateModule, MatSnackBarModule, MatDialogModule,
  MatStepperModule, MatProgressSpinnerModule, MatChipsModule, MatMenuModule, MAT_DIALOG_DEFAULT_OPTIONS,
} from '@angular/material';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSortModule } from '@angular/material/sort';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatPaginatorIntlEs } from './shared/helpers/paginator.helper';
import { ChartsModule } from 'ng2-charts';
import { FormsModule as AngularFormsModule, ReactiveFormsModule } from '@angular/forms';
import { PublicationBodyComponent } from './publication-body/publication-body.component';
import { PublicationBodyPreviewComponent } from './publication-body-preview/publication-body-preview.component';
import { AdminGuard } from './guards/admin.guard';
import { PublicationModalComponent } from './publication-modal/publication-modal.component';
import { PublicationModalEditComponent } from './publication-modal-edit/publication-modal-edit.component';
import {
  PublicationTargetpriceModalEditComponent,
} from './publication-targetprice-modal-edit/publication-targetprice-modal-edit.component';
import { AuthGuard } from './guards/auth.guard';
import { PipesModule } from './pipes.module';
import { PublicationSendModalComponent } from './publication-send-modal/publication-send-modal.component';
import { PublicationDisableModalComponent } from './publication-disable--modal/publication-disable--modal.component';
import { PublicationGuard } from './guards/publication.guard';

const routes: Routes = [
  {
    path: 'publication', component: PublicationMainComponent, children: [
      {
        path: '', component: PublicationListComponent, canActivate: [AuthGuard], data: {
          breadcrumb: 'Publicaciones',
        },
      },
      {
        path: 'femsa', component: PublicationIndexComponent, canActivate: [AuthGuard], data: {
          breadcrumb: 'Publicaciones guapetonas (:',
        },
      },
      {
        path: ':id', 
        component: PublicationBodyComponent, 
        canLoad: [PublicationGuard, AuthGuard], canActivate: [PublishedGuard], data: {
          breadcrumb: 'Publicación',
        },
      },
      {
        path: ':id/preview', 
        component: PublicationBodyPreviewComponent, canActivate: [AuthGuard], data: {
          breadcrumb: 'Publicación',
        },
      },
    ],
  },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    NgaModule,
    AngularFormsModule,
    AppTranslationModule,
    NgbModalModule,
    NgbRatingModule,
    DataTableModule,
    ReactiveFormsModule,
    PipesModule,
    BreadcrumbModule,
    // Flex
    FlexLayoutModule,
    // PrimeNG
    CalendarModule,
    ButtonModule,
    InputTextModule,
    DropdownModule,
    PanelModule,
    RatingModule,
    FieldsetModule,
    GrowlModule,
    InputTextareaModule,
    FileUploadModule,
    CheckboxModule,
    TabViewModule,
    ToolbarModule,
    InputMaskModule,
    SplitButtonModule,
    ChipsModule,
    ChartModule,
    // Material
    MatButtonModule,
    MatCheckboxModule,
    MatInputModule,
    MatSelectModule,
    MatTabsModule,
    MatCardModule,
    MatToolbarModule,
    MatIconModule,
    MatGridListModule,
    MatTableModule,
    MatPaginatorModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSnackBarModule,
    MatDialogModule,
    MatStepperModule,
    MatProgressSpinnerModule,
    MatChipsModule,
    MatSortModule,
    MatMenuModule,
    MatTooltipModule,
    MatSlideToggleModule,
    // ng2
    ChartsModule,
  ],
  declarations: [PublicationListComponent,
    PublicationIndexComponent,
    PublicationMainComponent,
    PublicationBodyComponent,
    DataFilterPipe,
    PublicationBodyPreviewComponent,
    PublicationModalComponent,
    PublicationModalEditComponent,
    PublicationTargetpriceModalEditComponent,
    PublicationSendModalComponent,
    PublicationDisableModalComponent,
    SendInfoComponent,
  ],
  entryComponents: [
    PublicationModalComponent,
    PublicationModalEditComponent,
    PublicationTargetpriceModalEditComponent,
    PublicationSendModalComponent,
    PublicationDisableModalComponent,
    SendInfoComponent,
  ],
  providers: [
    PublicationService,
    AdminGuard,
    PublicationGuard,
    PublishedGuard,
    [{ provide: MatPaginatorIntl, useClass: MatPaginatorIntlEs }],
  ],
})
export class PublicationModule { }

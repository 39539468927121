

import {CollectionViewer, DataSource} from "@angular/cdk/collections";
import {Observable} from "rxjs/Observable";
import {Publication} from '../models/publication';
import {PublicationService} from '../services/publication.service';
import {LoginService} from '../services/login.service';
import {BehaviorSubject} from "rxjs/BehaviorSubject";
import {catchError, finalize} from "rxjs/operators";
import {of} from "rxjs/observable/of";

import { ListResult } from 'app/shared/interfaces/list.result.interface';


export class PublicationData implements DataSource<Publication> {

    private publicationSubject = new BehaviorSubject<Publication[]>([]);

    private loadingSubject = new BehaviorSubject<boolean>(false);

    public loading$ = this.loadingSubject.asObservable();

    public dataRecords = [];

    constructor(private publicationService: PublicationService,
        private loginService: LoginService) {

    }

    loadPublications(searchString) {

        this.loadingSubject.next(true);

        this.publicationService.findPublications(searchString).pipe(
            catchError(() => of([])),
            finalize(() => this.loadingSubject.next(false))
        )
        .subscribe(publications => {
            this.dataRecords = publications;
            this.publicationSubject.next(publications);
        });

    }

    getRecords (){
        return this.dataRecords;
    }

    connect(collectionViewer: CollectionViewer): Observable<Publication[]> {
        return this.publicationSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.publicationSubject.complete();
        this.loadingSubject.complete();
    }

}

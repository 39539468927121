import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-catalog-main',
  templateUrl: './catalog-main.component.html',
  styleUrls: ['./catalog-main.component.scss']
})
export class CatalogMainComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

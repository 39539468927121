import { BreadcrumbModule } from './breadcrumb.module';
import { NgModule } from '@angular/core';
import { NgaModule } from './theme/nga.module';
import { Routes, RouterModule } from '@angular/router';
import { CommonModule, DatePipe } from '@angular/common';
import { AdminGuard } from './guards/admin.guard';
import { AuthGuard } from './guards/auth.guard';
import { PipesModule } from './pipes.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatButtonModule, MatCheckboxModule, MatInputModule, MatSelectModule, MatTabsModule,
  MatCardModule, MatToolbarModule, MatIconModule, MatGridListModule, MatTableModule,
  MatPaginatorModule, MatPaginatorIntl, MatNativeDateModule, MatSnackBarModule, MatDialogModule,
  MatStepperModule, MatProgressSpinnerModule, MatChipsModule, MatMenuModule,
} from '@angular/material';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSortModule } from '@angular/material/sort';
import { MatListModule } from '@angular/material/list';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatPaginatorIntlEs } from './shared/helpers/paginator.helper';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SummaryListComponent } from './summary-list/summary-list.component';
import { SummaryDetailComponent } from './summary-detail/summary-detail.component';
import { SummaryMainComponent } from './summary-main/summary-main.component';
import { SummaryModalComponent } from './summary-modal/summary-modal.component';
import { SummaryCreateComponent } from './summary-create/summary-create.component';
import { SummaryEditComponent } from './summary-edit/summary-edit.component';
import { SummaryModalSendComponent } from './summary-modal-send/summary-modal-send.component';
import { SummarySendComponent } from './summary-send/summary-send.component';

const routes: Routes = [
  {
    path: 'summary', component: SummaryMainComponent, children: [
      { path: '', component: SummaryListComponent, canActivate: [AdminGuard, AuthGuard], data: {
        breadcrumb: 'Resúmenes',
      } },
      { path: 'create', component: SummaryCreateComponent, canActivate: [AdminGuard, AuthGuard], data: {
        breadcrumb: 'Resúmenes',
      } },
      { path: 'send', component: SummarySendComponent, canActivate: [AdminGuard, AuthGuard], data: {
        breadcrumb: 'Resúmenes',
      } },
      { path: ':id', component: SummaryDetailComponent, canActivate: [AdminGuard, AuthGuard], data: {
        breadcrumb: 'Resumen',
      } },
      { path: ':id/publication/:pid', component: SummaryDetailComponent, canActivate: [AdminGuard, AuthGuard], data: {
        breadcrumb: 'Publicación',
      } },
      { path: ':id/edit', component: SummaryEditComponent, canActivate: [AdminGuard, AuthGuard], data: {
        breadcrumb: 'Resumen',
      } },
    ],
  },
];

@NgModule({
  imports: [
    CommonModule,
    NgaModule,
    RouterModule.forChild(routes),
    FormsModule,
    ReactiveFormsModule,
    BreadcrumbModule,
    PipesModule,
    // Flex
    FlexLayoutModule,
    MatButtonModule,
    MatCheckboxModule,
    MatInputModule,
    MatSelectModule,
    MatTabsModule,
    MatCardModule,
    MatToolbarModule,
    MatIconModule,
    MatGridListModule,
    MatTableModule,
    MatPaginatorModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSnackBarModule,
    MatDialogModule,
    MatStepperModule,
    MatProgressSpinnerModule,
    MatChipsModule,
    MatSortModule,
    MatMenuModule,
    MatTooltipModule,
    MatSlideToggleModule,
    MatListModule,
    MatExpansionModule,
  ],
  declarations: [
    SummaryListComponent,
    SummaryDetailComponent,
    SummaryMainComponent,
    SummaryModalComponent,
    SummaryCreateComponent,
    SummaryEditComponent,
    SummaryModalSendComponent,
    SummarySendComponent],
  entryComponents: [
    SummaryModalComponent,
    SummaryModalSendComponent,
  ],
  providers: [
    DatePipe,
  ],
})
export class SummaryModule { }
export class NewUser {
    constructor (
        public email: string,
        public name: string,
        public category: string,
        public lastname_Paternal: string,
        public lastname_Maternal: string,
        public role: string,
    ) { }
}

import { LoaderInterceptor } from './interceptors/loader-interceptor.service';
import { SummaryService } from './shared/services/summary.service';
import { EmailService } from './shared/services/email.service';
import { SearchService } from './shared/services/search.service';
import { CatalogModule } from './catalog.module';
import { AuthGuard } from './guards/auth.guard';
import { DashboardGuard } from './guards/dashboard.guard';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import * as jQuery from 'jquery';
/*
 * Platform and Environment providers/directives/pipes
 */
import { routing, routes } from './app.routing';

// App is our top level component
import { App } from './app.component';
import { AppState, InternalStateType } from './app.service';
import { GlobalState } from './global.state';
import { NgaModule } from './theme/nga.module';
import { PublicationModule } from './publication.module';
import { DashboardModule } from './dashboard.module';
import { LoginModule } from './login.module';
import { UserModule } from './user.module';
import { LoginService } from './shared/services/login.service';
import { SharedService } from './shared/shared.service';
import { SummaryModule } from './summary.module';
import { ParameterModule } from './parameter.module';
import { ParameterService } from 'app/shared/services/parameter.service';
import { SlimLoadingBarModule } from 'ng2-slim-loading-bar';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ContactModule } from './contact.module';
import { Globals } from './shared/globals';

import { MAT_SELECT_SCROLL_STRATEGY, MAT_DIALOG_DEFAULT_OPTIONS, MAT_DIALOG_DATA } from '@angular/material';
import { BlockScrollStrategy, Overlay } from '@angular/cdk/overlay';

export function scrollFactory(overlay: Overlay): () => BlockScrollStrategy {
  return () => overlay.scrollStrategies.block();
}


// Application wide providers
const APP_PROVIDERS = [
  AppState,
  GlobalState,
];

export type StoreType = {
  state: InternalStateType,
  restoreInputValues: () => void,
  disposeOldHosts: () => void,
};

/**
 * `AppModule` is the main entry point into Angular2's bootstraping process
 */
@NgModule({
  bootstrap: [App],
  declarations: [
    App,
  ],
  imports: [ // import Angular's modules
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    HttpModule,
    RouterModule.forRoot(routes),
    FormsModule,
    ReactiveFormsModule,
    NgaModule.forRoot(),
    NgbModule.forRoot(),
    PublicationModule,
    DashboardModule,
    LoginModule,
    CatalogModule,
    UserModule,
    SummaryModule,
    ParameterModule,
    ContactModule,
    routing,
    SlimLoadingBarModule.forRoot(),
  ],
  providers: [ // expose our Services and Providers into Angular's dependency injection
    APP_PROVIDERS,
    AuthGuard,
    DashboardGuard,
    LoginService,
    SearchService,
    SharedService,
    EmailService,
    SummaryService,
    ParameterService,
    Globals,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true,
    },
    [{ provide: MAT_SELECT_SCROLL_STRATEGY, useFactory: scrollFactory, deps: [Overlay] }],
    [
      { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { disableClose: true } },
      { provide: MAT_DIALOG_DATA, useValue: {} },
    ],
  ],
})

export class AppModule {

  constructor(public appState: AppState) {
  }
}

export class Analyst {
    constructor(
        public id: number,
        public bankId: number,
        public name: string,
        public lastname_Paternal: string,
        public lastname_Maternal: string,
        public email: string,
        public status: string,
        public ids: string,
    ) { }
}
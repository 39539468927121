import { BreadcrumbModule } from './breadcrumb.module';
import { PipesModule } from './pipes.module';
import { AuthGuard } from './guards/auth.guard';
import { DashboardGuard } from './guards/dashboard.guard';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardMainComponent } from './dashboard-main/dashboard-main.component';
import { DashboardBodyComponent } from './dashboard-body/dashboard-body.component';
import { Routes, RouterModule } from '@angular/router';
import { NgaModule } from './theme/nga.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppTranslationModule } from './app.translation.module';
import {
  MatButtonModule, MatCheckboxModule, MatInputModule, MatSelectModule, MatTabsModule,
  MatCardModule, MatToolbarModule, MatIconModule, MatGridListModule, MatTableModule,
  MatPaginatorModule, MatPaginatorIntl, MatNativeDateModule, MatSnackBarModule, MatDialogModule,
  MatStepperModule, MatProgressSpinnerModule, MatChipsModule, MatMenuModule,
} from '@angular/material';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSortModule } from '@angular/material/sort';
import { MatListModule } from '@angular/material/list';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatPaginatorIntlEs } from './shared/helpers/paginator.helper';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DashboardReaderComponent } from './dashboard-reader/dashboard-reader.component';
import { DashboardConfigModalComponent } from './dashboard-config-modal/dashboard-config-modal.component';
import { DashboardSearchResultsComponent } from './dashboard-search-results/dashboard-search-results.component';
import { DashboardIntroductionModalComponent } 
  from './dashboard-introduction-modal/dashboard-introduction-modal.component';

const routes: Routes = [
  {
    path: 'dashboard', component: DashboardMainComponent, children: [
      { path: '', component: DashboardBodyComponent, canActivate: [AuthGuard, DashboardGuard] },
      { path: 'reader', component: DashboardReaderComponent, canActivate: [AuthGuard] },
      { path: 'search', component: DashboardSearchResultsComponent, canActivate: [AuthGuard] },
    ],
  },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    NgaModule,
    AppTranslationModule,
    FormsModule,
    ReactiveFormsModule,
    BreadcrumbModule,
    PipesModule,
    // Flex
    FlexLayoutModule,
    // Material
    MatButtonModule,
    MatCheckboxModule,
    MatInputModule,
    MatSelectModule,
    MatTabsModule,
    MatCardModule,
    MatToolbarModule,
    MatIconModule,
    MatGridListModule,
    MatTableModule,
    MatPaginatorModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSnackBarModule,
    MatDialogModule,
    MatStepperModule,
    MatProgressSpinnerModule,
    MatChipsModule,
    MatSortModule,
    MatMenuModule,
    MatTooltipModule,
    MatListModule,
  ],
    declarations: [
      DashboardMainComponent,
      DashboardBodyComponent,
      DashboardReaderComponent,
      DashboardConfigModalComponent,
      DashboardSearchResultsComponent,
      DashboardIntroductionModalComponent],
   entryComponents: [
    DashboardConfigModalComponent,
    DashboardIntroductionModalComponent,
   ],
})
export class DashboardModule { }

import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { FormGroup, Validators, FormControl, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-publication-disable--modal',
  templateUrl: './publication-disable--modal.component.html',
  styleUrls: ['./publication-disable--modal.component.scss'],
})
export class PublicationDisableModalComponent implements OnInit {

  constructor(private _formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<PublicationDisableModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
  }

  disable() {    
    this.data = {
      disable: true,
    };
    this.dialogRef.close(this.data);
  }

  close() {
    this.dialogRef.close();
  }

}

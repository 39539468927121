import { Analyst } from '../shared/models/analyst';
import { Bank } from '../shared/models/bank';
import { PublicationService } from '../shared/services/publication.service';
import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { FormGroup, Validators, FormControl, NgForm, FormBuilder } from '@angular/forms';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { Observable } from 'rxjs/Rx';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-catalog-analyst-modal-edit',
  templateUrl: './catalog-analyst-modal-edit.component.html',
  styleUrls: ['./catalog-analyst-modal-edit.component.scss'],
})
export class CatalogAnalystModalEditComponent implements OnInit {
  @HostListener('wheel', ['$event'])
  handleWheelEvent(event) {
    event.preventDefault();
  }

  analyst: Analyst;
  bank: Bank;
  banks: Bank[];
  form: FormGroup;
  model: Analyst;

  constructor(
    fb: FormBuilder,
    public sanitizer: DomSanitizer,
    private publicationService: PublicationService,
    public dialogRef: MatDialogRef<CatalogAnalystModalEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

      this.model = data['analyst'];

      this.form = fb.group({
        'bank': [this.model.bankId, Validators.compose([Validators.required])],
        'name': ['', Validators.compose([Validators.required, Validators.minLength(2)])],
        'lastname_Paternal': ['', Validators.compose([Validators.required, Validators.minLength(2)])],
        'lastname_Maternal': ['', Validators.nullValidator],
        'email': ['', Validators.nullValidator],
      });

  }

  ngOnInit() {
    this.loadBanks();
  }

  // Category
  loadBanks() {
    this.publicationService.getCachedBanks().subscribe(
      banks => this.banks = banks['records'],
      err => {
      },
    );
  }

  save() {
    // this.model.status = 'active';
    this.data = {
      analyst: this.model,
    };
    this.dialogRef.close(this.data);
  }

  close() {
    this.dialogRef.close();
  }

}

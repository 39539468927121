import { PublicationService } from './../../../shared/services/publication.service';
import { Component, OnChanges, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalState } from '../../../global.state';
import { LoginService } from '../../../shared/services/login.service';
import { SearchService } from '../../../shared/services/search.service';
import { SharedService } from '../../../shared/shared.service';


@Component({
  selector: 'ba-page-top',
  templateUrl: './baPageTop.html',
  styleUrls: ['./baPageTop.scss'],
})
export class BaPageTop implements OnChanges {

  isScrolled: boolean = false;
  isMenuCollapsed: boolean = false;
  _isUserAdmin: Boolean = false;

  query: string;
  data: any;

  constructor(
    private _state: GlobalState,
    private loginService: LoginService,
    private publicationService: PublicationService,
    private searchService: SearchService,
    private router: Router,
    private sharedService: SharedService) {
    this._state.subscribe('menu.isCollapsed', (isCollapsed) => {
      this.isMenuCollapsed = isCollapsed;      
    });
    this._isUserAdmin = this.loginService.isUserAdmin();
  }

  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      if (this.query) {
        this.search();
      }
    }
  }

  ngOnChanges() {

  }

  toggleMenu() {
    this.isMenuCollapsed = !this.isMenuCollapsed;
    this._state.notifyDataChanged('menu.isCollapsed', this.isMenuCollapsed);
    return false;
  }

  scrolledChanged(isScrolled) {
    this.isScrolled = isScrolled;
  }

  logout() {
    this.publicationService.getUser(this.loginService.getUserId())
    .subscribe(user => {
      const _user = user;
      if (!this.loginService.isUserAdmin()) {
        _user.setPreferences = false;
        _user.setIntroduction = false;
        this.publicationService.updateUser(_user.id, _user).subscribe();
      }
      localStorage.removeItem('token');
      localStorage.removeItem('timer');
      this.router.navigate([`/login/`]); 
  }, err => {}/*, () => {
    localStorage.removeItem('token');
    localStorage.removeItem('timer');
    this.router.navigate([`/login/`]);
  }*/);

  }

  profile() {
    const userId = this.loginService.getUserId();
    this.router.navigate([`/user/`, userId]);
  }

  getUserName() {
    return this.loginService.userNameRole();
  }

  search() {
    this.sharedService.publishData(this.query);
    this.router.navigate([`/dashboard/search`]);
  }
}

import { DashboardIntroductionModalComponent }
  from './../dashboard-introduction-modal/dashboard-introduction-modal.component';
import { Component, OnInit, ViewChild, AfterViewInit, ElementRef, OnDestroy } from '@angular/core';
import { PublicationTag } from '../shared/interfaces/publication.tag.interface';
import { PublicationService } from '../shared/services/publication.service';
import { DatePipe } from '@angular/common';
import { LoginService } from '../shared/services/login.service';
import { PublicationBookmark } from '../shared/models/publication.bookmark';
import { Publication } from '../shared/models/publication';
import { SubCategory } from '../shared/models/subcategory';
import { Company } from '../shared/models/company';
import { Bank } from '../shared/models/bank';
import { Category } from '../shared/models/category';
import { Analyst } from '../shared/models/analyst';
import { User } from '../shared/models/user';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { saveAs as importedSaveAs } from 'file-saver';
import { filter } from 'rxjs/operators';
import {
  MatPaginator, MatTableDataSource, MatDialog, MatSort,
} from '@angular/material';
import { DashboardConfigModalComponent } from '../dashboard-config-modal/dashboard-config-modal.component';
import { Idle } from 'idlejs/dist';

@Component({
  selector: 'app-dashboard-reader',
  templateUrl: './dashboard-reader.component.html',
  styleUrls: ['./dashboard-reader.component.scss'],
})
export class DashboardReaderComponent implements OnInit, AfterViewInit, OnDestroy {

  analystList: Analyst[];
  bankList: Bank[];
  companyList: Company[];
  categoryList: Category[];
  subcategoryList: SubCategory[];

  showFilters: Boolean = true;
  searchTags: PublicationTag[] = [];
  rating: number;
  startDate: Date;
  endDate: Date;
  title = '';
  id: number;
  bank: Bank;
  analyst: Analyst;
  category: Category;
  subcategory: SubCategory;
  company: Company;
  banks: Bank[];
  analysts: Analyst[];
  categories: Category[];
  subcategories: SubCategory[];
  companies: Company[];

  userId: number;
  user: User;
  loading: Boolean = false;
  assetsEndpoint: string = environment.assetsEndpoint;

  userBankPublications: Publication[];
  publicationBookmarks: PublicationBookmark[];

  displayedColumnsPublications = [];
  dsPublications;
  @ViewChild('paginatorPublications') paginatorPublications: MatPaginator;
  @ViewChild('tablePublications', { read: MatSort }) sortPublications: MatSort;

  @ViewChild('dateInit') startDateInput:ElementRef;
  @ViewChild('dateEnd') endDateInput: ElementRef;

  constructor(
    private publicationService: PublicationService,
    private loginService: LoginService,
    private router: Router,
    public dialog: MatDialog) { }

  ngOnInit() {
    this.userId = this.loginService.getUserId();
    this.publicationService.getUser(this.userId)
      .subscribe(user => {
        this.user = user;

        if (!this.user.setPreferencesAfterSession) {
          if (!this.user.setPreferences) {
            this.showConfigModal();
          }
        }

        if (!this.user.setIntroductionAfterSession) {
          if (!this.user.setIntroduction) {
            this.showIntroductionModal();
          }
        }

      });
    this.displayedColumnsPublications = [
      'eye_id', 'title', 'date', 'bankName',
      'analystName', 'pages', 'categoryName',
      'subcategoryName', 'companyName'];

    this.loadBanks();
    this.loadAnalysts();
    this.loadCategories();
    this.loadSubCategories();
    this.loadCompanies();

    const idle = new Idle()
    .whenNotInteractive()
    .within(parseInt(localStorage.getItem('timer')))
    .do(() => this.router.navigate(['login']))
    .start();
  }

  ngOnDestroy(){
    this.dialog.closeAll();
  }

  ngAfterViewInit() {

    this.search();
    this.loadPreferencesPublications();
  }

  showIntroductionModal() {
    const dialogRef = this.dialog.open(DashboardIntroductionModalComponent, {
      height: '650px',
      width: '800px',
      closeOnNavigation: true,
      disableClose: true,
    });

    dialogRef.afterClosed()
      .pipe(filter(data => data))
      .subscribe(data => {
        if (data['setIntroduction']) {
          const userUpdated = this.user;
          userUpdated.setIntroductionAfterSession = true;
          this.publicationService
            .updateUser(this.userId, userUpdated)
            .subscribe(user => this.user = user);
        }
      }, err => {
      }, () => {
        const userUpdated = this.user;
        userUpdated.setIntroduction = true;
        this.publicationService
          .updateUser(this.userId, userUpdated)
          .subscribe(user => this.user = user);
      },
    );
  }

  showConfigModal() {
    const dialogRef = this.dialog.open(DashboardConfigModalComponent, {
      height: '650px',
      width: '800px',
      closeOnNavigation: true,
      disableClose: true,
      data: {
        userId: this.userId,
      },
    });

    dialogRef.afterClosed()
      .pipe(filter(data => data))
      .subscribe(data => {
        this.publicationService.deleteUserBanks(this.userId)
          .subscribe(() => { }, () => { }, () => {
            for (let id in data['bankIds']) {
              const userBank = { userId: this.userId, bankId: data['bankIds'][id] };
              this.publicationService.postUserBank(this.userId, userBank).subscribe();
            }
          },
        );

        this.publicationService.deleteUserAnalysts(this.userId)
          .subscribe(() => { }, () => { }, () => {
            for (let id in data['analystIds']) {
              const userAnalyst = { userId: this.userId, analystId: data['analystIds'][id] };
              this.publicationService.postUserAnalyst(this.userId, userAnalyst).subscribe();
            }
          },
        );

        this.publicationService.deleteUserCompanies(this.userId)
          .subscribe(() => { }, () => { }, () => {
            for (let id in data['companyIds']) {
              const userCompany = { userId: this.userId, companyId: data['companyIds'][id] };
              this.publicationService.postUserCompany(this.userId, userCompany).subscribe();
            }
          },
        );

        this.publicationService.deleteUserCategories(this.userId)
          .subscribe(() => { }, () => { }, () => {
            for (let id in data['categoryIds']) {
              const userCategory = { userId: this.userId, categoryId: data['categoryIds'][id] };
              this.publicationService.postUserCategory(this.userId, userCategory).subscribe();
            }
          },
        );

        if (data['setPreferences']) {
          const userUpdated = this.user;
          userUpdated.setPreferencesAfterSession = true;
          this.publicationService
            .updateUser(this.userId, userUpdated)
            .subscribe(user => this.user = user);
        }
      }, err => {
      }, () => {
        const userUpdated = this.user;
        userUpdated.setPreferences = true;
        this.publicationService
          .updateUser(this.userId, userUpdated)
          .subscribe(user => this.user = user);
          setTimeout(() => {
            this.loadPreferencesPublications();
          }, 2500);
      },
    );
  }

  loadBookmarks() {
    this.publicationService.getUserBookmarks(this.loginService.getUserId())
      .subscribe(publicationBookmarks => {
        this.publicationBookmarks = publicationBookmarks;
      }, () => {
      }, () => {
      });
  }

  loadPreferencesPublications() {
    this.publicationService.getUserPreferencesBankPublications(this.loginService.getUserId())
      .subscribe(publications => {
        this.userBankPublications = publications.filter(publication => publication.published);
        this.dsPublications =
          new MatTableDataSource<Publication>(this.userBankPublications);
        this.dsPublications.paginator = this.paginatorPublications;
        this.dsPublications.sort = this.sortPublications;

      }, () => {
      }, () => {
      });
  }

  // Bank
  loadBanks() {
    this.publicationService.getCachedBanksSystem().subscribe(
      banks => this.banks = banks,
      () => {
      },
    );
  }

  // Analyst
  loadAnalysts() {
    this.publicationService.getCachedAnalystsSystem().subscribe(
      analysts => this.analysts = analysts,
      () => {
      },
    );
  }

  // Category
  loadCategories() {
    this.publicationService.getCategoriesSystem().subscribe(
      categories => this.categories = categories,
      () => {
      },
    );
  }

  // Company
  loadCompanies() {
    this.publicationService.getCachedCompaniesSystem().subscribe(
      companies => this.companies = companies,
      () => {
      },
    );
  }

  // Sub category
  loadSubCategories() {
    this.publicationService.getAllSubCategoriesSystem().subscribe(
      subcategories => this.subcategories = subcategories,
      () => {
      },
    );
  }

  // Chip events
  dropDownBankChange() {
    this.searchTags = this.searchTags.filter(item => item.name !== 'bank');
    this.searchTags.push({ name: 'bank', value: this.bankList.map(bank => bank.name).join(', ') });
  }

  dropDownCompanyChange() {
    this.searchTags = this.searchTags.filter(item => item.name !== 'company');
    this.searchTags.push({ name: 'company', value: this.companyList.map(company => company.name).join(', ') });
  }

  dropDownAnalystChange() {
    this.searchTags = this.searchTags.filter(item => item.name !== 'analyst');
    this.searchTags.push({ name: 'analyst', value: this.analystList.map(analyst => analyst['fullName']).join(', ') });
  }

  dropDownCategoryChange() {
    this.searchTags = this.searchTags.filter(item => item.name !== 'category');
    this.searchTags.push({ name: 'category', value: this.categoryList.map(category => category.name).join(', ') });
  }

  dropDownSubCategoryChange() {
    this.searchTags = this.searchTags.filter(item => item.name !== 'subcategory');
    this.searchTags
      .push({ name: 'subcategory', value: this.subcategoryList.map(subcategory => subcategory.name).join(', ') });
  }

  rateChange() {
    this.searchTags = this.searchTags.filter(item => item.name !== 'rating');
    this.searchTags.push({ name: 'rating', value: this.rating });
  }

  rateCancel() {
    this.searchTags = this.searchTags.filter(item => item.name !== 'rating');
  }

  titleChange() {
    if (this.title != '') {
      this.searchTags = this.searchTags.filter(item => item.name !== 'title');
      this.searchTags.push({ name: 'title', value: this.title });
    }
  }

  startDateChange() {
    this.searchTags = this.searchTags.filter(item => item.name !== 'date1');
    const datePipe = new DatePipe('en');
    this.searchTags.push({ name: 'date1', value: datePipe.transform(this.startDate, 'yyyy-MM-dd') });
  }

  endDateChange() {
    this.searchTags = this.searchTags.filter(item => item.name !== 'date2');
    const datePipe = new DatePipe('en');
    this.searchTags.push({ name: 'date2', value: datePipe.transform(this.endDate, 'yyyy-MM-dd') });
  }

  goToPreview(id: number) {
    this.router.navigate([`/publication/${id}/preview`]);
  }

  getImageUrl(publicationId) {
    let url;
    this.publicationService
      .getPublicationImage(publicationId)
      .subscribe(publicationImage => {
        url = `${environment.assetsEndpoint}/${publicationImage.url}`;
      }, () => {
        // url = `${environment.assetsEndpoint}/img/publication/no_disponible.png`;
        url = 'http://placehold.it/350x200';
      },
    );

    return url;
  }

  deleteBookmark(publicationId) {
    this.publicationService
      .deleteBookmark(publicationId, this.loginService.getUserId())
      .subscribe(() => {
        this.search();
      },
    );
  }

  downloadFile(publicationId, fileName) {
    this.publicationService
      .downloadFile(publicationId)
      .subscribe(
        blob => {
          importedSaveAs(blob, fileName);

          let publicationDownloadOperation: Observable<Object>;
          publicationDownloadOperation = this.publicationService.postDownload(
            publicationId, { publicationid: publicationId, userid: this.loginService.getUserId(), date: new Date() });

          publicationDownloadOperation.subscribe(
            () => {
              // this.openSnackBar('Descargar registrada');
            },
            err => {
            }, () => {
            },
          );
        },
    );
  }

  search() {
    this.publicationService.searchUserBookmarkedPublications(this.loginService.getUserId(), this.buildSearchString())
      .subscribe(
        publicationBookmarks => {
          this.publicationBookmarks = publicationBookmarks;
        },
        () => {

        }, () => {
        },
    );
  }

  buildSearchString() {
    let result = `bookmarkUserId=${this.loginService.getUserId()}&`;

    if (this.title) {
      result += `title=${this.title}&`;
    }
    if (this.bankList) {
      this.bankList.forEach(bank => {
        result += `bankId=${bank.id}&`;
      });
    }
    if (this.analystList) {
      this.analystList.forEach(analyst => {
        result += `analystId=${analyst.id}&`;
      });
    }
    if (this.categoryList) {
      this.categoryList.forEach(category => {
        result += `categoryId=${category.id}&`;
      });
    }
    if (this.subcategoryList) {
      this.subcategoryList.forEach(subcategory => {
        result += `subcategoryId=${subcategory.id}&`;
      });
    }
    if (this.companyList) {
      this.companyList.forEach(company => {
        result += `companyId=${company.id}&`;
      });
    }
    if (this.rating) {
      result += `rating=${this.rating}&`;
    }
    if (this.startDate && this.endDate) {
      const datePipe = new DatePipe('en');
      result += `date1=${datePipe.transform(this.startDate, 'yyyy-MM-dd')}&`;
      result += `date2=${datePipe.transform(this.endDate, 'yyyy-MM-dd')}`;
    } else {
      result += 'date1=2000-01-01&date2=2099-01-01';
    }

    return result;
  }

  clear() {
    // this.publicationService
    //   .getPublications(this.loginService.getUserId())
    //   .subscribe(
    //     publications => {
    //       this.userBankPublications = publications.filter(publication => publication.published === true);
    //       this.dsPublications =
    //         new MatTableDataSource<Publication>(this.userBankPublications);
    //       this.dsPublications.paginator = this.paginatorPublications;
    //       this.dsPublications.sort = this.sortPublications;
    //     },
    //     err => {
    //     }, () => {
    //     },
    // );
    // Clear filter fields
    this.id = null;
    this.title = '';
    this.bank = null;
    this.analyst = null;
    this.category = null;
    this.subcategory = null;
    this.company = null;
    this.bankList = null;
    this.analystList = null;
    this.categoryList = null;
    this.subcategoryList = null;
    this.companyList = null;
    this.rating = null;
    this.startDate = null;
    this.endDate = null;

    this.searchTags = [];

    this.searchPreferencesPublications();
  }

  setCardHeaderStyle(imgUrl: string, id: number) {
    let url = '';
    if (imgUrl !== '') {
      url = `${environment.s3Endpoint}/publicaciones/${id}/img/${imgUrl}`;
    } else {
      url = `${environment.s3Endpoint}/app/img/no_disponible.png`;
    }

    const styles = {
      'background-image': `url(${url})`,
      'background-size': 'cover',
    };

    return styles;
  }

  removeTag(searchTag: any) {
    const index = this.searchTags.indexOf(searchTag);

    if (index >= 0) {
      this.searchTags.splice(index, 1);
    }

    switch (searchTag.name) {
      case 'bank':
        this.searchTags = this.searchTags.filter(item => item.name !== 'bank');
        this.bank = null;
        this.bankList = null;
        break;
      case 'company':
        this.searchTags = this.searchTags.filter(item => item.name !== 'company');
        this.company = null;
        this.companyList = null;
        break;
      case 'analyst':
        this.searchTags = this.searchTags.filter(item => item.name !== 'analyst');
        this.analyst = null;
        this.analystList = null;
        break;
      case 'category':
        this.searchTags = this.searchTags.filter(item => item.name !== 'category');
        this.category = null;
        this.categoryList = null;
        break;
      case 'subcategory':
        this.searchTags = this.searchTags.filter(item => item.name !== 'subcategory');
        this.subcategory = null;
        this.subcategoryList = null;
        break;
      case 'rating':
        this.searchTags = this.searchTags.filter(item => item.name !== 'rating');
        this.rating = 0;
        break;
      case 'id':
        this.searchTags = this.searchTags.filter(item => item.name !== 'id');
        this.id = null;
        break;
      case 'title':
        this.searchTags = this.searchTags.filter(item => item.name !== 'title');
        this.title = '';
        break;
      case 'date1':
        this.searchTags = this.searchTags.filter(item => item.name !== 'date1');
        this.startDate = null;
        break;
      case 'date2':
        this.searchTags = this.searchTags.filter(item => item.name !== 'date2');
        this.endDate = null;
        break;
    }

    this.publicationService.searchUserBookmarkedPublications(this.loginService.getUserId(), this.buildSearchString())
      .subscribe(
        publicationBookmarks => {
          this.loading = true;
          this.publicationBookmarks = publicationBookmarks;
        },
        () => {
          this.loading = false;
        }, () => {
          this.loading = false;
        },
    );
  }

  searchPreferencesPublications() {
    this.search();

    this.publicationService.searchDashboard(this.userId, this.buildSearchString()).subscribe(
      publications => {
        this.userBankPublications = publications.filter(publication => publication.published);
        this.dsPublications =
          new MatTableDataSource<Publication>(this.userBankPublications);
        this.dsPublications.paginator = this.paginatorPublications;
        this.dsPublications.sort = this.sortPublications;
      },
      () => {
      },
    );
  }

  toggleFilters() {
    this.showFilters = (this.showFilters) ? false : true;
  }

  removeAll(type: string){
    if(type === 'start'){
      this.startDateInput.nativeElement.value = '';
    } else {
      this.endDateInput.nativeElement.value = '';
    }
  }

}

import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, Validators, FormControl, FormBuilder } from '@angular/forms';
import { Currency } from '../shared/models/currency';

@Component({
  selector: 'app-parameter-currency-edit',
  templateUrl: './parameter-currency-edit.component.html',
  styleUrls: ['./parameter-currency-edit.component.scss'],
})
export class ParameterCurrencyEditComponent implements OnInit {

  form: FormGroup;
  model: Currency;

  constructor(
    fb: FormBuilder,
    public dialogRef: MatDialogRef<ParameterCurrencyEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.model = data['currency'];

    this.form = fb.group({
      'name': ['', Validators.compose([Validators.required, Validators.minLength(2)])],
    });
  }


  ngOnInit() {
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    this.data = {
      id: this.model.id,
      currency: this.model,
    };
    this.dialogRef.close(this.data);
  }

}

import { NewStockImage } from './../shared/models/new/stockimage.new';
import { Component, OnInit, Inject, ViewChild, ElementRef, HostListener } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, Validators, FormControl, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-parameter-stock-image-modal',
  templateUrl: './parameter-stock-image-modal.component.html',
  styleUrls: ['./parameter-stock-image-modal.component.scss']
})
export class ParameterStockImageModalComponent implements OnInit {
  @HostListener('wheel', ['$event'])
  handleWheelEvent(event) {
    event.preventDefault();
  }
  
  @ViewChild('divImgFile')
  divImgFile: ElementRef;
  imgSrc: string;
  @ViewChild('imgFileInput')
  imgFile: ElementRef;
  imgUrl: string = 'http://via.placeholder.com/200x500';

  form: FormGroup;
  model = new NewStockImage(null, null);

  constructor(
    fb: FormBuilder,
    public dialogRef: MatDialogRef<ParameterStockImageModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.form = fb.group({
      'name': ['', Validators.compose([Validators.required, Validators.minLength(2)])],
      'selectedImg': [{ value: '', disabled: true }, Validators.compose([Validators.required, Validators.minLength(2)])],
    });
  }

  close() {
    this.dialogRef.close();
  }

  ngOnInit() {
  }

  save() {
    this.data = {
      stockImageName: this.model.name,
      imgFile: this.imgFile.nativeElement.files[0],
    };
    this.dialogRef.close(this.data);
  }

  fileChangeEvent(fileInput: any) {
    if (fileInput.target.files && fileInput.target.files[0]) {
      const reader = new FileReader();

      reader.onload = ((e) => {
        this.imgUrl = e.target['result'];
      });
      const file = fileInput.target.files[0];
      this.imgSrc = file.name;
      // const img = this.divImgFile.nativeElement;
      // img.getElementsByTagName('span')[0].innerText = '';
      // img.getElementsByTagName('span')[0].innerHTML = '';

      reader.readAsDataURL(fileInput.target.files[0]);
    }
  }

}

import { Component, ViewContainerRef, OnInit, AfterViewInit } from '@angular/core';

import { GlobalState } from './global.state';
import { BaImageLoaderService, BaThemePreloader, BaThemeSpinner } from './theme/services';
// import { BaThemeConfig } from './theme/theme.config';
import { BaMenuService } from './theme';
import { PAGES_MENU } from './app.menu';
import { PAGES_MENU_READER } from './app.reader.menu';
import { Router, Routes } from '@angular/router';
import { LoginService } from './shared/services/login.service';
import * as $ from 'jquery';

/*
 * App Component
 * Top Level Component
 */
@Component({
    selector: 'app',
    styleUrls: ['./app.component.scss'],
    template: `
        <main [class.menu-collapsed]="isMenuCollapsed" baThemeRun>
            <div class="additional-bg">
            </div>
            <ng2-slim-loading-bar></ng2-slim-loading-bar>
            <router-outlet>
            </router-outlet>
        </main>
    `
})
export class App implements OnInit, AfterViewInit {

    ngOnInit() {
        if (this.loginService.isUserAdmin()) {
            this._menuService.updateMenuByRoutes(<Routes>PAGES_MENU);
            if (this.loginService.isTokenExpired()) {
                localStorage.removeItem('token');
                this.router.navigate(['login']);
            }
        } else {
            this._menuService.updateMenuByRoutes(<Routes>PAGES_MENU_READER);
            if (this.loginService.isTokenExpired()) {
                localStorage.removeItem('token');
                this.router.navigate(['login']);
            }
        }
    }

    isMenuCollapsed: boolean = false;

    constructor(private _state: GlobalState,
        // private _imageLoader: BaImageLoaderService,
        private _spinner: BaThemeSpinner,
        private viewContainerRef: ViewContainerRef,
        // private themeConfig: BaThemeConfig,
        private _menuService: BaMenuService,
        private loginService: LoginService,
        private router: Router) {

        // themeConfig.config();
        // this._loadImages();

        this._state.subscribe('menu.isCollapsed', (isCollapsed) => {
            this.isMenuCollapsed = isCollapsed;
        });
    }

    ngAfterViewInit(): void {
        // hide spinner once all loaders are completed
        BaThemePreloader.load().then((values) => {
            this._spinner.hide();
        });
    }

    private _loadImages(): void {
        // register some loaders
        // BaThemePreloader.registerLoader(this._imageLoader.load('assets/img/sky-bg.jpg'));
    }    

}

export class Company {
    constructor(
        public id: number,
        public name: string,
        public categoryId: number,
        public subCategoryId: number,
        public logo: string,
        public status: string,
    ) { }
}

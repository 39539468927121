import { BreadcrumbModule } from './breadcrumb.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContactMainComponent } from './contact-main/contact-main.component';
import { ContactBodyComponent } from './contact-body/contact-body.component';
import { NgaModule } from './theme/nga.module';
import {
  MatButtonModule, MatCheckboxModule, MatInputModule, MatSelectModule, MatTabsModule,
  MatCardModule, MatToolbarModule, MatIconModule, MatGridListModule, MatTableModule,
  MatPaginatorModule, MatPaginatorIntl, MatNativeDateModule, MatSnackBarModule, MatDialogModule,
  MatStepperModule, MatProgressSpinnerModule, MatChipsModule, MatMenuModule,
} from '@angular/material';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatListModule } from '@angular/material/list';
import { MatSortModule } from '@angular/material/sort';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { FlexLayoutModule } from '@angular/flex-layout';

const routes: Routes = [
  {
    path: 'contact', component: ContactMainComponent, children: [
      { path: '', component: ContactBodyComponent, canActivate: [AuthGuard], data: {
        breadcrumb: 'Contacto',
      } },
    ],
  },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    NgaModule,
    FormsModule,
    ReactiveFormsModule,
    BreadcrumbModule,
    // Flex
    FlexLayoutModule,
    // Material
    MatButtonModule,
    MatCheckboxModule,
    MatInputModule,
    MatSelectModule,
    MatTabsModule,
    MatCardModule,
    MatToolbarModule,
    MatIconModule,
    MatGridListModule,
    MatTableModule,
    MatPaginatorModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSnackBarModule,
    MatDialogModule,
    MatStepperModule,
    MatProgressSpinnerModule,
    MatChipsModule,
    MatSortModule,
    MatMenuModule,
    MatListModule,
    MatSlideToggleModule,
    MatExpansionModule,
  ],
  declarations: [
    ContactMainComponent, 
    ContactBodyComponent,
  ],
})
export class ContactModule { }

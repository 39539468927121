import { SearchService } from './../shared/services/search.service';
import { Component, OnInit, AfterViewInit, ViewChild, Input, OnChanges } from '@angular/core';
import {
  MatPaginator, MatTableDataSource, MatSort,
} from '@angular/material';
import { Publication } from '../shared/models/publication';
import { Analyst } from '../shared/models/analyst';
import { Bank } from '../shared/models/bank';
import { SharedService } from '../shared/shared.service';

@Component({
  selector: 'app-dashboard-search-results',
  templateUrl: './dashboard-search-results.component.html',
  styleUrls: ['./dashboard-search-results.component.scss'],
})
export class DashboardSearchResultsComponent implements OnInit, AfterViewInit, OnChanges {

  loading: Boolean;
  query: string;
  data: any[];

  dsPublications;
  displayedColumnsPublications = [];
  @ViewChild('paginatorPublications') paginatorPublications: MatPaginator;
  @ViewChild('tablePublications', { read: MatSort }) sortPublications: MatSort;

  dsAnalysts;
  displayedColumnsAnalysts = [];
  @ViewChild('paginatorAnalysts') paginatorAnalysts: MatPaginator;
  @ViewChild('tableAnalysts', { read: MatSort }) sortAnalysts: MatSort;

  dsBanks;
  displayedColumnsBanks = [];
  @ViewChild('paginatorBanks') paginatorBanks: MatPaginator;
  @ViewChild('tableBanks', { read: MatSort }) sortBanks: MatSort;

  dsCompanies;
  displayedColumnsCompanies = [];
  @ViewChild('paginatorCompanies') paginatorCompanies: MatPaginator;
  @ViewChild('tableCompanies', { read: MatSort }) sortCompanies: MatSort;

  dsCategories;
  displayedColumnsCategories = [];
  @ViewChild('paginatorCategories') paginatorCategories: MatPaginator;
  @ViewChild('tableCategories', { read: MatSort }) sortCategories: MatSort;

  constructor(
    private searchService: SearchService,
    private sharedService: SharedService,
  ) {
  }

  ngOnInit() {
    this.displayedColumnsPublications = ['eye_id', 'title', 'date',
      'bank', 'analyst', 'pages', 'category', 'subCategory', 'company', 'rating'];
    this.displayedColumnsAnalysts = ['analyst_eye_id', 'analyst_name'];
    this.displayedColumnsBanks = ['bank_eye_id', 'bank'];
    this.displayedColumnsCompanies = ['company_eye_id', 'company'];
    this.displayedColumnsCategories = ['category'];
  }

  ngOnChanges() {
  }

  ngAfterViewInit() {
    this.sharedService.query$.subscribe(
      query => {
        this.loading = true;
        this.query = query;
        this.searchService.search(this.query)
          .finally(() => this.loading = false)
          .subscribe(data => {
            this.loading = true;
            this.data = data;

            this.dsPublications =
              new MatTableDataSource<Publication>(this.data['publications']);
            this.dsPublications.paginator = this.paginatorPublications;
            this.dsPublications.sort = this.sortPublications;

            this.dsAnalysts =
              new MatTableDataSource<Analyst>(this.data['analysts']);
            this.dsAnalysts.paginator = this.paginatorAnalysts;
            this.dsAnalysts.sort = this.sortAnalysts;

            this.dsBanks =
              new MatTableDataSource<Bank>(this.data['banks']);
            this.dsBanks.paginator = this.paginatorBanks;
            this.dsBanks.sort = this.sortBanks;

            this.dsCompanies =
              new MatTableDataSource<Bank>(this.data['companies']);
            this.dsCompanies.paginator = this.paginatorCompanies;
            this.dsCompanies.sort = this.sortCompanies;

            this.dsCategories =
              new MatTableDataSource<Bank>(this.data['categories']);
            this.dsCategories.paginator = this.paginatorCategories;
            this.dsCategories.sort = this.sortCategories;

          }, err => {
            this.loading = false;
        });
      });
  }

}

import { Bank } from './../shared/models/bank';
import { PublicationService } from '../shared/services/publication.service';
import { Component, Input, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { FormGroup, AbstractControl, Validators, FormControl, NgForm, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-catalog-bank-modal-edit',
  templateUrl: './catalog-bank-modal-edit.component.html',
  styleUrls: ['./catalog-bank-modal-edit.component.scss'],
})
export class CatalogBankModalEditComponent implements OnInit {

  form: FormGroup;
  bank: Bank;
  @ViewChild('imgFileInput')
  imgFile: any;
  model: Bank;
  imgSrc: string;
  @ViewChild('divImgFile')
  divImgFile: ElementRef;

  constructor(
    fb: FormBuilder,
    public sanitizer: DomSanitizer,
    private publicationService: PublicationService,
    public dialogRef: MatDialogRef<CatalogBankModalEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

      this.model = data['bank'];
      this.imgSrc = this.model.logo;

    this.form = fb.group({
      name: [this.model.name, Validators.compose([Validators.required, Validators.minLength(2)])],
      'selectedImage': [{ value: '', disabled: true }, Validators.compose([Validators.nullValidator])],
    });
  }

  ngOnInit() {
  }

  save() {
    // this.model.status = 'active';
    this.data = {
      bank: this.model,
      imgFile: this.imgFile.nativeElement.files[0],
    };
    this.dialogRef.close(this.data);
  }

  close() {
    this.dialogRef.close();
  }

  fileChangeEvent(fileInput: any) {
    if (fileInput.target.files && fileInput.target.files[0]) {
      const file = fileInput.target.files[0];
      this.imgSrc = file.name;
      
      const img = this.divImgFile.nativeElement;
      img.getElementsByTagName('span')[0].innerText = '';
      img.getElementsByTagName('span')[0].innerHTML = '';

    }
  }

}

import { Component, OnInit, AfterViewInit, ViewChild, OnDestroy, ElementRef } from '@angular/core';
import { PublicationService } from '../shared/services/publication.service';
import { User } from '../shared/models/user';
import { NewUser } from '../shared/models/new/user.new';
import {
  MatPaginator, MatTableDataSource, MatPaginatorIntl,
  MatSnackBar, MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSort,
} from '@angular/material';
import { filter } from 'rxjs/operators';
import { saveAs as importedSaveAs } from 'file-saver';
import { UserModalComponent } from '../user-modal/user-modal.component';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { Status } from '../shared/models/helpers/status';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss'],
})
export class UserListComponent implements OnInit, AfterViewInit, OnDestroy {

  showFilters: Boolean = true;
  userId: number;
  userName: string;
  startDate: Date;
  endDate: Date;

  loading: Boolean = false;
  selectedItems: string[] = [];
  status: any;
  statuses: any;
  userType: any;
  userTypes: any;
  users: User[];
  st: Status;

  searchTags: any[] = [];

  dsUsers;
  displayedColumnsUsers = [];
  @ViewChild('paginatorUsers') paginatorUsers: MatPaginator;
  @ViewChild('tableUsers', { read: MatSort }) sortUsers: MatSort;

  @ViewChild('dateInit') startDateInput:ElementRef;
  @ViewChild('dateEnd') endDateInput: ElementRef;

  constructor(
    private publicationService: PublicationService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private router: Router) { }

  ngOnInit() {
    this.displayedColumnsUsers = [
      'eye_id', 'name', 'email', 'category', 'role', 'lastAccessDate', 'statusF'];

    this.loadUserTypes();
    this.loadStatuses();
  }

  ngOnDestroy(){
    this.dialog.closeAll();
  }

  ngAfterViewInit() {
    this.loadUsers();
  }

  removeAll(type: string){
    if(type === 'start'){
      this.startDateInput.nativeElement.value = '';
    } else {
      this.endDateInput.nativeElement.value = '';
    }
    
  }

  loadUsers() {
    this.publicationService.getUsers().subscribe(
      users => {
        this.users = users;
        this.dsUsers =
          new MatTableDataSource<User>(this.users);
        this.dsUsers.paginator = this.paginatorUsers;
        this.dsUsers.sort = this.sortUsers;
      },
      err => {
        this.openSnackBar(err);
      },
    );
  }

  // Status
  loadUserTypes() {
    this.userTypes = [
      { label: 'Administrador', value: 'Admin' },
      { label: 'Lector', value: 'Reader' },
    ];
  }

  // Status
  loadStatuses() {
    this.statuses = [
      { label: 'Activo', value: 'active' },
      { label: 'Inactivo', value: 'inactive' },
    ];
  }

  clear() {
    this.loadUsers();

    this.userId = null;
    this.userName = null;
    this.status = null;
    this.userType = null;
    this.startDate = null;
    this.endDate = null;

    this.searchTags = [];
  }

  search() {
    this.publicationService.searchUser(this.buildSearchString()).subscribe(
      users => {
        this.dsUsers =
          new MatTableDataSource<User>(users);
        this.dsUsers.paginator = this.paginatorUsers;
        this.dsUsers.sort = this.sortUsers;
      },
      err => {
        this.openSnackBar(err);
      },
    );
  }

  getExcel() {
    this.publicationService
      .getUserExcel(this.buildSearchString())
      .subscribe(blob => {
        importedSaveAs(blob, 'Usuarios.xlsx');
      },
        err => {
          this.openSnackBar(err);
        },
        () => {
        },
    );
  }

  buildSearchString() {
    let result = '';
    
    if (this.userName) {
      result += `fullName=${this.userName}&`;
    }
    if (this.status) {
      result += `status=${this.status.value}&`;
    }
    if (this.userType) {
      result += `role=${this.userType.value}&`;
    }
    if (this.startDate && this.endDate) {
      const datePipe = new DatePipe('en');
      result += `lastAccessDate1=${datePipe.transform(this.startDate, 'yyyy-MM-dd')}&`;
      result += `lastAccessDate2=${datePipe.transform(this.endDate, 'yyyy-MM-dd')}`;
    } else {
      result += 'lastAccessDate1=0001-01-01&lastAccessDate2=2099-01-01';
    }

    return result;
  }

  // Chips
  dropDownStatusChange() {
    this.searchTags = this.searchTags.filter(item => item.name !== 'status');
    this.searchTags.push({ name: 'status', value: this.status.label });
  }

  dropDownUserTypeChange() {
    this.searchTags = this.searchTags.filter(item => item.name !== 'role');
    this.searchTags.push({ name: 'role', value: this.userType.label });
  }

  userChange(e) {
    if (this.userName != '') {
      this.searchTags = this.searchTags.filter(item => item.name !== 'name');
      this.searchTags.push({ name: 'name', value: this.userName });
    }    
  }

  startDateChange(event) {
    this.searchTags = this.searchTags.filter(item => item.name !== 'date1');
    const datePipe = new DatePipe('en');
    this.searchTags.push({ name: 'date1', value: datePipe.transform(this.startDate, 'yyyy-MM-dd') });
  }

  endDateChange(event) {
    this.searchTags = this.searchTags.filter(item => item.name !== 'date2');
    const datePipe = new DatePipe('en');
    this.searchTags.push({ name: 'date2', value: datePipe.transform(this.endDate, 'yyyy-MM-dd') });
  }

  removeTag(searchTag: any) {
    const index = this.searchTags.indexOf(searchTag);

    if (index >= 0) {
      this.searchTags.splice(index, 1);
    }

    switch (searchTag.name) {
      case 'name':
        this.searchTags = this.searchTags.filter(item => item.name !== 'name');
        this.userName = null;
        break;
      case 'status':
        this.searchTags = this.searchTags.filter(item => item.name !== 'status');
        this.status = null;
        break;
      case 'role':
        this.searchTags = this.searchTags.filter(item => item.name !== 'role');
        this.userType = null;
        break;
      case 'id':
        this.searchTags = this.searchTags.filter(item => item.name !== 'id');
        this.userId = null;
        break;
      case 'date1':
        this.searchTags = this.searchTags.filter(item => item.name !== 'date1');
        this.startDate = null;
        break;
      case 'date2':
        this.searchTags = this.searchTags.filter(item => item.name !== 'date2');
        this.endDate = null;
        break;
    }

    this.publicationService.searchUser(this.buildSearchString()).subscribe(
      users => {
        this.dsUsers =
          new MatTableDataSource<User>(users);
        this.dsUsers.paginator = this.paginatorUsers;
        this.dsUsers.sort = this.sortUsers;
      },
      err => {
        this.openSnackBar(err);
      },
      () => {
      },
    );
  }

  onChange(event) {
    if (!(event.keyCode === 8 || event.keyCode === 46)) {
      return false;
    }

  }

  showCreateModal() {
    const dialog = this.dialog.open(UserModalComponent, {
      height: '500px',
      width: '800px',
      closeOnNavigation: true,
    });

    dialog.afterClosed()
      .pipe(filter(data => data))
      .subscribe(data => {
        this.loading = true;
        let userOperation: Observable<User>;
        userOperation = this.publicationService.postUser(data['user']);
        userOperation.subscribe(
          user => {
            // setTimeout(() => this.router.navigate([`/user/${user.id}`]), 500);
          },
          err => {
            this.loading = false;
            this.openSnackBar(err.error.message);
          },
          () => {
            this.loading = false;
            this.openSnackBar('Usuario registrado con éxito');
            this.loadUsers();
          },
        );
      },
    );
  }

  statusChange(id, event) {
    this.st = new Status(null);
    this.st.st = (event.checked) ? 'active' : 'inactive';
    this.publicationService
      .updateStatus(id, 'user', this.st)
      .subscribe(data => this.search());
  }

  toggleFilters() {
    this.showFilters = (this.showFilters) ? false : true;
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, '', {
      duration: 2000,
    });
  }
}

import { JwtHelper } from 'angular2-jwt';
import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions, ResponseContentType } from '@angular/http';
import { Observable } from 'rxjs/Rx';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { environment } from '../../../environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';

@Injectable()
export class EmailService {

    constructor(private http: Http, public _HttpClient: HttpClient) { }

    private baseUrl = environment.devApiEndpoint;

    sendPublications(body: Object): Observable<Object> {
        const bodyString = JSON.stringify(body);
        const headers = new HttpHeaders({ 'Content-type': 'application/json', 'Authorization':'Bearer '+localStorage.getItem('token'), 'Cache-Control':  'no-cache, no-store, private' });

        return this._HttpClient.post(`${this.baseUrl}/email/publication`, body, {headers})
            .map((res: Response) => res);
            // .catch((error: any) => Observable.throw(error.json().error || 'Error retrieving result list'));
    }

}

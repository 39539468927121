import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { Bank } from '../shared/models/bank';
import { NewUserBank } from '../shared/models/new/user.bank.new';
import { FormGroup, Validators, FormControl, FormBuilder } from '@angular/forms';
import { PublicationService } from '../shared/services/publication.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { UserBank } from 'app/shared/models/user.bank';
import { UserType } from 'app/shared/models/user.type';

@Component({
  selector: 'app-user-modal-bank',
  templateUrl: './user-modal-bank.component.html',
  styleUrls: ['./user-modal-bank.component.scss'],
})
export class UserModalBankComponent implements OnInit {

  userId: number;
  bank: Bank;
  banks: Bank[];  
  form: FormGroup;
  model = new Bank(null, null, null, null);
  userBank = new NewUserBank(null, null);

  constructor(
    fb: FormBuilder,
    private publicationService: PublicationService,
    public dialogRef: MatDialogRef<UserModalBankComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.userId = data['userId'];

    this.form = fb.group({
      'bank': ['', Validators.compose([Validators.required])],
    });

  }

  ngOnInit() {
    this.loadBanks();
  }

  // Category
  loadBanks() {
    this.publicationService.getPreferenceBanks().subscribe(
      // banks => this.banks = banks.filter(bank => bank.status === 'active'),
      banks => this.banks = banks,
      err => {
      },
    );
  }  

  save() {
    this.data = {
      userBank: { userId: this.userId, bankId: this.model.id },
    };
    this.dialogRef.close(this.data);
  }

  close() {
    this.dialogRef.close();
  }

}


import { SummaryCategory } from 'app/shared/models/summary.category';
import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { FormGroup, Validators, FormControl, FormBuilder } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ViewChild } from '@angular/core';
import { Delivery } from '../shared/models/delivery';
import {
  MatPaginator, MatTableDataSource, MatPaginatorIntl, MatDialogRef, MAT_DIALOG_DATA, MatSort,
} from '@angular/material';
import { User } from '../shared/models/user';
import { Publication } from '../shared/models/publication';
import { PublicationService } from '../shared/services/publication.service';
import { SummaryService } from '../shared/services/summary.service';

@Component({
  selector: 'app-publication-send-modal',
  templateUrl: './publication-send-modal.component.html',
  styleUrls: ['./publication-send-modal.component.scss'],
})
export class PublicationSendModalComponent implements OnInit {
  @HostListener('wheel', ['$event'])
  handleWheelEvent(event) {
    event.preventDefault();
  }

  descriptionFormGroup: FormGroup;
  model = new Delivery(null, null, null);
  types: any[];
  summaryCategory: SummaryCategory;
  summaryCategories: SummaryCategory[];
  categoryName: string;

  selectedPublications: string[] = [];
  publications: Publication[];
  dsPublications;
  displayedColumnsPublications = [];
  @ViewChild('paginatorPublications') paginatorPublications: MatPaginator;
  @ViewChild('tablePublications', { read: MatSort }) sortPublications: MatSort;
  
  selectedUsers: string[] = [];  
  users: User[];
  dsUsers;
  displayedColumnsUsers = [];
  @ViewChild('paginatorUsers') paginatorUsers: MatPaginator;
  @ViewChild('tableUsers', { read: MatSort }) sortUsers: MatSort;

  constructor(private _formBuilder: FormBuilder,
    private publicationService: PublicationService,
    private summaryService: SummaryService,
    public dialogRef: MatDialogRef<PublicationSendModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.selectedPublications = this.data['publications'];

    this.descriptionFormGroup = this._formBuilder.group({
      'subject': ['', Validators.compose([Validators.required, Validators.minLength(2)])],
      'summary': ['', Validators.compose([Validators.required, Validators.minLength(2)])],
      'type': ['', Validators.compose([Validators.required, Validators.minLength(2)])],
    });
  }

  ngOnInit() {
    this.displayedColumnsUsers = ['name', 'selected'];
    this.displayedColumnsPublications = ['title'];
    this.loadData();
    this.loadUsers();
    this.loadSelectedPublications();
    this.loadSummaryCategories();
  }

  loadData() {
    this.types = [
      { name: 'Type one' },
      { name: 'Type two' },
    ];
  }

  loadUsers() {
    this.publicationService
      .getUsers()
      .subscribe(
        users => {
          this.users = users;
          this.dsUsers =
            new MatTableDataSource<User>(this.users);
          this.dsUsers.paginator = this.paginatorUsers;
          this.dsUsers.sort = this.sortUsers;
        },
        err => {
        },
    );
  }

  loadSummaryCategories() {
    this.summaryService
    .getSummaryCategories()
    .subscribe(getSummaryCategories => {
      this.summaryCategories = getSummaryCategories;
    });
  }

  loadSelectedPublications() {
    this.publicationService
      .getPublicationsByIds(this.selectedPublications.join(','))
      .subscribe(
        publications => {
          this.publications = publications;
          this.dsPublications =
            new MatTableDataSource<Publication>(this.publications);
          this.dsPublications.paginator = this.paginatorPublications;
          this.dsPublications.sort = this.sortPublications;
        },
        err => {
        },
    );
  }

  onSelectUser(id) {
    const index = this.selectedUsers.indexOf(id.toString());

    if (event.target['checked']) {
      if (index < 0) {
        this.selectedUsers.push(id.toString());
      }
    } else {
      if (index >= 0) {
        this.selectedUsers.splice(index, 1);
      }
    }
  }

  onSelectPublicaton(id) {
    const index = this.selectedPublications.indexOf(id.toString());

    if (event.target['checked']) {
      if (index < 0) {
        this.selectedPublications.push(id.toString());
      }
    } else {
      if (index >= 0) {
        this.selectedPublications.splice(index, 1);
      }
    }
  }

  send() {    
    this.data = {
      userIds: this.selectedUsers,
      publicationIds: this.selectedPublications,
    };
    this.dialogRef.close(this.data);
  }

  close() {
    this.dialogRef.close();
  }

  dropDownSummaryCategoryChange(event) {
    this.categoryName = event.value['name'];
  }

}

import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd, Params, PRIMARY_OUTLET } from '@angular/router';
import 'rxjs/add/operator/filter';

interface IBreadcrumb {
    label: string;
    params?: Params;
    url: string;
}

@Component({
    selector: 'breadcrumb',
    template: `
    <ul class="css-breadcrumb">
      <li><a routerLink="/dashboard">Inicio</a></li>
      <li *ngFor="let breadcrumb of breadcrumbs">
        <a [routerLink]="[breadcrumb.url]">{{breadcrumb.label}}</a>
      </li>
    </ul>
  `
})
export class BreadcrumbComponent implements OnInit {

    breadcrumbs: IBreadcrumb[];

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
    ) {
        this.breadcrumbs = [];
        const ROUTE_DATA_BREADCRUMB: string = 'breadcrumb';

        // subscribe to the NavigationEnd event
        this.router.events.filter(event => event instanceof NavigationEnd).subscribe(event => {

            // set breadcrumbs
            const root: ActivatedRoute = this.activatedRoute.root;
            this.breadcrumbs = this.getBreadcrumbs(root);
        });
    }

    ngOnInit() {
        
    }

    private getBreadcrumbs(route: ActivatedRoute, url: string = '', breadcrumbs: IBreadcrumb[] = []): IBreadcrumb[] {
        const ROUTE_DATA_BREADCRUMB: string = 'breadcrumb';

        // get the child routes
        const children: ActivatedRoute[] = route.children;

        // return if there are no more children
        if (children.length === 0) {
            return breadcrumbs;
        } else {
            const routeURL: string = route.snapshot.url.map(segment => segment.path).join('/');
            url += `${routeURL}`;
            
            if (route.snapshot.data[ROUTE_DATA_BREADCRUMB] !== undefined) {
                const breadcrumb: IBreadcrumb = {
                    label: route.snapshot.data[ROUTE_DATA_BREADCRUMB],
                    params: route.snapshot.params,
                    url,
                };
                breadcrumbs.push(breadcrumb);
            }
        }

        // iterate over each children
        for (const child of children) {
            // verify primary route
            if (child.outlet !== PRIMARY_OUTLET) {
                continue;
            }

            // verify the custom data property "breadcrumb" is specified on the route
            if (!child.snapshot.data.hasOwnProperty(ROUTE_DATA_BREADCRUMB)) {
                return this.getBreadcrumbs(child, url, breadcrumbs);
            }

            // get the route's URL segment
            const routeURL: string = child.snapshot.url.map(segment => segment.path).join('/');

            // append route URL to URL
            //url += `/${routeURL}`;
            url = this.router.url;
            
            // add breadcrumb
            const breadcrumb: IBreadcrumb = {
                label: child.snapshot.data[ROUTE_DATA_BREADCRUMB],
                params: child.snapshot.params,
                url,
            };
            
            var urlParts = breadcrumb.url.split('/');
            if(urlParts[1] == 'publication'){
                breadcrumb.url = '/publication';
            }
            breadcrumbs.push(breadcrumb);

            // recursive
            return this.getBreadcrumbs(child, url, breadcrumbs);
        }

        // we should never get here, but just in case
        return breadcrumbs;
    }

}

import { RepeatPipe } from './shared/pipes/repeat.pipe';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [RepeatPipe],
  // providers: [
  //   RepeatPipe,
  // ],
  exports: [
    RepeatPipe,
  ],
})
export class PipesModule { 
//   static forRoot() {
//     return {
//         ngModule: PipesModule,
//         providers: [],
//     };
//  }
}

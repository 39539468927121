import { DOCUMENT } from '@angular/common';
import { environment } from '../../environments/environment';
import { UserPassword } from './../shared/models/user.password';
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { LoginService } from '../shared/services/login.service';
import { PasswordValidation } from '../shared/helpers/password-validator';
import { Observable } from 'rxjs/Observable';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-login-set-password',
  templateUrl: './login-set-password.component.html',
  styleUrls: ['./login-set-password.component.scss'],
})
export class LoginSetPasswordComponent implements OnInit {

  privacyFileLink: string;
  arcoFileLink: string;
  consentFileLink: string;
  loginBackground: string;

  isTokenExpired: Boolean = false;
  loading: Boolean = false;
  submitted: boolean = false;
  form: FormGroup;
  password: AbstractControl;
  confirmPassword: AbstractControl;
  statusText: string;
  model = new UserPassword(null, null, null);

  constructor(
    fb: FormBuilder,
    private loginService: LoginService,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private router: Router,
    @Inject(DOCUMENT) private document: any
  ) {
    this.form = fb.group({
      'password': 
      ['', Validators.compose([Validators.required, 
        Validators.minLength(8), 
        Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$')])],
      'confirmPassword': 
      ['', Validators.compose([Validators.required, 
        Validators.minLength(8), 
        Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$')])],
    }, {
        validator: PasswordValidation.MatchPassword,
      });

    this.loginBackground = `${environment.assetsEndpoint}/img/background_login.jpg`;

    this.privacyFileLink = `${environment.s3Endpoint}/legal/aviso_privacidad.pdf`;
    this.arcoFileLink = `${environment.s3Endpoint}/legal/ejercicio_derechos_arco_v1.pdf`;
    this.consentFileLink = `${environment.s3Endpoint}/legal/revocacion_consentimiento_v1.2.pdf`;

    this.password = this.form.controls['password'];
    this.confirmPassword = this.form.controls['confirmPassword'];
  }

  onSubmit(values: Object): void {
    this.loading = true;
    this.submitted = true;
    const token = this.activatedRoute.snapshot.queryParams['tkn'];
    if (this.form.valid) {
      let loginOperation: Observable<Object>;
      this.loginService.postReset(this.model, token).subscribe(
        loginResponse => {
          if (loginResponse['statusText'] === 'Ok') {
            this.statusText = 'Contraseña actualizada';
            this.document.location.href = 'https://femsa-analistas.com';
            //setTimeout(() => this.router.navigate([`/login`]), 1500);
          } else {
            this.statusText = 'Error al actualizar contraseña';
          }
          // this.router.navigate([`dashboard`]);          
          // if (this.loginService.isUserAdmin()) {
          //   this._menuService.updateMenuByRoutes(<Routes>PAGES_MENU);
          //   if (this.loginService.isTokenExpired()) {
          //     localStorage.removeItem('token');
          //     this.router.navigate(['login']);
          //   }
          // } else {
          //   this._menuService.updateMenuByRoutes(<Routes>PAGES_MENU_READER);
          //   if (this.loginService.isTokenExpired()) {
          //     localStorage.removeItem('token');
          //     this.router.navigate(['login']);
          //   }
          // }
        }, error => {
          this.statusText = 'Usuario y/o contraseña incorrectos';
            //this.statusText = 'Ocurrio un error';
         //setTimeout(() => this.router.navigate([`/login`]), 1500);
         this.document.location.href = 'https://femsa-analistas.com';
        },
      );
    }
  }

  ngOnInit() {
    const token = this.activatedRoute.snapshot.queryParams['tkn'];
    this.isTokenExpired = this.loginService.isLoginTokenExpired(token);
    const decodedToken = this.loginService.jwtHelper.decodeToken(token);

    this.model.email = decodedToken['sub'];

   //console.log(this.model);
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginBodyComponent } from './login-body/login-body.component';
import { Routes, RouterModule } from '@angular/router';
import { NgaModule } from './theme/nga.module';
import { AppTranslationModule } from './app.translation.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputTextModule, ButtonModule } from 'primeng/primeng';
import { LoginService } from './shared/services/login.service';
import {
  MatButtonModule, MatInputModule,
  MatCardModule, MatToolbarModule, MatIconModule, MatGridListModule, MatSnackBarModule, MatDialogModule,
  MatProgressSpinnerModule,
} from '@angular/material';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FlexLayoutModule } from '@angular/flex-layout';
import { LoginResetPasswordComponent } from './login-reset-password/login-reset-password.component';
import { LoginSetPasswordComponent } from './login-set-password/login-set-password.component';

const routes: Routes = [
  { path: 'login', component: LoginBodyComponent },
  { path: 'login/recover', component: LoginResetPasswordComponent },
  { path: 'login/reset', component: LoginSetPasswordComponent },
];
@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    NgaModule,
    AppTranslationModule,
    ReactiveFormsModule,
    FormsModule,
    InputTextModule,
    ButtonModule,
    // Flex
    FlexLayoutModule,
    // Material
    MatButtonModule,
    MatInputModule,
    MatCardModule,
    MatToolbarModule,
    MatIconModule,
    MatGridListModule,
    MatSnackBarModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
  ],
  declarations: [LoginBodyComponent, LoginResetPasswordComponent, LoginSetPasswordComponent],
  providers: [
    LoginService,
  ],
})
export class LoginModule { }

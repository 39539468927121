

import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { Observable } from 'rxjs/Observable';
import { Bank } from '../models/bank';
import { PublicationService } from '../services/publication.service';
import { LoginService } from '../services/login.service';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { catchError, finalize } from 'rxjs/operators';
import { of } from 'rxjs/observable/of';


export class BankData implements DataSource<Bank> {

    private bankSubject = new BehaviorSubject<Bank[]>([]);

    private loadingSubject = new BehaviorSubject<boolean>(false);

     loading$ = this.loadingSubject.asObservable();

    constructor(private publicationService: PublicationService,
        private loginService: LoginService) {

    }

    loadBanks(searchString) {

        this.loadingSubject.next(true);

        this.publicationService.findBank(searchString).pipe(
            catchError(() => of([])),
            finalize(() => this.loadingSubject.next(false)),
        )
        .subscribe(bank => this.bankSubject.next(bank));

    }

    connect(collectionViewer: CollectionViewer): Observable<Bank[]> {
        return this.bankSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.bankSubject.complete();
        this.loadingSubject.complete();
    }

}

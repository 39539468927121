import { Globals } from './../shared/globals';
import { BaMenuService } from './../theme/services/baMenu/baMenu.service';
import { GlobalState } from './../global.state';
import { PublicationDisableModalComponent } from './../publication-disable--modal/publication-disable--modal.component';
import { PublicationModalComponent } from './../publication-modal/publication-modal.component';
import { PublicationTargetpriceModalEditComponent }
  from './../publication-targetprice-modal-edit/publication-targetprice-modal-edit.component';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { PublicationView } from '../shared/models/publication.view';
import { PublicationService } from '../shared/services/publication.service';
import { LoginService } from '../shared/services/login.service';
import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy, ElementRef } from '@angular/core';
import { Publication } from '../shared/models/publication';
import { ActivatedRoute, Params } from '@angular/router';
import { PublicationTargetPrice } from '../shared/models/publication.targetprice';
import { PublicationImage } from '../shared/models/publication.image';
import { Message } from 'primeng/primeng';
import { PublicationDownload } from '../shared/models/publication.download';
import { PublicationFile } from '../shared/models/publication.file';
import { PublicationBookmark } from '../shared/models/publication.bookmark';
import { PublicationModalEditComponent } from '../publication-modal-edit/publication-modal-edit.component';
import { saveAs as importedSaveAs } from 'file-saver';
// import { mergeMap } from 'rxjs/operators';
import { forkJoin } from 'rxjs/observable/forkJoin';
// import { setTimeout } from 'timers';
import { DatePipe } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Company } from '../shared/models/company';
import { Bank } from '../shared/models/bank';
import { Currency } from '../shared/models/currency';
import { RecommendedAction } from '../shared/models/recommendedAction';
import { Analyst } from '../shared/models/analyst';
import { Category } from '../shared/models/category';
import { SubCategory } from '../shared/models/subcategory';
import { DataSource } from '@angular/cdk/collections';
import {
  MatPaginator, MatTableDataSource, MatPaginatorIntl,
  MatSnackBar, MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSort,
} from '@angular/material';
import { filter } from 'rxjs/operators';
import { Http, Response } from '@angular/http';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-publication-body',
  templateUrl: './publication-body.component.html',
  styleUrls: ['./publication-body.component.scss'],
})
export class PublicationBodyComponent implements OnInit, AfterViewInit, OnDestroy {

  load: Boolean = false;
  hasTargetPrice: Boolean = false;
  loading: Boolean = false;
  displayedColumns = [];
  chartData: any;
  publicationId = 0;
  filterQuery = '';
  rowsOnPage = 10;
  sortBy = 'publicationid';
  sortOrder = 'asc';
  imageUrl = '';
  imageLinkUrl = '';
  fileLinkUrl = '';
  publication: Publication;
  targetPrice: PublicationTargetPrice;
  publicationViews: PublicationView[];
  publicationImage: PublicationImage;
  publicationFile: PublicationFile;
  publicationBookmark: PublicationBookmark;
  msgs: Message[] = [];
  private publicationView: PublicationView;
  private publicationDownload: PublicationDownload;
  isBookmarked: boolean = false;
  hasFile: boolean = false;
  viewCount: number;
  downloadCount: number;
  bookmarkCount: number;
  viewGraphData: number[];
  downloadGraphData: number[];
  bookmarkGraphData: number[];
  dateRange: Date[];
  es: any;
  editMode: boolean = false;
  company: Company;
  bank: Bank;
  analyst: Analyst;
  category: Category;
  subcategory: SubCategory;
  companyName: any;
  analysts: Analyst[];
  companies: Company[];
  banks: Bank[];
  subcategories: SubCategory[];
  datasource;
  beginDate: any;
  endDate: any;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('table', { read: MatSort }) sortViews: MatSort;
  imageFile: any;
  graphYear: number;
  yearList: Array<{ value: number }> = [{ value: 2017 }];
  tmpImg: HTMLImageElement;
  @ViewChild('dateInit') startDateInput:ElementRef;
  @ViewChild('dateEnd') endDateInput: ElementRef;

  // Modal data
  currencies: Currency[];
  recommendedActions: RecommendedAction[];
  public showDownloadPDF: boolean = true;
  public showDownloadDocx: boolean = false;

  chartOptions = {
    responsive: true,
  };

  onChartClick(event) {
  }

  constructor(
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private _service: BaMenuService,
    private publicationService: PublicationService,
    private loginService: LoginService,
    private router: Router,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    private _state: GlobalState,
    private _globals: Globals,
    private http: Http,
    public _HttpClient: HttpClient,) {
    this._globals.pageTitle = 'Publicaciones/';
  }

  ngOnInit() {
    this.graphYear = new Date().getFullYear();

    this.publicationView = new PublicationView(
      this.route.snapshot.params['id'], this.loginService.getUserId(), new Date());
    this.publicationDownload = new PublicationDownload(
      this.route.snapshot.params['id'], this.loginService.getUserId(), new Date());

    this.loading = true;
    // this.datasource = new PublucationViewDataSource(this.publicationService);
    this.displayedColumns = ['name', 'email', 'date', 'bookmarked', 'downloaded'];
    // Set the publication id
    this.publicationId = this.route.snapshot.params['id'];
    //
    this.loadData();
    //
    this.postView();
    this.getImageUrl();
    // Create the publication bookmark
    this.setBookmarked();
    // Get widget values    
    this.getDownloadCount();
    this.getBookmarkCount();
    // Check if the publication has a file attached
    this.checkFile();
    // Get graph data and create chart
   this.createChart();
    this.loadGraphData();

    this.loadEsLabels();

   this.graphYearChange();

    // Load edit data
    // this.loadAnalysts();
    // this.loadCompanies();
    // this.loadBanks();
    // Load modal data
    // this.loadCurrencies();
    // this.loadRecommededActions();
  }

  ngOnDestroy(){
    this.dialog.closeAll();
  }

  ngAfterViewInit() {
    // this.getViews();   
  }

  public TimesTP: number = 0;
  fnGettargetPrice(publicationId: number){
    this.publicationService
      .getTargetPrice(publicationId)
      .subscribe(targetPrice => {
        this.targetPrice = targetPrice;
        this.hasTargetPrice = true;
      },
      err => {
        /*this.TimesTP++;
        if(this.TimesTP <= 55){
          this.fnGettargetPrice(publicationId);
        } else {
          this.hasTargetPrice = false;
        }*/
      });
  }

  loadData() {
    // Get Publication
    const publicationOperation = this.publicationService.getPublication(this.route.snapshot.params['id']);
    // Get Target Price
    // this.publicationService
    //   .getTargetPrice(this.route.snapshot.params['id'])
    //   .subscribe(targetPrice => {
    //     this.targetPrice = targetPrice;
    //     this.hasTargetPrice = true;
    //   },
    //   err => {
    //     this.hasTargetPrice = false;
    //   });
    
    // Get Currencies
    const currencyOperation = this.publicationService.getCurrencies();
    // Get Recommended Actions
    const recommendedActionOperation = this.publicationService.getRecommendedActions();
    // Get Analysts
   /* const analystOperation = this.publicationService.getCachedAnalysts();*/
    // Get Companies
    /*const companyOperation = this.publicationService.getCachedCompanies();*/
    // Get Banks
    /*const bankOperation = this.publicationService.getCachedBanks();*/
    // Get file
    // const fileOperation = this.publicationService.getPublicationFile(this.route.snapshot.params['id']);


    const fj = forkJoin([
      publicationOperation,
      currencyOperation,
      recommendedActionOperation,
      //analystOperation,
      //companyOperation,
      //bankOperation,
      // publicationTargetPriceOperation,
      // fileOperation]).onErrorResumeNext().finally(() => this.loading = false);
    ]).onErrorResumeNext().finally(() => this.loading = false);

    fj.subscribe(
      results => {
        // this.hasTargetPrice = true;
        this.publication = results[0];
        this.currencies = results[1];
        this.recommendedActions = results[2];
        this.analysts = results[3];
        this.companies = results[4];
        this.banks = results[5];
        // this.targetPrice = results[6];
        // this.publicationFile = results[6];
        this.getViews();
      },
    );
    this.fnGettargetPrice(this.route.snapshot.params['id']);
    
  }

  // Currency
  loadCurrencies() {
    this.publicationService.getCurrencies().subscribe(
      currencies => this.currencies = currencies,
      err => {
        
      },
    );
  }

  // Recommended Actions
  loadRecommededActions() {
    this.publicationService.getRecommendedActions().subscribe(
      recommendedActions => this.recommendedActions = recommendedActions,
      err => {
        
      },
    );
  }

  // Calendar labels
  loadEsLabels() {
    this.es = {
      firstDayOfWeek: 1,
      dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
      dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
      dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
      monthNames: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio',
        'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
      monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
      today: 'Hoy',
      clear: 'Borrar',
    };
  }

  openTargetPriceModal(): void {
    const dialog = this.dialog.open(PublicationTargetpriceModalEditComponent, {
      height: '250px',
      width: '800px',
      closeOnNavigation: true,
      data: {
        publicationId: this.publicationId,
        publicationTargetPrice: this.targetPrice,
        currencies: this.currencies,
        recommendedActions: this.recommendedActions,
      },
    });

    dialog.afterClosed().pipe(filter(data => data)).subscribe(data => {
        let publicationTargetPriceOperation: Observable<PublicationTargetPrice>;
        publicationTargetPriceOperation = this.publicationService
          .updateTargetPrice(this.publicationId, data['publicationTargetPrice']);
        publicationTargetPriceOperation.subscribe(publicationTargetPrice => {
          this.targetPrice = publicationTargetPrice;
          this.hasTargetPrice = true;
        }, err => {
          this.hasTargetPrice = false;
        },
        );
      });
  }

  openDialog(): void {
    
    let dataModal;
    if (this.targetPrice) {
      dataModal = { publication: this.publication, publicationTargetPrice: this.targetPrice };
    } else {
      dataModal = { publication: this.publication };
    }
    const dialogRef = this.dialog.open(PublicationModalEditComponent, {
      height: '650px',
      width: '800px',
      closeOnNavigation: true,
      data: dataModal,
    });

    dialogRef.afterClosed()
      .pipe(filter(data => data))
      .subscribe(data => {
        let publicationOperation: Observable<Publication>;
        publicationOperation = this.publicationService.updatePublication(this.publicationId, data['publication']);
        publicationOperation.subscribe(
          publication => {
            this.loading = true;
            this.publication = publication;
            if (data['publicationFile']) {
              this.updateFile(this.publicationId, data['publicationFile']);
            }
            if (data['imgFile'] || data['selectedStockImageId'] > 0) {
              this.updateImage(this.publicationId, data['imgFile'], data['selectedStockImageId']);
            }
          },
          err => {
            this.loading = false;
          },
          () => {
            this.loading = false;
          },
        );
      },
    );
  }

  // Chart
  createChart() {
    this.chartData = {
      labels: [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre'],
      datasets: [
        {
          label: 'Consultas',
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          fill: false,
          borderColor: '#4bc0c0',
        },
        {
          label: 'Favoritos',
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          fill: false,
          borderColor: '#565656',
        },
        {
          label: 'Descargas',
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          fill: false,
          borderColor: '#ffa500',
        },
      ],
    };
  }

  updateChart() {
    this.chartData = {
      labels: [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre'],
      datasets: [
        {
          label: 'Consultas',
          data: this.viewGraphData,
          borderColor: '#4bc0c0',
        },
        {
          label: 'Favoritos',
          data: this.bookmarkGraphData,
          borderColor: '#565656',
        },
        {
          label: 'Descargas',
          data: this.downloadGraphData,
          borderColor: '#ffa500',
        },
      ],
    };
  }

  loadGraphData() {
    const viewGraphOperation = this.publicationService.getViewGraph(this.publicationId, 2018);
    const downloadGraphOperation = this.publicationService.getDownloadGraph(this.publicationId, 2018);
    const bookmarkGraphOperation = this.publicationService.getBookmarkGraph(this.publicationId, 2018);

    forkJoin([viewGraphOperation, downloadGraphOperation, bookmarkGraphOperation]).subscribe(results => {
      this.viewGraphData = results[0];
      this.downloadGraphData = results[1];
      this.bookmarkGraphData = results[2];
      this.updateChart();
    });
  }

  // View
  getViews() {
    this.publicationService
      .getViews(this.route.snapshot.params['id'])
      .subscribe(publicationViews => {
        this.publicationViews = publicationViews;
        this.datasource =
          new MatTableDataSource<PublicationView>(this.publicationViews);
        this.datasource.paginator = this.paginator;
        this.datasource.sort = this.sortViews;
      },
    );
  }

  postView() {
    let publicationViewOperation: Observable<PublicationView>;
    publicationViewOperation = this.publicationService.postView(
      this.route.snapshot.params['id'], this.publicationView);

    publicationViewOperation.subscribe(
      publicationView => {
        this.publicationView = new PublicationView(
          this.route.snapshot.params['id'], this.loginService.getUserId(), new Date());
      },
      err => {
        
      }, () => {
        this.getViewCount();
      },
    );
  }

  // Update Publication File
  updateFile(id: number, file: any) {
    const headers = new HttpHeaders({ 'Authorization':'Bearer '+localStorage.getItem('token'), 'Cache-Control':  'no-cache, no-store, private' });

    const formData = new FormData();
    formData.append('file', file);
    formData.append('id', id.toString());
    this._HttpClient.post(`${environment.devApiEndpoint}/file`, formData, {headers})
      .map(response => response)
      .catch((error: any) => Observable.throw(error || 'Error updating publication\'s file'))
      .subscribe(result => {
        // this.publicationService
        //   .getPublicationFile(this.publicationId)
        //   .subscribe(publicationFile => {
        //     this.publicationFile = publicationFile;
        //   },
        //     err => {
        //       
        //     });
        this.checkFile();
      }, err => {
        
      }, () => {
        this.checkFile();
      });
  }

  // Post Publication Image
  updateImage(id: number, file: any, selectedStockImageId: number) {
    const headers = new HttpHeaders({ 'Authorization':'Bearer '+localStorage.getItem('token'), 'Cache-Control':  'no-cache, no-store, private' });

    const formData = new FormData();
    if (selectedStockImageId > 0) {
      this.publicationService.getPublicationImageById(selectedStockImageId)
        .subscribe(data => {
          this.imageFile = data;
          this.createImageFromBlob(data);
          formData.append('file', this.imageFile, `publication${id}.jpg`);
          formData.append('id', id.toString());
          this._HttpClient.post(`${environment.devApiEndpoint}/file/image`, formData, {headers})
            .map(response => response)
            .subscribe(() => {
              this.getImageUrl();
            });
        });
    } else {
      formData.append('file', file);
      formData.append('id', id.toString());
      this._HttpClient.post(`${environment.devApiEndpoint}/file/image`, formData, {headers})
        .map(response => response)
        .subscribe(() => {
          this.getImageUrl();
        });
    }
  }

  createImageFromBlob(image: Blob) {
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      this.imageFile = reader.result;
    }, false);

    if (image) {
      reader.readAsDataURL(image);
    }
  }

  // Publish Publication
  publish() {
    this.publicationService
      .publishPublication(this.publicationId)
      .subscribe(publication => {
        this.msgs = [];
        this.msgs.push({ severity: 'success', summary: 'Correcto', detail: 'Publicación actualizada' });
        this.publicationService
          .getPublication(this.route.snapshot.params['id'])
          .subscribe(publication2 => {
            this.publication = publication;
            this.openSnackBar('Publicación publicada');
          });
      },
    );
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, '', {
      duration: 2000,
    });
  }

  // Image
  public TimesImg: number = 0;
  getImageUrl() {
    this.imageUrl = `${environment.s3Endpoint}/app/img/imgLoading32.svg`;

    const loaded = () => {
      this.publicationService
        .getPublicationImage(this.publicationId)
        .subscribe(publicationImage => {
          this.publicationImage = publicationImage;
          this.imageUrl = `${environment.s3Endpoint}/publicaciones/${this.publicationId}/img/${this.publicationImage.url}?random=${Math.random()}`;
          this.imageLinkUrl = `${environment.s3Endpoint}/publicaciones/${this.publicationId}/img/${this.publicationImage.url}?random=${Math.random()}`;
        }, err => {
          this.imageUrl = `${environment.s3Endpoint}/app/img/no_disponible.png`;
          /*this.TimesImg++;
          if(this.TimesImg <= 25){
            this.getImageUrl();
          } else {
            this.imageUrl = `${environment.s3Endpoint}/app/img/no_disponible.png`;
          }*/
          
        },
      );
    };

    if (this.tmpImg) {
      this.tmpImg.onload = null;
    }

    this.tmpImg = new Image();
    this.tmpImg.onload = loaded;
    this.tmpImg.src = this.imageUrl;

  }

  openFile() {
    window.open(this.fileLinkUrl, '_blank');
    //window.open(`https://femsa-analistas.mx/api/file/${this.publicationId}`, '_blank');
    //window.open(`https://reportesanalistas.femsa.com.mx:44443/femsa-analistas/publicaciones/${this.publicationId}/files/publication_${this.publicationId}.pdf`, '_blank');
  }

  // Go to preview
  toPreview() {
    this.router.navigate([`/publication/${this.publicationId}/preview`]);
  }

  // Download the publication´s file and save the entry
  downloadFile() {
    this.loading = true;
    this.publicationService.downloadFile(this.publicationId).finally(() => this.loading = false).subscribe(
      blob => {
        importedSaveAs(blob, this.publicationFile.name);
        //importedSaveAs(blob, this.publication.title+'.pdf');
      }, err => {
        
      }, () => {
        let publicationDownloadOperation: Observable<Object>;
        publicationDownloadOperation = this.publicationService.postDownload(
          this.route.snapshot.params['id'], this.publicationDownload);

        publicationDownloadOperation.subscribe(
          publicationDownload => {
            this.getDownloadCount();
            this.openSnackBar('Descargar registrada');
          },
          err => {
            
          },
        );
      },
    );
  }

  // Check whether publication´s file exists
  checkFile() {
    this.publicationService.getPublicationFile(this.publicationId).subscribe(
      publicationFile => {
        let extension = publicationFile.name.split('.')[1];
        if(extension === 'docx' || extension === 'doc'){
          this.showDownloadPDF = false;
          this.showDownloadDocx = true;
        } else {
          this.showDownloadPDF = true;
          this.showDownloadDocx = false;
        }
        this.publicationFile = publicationFile;
        if(publicationFile['publicationId'] == this.publicationId){
          this.hasFile = true;
        } else {
          this.hasFile = false;
        }
        this.getImageUrl();
        this.fileLinkUrl = `${environment.s3Endpoint}/publicaciones/${this.publicationId}/files/${this.publicationFile.url}`;
        //this.fileLinkUrl = `${environment.s3Endpoint}/publicaciones/${this.publicationId}/files/publication_${this.publicationId}`;
      },
      err => {
        //this.checkFile();
      },
    );
  }

  imageLoaded() {
    this.load = true;
  }

  // Set the publication as bookmarked
  setBookmarked() {
    this.publicationService.getBookmark(this.publicationId, this.loginService.getUserId()).subscribe(
      publicationBookmark => {
        this.publicationBookmark = publicationBookmark;
        this.isBookmarked = true;
      },
      err => {
        this.publicationBookmark = new PublicationBookmark(
          this.route.snapshot.params['id'], this.loginService.getUserId(), new Date());
        this.isBookmarked = false;
      },
    );
  }

  // Bookmark
  postBookmark() {
    this.publicationService
      .postBookmark(this.publicationId, this.publicationBookmark)
      .subscribe(res => {
        this.isBookmarked = true;
        this.getBookmarkCount();
        this.getViews();
        this.openSnackBar('Agregado a favoritos');
      },
    );
  }

  deleteBookmark() {
    this.publicationService
      .deleteBookmark(this.publicationId, this.loginService.getUserId())
      .subscribe(res => {
        this.isBookmarked = false;
        this.publicationBookmark = new PublicationBookmark(
          this.route.snapshot.params['id'], this.loginService.getUserId(), new Date());
        this.getBookmarkCount();
        this.getViews();
        this.openSnackBar('Borrado de favoritos');
      },
    );
  }

  // Widgets
  getViewCount() {
    this.publicationService.getViewCount(this.publicationId).subscribe(
      viewCount => this.viewCount = viewCount,
    );
  }

  getDownloadCount() {
    this.publicationService.getDownloadCount(this.publicationId).subscribe(
      downloadCount => this.downloadCount = downloadCount,
    );
  }

  getBookmarkCount() {
    this.publicationService.getBookmarkCount(this.publicationId).subscribe(
      bookmarkCount => this.bookmarkCount = bookmarkCount,
    );
  }

  // Search
  filterPublicationViews() {
    this.loading = true;

    this.publicationService.searchPublicationViews(this.publicationId, this.buildSearchString()).subscribe(
      publicationViews => {
        this.publicationViews = publicationViews;
        this.datasource =
          new MatTableDataSource<PublicationView>(this.publicationViews);
        this.datasource.paginator = this.paginator;
        this.datasource.sort = this.sortViews;
      },
      err => {
        this.loading = false;
        this.msgs = [];
        this.msgs.push({ severity: 'error', summary: 'Error', detail: err });
      },
      () => {
        this.loading = false;
      },
    );
  }

  buildSearchString() {
    let result = '';
    const datePipe = new DatePipe('en');
    result += `date1=${datePipe.transform(this.beginDate, 'yyyy-MM-dd')}&date2=${datePipe.transform(this.endDate, 'yyyy-MM-dd')}`;

    return result;
  }

  validFilterDates() {
    let result = true;
    if (this.beginDate && this.endDate) {
      result = false;
    }

    return result;
  }

  clearFilters() {
    this.beginDate = null;
    this.endDate = null;
    this.getViews();
  }

  // Analyst
  loadAnalysts() {
    this.publicationService.getAnalysts().subscribe(
      analysts => this.analysts = analysts.filter(analyst => analyst.status === 'active'),
      err => {
        
      },
    );
  }

  // Company
  loadCompanies() {
    this.publicationService.getCompanies().subscribe(
      companies => this.companies = companies,
      err => {
        
      },
    );
  }

  // Bank
  loadBanks() {
    this.publicationService.getBanks().subscribe(
      banks => this.banks = banks.filter(bank => bank.status === 'active'),
      err => {
        
      },
    );
  }

  openDisableModal() {
    const dialogRef = this.dialog.open(PublicationDisableModalComponent, {
      height: '200px',
      width: '600px',
      closeOnNavigation: true,
    });

    dialogRef.afterClosed()
      .pipe(filter(data => data))
      .subscribe(data => {
        if (data['disable'] === true) {
          this.publication.published = false;
          let publicationOperation: Observable<Publication>;
          publicationOperation = this.publicationService.updatePublication(this.publicationId, this.publication);
          publicationOperation.subscribe(
            publication => {
              this.publication = publication;
            },
            err => {
              
            },
            () => {
              this.openSnackBar('Publicación deshabilitada');
            },
          );
        }
      },
    );
  }

  showCreateModal() {
    const dialogRef = this.dialog.open(PublicationModalComponent, {
      height: '550px',
      width: '800px',
      closeOnNavigation: true,
    });

    dialogRef.afterClosed()
      .pipe(filter(data => data))
      .subscribe(data => {
        let publicationOperation: Observable<Publication>;
        publicationOperation = this.publicationService.addPublication(data['publication']);
        publicationOperation.subscribe(
          publication => {
            this.publication = publication;
            if (data['hasTargetPrice']) {
              this.postTargetPrice(this.publication['id'], data['publicationTargetPrice']);
            }
            if (data['publicationFile']) {
              this.postFile(publication['id'], data['publicationFile']);
            }
            if (data['imgFile'] || data['selectedStockImageId'] > 0) {
              this.postImage(publication['id'], data['imgFile'], data['selectedStockImageId']);
            }
            this.loading = true;
            if (data['preview']) {
              setTimeout(() => this.router.navigate([`/publication/${this.publication['id']}/preview`]), 500);
            } else {
              setTimeout(() => this.router.navigate([`/publication/${this.publication['id']}`]), 500);
            }
          },
          err => {
            
          },
        );
      },
    );
  }

  postTargetPrice(id: number, publicationTargetPrice: PublicationTargetPrice) {
    publicationTargetPrice['publicationid'] = id;
    publicationTargetPrice['price'] = +publicationTargetPrice['price'];

    let publicationTargetPriceOperation: Observable<PublicationTargetPrice>;
    publicationTargetPriceOperation = this.publicationService.postTargetPrice(id, publicationTargetPrice);
    publicationTargetPriceOperation.subscribe();
  }

  postImage(id: number, file: any, selectedStockImageId: number) {
    const headers = new HttpHeaders({ 'Content-type': 'application/json', 
    'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
    'Cache-Control':  'no-cache, no-store, private', 
    'Authorization':'Bearer '+localStorage.getItem('token') });

    const formData = new FormData();
    if (selectedStockImageId > 0) {
      this.publicationService.getPublicationImageById(selectedStockImageId)
        .subscribe(data => {
          this.imageFile = data;
          this.createImageFromBlob(data);
          formData.append('file', this.imageFile, `publication${id}.jpg`);
          formData.append('id', id.toString());
          this._HttpClient.post(`${environment.devApiEndpoint}/file/image`, formData, {headers})
            .map((res: Response) => res.json())
            .catch((error: any) => Observable.throw(error.json().error || 'Error posting the image'))
            .subscribe();
        });
    } else {
      this.imageFile = file;
      formData.append('file', this.imageFile);
      formData.append('id', id.toString());
      this._HttpClient.post(`${environment.devApiEndpoint}/file/image`, formData, {headers})
        .map((res: Response) => res.json())
        .catch((error: any) => Observable.throw(error.json().error || 'Error posting the image'))
        .subscribe();
    }
  }
  graphYearChange() {
    const viewGraphOperation = this.publicationService.getViewGraph(this.publicationId, this.graphYear);
    const downloadGraphOperation = this.publicationService.getDownloadGraph(this.publicationId, this.graphYear);
    const bookmarkGraphOperation = this.publicationService.getBookmarkGraph(this.publicationId, this.graphYear);

    forkJoin([viewGraphOperation, downloadGraphOperation, bookmarkGraphOperation]).subscribe(results => {
      this.viewGraphData = results[0];
      this.downloadGraphData = results[1];
      this.bookmarkGraphData = results[2];
      this.updateChart();
    });
  }
  // Post Publication File
  postFile(id: number, file: any) {
    const headers = new HttpHeaders({ 'Content-type': 'application/json', 
    'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
    'Cache-Control':  'no-cache, no-store, private',
    'Authorization':'Bearer '+localStorage.getItem('token') });
    const formData = new FormData();
    formData.append('file', file);
    formData.append('id', id.toString());
    this._HttpClient.post(`${environment.devApiEndpoint}/file`, formData, {headers})
      .map((res: Response) => res.json())
      .catch((error: any) => Observable.throw(error.json().error || 'Error posting the file'))
      .subscribe(result => {
        //this.hasFile = true;
      });
  }

  removeAll(type: string){
    if(type === 'start'){
      this.startDateInput.nativeElement.value = '';
    } else {
      this.endDateInput.nativeElement.value = '';
    }
    
  }

  // categoryChange() {
  //   this.publicationService.getSubCategories(this.model.categoryid['id']).subscribe(
  //     subcategories => this.subcategories = subcategories,
  //     err => {
  //       
  //     },
  //   );
  // }
}

export class PublicationViewDataSource extends DataSource<any> {
  constructor(
    private publicationService: PublicationService,
    private publicationId: number,
  ) { super(); }
  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<PublicationView[]> {
    return this.publicationService.getViews(this.publicationId);
  }


  disconnect() { }
}
